import React from 'react';

import { OAuthProvider } from '../contexts';
import { oAuthRequests } from '../requests';
import FetchApi from '../../../core/fetch-api';

export const OAuthService = (WrappedComponent) => (props) => {
  return (
    <OAuthProvider value={oAuthRequests(FetchApi)}>
      <WrappedComponent {...props} />
    </OAuthProvider>
  );
};
