/* eslint-disable no-console */
import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import consoleLog from '../../console-log/consoleLog';
import { addLogs } from '../../logs-buffer/addLogs';
import {
  removeUndefineds,
  metadata,
  newId,
  updateCreatedAt,
  updateId,
  check403,
  addToPendingRequest,
} from '../utils/pouch-db.utils';

let userPouch;
/** An object that initializes a local and remote couchdb database. And it syncs data bidirectionally from local to remote. */
const PouchInitializer = {
  init: (user, setIsSyncing, test) => {
    userPouch = user.enrollment ? user.enrollment.toLowerCase() : undefined;
    PouchDB.plugin(PouchDBFind);
    const localDBName = test
      ? process.env.REACT_APP_DBNAME
      : `${userPouch}_v${process.env.REACT_APP_VERSION}`;
    // const remoteUrl = `https://${user.userName}:${user.userKey}@${process.env.REACT_APP_CLOUDANT_HOST}/${process.env.REACT_APP_DBNAME}`;
    const remoteUrl = `https://${user.userName}:${user.userKey}@${process.env.REACT_APP_CLOUDANT_HOST}/${user.enrollment}`;
    PouchDBInstance.localDB = new PouchDB(localDBName, {
      auto_compaction: true,
      revs_limit: 10,
    });
    PouchDBInstance.remoteDB = new PouchDB(remoteUrl, {
      auto_compaction: true,
      revs_limit: 10,
      skip_setup: true,
    });

    const options = {
      live: true,
      retry: true,
      batch_size: process.env.REACT_APP_REPLICATION_BATCH_SIZE || 3000,
      since: 'now',
      limit: 3000,
      back_off_function: () =>
        process.env.REACT_APP_REPLICATION_BACK_OFF_DELAY || 5000,
      selector: {
        $or: [
          {
            _id: { $regex: userPouch },
          },
          {
            _id: '_design/mapis',
          },
        ],
      },
    };
    if (test) delete options.selector;

    const onActive = (info) => {
      consoleLog('log', `Sincronización reanudada: ${JSON.stringify(info)}`);

      setIsSyncing(true);
    };

    const onPaused = (info) => {
      consoleLog('log', `Sincronización bidireccional finalizada con éxito`);
      setIsSyncing(false);
    };

    const onDenied = (error) => {
      consoleLog(
        'error',
        `Fallo en la sincronización: ${JSON.stringify(error)}`
      );
      setIsSyncing(false);
    };

    const onError = (error) => {
      consoleLog('error', `Un error ha ocurrido: ${JSON.stringify(error)}`);
      setIsSyncing(false);
      check403();
    };

    // const onComplete = (info) => {
    //   consoleLog(
    //     'log',
    //     `Se lanzó el evento de replicación completa: ${JSON.stringify(info)}`
    //   );
    //   setIsSyncing(false);
    // };

    const onReplicateComplete = (resolve) => (info) => {
      consoleLog(
        'log',
        `[Sincronización Inicial] Sincronización bidireccional finalizada con éxito`
      );

      setIsSyncing(false);

      if (resolve) resolve(PouchDBInstance);
    };

    const synchronization = (resolve) => {
      PouchDBInstance.localDB
        .sync(PouchDBInstance.remoteDB, { ...options })
        .on('complete', onReplicateComplete(resolve))
        .on('paused', onPaused)
        .on('active', onActive)
        .on('denied', onDenied)
        .on('error', onError);
    };

    let promise;
    if (test) {
      promise = new Promise((resolve) =>
        synchronization(resolve(PouchDBInstance))
      );
    } else {
      synchronization();
    }

    if (!test) consoleLog('log', PouchDBInstance);
    return test ? promise : PouchDBInstance;
  },
};

export const PouchDBInstance = {
  localDB: {},
  remoteDB: {},
  getAll: () =>
    PouchDBInstance.localDB
      .allDocs({ include_docs: true, conflicts: false })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        consoleLog('error', 'Error al recuperar documentos(allDocs): ' + err);
      }),
  getAllInit: async (setLoading, setIsSyncing, userEnrollment) => {
    setIsSyncing(true);

    const data = await PouchDBInstance.remoteDB.allDocs({
      include_docs: true,
      conflicts: false,
    });
    // console.log('data', data.rows);

    let docs = [];
    data.rows.forEach((element) => {
      if (element.id.indexOf(userEnrollment + ":") === 0) {
        try {
          docs.push(element.doc);
        } catch (error) {
          //console.log(error, bulkGetData.results[i]);
        }
      }
    });
    // console.log('docs', docs);

    await PouchDBInstance.localDB.bulkDocs(docs, { new_edits: false });
    setLoading(false);
    setIsSyncing(false);
  },
  getById: (id) =>
    PouchDBInstance.localDB
      .get(id)
      .then((obj) => {
        return obj;
      })
      .catch((err) => {
        if (id !== '_local/favorites')
          consoleLog('error', `Error al recuperar documento(${id}): ${err}`);
      }),
  findData: (query, options) => {
    // Query filtered by userStatement
    const queryFiltered = {
      ...query,
      '@user': userPouch,
    };
    removeUndefineds(query);

    if (typeof queryFiltered === 'string') {
      return PouchDBInstance.getById(queryFiltered);
    } else {
      // console.log(query,'options',options)
      const selectorsKeys = Object.keys(queryFiltered);
      // console.log(selectorsKeys)
      let selectors;
      try {
        selectors = selectorsKeys.findQuery(queryFiltered);
      } catch (error) {
        selectors = { ...queryFiltered };
      }
      // console.log({selector: {...selectors}, ...options})
      return PouchDBInstance.localDB
        .find({ selector: { ...selectors }, ...options })
        .catch((err) => {
          consoleLog(
            'error',
            'Error al recuperar documento con findData: ' + err
          );
        });
    }
  },
  // NOT USED
  findDataInit: async (
    query,
    setLoading,
    setIsSyncing,
    haveFavorites = false
  ) => {
    setIsSyncing(true);
    removeUndefineds(query);
    if (haveFavorites) {
      PouchDBInstance.getById(query).then((res) => {
        //    if (res) {
        // console.log(res);
        //    }
      });
    }

    const selectorsKeys = Object.keys(query);
    let selectors;
    try {
      selectors = selectorsKeys.findQuery(query);
      // console.log(selectors)
    } catch (error) {
      selectors = { ...query };
    }
    const q = {
      selector: selectors,
      fields: ['_id'],
    };

    const data = await PouchDBInstance.remoteDB.find(q);

    // swap _id for id
    data.docs.map((obj) => {
      obj.id = obj._id;
      delete obj._id;
    });
    // fetch the document bodies with revision history
    const bulkGetData = await PouchDBInstance.remoteDB.bulkGet({
      docs: data.docs,
      revs: true,
    });
    let docs = [];
    for (var i in bulkGetData.results) {
      try {
        docs.push(bulkGetData.results[i]?.docs[0].ok);
      } catch (error) {
        //console.log(error, bulkGetData.results[i]);
      }
    }
    // write to local PouchDB
    await PouchDBInstance.localDB.bulkDocs(docs, { new_edits: false });
    setLoading(false);
    setIsSyncing(false);
  },
  // NOT USED
  findWithIndex: (query, options) => {
    const selectorsKeys = Object.keys(query);

    return PouchDBInstance.localDB
      .createIndex({
        index: { fields: selectorsKeys },
      })
      .then(() => {
        return PouchDBInstance.localDB.find({ selector: query, ...options });
      })
      .catch((err) => {
        consoleLog(
          'error',
          'Error al recuperar documento con findWithIndex: ' + err
        );
      });
  },
  create: (
    obj,
    action,
    openSnackbar,
    successCallback = null,
    catchCallback = null,
    addRedirection = null,
    objWithImages = null,
    uid = null
  ) => {
    removeUndefineds(obj);
    const toSend = {
      ...obj,
      ...newId(obj, userPouch),
      ...metadata(userPouch, action, obj.createdAt, uid),
    };
    const toSendWithImages = {
      ...objWithImages,
      ...newId(obj, userPouch),
      ...metadata(userPouch, action, obj.createdAt, uid),
    };
    if (!navigator.onLine) {
      if (addRedirection) addRedirection();

      if (objWithImages) addToPendingRequest(openSnackbar, toSendWithImages);
      else addToPendingRequest(openSnackbar, toSend);
    } else
      return PouchDBInstance.localDB
        .post(toSend)
        .then(
          (resp) => {
            // console.log(resp);
            // console.log('entro create then');
            if (successCallback !== null) {
              // console.log('entro create then: tiene success func');
              successCallback(resp);
            } else {
              // console.log('entro create then: no tiene success func');
              if (openSnackbar) {
                openSnackbar({
                  type: 'success',
                  visible: true,
                  title: `Creación de documento de tipo ${toSend.document}`,
                  message: `Se han guardado correctamente el documento`,
                });
              }
            }
          },
          (reason) => {
            if (catchCallback !== null) {
              // console.log('entro create reason: tiene catchCallback func');
              catchCallback();
            } else {
              if (openSnackbar) {
                // console.log('entro create reason: no tiene catchCallback func');
                if (toSend.document === 'check-list') {
                  addLogs(
                    'new check-list',
                    'WRITE_TRACES',
                    'send new check-list',
                    `Respuesta de crear check-list: error`,
                    userPouch,
                    reason
                  );
                }
                openSnackbar({
                  type: 'error',
                  visible: true,
                  title: `Creación de documento de tipo ${toSend.document}`,
                  message: `Hubo un error al crear el documento`,
                });
              }
            }
            console.error(
              `Error al crear el documento: ${JSON.stringify(reason)}`
            );
          }
        )
        .catch((err) => {
          if (catchCallback !== null) {
            // console.log('entro create catch: tiene catchCallback func');
            catchCallback();
          } else {
            // console.log('entro create catch: no tiene catchCallback func');
            if (toSend.document === 'check-list') {
              // console.log('entro create catch CL');
              addLogs(
                'new check-list',
                'WRITE_TRACES',
                'send new check-list',
                `Respuesta de crear check-list: error`,
                userPouch,
                err
              );
            }
            if (openSnackbar) {
              openSnackbar({
                type: 'error',
                visible: true,
                title: `Creación de documento de tipo ${toSend.document}`,
                message: `Hubo un error al crear el documento`,
              });
            }
          }
          console.error(`Error al crear el documento: ${JSON.stringify(err)}`);
        });
  },
  update: (obj, action, uid = null) =>
    PouchDBInstance.localDB
      .get(obj._id)
      .then((res) => {
        // console.log(res);
        if (obj.document && obj.document.includes('update_property')) {
          const send = {
            _id: res._id,
            ...obj,
            ...metadata(userPouch, action, obj.createdAt, uid),
          };
          delete send._rev;
          send._id = updateId(obj, userPouch);
          // console.log(send._id);
          return PouchDBInstance.localDB.get(send._id).then(
            (r) => {
              consoleLog('log', 'updated update_property', send);
              if (r._id === send._id) {
                const toSend = {
                  ...send,
                  createdAt: r.createdAt,
                  _rev: r._rev,
                };
                addToPendingRequest(PouchDBInstance, toSend);
                // console.log(toSend);
                return PouchDBInstance.localDB.put(toSend);
              }
            },
            (err) => {
              send.createdAt = updateCreatedAt();
              consoleLog('log', 'created update_property', send);
              addToPendingRequest(PouchDBInstance, send);
              return PouchDBInstance.localDB.post(send);
            }
          );
        }
        let send = { ...res, ...obj };
        if (send._id !== '_local/favorites')
          send = { ...send, ...metadata(userPouch, action, obj.createdAt, uid) };

        //consoleLog('log', 'updated', send);
        addToPendingRequest(PouchDBInstance, send);
        return PouchDBInstance.localDB.put(send);
      })
      .catch((err) => {
        if (obj._id.includes('_local/')) {
          PouchDBInstance.localDB
            .put(obj)
            .catch((err) =>
              consoleLog('error', 'Error al crear documento local: ' + err)
            );
        } else {
          consoleLog('error', 'Error al actualizar documento: ' + err, obj);
        }
      }),
  deleteItem: (id, rev) =>
    PouchDBInstance.localDB
      .get(id)
      .then((doc) => {
        // console.log(doc);
        doc._deleted = true;
        return PouchDBInstance.localDB.put(doc);
      })
      .catch((error) => {
        consoleLog('error', 'Error al eliminar documento: ' + error);
      }),
};

export { PouchInitializer };
