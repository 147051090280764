import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from '../../../core/hooks/use-selector';
import KPIsSubviewTemplate from '../template';
import { getDataFromView } from './utils';
import styles from '../ControlPanel/styles';
import { withStyles } from '@material-ui/core';

const View = ({ classes }) => {
  const [data, setData] = useState({});

  const history = useHistory();
  const { view } = useParams();
  const state = useSelector((s) => s.kpiData);

  useEffect(() => {
    setData(getDataFromView(view, state, () => history.push('/kpis')), classes);
  }, [view, state]);

  return <KPIsSubviewTemplate {...data} />;
};

export default withStyles(styles)(View);
