import React from 'react';

import events from 'events';
import ReactDOM from 'react-dom';

import './utils';
import './index.scss';
import './styles/styles.scss'

import * as serviceWorker from './serviceWorker';

import { AppMonitor } from './core/app-monitor';

import { Bootstrap } from './bootstrap';

import 'semantic-ui-css/semantic.min.css'

events.EventEmitter.defaultMaxListeners = 5;

ReactDOM.render(
  <AppMonitor>
    <Bootstrap />
  </AppMonitor>,
  document.getElementById('root')
);

serviceWorker.register();
