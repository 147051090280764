import {
  Box,
  Container,
  Divider,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useModalContext } from '../../../ui/modal/contexts';
import {
  PROPERTIES_FILTER_ERROR_REQUEST,
  PROPERTIES_FILTER_MODAL_DATA,
} from '../../constants';
import { usePropertiesContext } from '../../contexts';
import { propertiesListStyles } from './properties-list.styles';
import { useForm, useWatch } from 'react-hook-form';
import FetchApi from '../../../core/fetch-api';

import './properties-filter.scss';
import { getFormElements, renderButtonsForm } from './form-inputs';
import { getLocalitiesByProvince, getProvincesEffect } from './requests';
import { DialogLoading, DialogMessage } from '../../../elements/dialog';
import { Form } from 'semantic-ui-react';

export const PropertiesFilter = withStyles(propertiesListStyles)((props) => {
  const [formFields, setFormFields] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [localitiesOptions, setLocalitiesOptions] = useState([]);
  const [zipcodesOptions, setZipcodesOptions] = useState([]);

  const history = useHistory();
  const { classes } = props;

  const defaultValues = {
    commercialType: 1,
    propertyType: [],
    rentPrice: [],
    chambers: [],
    salePrice: [],
    provinceId: '',
    localityId: '',
    zipCodeId: '',
    reference: '',
    address: '',
  };

  const {
    clientId,
    actions: { getProperties },
    tableValues = {},
  } = usePropertiesContext();

  const {
    actions: { open: openModal, close: closeModal },
  } = useModalContext();

  const {
    handleSubmit,
    register,
    setValue,
    control
  } = useForm({
    defaultValues,
  });
  const onSubmit = (data) => {
    // Open loading modal
    openModal({
      Component: DialogLoading,
      view: 'urs-loading'
    });

    if (data.reference && data.reference.toString().length < 9) {
      let ref = data.reference;
      data.reference = ref.padStart(9, '0');
    }

    const isValid = (({ reference, myFootprint, propertyType, provinceId, localityId }) =>
      /\d{9}/.test(reference) || myFootprint ||
      (propertyType.length && provinceId && localityId))(data);

    if (isValid) {
      getProperties(data)
        .then((data) => {
          // Close loading modal
          closeModal();

          history.push(
            `/properties-list${clientId ? `?clientId=${clientId}` : ''}`
          );
        })
        .catch((error) => {
          openModal({
            Component: DialogMessage,
            data: PROPERTIES_FILTER_ERROR_REQUEST,
          });
        });
    } else {
      openModal({
        Component: DialogMessage,
        data: PROPERTIES_FILTER_MODAL_DATA,
      });
    }
  };

  const selectedProvince = useWatch({ control, name: 'provinceId' });
  const selectedLocality = useWatch({ control, name: 'localityId' });
  const selectedCommercialType = useWatch({ control, name: 'commercialType' });

  useEffect(() => {
    window.scrollTo(0, 0);
    getProvincesEffect(FetchApi, setProvinceOptions);
  }, []);

  useEffect(() => {
    setFormFields(
      getFormElements(classes, tableValues, {
        provinces: provinceOptions,
        localities: localitiesOptions,
        zipcodes: zipcodesOptions,
        commercialType: selectedCommercialType,
      })
    );
  }, [
    tableValues,
    provinceOptions,
    localitiesOptions,
    zipcodesOptions,
    selectedCommercialType,
  ]);

  useEffect(() => {
    getLocalitiesByProvince(
      FetchApi,
      setLocalitiesOptions,
      provinceOptions.find((p) => p.value === selectedProvince)?.label
    );
  }, [selectedProvince]);

  useEffect(() => {
    const aux = [
      ...localitiesOptions.map((l) => {
        return { ...l };
      }),
    ];
    const temp = aux.find((l) => l.value == selectedLocality);
    if (temp && temp.children)
      setZipcodesOptions(
        temp.children.map((zc) => {
          return {
            label: zc,
            value: zc,
          };
        })
      );
  }, [selectedLocality]);

  const buttons = renderButtonsForm({
    history,
    onSubmit: handleSubmit(onSubmit),
  });

  return (
    <Container className={classes.container}>
      <Typography variant="subtitle2" gutterBottom>
        ¿QUE ESTOY BUSCANDO?
      </Typography>

      <Divider className={classes.divider} />

      <Form className="properties-filter-form">
        {formFields.map((f) => {
          return <Form.Field>{f.component(register, setValue)}</Form.Field>;
        })}
      </Form>
      <Box className={classes.buttons}>
        {buttons.map(({ Button, props }, index) => (
          <Button key={index} {...props}>
            {props.label}
          </Button>
        ))}
      </Box>
    </Container>
  );
});
