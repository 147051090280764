/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from 'react';
import { Button, CircularProgress, Icon } from '@material-ui/core';
import { attachsReducer } from '../reducers';
import { IMAGE_UPLOADER_CONFIG } from '../../../_incidences/constants';
import { setPromotionData, setScreenFourData } from '../../../store/action';
import { useDispatch, useSelector } from '../../../core/hooks/use-selector';
import { sendPicturesStyles } from './image-uploader.style';
import { withStyles } from '@material-ui/core';
import { TextAreaInput } from '../../../elements/input/components/text-area-input-custom.component';
import {
  dataURLToBlob,
  getImage,
  getValidImages,
  resizeAndSaveImage,
} from '../../../shared/checklists-wizard/utils/image-uploader.utils';
import ImageUploaderImage from './image-uploader-image.component';
import ImageUploaderInputFile from './image-uploader-inputFile.component';

export const ImageUploader = withStyles(sendPicturesStyles)(
  ({ classes, ...props }) => {
    const {
      form,
      onChange,
      errorMessage,
      limit = 1,
      isCheckList = false,
    } = props;
    const [attachs, setAttachs] = useReducer(attachsReducer, {});
    const [wasDeleting, setWasDeleting] = useState(false);
    const [isLoading, setLoadingState] = useState(false);
    const [imagesToDisplay, setImagesToDisplay] = useState([]);
    const dispatch = useDispatch();

    let state = useSelector((s) => s.screenFourData);

    const setImages = async (attachments) => {
      setWasDeleting(true);
      const images = Object.entries(attachments).map(async (attachment) => {
        const imageData = await getImage(attachment[1].data);
        return { src: imageData, name: attachment[0] };
      });
      const resolvedImages = await Promise.all(images);
      setImagesToDisplay(resolvedImages);
      setLoadingState(false);

      if (isCheckList) {
        const attachments1 = [];
        const send = attachments;
        const keys = Object.values(send);
        keys.forEach((v, i) => {
          const key = Object.keys(attachments);
          attachments1.push({ ...v, name: key[i] });
        });

        setScreenFourData(dispatch, {
          send: attachments1,
          view: resolvedImages,
        });
      } else {
        onChange({ target: { name: 'attachments', value: attachs } });
      }
    };

    useEffect(() => {
      if (Object.keys(attachs).length <= 0 && state.send.length > 0) {
        if (!wasDeleting) {
          const payload = {};
          state.send.forEach((image) => {
            payload[image.name] = {
              type: image.type,
              data: image.data,
            };
          });
          setAttachs({ payload: { ...payload } });
        }
      }
      if (Object.keys(attachs).length > 0) setImages(attachs);
    }, [attachs]);

    const handleChangeUpload = (e) => {
      const files = e.target.files;
      if (files.length > 0) {
        if (files.length + Object.keys(attachs).length <= limit) {
          const images = getValidImages(files, form, limit);
          if (images.length > 0) {
            setLoadingState(true);
            images.forEach((file, i) => {
              resizeAndSaveImage(file, attachs, setAttachs, limit);
            });
          }
        }
      }
    };

    const handleRemoveAttachment = (index, imageName) => {
      setWasDeleting(true);
      const aux = [
        ...imagesToDisplay.map((i) => {
          return { ...i };
        }),
      ];
      aux.splice(index, 1);
      const aux2 = [
        ...state.send.map((i) => {
          return { ...i };
        }),
      ];
      aux2.splice(index, 1);

      setScreenFourData(dispatch, { send: aux2, view: aux });

      setImagesToDisplay(aux);

      for (let key in attachs) {
        if (key === imageName) {
          const images = imagesToDisplay.filter(
            (image) => image.name !== imageName
          );
          setImagesToDisplay(images);
          delete attachs[imageName];
          setAttachs({ payload: attachs });
        }
      }
    };

    return (
      <>
        <ImageUploaderInputFile
          classes={classes}
          imagesToDisplay={imagesToDisplay}
          attachs={attachs}
          handleChangeUpload={handleChangeUpload}
          errorMessage={errorMessage}
          isLoading={isLoading}
          limit={limit}
        />
        <div style={{ marginTop: '20px', textAlign: 'left' }}>
          {imagesToDisplay.length > 0 &&
            imagesToDisplay.map((image, i) => {
              return (
                <ImageUploaderImage
                  handleRemoveAttachment={handleRemoveAttachment}
                  image={image}
                  index={i}
                />
              );
            })}
        </div>
      </>
    );
  }
);
