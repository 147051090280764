import React from 'react';

export const IncidencesFormButtons = (props) => {
  return (
    <div
      className="actions"
      style={{
        margin: '20px 0',
        float: 'right',
      }}
    >
      {props.currentStep === 0 && (
        <span
          style={{
            marginRight: 15,
            fontWeight: '400',
            fontSize: '14px',
            color: '#005442',
          }}
          onClick={props.reset}
        >
          CANCELAR
        </span>
      )}
      {props.currentStep !== 0 && (
        <span
          style={{
            marginRight: 15,
            fontWeight: '400',
            fontSize: '14px',
            color: '#005442',
          }}
          onClick={props.prev}
        >
          ATRÁS
        </span>
      )}
      {props.currentStep !== props.steps.length - 1 ? (
        <button
          type="submit"
          style={{
            fontWeight: '400',
            color: '#FFFFFF',
            background: '#005442',
            borderRadius: 5,
            padding: '10px',
            fontSize: '14px',
            border: 'none',
          }}
        >
          SIGUIENTE
        </button>
      ) : (
        <button
          style={{
            fontWeight: '400',
            color: '#FFFFFF',
            background: '#005442',
            borderRadius: 5,
            padding: '10px',
            fontSize: '14px',
            border: 'none',
          }}
          onClick={props.submit}
        >
          ENVIAR
        </button>
      )}
    </div>
  );
};
