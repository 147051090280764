export const FORM_ELEMENTS = [
  {
    name: 'name',
    type: 'input',
    required: false,
    requiredText: false,
    label: 'Nombre​',
    props: {
      type: 'text',
      placeholder: 'Nombre',
    },
  },
  {
    name: 'phone',
    type: 'input',
    required: false,
    requiredText: false,
    label: 'Teléfono',
    rules: {
      validate: {
        isPhone: (value) => {
          if (value?.length > 0) {
            const r = /^\d{9}$/;
            return r.test(value);
          } else {
            return true;
          }
        },
      },
    },
    props: {
      type: 'number',
      placeholder: 'Teléfono',
    },
  },
  {
    name: 'email',
    type: 'input',
    required: false,
    requiredText: false,
    label: 'Email',
    rules: {
      validate: {
        isValidEmail: (value) => {
          if (value?.length > 0) {
            const r = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            return r.test(value);
          } else {
            return true;
          }
        },
      },
    },
    props: {
      type: 'email',
      placeholder: 'Email',
    },
  },
];

export const EXTRA_ELEMENTS_FORMS = [
  {
    name: 'comment',
    type: 'textarea',
    required: false,
    requiredText: false,
    label: 'Comentarios',
    props: {
      type: 'text',
      placeholder: 'Escriba su descripción o comentarios',
      maxLength: 150,
    },
  },
];
