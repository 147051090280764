import React from 'react';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import withStyles from '@material-ui/core/styles/withStyles';
import FormHelperText from '@material-ui/core/FormHelperText';

import { passwordStyles } from './password.style';

export const Password = withStyles(passwordStyles)(
  ({ classes, error, outlined, ...props }) => {
    const CustomInput = outlined
      ? OutlinedInput
      : props.filled
      ? FilledInput
      : Input;

    return (
      <FormControl
        classes={{ root: classes.root }}
        variant={outlined ? 'outlined' : props.filled ? 'filled' : ''}
      >
        <InputLabel error={error} htmlFor="password">
          {props.label || props.name}
        </InputLabel>
        <CustomInput
          id="password"
          type="password"
          value={props.value}
          onChange={props.onChange}
          error={error}
          {...props}
        />
        <FormHelperText id="my-helper-text" error={error}>
          {error ? props.errorMessage : props.fieldMessage}
        </FormHelperText>
      </FormControl>
    );
  }
);
