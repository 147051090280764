import React from 'react';
import { useDispatch, useSelector } from '../../../../core/hooks/use-selector';
import { useLocation } from 'react-router-dom';
import { DialogMessage } from '../../../../elements/dialog/components';
import { MESSAGES_STEPS, REGISTRATION_UNIT_FORMS } from '../constants';
import { setPromotionData } from '../../../../store/action';
import { useModalContext } from '../../../../ui/modal/contexts';
import WizardView from '../../../../shared/checklists-wizard/step-base.component';
import { useInitCheckList } from '../../../../shared/checklists-wizard/hooks/useInitCheckList';

const Step2RegistrationUnits = (props) => {
  const { onChangeStepHandler } = props;
  const state = useSelector((s) => s.promotionData);
  const dispatch = useDispatch();
  const history = useLocation();
  const urs = () => {
    const getUrs = {};
    if (state.urs) {
      Object.entries(state.urs).forEach((ur) => {
        getUrs[ur[0]] = true;
      });
    }

    return getUrs;
  };
  const {
    actions: { open: openModal },
  } = useModalContext();

  const FORM_ELEMENTS = REGISTRATION_UNIT_FORMS(state.item, state.childrenData);
  const { fields, handleSubmit, id: promotionId, getValues } = useInitCheckList(urs(), FORM_ELEMENTS, history);

  const getRealDataSelected = (data) => {
    let realDataSelected = [];

    Object.entries(data).forEach((value) => {
      if (value[1] === true) {
        const infoUr = state.childrenData.find(children => children.propertyId === value[0]);
        realDataSelected[value[0]] = {
          propertyId: infoUr.propertyId,
          address: infoUr.address
        };
      }
    });

    // Sobreescribe los datos de la URs que ya estaba rellenada.
    if (state.urs) {
      Object.entries(realDataSelected).map((value) => {
        Object.entries(state.urs).map((valueState) => {
          if (value[0] === valueState[0]) realDataSelected[value[0]] = valueState[1];
        });
      });
    };

    return realDataSelected;
  };

  const onSubmitHandler = (data) => {
    const dataSelected = getRealDataSelected(data);

    if (Object.keys(dataSelected).length > 0) {
      setPromotionData(dispatch, { ...state, urs: { ...dataSelected } });
      onChangeStepHandler(2);
    } else {
      openModal({
        Component: DialogMessage,
        view: 'add-checklist',
        data: {
          title: 'Debe seleccionar una UR mínimo',
          message: 'No podrás continuar hasta que se seleccione una UR.',
        },
      });
    }
  };
  const onBackWizard = () => {
    const dataSelected = getRealDataSelected(getValues());
    setPromotionData(dispatch, { ...state, urs: dataSelected });

    onChangeStepHandler(0);
  };

  return (
    <WizardView
      title={`2. Unidades Registrales`}
      fields={fields}
      onNext={handleSubmit(onSubmitHandler)}
      onBack={onBackWizard}
      haveBack={true}
      isPromotion={{ id: state.item.id, address: state.item.address, message: MESSAGES_STEPS.REGISTRATION_UNIT }}
      progress={2}
    />
  );
};

export default Step2RegistrationUnits;
