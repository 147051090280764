import React, { useContext } from 'react';

import {
  Dialog,
  withStyles,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

import { ModalContext } from '../contexts';
import { modalStyles } from './modal.styles';

export const Modal = withStyles(modalStyles)(({ classes }) => {
  const {
    actions: { close },
    isOpen,
    isFullScreen,
    type,
    Component,
    data,
    hasBackdrop,
  } = useContext(ModalContext);

  return (
    <>
      <Dialog
        open={isOpen}
        fullScreen={isFullScreen}
        className={classes[type]}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Component close={close} {...data} />
      </Dialog>

      <Backdrop className={classes.backdrop} open={hasBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
});
