import React from 'react';
import { Button, DialogActions } from '@mui/material';
import { useStyles } from './useStyles.checklistsComponent';
import classNames from 'classnames';

/** Custom buttons DatePicker */
export const ButtonsActionBar = ({
  onAccept,
  onCancel,
}) => {
  const classes = useStyles();

  return (
    <DialogActions>
      <Button variant="text"
        className={classNames(classes.buttonBase, classes.buttonBaseCancel)}
        onClick={onCancel}
      >
        Cancelar
      </Button>

      <Button variant="contained"
        className={classNames(classes.buttonBase, classes.buttonBaseConfirm)}
        onClick={onAccept}
      >
        Confirmar
      </Button>
    </DialogActions>
  );
};

/** Clean Buttons DatePicker */
export const CleanFiltersBtn = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.filterContainer}
      onClick={() => {
        props.cleanFilters();
      }}
    >
      <span className={classes.filterButton}>Limpiar</span>
    </div>
  );
};