export const FORM_CONFIG = {
  STEP_ONE: {
    VIEW: {
      title: 'Envío fotos inmueble',
      hasActionButton: true,
      hasCounter: true,
    },
  },
  STEP_TWO: {
    VIEW: {
      title: 'Envío fotos inmueble',
      hasActionButton: true,
      hasCounter: true,
      nextLabel: 'Enviar',
    },
    FIELDS: {
      name: {
        name: 'name',
        variant: 'filled',
        label: 'Nombre',
      },
      telephone: {
        name: 'phone',
        variant: 'filled',
        label: 'Teléfono',
      },
      email: {
        name: 'email',
        variant: 'filled',
        label: 'E-mail',
      },
    },
  },
};
