import React, { useContext } from 'react';

import { Container, withStyles } from '@material-ui/core';

import { UserContext } from '../../../core/user';
import { oAuthLoginStyles } from './o-auth-login.styles';
import { OAuthLoginFormComponent, OAuthRecoverComponent } from '.';
import { SnackBarContext } from '../../../ui/snack-bar/contexts';

export const OAuthLoginComponent = withStyles(oAuthLoginStyles)(
    ({ classes }) => {
        const { dispatch } = useContext(UserContext);
        const {
            actions: { open },
        } = useContext(SnackBarContext);

        // Clean cache storage
        indexedDB.clearPouchDBs();
        localStorage.clear();
        if ('caches' in window) {
            caches.keys().then((names) => {
                // Delete all the cache files        
                names.forEach(name => {
                    caches.delete(name);
                })
            })
        }

        return (
            <Container>
                <div className={classes.watermark}>{`v${process.env.REACT_APP_VERSION}`}</div>
                <div className={classes.logo}>
                    <img src="/images/logo-white.png" alt="Logo servihabitat" />
                </div>
                <OAuthLoginFormComponent dispatch={dispatch} feedback={open} />
                <OAuthRecoverComponent />
            </Container>
        );
    }
);
