export const sendPicturesStyles = {
    amountContainer: {
        padding: '10px',
        marginTop: '20px',
        backgroundColor: '#efeeee',
        textAlign: 'left',
    },
    textArea: {
        height: '80px'
    },
    infoPicturesText: {
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
        marginTop: '20px',
        marginBottom: '20px',
        padding: '1rem',
        textAlign: 'left'
    },
    inputFile: {
        display: 'none'
    },
    buttonImage: {
        height: '120px',
        width: '100%',
        border: '1px solid #005442',
        borderRadius: '4%',
        marginTop: '20px',
    },
    iconImage: {
        fontSize: '40px',
        color: '#90b8b3'
    },
    imageContainer: {
        marginTop: '20px',
        textAlign: 'left'
    },
    infAccordion: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '0 0 0 16px',
    },
    fieldsAccordion: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    errorMessage: {
        color: 'red'
    },
    selectUr: {
        width: '100%',
        marginTop: '8px',
        marginBottom: '24px',
    }
}