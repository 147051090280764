import React, { useMemo, useEffect, useReducer, useCallback } from 'react';

import { Switch, Route, useHistory, useParams } from 'react-router-dom';

import { useQuery } from '../core/hooks';
import { useTableValuesContext } from '../core';
import { usePouchDbContext } from '../core/pouch-db';

import { SendPics } from '../send-pics';
import { ActionBar } from '../ui/action-bar';
import { propertiesReducer } from './reducers';
import { PropertiesProvider } from './contexts';
import { PromoDetail, PromoProvider } from '../promo';
import { useModalContext } from '../ui/modal/contexts';
import { useLeadContext } from '../pages/leads/contexts';
import { LoadingMessageComponent } from '../shared/loading';
import { DEFAULT_PROPERTIES, ACTION_BAR } from './constants';
import { getPropertiesAction, propertiesListItemOnClick } from './actions';
import {
  updatePropertyEffect,
  propertyEffect,
  propertiesRoutingEffect,
  propertiesActionBarEffect,
} from './effects';
import {
  PropertiesDetail,
  PropertiesList,
  PropertiesFilter,
} from './components';
import FetchApi from '../core/fetch-api';
import ChecklistStepsWizard from '../pages/checklists-steps';
import { useSelector } from '../core/hooks/use-selector';

export const PropertiesRouting = () => {
  const history = useHistory();
  const lead = useLeadContext();
  const { clientId } = useQuery();
  const tableValues = useTableValuesContext();
  const { type, leadId, propertyId, promoId } = useParams();
  const { getData, setData, isSyncing } = usePouchDbContext();
  const {
    actions: { toggleBackdrop, open: openModal },
  } = useModalContext();
  const [properties, setProperties] = useReducer(propertiesReducer, {
    propertyId,
    ...DEFAULT_PROPERTIES,
  });

  const uid = useSelector((s) => s.uid);

  const getProperties = useCallback(
    getPropertiesAction(FetchApi, properties, setProperties),
    [properties]
  );

  const onClickListItem = useCallback(
    propertiesListItemOnClick(clientId, history, properties, setProperties),
    [clientId, history, properties, setProperties]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(
    propertiesActionBarEffect(
      clientId,
      properties.listItem,
      toggleBackdrop,
      openModal,
      setProperties,
      setData,
      history,
      null,
      uid
    ),
    [
      clientId,
      properties.listItem.selected,
      toggleBackdrop,
      openModal,
      setProperties,
    ]
  );

  useEffect(
    propertiesRoutingEffect(leadId, tableValues, type, setProperties, promoId),
    [leadId, tableValues, type, setProperties, promoId]
  );

  useEffect(updatePropertyEffect(propertyId, type, lead, FetchApi), []);
  useEffect(
    propertyEffect(
      getData,
      isSyncing,
      propertyId,
      lead,
      properties,
      setProperties,
      promoId,
      FetchApi
    ),
    [propertyId, properties.lead, isSyncing]
  );

  return useMemo(
    () => (
      <PropertiesProvider
        value={{ ...properties, actions: { getProperties, onClickListItem } }}
      >
        <PromoProvider value={properties.property}>
          <Route
            path={['/properties-list', '/properties-filter']}
            render={() =>
              clientId && (
                <ActionBar {...ACTION_BAR} {...properties.actionBar} />
              )
            }
          />

          <Switch>
            <Route exact path="/properties-list" component={PropertiesList} />
            <Route
              exact
              path="/properties-filter"
              component={PropertiesFilter}
            />

            <Route
              path={`${properties.prefix}/properties/${propertyId}`}
              render={({ match: { url } }) =>
                properties.property &&
                  properties.property.propertyId === propertyId ? (
                  <>
                    <Route exact path={`${url}`} component={PropertiesDetail} />
                    <Route
                      exact
                      path={`${url}/promo`}
                      component={PromoDetail}
                    />
                    <Route
                      exact
                      path={`${url}/check-list`}
                      component={ChecklistStepsWizard}
                    />
                    <Route
                      exact
                      path={`${url}/send-pics`}
                      component={SendPics}
                    />
                  </>
                ) : (
                  <LoadingMessageComponent resource="el inmueble" />
                )
              }
            />
          </Switch>
        </PromoProvider>
      </PropertiesProvider>
    ),
    [propertyId, properties, clientId, getProperties, onClickListItem]
  );
};
