import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core';

import { expansionPanelStyles } from './expansion-panel.styles';

export const ExpansionPanelComponent = withStyles(expansionPanelStyles)(
  ({ classes, title, content }) => {
    return (
      <Accordion
        className={classes.accordion}
        classes={{ expanded: classes.expanded }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordionSummary}
        >
          <Typography color="primary" variant="h6">
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            {content ? content : 'No hay datos'}
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  }
);
