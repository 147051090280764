import React from 'react';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';
import FormHelperText from '@material-ui/core/FormHelperText';
import NumberFormat from 'react-number-format';

import { inputStyles } from './input.style';

export const Input = withStyles(inputStyles)(
  ({ classes, background, color, ...props }) => {
      return (
      <FormControl className={classes.root}>
        {props.currencyformat ? (
          <NumberFormat
            fullWidth={props.fullWidth}
            name={props.name}
            value={Number(props.value)}
            variant={props.variant}
            customInput={TextField}
            suffix={'€'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            allowNegative={false}
            label={props.label || props.name}
            onValueChange={(value) => {
              if (props.onChange) {
                props.onChange({
                  target: { name: props.name, value: value.floatValue },
                });
              }
            }}
          />
        ) : (
          <TextField
            {...props}
            select={false}
            type={props.type}
            value={props.value || ''}
            variant={props.variant}
            onChange={props.onChange}
            className={classes.field}
            label={props.label || props.name}
            outlined={props.outlined ? 'true' : 'false'}
            InputLabelProps={{ className: classes.label }}
            InputProps={{ className: classes.input, ...props.InputProps }}
          />
        )}

        <FormHelperText id="my-helper-text" error={props.error}>
          {props.error ? props.errormessage : props.fieldMessage}
        </FormHelperText>
      </FormControl>
    );
  }
);
