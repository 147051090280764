export const sendPicsHandleSubmit =
  (openSnackBar, form, history, fetchApi, testJest) => (data) => {
    history.goBack();

    const attachments = [];
    if (form && form.attachments) {
      const keys = Object.keys(form.attachments);
      keys.forEach((x) =>
        attachments.push({ ...form.attachments[x], name: x })
      );
    }
    const dto = { ...form, ...data, type: 'pics', attachments: attachments };

    return fetchApi
      .sendImages('post', `/urproperty/${dto.propertyId}/send-pics`, dto)
      .then((res) => {
        if (res && res.status === 200) {
          if (testJest) testJest(res);
          openSnackBar({
            type: 'success',
            visible: true,
            title: 'Notificación fotos',
            message: 'Las fotos se han enviado correctamente',
          });
        } else if (
          res &&
          res.results &&
          res.results.message === 'Failed to fetch'
        ) {
          openSnackBar({
            type: 'error',
            visible: true,
            title: 'Error al enviar fotos',
            message: 'Necesita conexión a internet para poder enviar fotos',
          });
        } else {
          openSnackBar({
            type: 'error',
            visible: true,
            title: 'Error al enviar fotos',
            message: 'Las fotos no se han podido enviar correctamente',
          });
        }
      });
  };
