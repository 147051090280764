import React from 'react';

import { ViewHeaderComponent } from './view-header.component';
import { ViewContentComponent } from './view-content.component';

export const ViewComponent = ({
  align,
  children,
  title,
  subtitle = '',
  isEditable,
  onEdit,
}) => (
  <>
    <ViewHeaderComponent
      title={title}
      onEdit={onEdit}
      subtitle={subtitle}
      isEditable={isEditable}
    />

    <ViewContentComponent align={align} children={children} />
  </>
);
