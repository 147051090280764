import React from 'react';

import { FormControl, FormHelperText, TextField } from '@material-ui/core';

export const TextAreaInput = (props) => {
  return (
    <FormControl style={{ width: '100%' }}>
      <TextField
        {...props}
        className={props.className || ''}
        label={props.label}
        multiline
        name={props.name}
        rows={props.rows || '4'}
        variant={props.variant}
        style={{ width: '100%', ...props.style }}
        value={props.value}
        onChange={props.onChange}
        margin={props.margin}
        inputProps={props.inputProps}
      />
      <FormHelperText id="my-helper-text" error={props.error}>
        {props.error ? props.errormessage : props.fieldMessage}
      </FormHelperText>
    </FormControl>
  );
};
