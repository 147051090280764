import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { List } from '../../../shared/list';
import { usePropertiesContext } from '../../contexts';
import { getPropertiesHeaderElements } from '../../utils';
import { PROPERTIES_LIST_ITEM_CONFIG } from '../../constants';

export const PropertiesList = () => {
  const history = useHistory();
  const {
    list,
    clientId,
    listItem,
    actions: { onClickListItem },
  } = usePropertiesContext();
  const { buttons } = useCallback(
    getPropertiesHeaderElements(history, clientId),
    [history, clientId]
  );

  return (
    <List
      {...PROPERTIES_LIST_ITEM_CONFIG(clientId)}
      {...listItem}
      data={list}
      itemSize={122}
      headerConfig={{ buttons }}
      onClick={onClickListItem}
    />
  );
};
