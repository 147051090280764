import React from 'react';

import { TextInput } from '../../elements';

import { IncidencesFormButtons } from './incidences-form-buttons.component';

export const IncidencesContactForm = (props) => {
  return (
    <>
      <form
        onSubmit={props.next}
        style={{
          minHeight: '85%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <div style={{ flexGrow: 1, flexShrink: 0 }}>
          <div
            className="description"
            style={{
              padding: '10px',
              marginTop: '20px',
              background: '#efeeee',
              textAlign: 'left',
            }}
          >
            <strong>Información</strong>
            <br />
            <span>
              Estos datos no son obligatorios. En caso de dejarlos vacíos, se
              autorellenarán con los datos de contacto del API.
            </span>
          </div>
          <TextInput
            name="name"
            label="Nombre"
            value={props.state.formValues['name']}
            variant="filled"
            onChange={props.onChange('name')}
            margin="normal"
          />
          <TextInput
            type="tel"
            inputProps={{ pattern: '[0-9]*' }}
            name="phone"
            label="Teléfono"
            value={props.state.formValues['phone']}
            variant="filled"
            onChange={props.onChange('phone')}
            margin="normal"
          />
          <TextInput
            type="email"
            name="email"
            label="E-mail"
            value={props.state.formValues['email']}
            variant="filled"
            onChange={props.onChange('email')}
            margin="normal"
          />
        </div>
        <div style={{ flexShrink: 0 }}>
          <IncidencesFormButtons {...props} />
        </div>
      </form>
    </>
  );
};
