import React, { useState } from 'react';
import { Accordion, Tab, Tabs, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  tab: {
    padding: '5px 10px',
  },
  search: {
    padding: '5px 10px',
    marginLeft: 'auto',
    right: '0',
    marginTop: '6px',
  },
  favorite: {
    padding: '5px 0',
    marginTop: '6px',
    marginLeft: '5px',
  },
});

const FilterButtons = ({ visible, filterModel, config, onChangeState }) => {
  const classes = useStyles();
  const handleFilterChange = (event, newFilter) => {
    onChangeState({
      filterValue: newFilter,
    });
  };

  return (
    visible && (
      <div className={classes.root}>
        <Tabs
          value={filterModel.filterValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleFilterChange}
          aria-label="simple tabs example"
        >
          <Tab
            className={classes.tab}
            label={config.fields[0].options[0].label}
            value={config.fields[0].options[0].value}
            id="new"
          />
          <Tab
            className={classes.tab}
            label={config.fields[0].options[1].label}
            value={config.fields[0].options[1].value}
            id="in-progress"
          />
        </Tabs>
      </div>
    )
  );
};

const SearchButton = ({ expanded, setExpanded, customFilters }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.search}
      onClick={() => {
        setExpanded(!expanded);
      }}
    >
      <i
        className="material-icons tiny"
        style={{ fontSize: '24px', cursor: 'pointer' }}
      >
        {!!customFilters &&
        (customFilters.name !== '' ||
          customFilters.phone1 !== '' ||
          customFilters.assignedTo !== null) ? (
          <i
            className="material-icons tiny"
            style={{ fontSize: '24px', cursor: 'pointer', color: '#005442' }}
          >
            search
          </i>
        ) : (
          <i
            className="material-icons tiny"
            style={{ fontSize: '24px', cursor: 'pointer' }}
          >
            search
          </i>
        )}
      </i>
    </div>
  );
};

const FilterFavButton = ({ visible, state, onChangeState }) => {
  const classes = useStyles();

  return (
    visible && (
      <div
        className={classes.favorite}
        onClick={() =>
          onChangeState({
            isFavorite: !state,
          })
        }
      >
        <i
          className="material-icons tiny"
          style={{ fontSize: '24px', cursor: 'pointer' }}
        >
          {state ? 'star' : 'star_border'}
        </i>
      </div>
    )
  );
};

export const ListHeaderComponent = ({ children, props }) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <div
      style={{
        top: '55px',
        zIndex: '2',
        background: '#ffffff',
      }}
    >
      <div
        style={{
          display: 'flex',
          padding: '0px 12px',
          borderBottom: '1px solid #E0E0E0',
          alignItems: 'center',
        }}
      >
        <FilterButtons
          visible={props.filterConfig.hasHeader}
          filterModel={props.filterModel}
          config={props.filterConfig}
          onChangeState={props.onChangeFilter}
        />
        <SearchButton
          expanded={expanded}
          setExpanded={setExpanded}
          customFilters={props.customFilters}
        />

        <FilterFavButton
          visible={props.filterConfig.hasFavs}
          state={props.filterModel.isFavorite}
          onChangeState={props.onChangeFilter}
        />
      </div>

      <Accordion children={children} hidden={expanded}></Accordion>
    </div>
  );
};
