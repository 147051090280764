import React from 'react';

import { withStyles, Box, Chip } from '@material-ui/core';
import { listStyles } from './list.styles';

export const ListHeader = withStyles(listStyles)(
  ({ classes, buttons, total }) => {
    return (
      <Box className={classes.header}>
        {buttons.map(({ Button, Icon, props }, index) => (
          <Button style={{ color: '#000' }} key={index} {...props}>
            <Icon />
          </Button>
        ))}
        <Chip
          label={total === 1 ? '1 RESULTADO' : `${total} RESULTADOS`}
          variant="outlined"
          color="primary"
        />
      </Box>
    );
  }
);
