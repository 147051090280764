import React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { withStyles, Checkbox, FormControlLabel, Box, Typography } from '@material-ui/core';
import { checkBoxStyles } from './check-box.styles';

export const CheckBoxCustom = withStyles(checkBoxStyles)(
  ({ classes, address, label, functions, childrens, currentValues }) => {
    /**
     * Get checkbox length in true. 
     * @returns int
     */
    const getCurrentValuesLength = () => {
      let length = 0;
      Object.entries(currentValues).forEach((value) => {
        if (value[1] === true) length++;
      });
      return length;
    };

    // Sort URs by propertyId - ASC
    childrens = childrens.sort((a, b) => {
      if (a.propertyId < b.propertyId) return -1;
      if (a.propertyId > b.propertyId) return 1;
      return 0;
    });

    return (
      <>
        <Box sx={{
          my: 1
        }}>
          <FormControlLabel
            label={
              <Typography variant='subtitle1'>
                {label}
              </Typography>
            }
            control={
              <Checkbox
                classes={classes}
                checked={(getCurrentValuesLength()) === childrens.length}
                indeterminate={(getCurrentValuesLength()) > 0 && childrens.length !== (getCurrentValuesLength())}
                onChange={(e, value) => childrens.map((child) => functions.setValue(child.propertyId, value))}
                inputProps={{
                  'data-testid': 'checkBoxParent'
                }}
              />
            }
          />
        </Box>
        <Box>
          <Box sx={{
            mb: 1
          }}>
            <Typography variant='body1'>
              {address}
            </Typography>
          </Box>
          {childrens.map((child) => {
            return (
              <Box key={child.propertyId}>
                <FormControlLabel
                  label={
                    <>
                      {/* // TODO Cambiar por el campo child.description  */}
                      {/* <span style={{ fontWeight: 800 }}>UR: {child.propertyId}</span> | {child.description} */}
                      <span style={{ fontWeight: 800 }}>UR: {child.propertyId}</span> | {child.address}
                    </>
                  }
                  control={
                    <Checkbox
                      classes={classes}
                      checked={currentValues[child.propertyId] === true}
                      onChange={(e, value) => functions.setValue(child.propertyId, value)}
                      inputProps={{
                        'data-testid': 'checkBox-' + child.propertyId
                      }}
                    />
                  }
                />
              </Box>
            );
          })}
        </Box>
      </>
    )
  }
);
