import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core';

import { InfoItem } from '../../../elements/info-item';
import { boxContentStyles } from './box-content.styles';
import { BiddingPeriodText, CommissionDetailContent } from '../../detail/components/detail-comission.component';
export const BoxContent = withStyles(boxContentStyles)(
  ({
    classes,
    model = {},
    localData = {},
    title,
    dataCy,
    onlyTitle,
    extraTitle,
    subtitle,
    info,
    actionIcon,
    onClick = () => ({}),
  }) => (
    <Card className={classes.root}>
      <CardHeader
        onClick={onClick}
        className={classes.header}
        action={
          actionIcon ? (
            <IconButton
              data-cy={dataCy}
              aria-label="settings"
              className={classes.iconButton}
            >
              {actionIcon}
            </IconButton>
          ) : (
            ''
          )
        }
        title={
          <Typography variant="h6" className={classes.title}>
            {model[title] || title}{' '}
            {extraTitle && model[extraTitle] ? `en ${model[extraTitle]}` : ''}
          </Typography>
        }
        subheader={
          subtitle ? (
            <Typography
              variant="h5"
              className={classes.subheader}
            >{`Ref: ${model[subtitle]}`}</Typography>
          ) : (
            ''
          )
        }
      />
      {!onlyTitle ? (
        <CardContent>
          {/* isProperty = {subtitle === 'propertyId'}    */}
          <BiddingPeriodText data={localData} />
          {Array.isArray(model) ? (
            model.length ? (
              model.map((element, key) => (
                <>
                  <InfoItem
                    key={key}
                    parentKey={key}
                    model={element}
                    info={info}
                  />
                </>
              ))
            ) : (
              <Typography
                variant="body1"
                className={classes.message}
              >{`No hay ${title.toLowerCase()}`}</Typography>
            )
          ) : (
            <InfoItem model={model} info={info} />
          )}
          {subtitle === 'propertyId' && (
            <CommissionDetailContent
              comissionCommercialType={localData.commercialType}
              commissionApiImport={localData.commissionApiImport}
              commissionApiPerc={localData.commissionApiPerc}
              commissionOtherImport={localData.commissionOtherImport}
              commissionOtherPerc={localData.commissionOtherPerc}
            />
          )
          }

        </CardContent>
      ) : (
        ''
      )}
    </Card>
  )
);