import FetchApi from '../../../core/fetch-api';
import tableValues from '../../../core/json-table-values.json';

export const activitiesEffect = (setActivityList) => () => {
    FetchApi.refreshToken();
    const { data: model } = tableValues.find(
        (element) => element.type === 'activities-tree'
    );

    const labels = model.map((data) => { return data.label });

    setActivityList(labels);
};
