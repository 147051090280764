import React, { useContext, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core';

import { View } from '../../../shared/view';
import { TYPES } from '../../../ui/modal/constants';
import { PropertiesContext } from '../../contexts';
import { InfoItem } from '../../../elements/info-item';
import { PouchDBContext } from '../../../core/pouch-db';
import { SnackBarContext } from '../../../ui/snack-bar';
import { propertiesStyles } from './properties.styles';
import { ModalContext } from '../../../ui/modal/contexts';
import { PropertyForm } from '../../../_properties/form/components';
import { ExpansionPanelComponent } from '../../../elements/expansion-panel';
import { PROPERTY_BASIC_INFO, PROPERTY_TYPOLOGY_INFO } from '../../constants';
import { Incidences } from '../../../_incidences/containers/incidences.container';
import FetchApi from '../../../core/fetch-api';
import { BiddingPeriodText, CommissionDetailContent } from '../../../shared/detail/components/detail-comission.component';
import { useDispatch } from '../../../core/hooks/use-selector';

import { handleSelectItem } from '../../../pages/checklists/utils/checklist.utils';
export const PropertiesDetail = withStyles(propertiesStyles)(({ classes }) => {
    const history = useHistory();
    const {
        actions: { open, close },
    } = useContext(ModalContext);
    const { property } = useContext(PropertiesContext);
    const { getData, setData, putData } = useContext(PouchDBContext);
    const {
        actions: { open: openSnackBar },
    } = useContext(SnackBarContext);
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        FetchApi.refreshToken();
    }, []);

    return (
        <View
            align="center"
            title="Detalle del inmueble"
            subtitle={`Ref: ${property.propertyId}`}
            isEditable
            onEdit={() =>
                open({
                    Component: () => (
                        <PropertyForm
                            classes={classes}
                            model={property}
                            putData={putData}
                            closeModal={close}
                            propertyId={property.propertyId}
                            openSnackBar={openSnackBar}
                        />
                    ),
                    type: TYPES.HALF_VIEW,
                    view: 'edit-property',
                })
            }
        >
            {/* isProperty={true}   */}
            <BiddingPeriodText data={JSON.parse(JSON.stringify(property))} />
            {
                property._id && <InfoItem
                    model={property}
                    info={PROPERTY_BASIC_INFO.concat(
                        PROPERTY_TYPOLOGY_INFO[property.category]
                    )}
                />
            }
            <Divider style={{ margin: '0 -16px' }} />
            <ExpansionPanelComponent
                title="Descripción"
                content={property.description}
            />
            <Divider style={{ margin: '0 -16px' }} />
            <div style={{ paddingTop: '8px' }}>
                <CommissionDetailContent
                    comissionCommercialType={property.commercialType}
                    commissionApiImport={property.commissionApiImport}
                    commissionApiPerc={property.commissionApiPerc}
                    commissionOtherImport={property.commissionOtherImport}
                    commissionOtherPerc={property.commissionOtherPerc}
                />
            </div>
            <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={() =>
                    open({
                        type: TYPES.FULL_VIEW,
                        view: 'add-incidence',
                        Component: () => (
                            <Incidences
                                getData={getData}
                                setData={setData}
                                closeModal={close}
                                propertyId={property.propertyId}
                                openSnackBar={openSnackBar}
                            />
                        ),
                    })
                }
            >
                Incidencia de llaves
            </Button>

            <Button
                onClick={() => handleSelectItem(property, history, dispatch)}
                color="primary"
                variant="contained"
                className={classes.button}
            >
                Revisa el inmueble
            </Button>

            <Button
                onClick={() =>
                    history.push(`/properties/${property.propertyId}/send-pics`)
                }
                color="primary"
                variant="contained"
                className={classes.button}
            >
                Envía fotos
            </Button>
        </View>
    );
});
