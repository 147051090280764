import { radioOptions } from "../../../shared/checklists-wizard/constants/constants";
import { propertyStateOptions } from "../../../shared/checklists-wizard/constants/property/form-elements";
import { stressedPriceOptions, reasonToNotAccessOptions, correctPicsOptions } from "../../../shared/checklists-wizard/constants/property/form-elements";
import { FORM_ELEMENTS as securityMaintanceOptions } from "../../../shared/checklists-wizard/constants/securityMaintenance/form-elements";

export const MESSAGES_STEPS = {
    REGISTRATION_UNIT: 'Seleccione las Unidades Registrales que desee incluir en la checklist. ' +
        'En el siguiente paso tendrá que completar los datos de cada Unidad Registral seleccionada.',
    PRICE: 'Complete la información de cada una de las Unidades Registrales.'
};

export const LABELS = {
    SAVE: 'Guardar',
    DELETE: 'Borrar'
};

export const priceOptions_A = [
    {
        name: 'visited',
        type: 'radio',
        required: true,
        requiredText: false,
        rules: { required: true },
        label: 'Ha podido acceder al inmueble',
        inline: true,
        options: radioOptions,
        props: {},
    },
    {
        name: 'recommendedPrice',
        type: 'input',
        required: true,
        requiredText: false,
        rules: { required: true },
        label: 'Recomendación precio estado actual',
        props: {
            type: 'number',
        },
        currencyFormat: true
    },
    {
        name: 'commentPrice',
        type: 'textarea',
        required: true,
        requiredText: false,
        rules: { required: true },
        label: 'Comentario precio',
        props: {
            maxLength: 150,
        },
    },
    {
        name: 'urStatus',
        type: 'dropdown',
        required: true,
        requiredText: false,
        rules: { required: true },
        label: 'Estado del inmueble',
        props: {
            fluid: true,
            selection: true,
            clearable: true,
            search: true,
            options: propertyStateOptions,
        },
    },
    {
        name: 'price',
        type: 'input',
        required: true,
        requiredText: false,
        rules: { required: true },
        label: 'Precio venta',
        props: {
            type: 'number',
        },
        currencyFormat: true
    },
    {
        name: 'stressedPrice',
        type: 'radio',
        required: false,
        requiredText: false,
        label: '¿El precio publicado es normal o estresado?',
        inline: true,
        options: stressedPriceOptions,
        props: {},
    }
];

export const priceOptions_B = [
    ...priceOptions_A,
    {
        name: 'correctDescription',
        type: 'radio',
        required: false,
        requiredText: false,
        label: '¿Es correcto el descriptivo de la web?',
        info: 'En caso de no serlo, envíe la descripción propuesta desde la opción "Envío fotos" del detalle del inmueble',
        inline: true,
        options: radioOptions,
        props: {},
    },
    {
        name: 'correctPics',
        type: 'radio',
        required: false,
        requiredText: false,
        label: '¿Las fotos de la web son correctas?',
        info: 'En caso de no serlo, envíe las fotos desde la opción “Envío fotos” del detalle del inmueble',
        inline: true,
        options: correctPicsOptions,
        props: {},
    }
];

const reasonsToNotAccessOptions = [
    {
        name: 'reasonToNotAccess',
        type: 'dropdown',
        required: false,
        requiredText: false,
        label: 'Señala el motivo para no poder acceder',
        props: {
            fluid: true,
            selection: true,
            clearable: true,
            search: true,
            options: reasonToNotAccessOptions,
        },
    }
]

const securityandmaintenanceOptions = [
    {
        type: 'label',
        label: 'Seguridad y mantenimiento'
    },
    ...securityMaintanceOptions
];

export const REGISTRATION_UNIT_FORMS = (parent, urs) => {
    return [
        {
            name: 'parentCheckbox',
            type: 'checkbox',
            label: 'Seleccionar todo',
            address: parent.address,
            childrens: urs.map((ur) => { return ur })
        }
    ];
};

export const PRICE_FORMS = (ur, error, newPublish) => {
    error = error ? true : false;
    return {
        name: ur.id ?? ur.propertyId,
        type: 'accordionPromotion',
        title: ur.propertyId,
        // TODO Cambiar por el campo ur.description
        // description: ur.description, 
        description: ur.address,
        options: newPublish === '1' ? priceOptions_A : priceOptions_B,
        optionalOptions: [reasonsToNotAccessOptions, securityandmaintenanceOptions],
        error: error
    };
};

export const LIMIT = 25;