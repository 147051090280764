import React from "react";
import { Box, Typography } from "@material-ui/core";
import { getViewOfObjective } from "./utils";

export const renderBox = ({ title, description, objective, path }, history, classes) => {

    return (
        <Box
            key={title}
            className={classes.boxContainer}
            onClick={ () => history.push(path) }
        >
            <div>
                <Typography className={classes.boxTitle}>
                    {title}
                </Typography>
            </div>
            <div>
                <Typography className={classes.boxDescription}>
                    {description}
                </Typography>
            </div>
            <div>
                {getViewOfObjective(objective, classes)}
            </div>
        </Box>
    );
};