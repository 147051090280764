import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '../../../../core/hooks/use-selector';
import { useModalContext } from '../../../../ui/modal/contexts';
import { DialogMessage } from '../../../../elements/dialog/components';
import { setPromotionData } from '../../../../store/action';
import { LIMIT } from '../constants';
import WizardView from '../../../../shared/checklists-wizard/step-base.component';
import { ImageUploaderPromotion } from '../../components/image-uploader/image-uploader.component';

/** View screen 5 : Fotografías del inmueble 1/2 */
const Step5Pictures = (props) => {
  const { onChangeStepHandler, haveBack = true } = props;
  const state = useSelector((s) => s.promotionData);
  const { pictures } = state;
  const {
    actions: { open: openModal },
  } = useModalContext();
  const [haveErrors, setHaveErrors] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getErrors = (values) => {
    let errors = [];
    values.forEach((se) => {
      if (se[1].description === '' || se[1].ur === '') {
        errors.push(true)
      } 
    });

    return errors.indexOf(true) > -1
  };

  const onClickNextHandler = () => {
    if (pictures.send.length <= 0) {
      openModal({
        Component: DialogMessage,
        view: 'add-checklist',
        data: {
          title: 'Advertencia sobre las imágenes',
          message: 'No has adjuntado ninguna imagen. ¿Confirmas el envío?',
          btnConfirmLabel: 'Aceptar',
          onClose: (hasConfirm) => {
            if (hasConfirm) {
              setPromotionData(dispatch, {
                ...state,
                pictures: { send: [], view: [] },
                picturesData: {},
              });
              onChangeStepHandler(6);
            }
          },
        },
      });
    } else {
      if (getErrors(state.pictures.send)) {
        setHaveErrors(true);
      } else {
        onChangeStepHandler(5);
      }
    }
  };

  const fields = (
    <>
      <ImageUploaderPromotion
        limit={LIMIT}
        urs={state.urs}
        errors={haveErrors}
      />
    </>
  );

  return (
    <WizardView
      title="Fotografías del inmueble (1/2)"
      fields={fields}
      onNext={onClickNextHandler}
      onBack={() => onChangeStepHandler(3)}
      haveBack={haveBack}
      isPromotion={{ id: state.item.id, address: state.item.address }}
      progress={5}
    />
  );
};

export default Step5Pictures;
