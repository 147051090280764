import React, {
  useContext as context,
  useEffect,
  useMemo,
  useState,
  useReducer,
} from 'react';

import { PouchDBContext } from '../contexts';
import { PouchInitializer } from '../instance';
import { UserContext } from '../../user/contexts';
import { LoadingComponent } from '../../../shared/loading/components';
import { SnackBarContext } from '../../../ui/snack-bar/contexts';
import { deleteOldData, sendPendingRequest } from '../utils/pouch-db.utils';
import { PouchDBInstance } from '../instance/pouch-db.instance';
import { useHistory } from 'react-router-dom';
import { useDispatch } from '../../hooks/use-selector';
import { setUid } from '../../../store/action';
/** APP INITIALIZATION: SYNC
    *  It is the context that encompasses the application, for the use of offline data
    *  With INIT initialize the synchro setting to true and initializing the local databases,
    *  Check that local has the same as remote, looking first local and if there are changes it synchronizes them
    *  Bringing data from remote to local
    *  once this is done it is set to false
    *  When it is synchronized it shows the data
    *  This syncro brings all the data with global querys alldocs filtering by user again from the database
 *  and writing the changes locally
/

/**
 * USE OF THE SYNCHRO ONCE STARTED:
   * When you create or modify, go to local first, and do this synchronization from local to remote
   * and later with find it brings it filtered by user
 */
export const PouchDB = ({ children }) => {
  const { state: userState } = context(UserContext);
  const {
    actions: { open: openSnackBar },
  } = context(SnackBarContext);
  const [loading, setLoading] = useState(true);
  const [isSyncing, setIsSyncing] = useState(true);
  const [state] = useReducer(
    () => { },
    {},
    () => PouchInitializer.init(userState, setIsSyncing)
  );
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    deleteOldData(PouchDBInstance);
    sendPendingRequest(PouchDBInstance, openSnackBar, history);
    window.addEventListener('online', function (e) {
      deleteOldData(PouchDBInstance);
      sendPendingRequest(PouchDBInstance, openSnackBar, history);
      setIsSyncing(true);
    });
    // state.findDataInit(
    //   { '@user': userState.enrollment },
    //   setLoading,
    //   setIsSyncing
    // );
    state.getAllInit(setLoading, setIsSyncing, userState.enrollment);
    //cuando nos digan que se ha creado base de dato por usuario, cambiar a ella state.findDataInit({},setLoading,setIsSyncing);

    // Fill Uid context with uid local storage
    setUid(dispatch, window.localStorage.getItem('wuid_v2'));
  }, []);

  return useMemo(() => {
    return (
      <PouchDBContext.Provider
        value={{
          getData: state.findData,
          putData: state.update,
          setData: state.create,
          deleteData: state.deleteItem,
          isSyncing,
          setIsSyncing,
        }}
      >
        {loading ? (
          <LoadingComponent />
        ) : (
          <div
            className="content"
            style={{ height: '100%', minHeight: '100%', background: '#fafafa' }}
          >
            {children}
          </div>
        )}
      </PouchDBContext.Provider>
    );
  }, [userState, state, loading, isSyncing]);
};
