import React, { useState } from 'react';
import { useSelector } from '../../../../../core/hooks/use-selector';
import { TRANSLATIONS_ITEMS as picturesTranslations } from '../../../../../shared/checklists-wizard/constants/summary/picturesSummaryItem/constants';
import Picture from '../../../../checklists-steps/Picture';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, withStyles } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { picturesSummaryStyles } from './styles';
import { renderOptions } from '../../../../../shared/checklists-wizard/constants/summary/utils';
import { TextAreaInput } from '../../../../../elements/input/components/text-area-input-custom.component';
import { useConnectionStatus } from '../../../../../core/connection-status';

const PicturesSummaryItem = ({ classes }) => {
    const { pictures, picturesData } = useSelector((s) => s.promotionData);
    const [selectedAccordion, setSelectedAccordion] = useState(null);
    const { online } = useConnectionStatus();
    let imagesHTML = [];

    if (pictures.view) {
        // Resumen antes de enviar
        imagesHTML = (
            <div className={classes.imagesContainer}>
                {pictures.view.map((img, i) => (
                    <Box sx={{
                        mt: 2
                    }}
                    >
                        <Accordion
                            key={`accordionImage-${i}`}
                            data-testid={`accordionImage-${i}`}
                            expanded={selectedAccordion === `accordionImage-${i}`}
                            onChange={() => setSelectedAccordion(selectedAccordion === `accordionImage-${i}` ? '' : `accordionImage-${i}`)}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon />
                                }
                            >
                                <div>
                                    <Picture src={img.src} />
                                </div>
                                <div className={classes.summaryInf}>
                                    <Typography variant='h6'>
                                        UR: {img.ur ? img.ur : 'No seleccionado'}
                                    </Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{
                                    width: '100%'
                                }}>
                                    <div>
                                        <Typography color="textSecondary">
                                            Unidad Registral
                                        </Typography>
                                        <Typography>
                                            {img.ur}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography color="textSecondary">
                                            Descripción
                                        </Typography>
                                        <Typography>
                                            {img.description}
                                        </Typography>
                                    </div>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                ))}
            </div>
        )
    } else {
        // Resumen de checklist "in-progress"
        if (pictures.nonConnection || !online) {
            // Resumen de checklist "in-progress" - SIN CONEXIÓN
            imagesHTML = (
                <div className={classes.imagesContainer}>
                    <Typography color="textSecondary">
                        Fotografías no visibles sin conexión
                    </Typography>
                </div>
            );
        } else {
            // Resumen de checklist "in-progress" - CON CONEXIÓN
            imagesHTML = (
                <div className={classes.imagesContainer}>
                    {pictures.map((img, i) => (
                        <Box sx={{
                            mt: 2
                        }}
                        >
                            <Accordion
                                key={`accordionImage-${i}`}
                                data-testid={`accordionImage-${i}`}
                                expanded={selectedAccordion === `accordionImage-${i}`}
                                onChange={() => setSelectedAccordion(selectedAccordion === `accordionImage-${i}` ? '' : `accordionImage-${i}`)}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon />
                                    }
                                >
                                    <div>
                                        <Picture
                                            src={
                                                typeof img === 'object'
                                                    ? img.src
                                                    : `data:image/jpeg;base64,${img}`
                                            }
                                        />
                                    </div>
                                    <div className={classes.summaryInf}>
                                        <Typography variant='h6'>
                                            UR: {img.ur ? img.ur : 'No seleccionado'}
                                        </Typography>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box sx={{
                                        width: '100%'
                                    }}>
                                        <div>
                                            <Typography color="textSecondary">
                                                Unidad Registral
                                            </Typography>
                                            <Typography>
                                                {img.ur}
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography color="textSecondary">
                                                Descripción
                                            </Typography>
                                            <Typography>
                                                {img.description}
                                            </Typography>
                                        </div>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    ))}
                </div>
            )
        }
    }

    return (
        <div>
            {!Object.values(picturesData ?? {}).every(element => element === undefined) ? (
                <>
                    <Typography variant='h6'>
                        Datos de contacto
                    </Typography>
                    {renderOptions(picturesData, picturesTranslations)}
                </>
            ) : null}
            {imagesHTML}
        </div>
    );
};

export default withStyles(picturesSummaryStyles)(PicturesSummaryItem);