export const OPEN = { type: 'OPEN' };
export const CLOSE = { type: 'CLOSE' };

export const SNACKBAR_TYPES = {
  INFO: 'info',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const SNACKBAR_ANCHOR = {
  vertical: 'top',
  horizontal: 'center',
};

export const DEFAULT_STATE = {
  Component: '',
  isOpen: false,
  isFullScreen: false,
  type: SNACKBAR_TYPES.SUCCESS,
};
