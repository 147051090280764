const setData = (res, lead, setProperties, promoId, propertyId) => {
  let data = res.docs;
  data = data.map((element) => {
    if (element) element = { ...element, ...lead };

    return element;
  });
  setProperties({ payload: { property: { promoId, ...data[0], propertyId } } });
};

const propertyEffect =
  (
    getData,
    isSyncing,
    propertyId,
    lead,
    properties,
    setProperties,
    promoId,
    fetchApi
  ) =>
    () => {
      if (!isSyncing && propertyId && propertyId !== 'filter') {
        promoId = promoId || (lead || {}).promoId;

        if (properties && properties.list && properties.list.length > 0 && !promoId) {
          let data = properties.list.find((p) => p.propertyId === propertyId);
          data = { ...data, ...lead };
          setProperties({
            payload: { property: { promoId, ...data, propertyId } },
          });

          let toRequest = { document: 'properties', propertyId: propertyId };
          if (promoId) toRequest = { ...toRequest, promoId: promoId };

          if (promoId) {
            fetchApi.request('get', `/urproperty/promotion/${promoId}`);
          } else {
            fetchApi.request('get', `/urproperty/${propertyId}`);
          }
        } else {
          return getData({ document: 'properties', propertyId: propertyId }).then(
            (res) => setData(res, lead, setProperties, promoId, propertyId)
          );
        }
      }
    };

const updatePropertyEffect = (propertyId, type, lead, fetchApi) => () => {
  // TODO remove fetch Api when it is not necessary to use endpoint to update SAP
  if (type === 'promo') {
    const promoId = lead.promoId;
    fetchApi.request('get', `/urproperty/promotion/${promoId}`);
  } else {
    if (propertyId) fetchApi.request('get', `/urproperty/${propertyId}`);
  }
};

export { propertyEffect, updatePropertyEffect };
