import PromotionSummaryItem from "./promotionSummaryItem";
import RegistrationUnitsSummaryItem from "./registrationUnitsSummaryItem";
import PriceSummaryItem from "./priceSummaryItem";
import EnvironmentSummaryItem from "./environmentSumaryItem";
import PicturesSummaryItem from "./picturesSummaryItem";

export const formSummary = [
    {
        title: 'Datos de la promoción',
        activeIndex: 0,
        index: 0,
        Component: PromotionSummaryItem
    },
    {
        title: 'Unidades Registrales',
        activeIndex: 1,
        index: 1,
        Component: RegistrationUnitsSummaryItem
    },
    {
        title: 'Precio',
        activeIndex: 2,
        index: 2,
        Component: PriceSummaryItem
    },
    {
        title: 'Entorno',
        activeIndex: 3,
        index: 3,
        Component: EnvironmentSummaryItem
    },
    {
        title: 'Fotos de la promoción',
        activeIndex: 4,
        index: 4,
        Component: PicturesSummaryItem
    },
];