import React, { useState } from 'react';
import { useDispatch, useSelector } from '../../../../core/hooks/use-selector';
import { useLocation } from 'react-router-dom';
import { MESSAGES_STEPS, PRICE_FORMS } from '../constants';
import { setPromotionData } from '../../../../store/action';
import WizardView from '../../../../shared/checklists-wizard/step-base.component';
import { useInitCheckList } from '../../../../shared/checklists-wizard/hooks/useInitCheckList';
import { useModalContext } from '../../../../ui/modal/contexts';
import { DialogLoading } from '../../../../elements/dialog/components';

const Step3Price = (props) => {
    const { onChangeStepHandler } = props;
    const state = useSelector((s) => s.promotionData);
    const dispatch = useDispatch();
    const history = useLocation();
    const [ursError, setUrsError] = useState([]);

    const FORM_ELEMENTS = Object.keys(state.urs).map((ur) => PRICE_FORMS(
        state.childrenData.find(element => element.propertyId === ur),
        ursError.find(element => element === ur),
        state.screenGeneral.newPublish
    ));
    const { fields, handleSubmit, id: promotionId, getValues } = useInitCheckList(state.urs, FORM_ELEMENTS, history);
    const {
        actions: { open: openModal, close: closeModal },
    } = useModalContext();

    const getDataEmpty = (data) => {
        let filled = [];
        Object.entries(data).forEach((d) => {
            if (Object.keys(d[1]).length <= 2) filled.push(d[0]);
        });

        return filled;
    }

    /**
     * Sleep process X ms
     * @param {int} delay 
     * @returns promise
     */
    function sleep(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    const onSubmitHandler = async (data) => {
        // Get all save buttons
        const saveButtons = document.querySelectorAll('#save-price-button');
        // Click in all save buttons
        Array.from(saveButtons).forEach((saveButton) => {
            saveButton.click();
        });

        let dataEmpty = getDataEmpty(getValues());

        if (dataEmpty !== 0) {
            // Open loading modal
            openModal({
                Component: DialogLoading,
                view: 'urs-loading'
            });

            // Wait 250ms X URs selected
            await sleep(Object.entries(data).length * 250);

            // Close loading modal
            closeModal();
        }

        dataEmpty = getDataEmpty(getValues());

        if (dataEmpty.length === 0) {
            setPromotionData(dispatch, { ...state, urs: getValues() });
            onChangeStepHandler(3);
        } else {
            setUrsError(dataEmpty);
        }
    };

    const onBackWizard = () => {
        const data = getValues();
        setPromotionData(dispatch, { ...state, urs: data });

        onChangeStepHandler(1);
    };

    return (
        <WizardView
            title={`3. Precio`}
            fields={fields}
            onNext={handleSubmit(onSubmitHandler)}
            onBack={onBackWizard}
            haveBack={true}
            isPromotion={{ id: state.item.id, address: state.item.address, message: MESSAGES_STEPS.PRICE }}
            progress={3}
        />
    );
};

export default Step3Price;