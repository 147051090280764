export const activitiesFormEffect =
  (form, handleChange, tableValues, setReasons) => () => {
    if (form.motive) {
      const data = tableValues.find((element) => element.value === form.motive);

      if (data) {
        let reason = {};
        if (data.children.length) reason = data.children[0];

        setReasons(data.children);
        if (reason.value) {
          handleChange({ target: { value: reason.value, name: 'reasonId' } });
          handleChange({ target: { value: reason.label, name: 'reason' } });
        }
      }
    } else {
      setReasons([]);
    }
  };

  export const activitiesFormReasonEffect =
  (reasons, handleChange,{ target: {value} }) => {
      const data = reasons.find((element) => element.value === value);
      if (data) {
        let reason = data;
        if (reason.value) {
          handleChange({ target: { value: reason.value, name: 'reasonId' } });
          handleChange({ target: { value: reason.label, name: 'reason' } });
        }
      }

  };
