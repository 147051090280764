import React, { useContext, useEffect, useState } from 'react';

import { DetailComponent } from '../../shared/detail';
import { PouchDBContext } from '../../core/pouch-db/contexts';
import {
  promoProperties,
  updatePromoProperty,
} from '../effects/promo-properties.effect';
import { usePromoContext } from '../contexts';
import FetchApi from '../../core/fetch-api';

export const PromoDetail = () => {
  const { promoId, rentalStatus, propertyId } = usePromoContext();
  const { getData, isSyncing } = useContext(PouchDBContext);
  const [promos, setPromos] = useState([]);
  
  useEffect(() => {
    window.scrollTo(0, 0)
 }, [])

  useEffect(updatePromoProperty(promoId || propertyId, FetchApi), []);
  useEffect(
    promoProperties(
      getData,
      isSyncing,
      promoId || propertyId,
      rentalStatus,
      setPromos
    ),
    [promoId, isSyncing]
  );

  return (
    <DetailComponent
      list={promos.filter(
        (p) => p.propertyId.indexOf('P') !== 0 && p.propertyId !== propertyId
      )}
      doNotCallUpdateProperty
    />
  );
};
