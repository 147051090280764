import React from 'react';
import { Chip } from '@material-ui/core';
import classNames from 'classnames';


export const getViewOfObjective = (objective, classes) => {
    switch (objective) {
        case 'VERDE':
            return <Chip label='EN OBJETIVO' className={classNames(classes.objectiveBox, classes.objectiveGreen)} />
        case 'AMARILLO':
            return <Chip label='CERCA DE OBJETIVO' className={classNames(classes.objectiveBox, classes.objectiveYellow)} />
        case 'ROJO':
            return <Chip label='LEJOS DE OBJETIVO' className={classNames(classes.objectiveBox, classes.objectiveRed)} />
        default:
            return null
    }
};