import React from 'react';

import { ListComponent } from './list.component';
import { ListFilterComponent } from './list-filter.component';
import { ListHeaderComponent } from './list-header.component';
import { NoData } from '../../no-data';

export const SwipeableListComponent = (props) => {
  return (
    <>
      {props.hasOwnProperty('filterConfig') && (
        <ListHeaderComponent props={props}>
          <ListFilterComponent
            customFilters={props.customFilters}
            onChangeFilter={props.onChangeFilter}
            cleanFilters={props.cleanFilters}
            leadsArray={props.leadsArray}
            filterModel={props.filterModel}
          />
        </ListHeaderComponent>
      )}
      <div id="div-list-content" style={{ padding: '8px 8px 0' }}>
        {props.model?.length ? (
          <ListComponent {...props} listModel={props.model} isChecklist={props.isChecklist}/>
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
};
