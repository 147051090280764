export const listStyles = ({ spacing, palette, wrappers }) => ({
  listContainer: {
    marginBottom: spacing(1),
    width: wrappers.fullWidth,
    border: wrappers.border(palette.grey[300]),
  },
  listItemIcon: {
    minWidth: spacing(4),
    color: palette.secondary.main,
  },
  listItemBox: { display: 'flex', alignItems: 'center' },
  listItemCheckbox: { padding: '0 9px 0 0' },
  header: {
    padding: '0 4px',
    backgroundColor: palette.white.main,
  },
  pl0: { paddingLeft: spacing(0) },
  pt0: { paddingTop: spacing(0) },
  pb0: { paddingBottom: spacing(0) },
});
