import React from 'react';

import { useHistory } from 'react-router-dom';

import List from '@material-ui/core/List';
import EditIcon from '@material-ui/icons/Edit';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { viewStyles } from './view.styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(viewStyles);

export const ViewHeaderComponent = ({
  title,
  subtitle,
  isEditable,
  onEdit,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <List className={classes.root}>
      <ListItem className={classes.list}>
        <ListItemIcon className={classes.back} onClick={() => history.goBack()}>
          <IconButton className={classes.iconButton}>
            <ArrowBackIosIcon />
          </IconButton>
        </ListItemIcon>
        <ListItemText
          className={classes.title}
          primary={title}
          secondary={
            <Typography component="span" className={classes.subtitle}>
              {subtitle}
            </Typography>
          }
        />
        {isEditable && (
          <ListItemSecondaryAction className={classes.iconButton}>
            <IconButton
              onClick={onEdit}
              edge="end"
              aria-label="edit"
              className={classes.iconButton}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </List>
  );
};
