import React from 'react';

import Paper from '@material-ui/core/Paper';

import { withStyles } from '@material-ui/core';

import { noDataStyles } from './no-data.styles';

export const NoData = withStyles(noDataStyles)(({ classes }) => (
  <Paper elevation={0} className={classes.root}>
    No hay datos
  </Paper>
));
