export const Validation = {
  Required: (value) => {
    return !value
      ? {
          required: 'Este campo es requerido',
        }
      : {};
  },
  MaxLength: (length) => (value) => {
    return value.length > length
      ? {
          maxLength: `El tamaño de este campo no pued eser mayor de ${length}`,
        }
      : {};
  },
  MinLength: (length) => (value) => {
    return value.length < length
      ? {
          minLength: `El tamaño de este campo no puede ser menor de ${length}`,
        }
      : {};
  },
  Email: (value) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || !value
      ? {}
      : {
          email: 'El email no es válido',
        };
  },
  Number: (value) => {
    return value && !+value
      ? {
          number: 'Este campo no es de tipo númerico',
        }
      : {};
  },
};
