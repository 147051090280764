import React from 'react';
import { CircularProgress, DialogContent, } from '@material-ui/core';

export const DialogLoading = () => {
  return <>
    <DialogContent>
      <CircularProgress 
      size={60}
      />
    </DialogContent>
  </>
};
