import React from 'react';

import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';

export const CheckboxInput = (props) => {
  return (
    <FormControl style={{ width: '100%' }} margin={props.margin}>
      <FormLabel component="legend">{props.label}</FormLabel>
      <RadioGroup
        aria-label="position"
        name="position"
        value={props.value}
        onChange={props.onChange}
        row
      >
        <FormControlLabel
          value={props.isBool ? 'true' : '01'}
          control={<Radio color="primary" required={props.required} />}
          label="Sí"
          labelPlacement="end"
        />
        <FormControlLabel
          value={props.isBool ? 'false' : '00'}
          control={<Radio color="primary" required={props.required} />}
          label="No"
          labelPlacement="end"
        />
      </RadioGroup>
    </FormControl>
  );
};
