import { withStyles } from '@material-ui/core';
import { Icon, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useKPIsAccordion from '../../../core/hooks/use-kpis-accordion';
import styles from './styles';
import { getSubtitleOfView } from './utils';

const KPIsSubviewTemplate = ({ data, title, color, classes }) => {
  const history = useHistory();

  return (
    <div data-testid="kpiTemplate">
      <div className={classes.header}>
        <div className={classes.gradient}>
          <div className={classes.titleWrapper}>
            <IconButton
              aria-label="Atrás"
              className={classes.back}
              onClick={() => history.push('/kpis')}
            >
              <Icon className={classes.icon}>keyboard_arrow_left</Icon>
            </IconButton>
            <div className={classes.status}>
              <div>
                <Typography
                  className={classes.title}
                  variant="subtitle1"
                  align="center"
                  noWrap
                >
                  {title}
                </Typography>
                <Typography
                  className={classes.title}
                  variant="subtitle1"
                  align="center"
                  noWrap
                >
                  {color ? getSubtitleOfView(color, classes) : ''}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>{useKPIsAccordion(data, classes)}</div>
    </div>
  );
};

export default withStyles(styles)(KPIsSubviewTemplate);
