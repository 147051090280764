import React from 'react';

import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  withStyles,
} from '@material-ui/core';

import { dialogContactStyles } from './dialog-contact.styles';

export const DialogContact = withStyles(dialogContactStyles)(
  ({ classes, close }) => (
    <>
      <DialogTitle id="alert-dialog-title">Teléfono de contacto</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Si necesitas ayuda, puedes llamar al teléfono exclusivo Mapis 942 049
          002.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} className={classes.button}>
          Cerrar
        </Button>
      </DialogActions>
    </>
  )
);
