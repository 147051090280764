import React, { useState, useEffect } from 'react';

import { IncidencesFormButtons } from './incidences-form-buttons.component';
import { Button, Icon, CircularProgress } from '@material-ui/core';
import { IMAGE_UPLOADER_CONFIG } from '../constants';

const ImageUploader = (props) => {
  const [imagesToDisplay, setImagesToDisplay] = useState([]);
  const [isLoading, setLoadingState] = useState(false);

  useEffect(() => {
    const setImages = async (attachments) => {
      const images = Object.entries(attachments).map(async (attachment) => {
        const imageData = await getImage(attachment[1].data);
        return { src: imageData, name: attachment[0] };
      });
      const resolvedImages = await Promise.all(images);
      setImagesToDisplay(resolvedImages);
      setLoadingState(false);
    };
    if (props.state.formValues._attachments) {
      setImages(props.state.formValues._attachments);
    }
  }, [props.state.formValues._attachments]);

  const getImage = (file) => {
    return new Promise((resolve, reject) => {
      let result = '';
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        result = e.target.result;
        resolve(result);
      };
      reader.onerror = (e) => {
        reject(e);
      };
    });
  };

  const getValidImages = (files) => {
    let filesList = Object.values(files);
    const currentUploadedImages = Object.values(
      props.state.formValues['_attachments'] || []
    ).length;
    let availableImagesToUpload =
      IMAGE_UPLOADER_CONFIG.QUANTITY - currentUploadedImages;
    let validImages = [];
    filesList.forEach((file, i) => {
      if (availableImagesToUpload !== 0) {
        availableImagesToUpload = --availableImagesToUpload;
        const isValidType = IMAGE_UPLOADER_CONFIG.TYPES.some(
          (permitedType) => file.type.split('/')[1] === permitedType
        );
        if (isValidType) {
          validImages.push(file);
        } else {
          throwFeedback('invalidType');
        }
      } else {
        throwFeedback('invalidQuantity');
      }
    });
    return validImages;
  };

  const throwFeedback = (type) => {
    let message = '';
    if (type === 'invalidQuantity') {
      message = `No se pueden añadir más de ${IMAGE_UPLOADER_CONFIG.QUANTITY} imágenes`;
    } else if (type === 'invalidType') {
      message = `El formato de la imagen tiene que ser: ${IMAGE_UPLOADER_CONFIG.TYPES.map(
        (label) => label
      )}`;
    }
    props.actions.setStatus({
      feedback: {
        visible: true,
        type: 'error',
        title: 'Atención',
        message,
      },
    });
  };

  const dataURLToBlob = (dataURL) => {
    const BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      const parts = dataURL.split(',');
      const contentType = parts[0].split(':')[1];
      const raw = parts[1];

      return new Blob([raw], { type: contentType });
    }

    const parts = dataURL.split(BASE64_MARKER);
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;

    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  };

  const resizeAndSaveImage = (file) => {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      const image = new Image();
      image.src = readerEvent.target.result;
      image.onload = () => {
        let canvas = document.createElement('canvas'),
          max_width_size = IMAGE_UPLOADER_CONFIG.MAX_WIDTH_SIZE,
          width = image.width,
          height = image.height;
        if (width > height) {
          if (width > max_width_size) {
            height *= max_width_size / width;
            width = max_width_size;
          }
        } else {
          if (height > max_width_size) {
            width *= max_width_size / height;
            height = max_width_size;
          }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        const dataUrl = canvas.toDataURL('image/jpeg');
        const resizedImage = dataURLToBlob(dataUrl);

        const newAttachment = {
          type: file.type,
          data: resizedImage,
        };
        const attachments = {
          ...props.state.formValues['_attachments'],
          [file.name]: newAttachment,
        };
        props.onChange('_attachments', attachments)({});
      };
    };
    reader.readAsDataURL(file);
  };

  const handleChangeUpload = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const images = getValidImages(files);
      if (images.length > 0) {
        setLoadingState(true);
        images.forEach((file) => {
          resizeAndSaveImage(file);
        });
      }
    }
  };

  const handleRemoveAttachment = (name) => {
    for (let key in props.state.formValues['_attachments']) {
      if (key === name) {
        const images = imagesToDisplay.filter((image) => image.name !== name);
        setImagesToDisplay(images);
        delete props.state.formValues['_attachments'][name];
        props.onChange('_attachments', props.state.formValues['_attachments']);
      }
    }
  };

  return (
    <>
      <div
        className="description"
        style={{
          padding: '10px',
          marginTop: '20px',
          background: '#efeeee',
          textAlign: 'left',
        }}
      >
        <strong>Información</strong>
        <br />
        <div>
          <strong>Cantidad máxima: </strong>
          {IMAGE_UPLOADER_CONFIG.QUANTITY}
        </div>
        <div>
          <strong>Formatos permitidos: </strong>
          {IMAGE_UPLOADER_CONFIG.TYPES.map((label, i) => (
            <span key={i}>{label} </span>
          ))}
        </div>
      </div>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleChangeUpload}
      />
      <label htmlFor="raised-button-file">
        <Button
          component="span"
          style={{
            height: '120px',
            width: '100%',
            border: '1px solid #005442',
            borderRadius: '4%',
            marginTop: '20px',
          }}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Icon style={{ fontSize: '40px', color: '#90b8b3' }}>
              photo_camera
            </Icon>
          )}
        </Button>
      </label>
      <div style={{ marginTop: '20px', textAlign: 'left' }}>
        {imagesToDisplay.length > 0 &&
          imagesToDisplay.map((image, i) => {
            return (
              <span
                key={i}
                style={{
                  position: 'relative',
                  padding: '4px 4px 4px 0',
                  cursor: 'pointer',
                }}
                onClick={() => handleRemoveAttachment(image.name)}
              >
                <img
                  src={image.src}
                  alt="error"
                  style={{
                    height: '80px',
                    width: '80px',
                    position: 'relative',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    height: '80px',
                    width: '80px',
                    top: '-62px',
                    left: '0px',
                    backgroundColor: '#000000',
                    opacity: 0.4,
                  }}
                >
                  <Icon
                    style={{
                      color: '#FFFFFF',
                      fontSize: '34px',
                      position: 'absolute',
                      top: '20px',
                      left: '23px',
                    }}
                  >
                    delete_outline
                  </Icon>
                </div>
              </span>
            );
          })}
      </div>
    </>
  );
};

export const IncidencesAttachForm = (props) => (
  <form
    onSubmit={props.next}
    style={{
      minHeight: '85%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    }}
  >
    <div style={{ flexGrow: 1, flexShrink: 0, textAlign: 'center' }}>
      <ImageUploader {...props} />
    </div>
    <div style={{ flexShrink: 0 }}>
      <IncidencesFormButtons {...props} />
    </div>
  </form>
);
