import { FULLSCREEN_TYPES } from '../constants';

export const modalReducer = (state, { type, payload }) => {
  const isOpen = type === 'OPEN';
  const modalType = payload && payload.type;

  return {
    ...state,
    ...payload,
    isFullScreen: FULLSCREEN_TYPES.includes(modalType),
    isOpen,
  };
};
