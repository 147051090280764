import { radioOptions } from '../../constants';
import {
  bestLocalActivityOptions,
  clasificationUrbanisticOptions,
  correctPicsOptions,
  keyworksOptions,
  localTypologyOptions,
  mayorityUseOptions,
  propertyStateOptions,
  propertyTypeOptions,
  reasonToNotAccessOptions,
  saleTimeOptions,
  smokeScapeOptions,
  stressedPriceOptions,
  trustInfoOptions,
  wipOptions,
} from '../../property/form-elements';

export const TRANSLATIONS_ITEMS = [
  {
    documentKey: 'createdAt',
    type: 'input',
    summaryKey: 'Fecha de visita',
    values: '',
  },
  {
    documentKey: 'visited',
    type: 'radio',
    summaryKey: 'Ha podido acceder al inmueble',
    values: radioOptions,
  },
  {
    documentKey: 'keyWorks',
    type: 'radio',
    summaryKey:
      '¿La llave privativa de al menos una UR representativa te ha funcionado?',
    values: keyworksOptions,
  },
  {
    documentKey: 'propertyTypeCL',
    type: 'dropdown',
    summaryKey: 'Tipo de inmueble',
    values: propertyTypeOptions,
  },
  {
    documentKey: 'newPublish',
    type: 'radio',
    summaryKey: '¿Este activo es de nueva publicación?',
    values: radioOptions,
  },
  {
    documentKey: 'isCommercialPromotion',
    type: 'radio',
    summaryKey: '¿Se trata de una promoción comercial?',
    values: radioOptions,
  },
  {
    documentKey: 'recommendedPrice',
    type: 'input',
    summaryKey: 'Recomendación precio estado actual',
    values: '',
  },
  {
    documentKey: 'salePriceEstimated',
    type: 'dropdown',
    summaryKey: 'Tiempo de venta estimado con el precio propuesto',
    values: saleTimeOptions,
  },
  {
    documentKey: 'commentPrice',
    type: 'textarea',
    summaryKey: 'Comentario precio',
    values: '',
  },
  {
    documentKey: 'urStatus',
    type: 'dropdown',
    summaryKey: 'Estado del inmueble',
    values: propertyStateOptions,
  },
  {
    documentKey: 'CAPEXText',
    type: 'textarea',
    summaryKey: 'Descripción importe CAPEX',
    values: '',
  },
  {
    documentKey: 'publishedPrice',
    type: 'input',
    summaryKey: 'Precio publicado',
    values: '',
  },
  {
    documentKey: 'stressedPrice',
    type: 'radio',
    summaryKey: '¿El precio publicado es normal o estresado?',
    values: stressedPriceOptions,
  },
  {
    documentKey: 'correctPics',
    type: 'radio',
    summaryKey: '¿Las fotos de la web son correctas?',
    values: correctPicsOptions,
  },
  {
    documentKey: 'correctDescription',
    type: 'radio',
    summaryKey: '¿Es correcto el descriptivo de la web?',
    values: radioOptions,
  },
  {
    documentKey: 'reasonToNotAccess',
    type: 'dropdown',
    summaryKey: 'Estado del inmueble',
    values: reasonToNotAccessOptions,
  },
  {
    documentKey: 'propertyState',
    type: 'dropdown',
    summaryKey: 'Estado del inmueble',
    values: propertyStateOptions,
  },
  {
    documentKey: 'garages',
    type: 'radio',
    summaryKey: 'Comentario precio',
    values: radioOptions,
  },
  {
    documentKey: 'airConditioner',
    type: 'radio',
    summaryKey: 'Aire acondicionado',
    values: radioOptions,
  },
  {
    documentKey: 'elevator',
    type: 'radio',
    summaryKey: 'Ascensor',
    values: radioOptions,
  },
  {
    documentKey: 'penthouseWithTerrace',
    type: 'radio',
    summaryKey: 'Promoción áticos con terraza',
    values: radioOptions,
  },
  {
    documentKey: 'groundFloorWithGarden',
    type: 'radio',
    summaryKey: 'Bajos con jardín',
    values: radioOptions,
  },
  {
    documentKey: 'greenAreas',
    type: 'radio',
    summaryKey: 'Zonas verdes',
    values: radioOptions,
  },
  {
    documentKey: 'sportsAreas',
    type: 'radio',
    summaryKey: 'Zonas deportivas',
    values: radioOptions,
  },
  {
    documentKey: 'communalSwimmingPool',
    type: 'radio',
    summaryKey: 'Piscina comunitaria',
    values: radioOptions,
  },
  {
    documentKey: 'percentageConstruction',
    type: 'radio',
    summaryKey: '% de construcción estimado en visita',
    values: wipOptions,
  },
  {
    documentKey: 'clasificationUrbanistic',
    type: 'radio',
    summaryKey: 'Clasificación urbanística',
    values: clasificationUrbanisticOptions,
  },
  {
    documentKey: 'mayorityUse',
    type: 'radio',
    summaryKey: 'Uso permitido mayoritario',
    values: mayorityUseOptions,
  },
  {
    documentKey: 'trustInfo',
    type: 'radio',
    summaryKey:
      'La información urbanística (clasificación/uso)  que reportas está contrastada',
    values: trustInfoOptions,
  },
  {
    documentKey: 'localTypology',
    type: 'radio',
    summaryKey: 'Tipología del local',
    values: localTypologyOptions,
  },
  {
    documentKey: 'bestLocalActivity',
    type: 'radio',
    summaryKey: 'Mejor actividad para el local',
    values: bestLocalActivityOptions,
  },
  {
    documentKey: 'useChange',
    type: 'radio',
    summaryKey: '¿Valorarías un cambio de uso?',
    values: radioOptions,
  },
  {
    documentKey: 'smokeScape',
    type: 'radio',
    summaryKey: '¿Tiene salida de humos?',
    values: smokeScapeOptions,
  },
];

export const TRANSLATIONS_ITEMS_VALUATIONS = [
  {
    documentKey: 'createdAt',
    type: 'input',
    summaryKey: 'Fecha de visita',
    values: '',
  },
  {
    documentKey: 'visited',
    type: 'radio',
    summaryKey: 'Ha podido acceder al inmueble',
    values: radioOptions,
  },
  {
    documentKey: 'keyWorks',
    type: 'radio',
    summaryKey:
      '¿La llave privativa te ha funcionado?',
    values: keyworksOptions,
  },
  {
    documentKey: 'propertyTypeCL',
    type: 'dropdown',
    summaryKey: 'Tipo de inmueble',
    values: propertyTypeOptions,
  },
  {
    documentKey: 'newPublish',
    type: 'radio',
    summaryKey: '¿Este activo es de nueva publicación?',
    values: radioOptions,
  },
  {
    documentKey: 'isCommercialPromotion',
    type: 'radio',
    summaryKey: '¿Se trata de una promoción comercial?',
    values: radioOptions,
  },
  {
    documentKey: 'recommendedPrice',
    type: 'input',
    summaryKey: 'Recomendación precio estado actual',
    values: '',
  },
  {
    documentKey: 'salePriceEstimated',
    type: 'dropdown',
    summaryKey: 'Tiempo de venta estimado con el precio propuesto',
    values: saleTimeOptions,
  },
  {
    documentKey: 'commentPrice',
    type: 'textarea',
    summaryKey: 'Comentario precio',
    values: '',
  },
  {
    documentKey: 'urStatus',
    type: 'dropdown',
    summaryKey: 'Estado del inmueble',
    values: propertyStateOptions,
  },
  {
    documentKey: 'CAPEXText',
    type: 'textarea',
    summaryKey: 'Descripción importe CAPEX',
    values: '',
  },
  {
    documentKey: 'publishedPrice',
    type: 'input',
    summaryKey: 'Precio publicado',
    values: '',
  },
  {
    documentKey: 'stressedPrice',
    type: 'radio',
    summaryKey: '¿El precio publicado es normal o estresado?',
    values: stressedPriceOptions,
  },
  {
    documentKey: 'correctPics',
    type: 'radio',
    summaryKey: '¿Las fotos de la web son correctas?',
    values: correctPicsOptions,
  },
  {
    documentKey: 'correctDescription',
    type: 'radio',
    summaryKey: '¿Es correcto el descriptivo de la web?',
    values: radioOptions,
  },
  {
    documentKey: 'reasonToNotAccess',
    type: 'dropdown',
    summaryKey: 'Estado del inmueble',
    values: reasonToNotAccessOptions,
  },
  {
    documentKey: 'propertyState',
    type: 'dropdown',
    summaryKey: 'Estado del inmueble',
    values: propertyStateOptions,
  },
  {
    documentKey: 'garages',
    type: 'radio',
    summaryKey: 'Comentario precio',
    values: radioOptions,
  },
  {
    documentKey: 'airConditioner',
    type: 'radio',
    summaryKey: 'Aire acondicionado',
    values: radioOptions,
  },
  {
    documentKey: 'elevator',
    type: 'radio',
    summaryKey: 'Ascensor',
    values: radioOptions,
  },
  {
    documentKey: 'penthouseWithTerrace',
    type: 'radio',
    summaryKey: 'Promoción áticos con terraza',
    values: radioOptions,
  },
  {
    documentKey: 'groundFloorWithGarden',
    type: 'radio',
    summaryKey: 'Bajos con jardín',
    values: radioOptions,
  },
  {
    documentKey: 'greenAreas',
    type: 'radio',
    summaryKey: 'Zonas verdes',
    values: radioOptions,
  },
  {
    documentKey: 'sportsAreas',
    type: 'radio',
    summaryKey: 'Zonas deportivas',
    values: radioOptions,
  },
  {
    documentKey: 'communalSwimmingPool',
    type: 'radio',
    summaryKey: 'Piscina comunitaria',
    values: radioOptions,
  },
  {
    documentKey: 'percentageConstruction',
    type: 'radio',
    summaryKey: '% de construcción estimado en visita',
    values: wipOptions,
  },
  {
    documentKey: 'clasificationUrbanistic',
    type: 'radio',
    summaryKey: 'Clasificación urbanística',
    values: clasificationUrbanisticOptions,
  },
  {
    documentKey: 'mayorityUse',
    type: 'radio',
    summaryKey: 'Uso permitido mayoritario',
    values: mayorityUseOptions,
  },
  {
    documentKey: 'trustInfo',
    type: 'radio',
    summaryKey:
      'La información urbanística (clasificación/uso)  que reportas está contrastada',
    values: trustInfoOptions,
  },
  {
    documentKey: 'localTypology',
    type: 'radio',
    summaryKey: 'Tipología del local',
    values: localTypologyOptions,
  },
  {
    documentKey: 'bestLocalActivity',
    type: 'radio',
    summaryKey: 'Mejor actividad para el local',
    values: bestLocalActivityOptions,
  },
  {
    documentKey: 'useChange',
    type: 'radio',
    summaryKey: '¿Valorarías un cambio de uso?',
    values: radioOptions,
  },
  {
    documentKey: 'smokeScape',
    type: 'radio',
    summaryKey: '¿Tiene salida de humos?',
    values: smokeScapeOptions,
  },
];

export const TRANSLATIONS_ITEMS_PROMOTION = [
  {
    documentKey: 'createdAt',
    type: 'input',
    summaryKey: 'Fecha de visita',
    values: '',
  },
  {
    documentKey: 'visited',
    type: 'radio',
    summaryKey: '¿Has podido acceder a la promoción?',
    values: radioOptions,
  },
  {
    documentKey: 'keyWorks',
    type: 'radio',
    summaryKey:
      '¿La llave privativa de al menos una UR representativa te ha funcionado?',
    values: keyworksOptions,
  },
  {
    documentKey: 'propertyTypeCL',
    type: 'dropdown',
    summaryKey: 'Tipo de inmueble',
    values: propertyTypeOptions,
  },
  {
    documentKey: 'newPublish',
    type: 'radio',
    summaryKey: '¿La promoción es de nueva publicación?',
    values: radioOptions,
  },
  {
    documentKey: 'isCommercialPromotion',
    type: 'radio',
    summaryKey: '¿Se trata de una promoción comercial?',
    values: radioOptions,
  },
  {
    documentKey: 'recommendedPrice',
    type: 'input',
    summaryKey: 'Recomendación precio estado actual',
    values: '',
  },
  {
    documentKey: 'salePriceEstimated',
    type: 'dropdown',
    summaryKey: 'Tiempo de venta estimado con el precio propuesto',
    values: saleTimeOptions,
  },
  {
    documentKey: 'commentPrice',
    type: 'textarea',
    summaryKey: 'Comentario precio',
    values: '',
  },
  {
    documentKey: 'urStatus',
    type: 'dropdown',
    summaryKey: 'Estado del inmueble',
    values: propertyStateOptions,
  },
  {
    documentKey: 'CAPEXText',
    type: 'textarea',
    summaryKey: 'Descripción importe CAPEX',
    values: '',
  },
  {
    documentKey: 'publishedPrice',
    type: 'input',
    summaryKey: 'Precio publicado',
    values: '',
  },
  {
    documentKey: 'stressedPrice',
    type: 'radio',
    summaryKey: '¿El precio publicado es normal o estresado?',
    values: stressedPriceOptions,
  },
  {
    documentKey: 'correctPics',
    type: 'radio',
    summaryKey: '¿Las fotos de la web son correctas?',
    values: correctPicsOptions,
  },
  {
    documentKey: 'correctDescription',
    type: 'radio',
    summaryKey: '¿Es correcto el descriptivo de la web?',
    values: radioOptions,
  },
  {
    documentKey: 'reasonToNotAccess',
    type: 'dropdown',
    summaryKey: 'Estado del inmueble',
    values: reasonToNotAccessOptions,
  },
  {
    documentKey: 'propertyState',
    type: 'dropdown',
    summaryKey: 'Estado del inmueble',
    values: propertyStateOptions,
  },
  {
    documentKey: 'garages',
    type: 'radio',
    summaryKey: 'Comentario precio',
    values: radioOptions,
  },
  {
    documentKey: 'airConditioner',
    type: 'radio',
    summaryKey: 'Aire acondicionado',
    values: radioOptions,
  },
  {
    documentKey: 'elevator',
    type: 'radio',
    summaryKey: 'Ascensor',
    values: radioOptions,
  },
  {
    documentKey: 'penthouseWithTerrace',
    type: 'radio',
    summaryKey: 'Promoción áticos con terraza',
    values: radioOptions,
  },
  {
    documentKey: 'groundFloorWithGarden',
    type: 'radio',
    summaryKey: 'Bajos con jardín',
    values: radioOptions,
  },
  {
    documentKey: 'greenAreas',
    type: 'radio',
    summaryKey: 'Zonas verdes',
    values: radioOptions,
  },
  {
    documentKey: 'sportsAreas',
    type: 'radio',
    summaryKey: 'Zonas deportivas',
    values: radioOptions,
  },
  {
    documentKey: 'communalSwimmingPool',
    type: 'radio',
    summaryKey: 'Piscina comunitaria',
    values: radioOptions,
  },
  {
    documentKey: 'percentageConstruction',
    type: 'radio',
    summaryKey: '% de construcción estimado en visita',
    values: wipOptions,
  },
  {
    documentKey: 'clasificationUrbanistic',
    type: 'radio',
    summaryKey: 'Clasificación urbanística',
    values: clasificationUrbanisticOptions,
  },
  {
    documentKey: 'mayorityUse',
    type: 'radio',
    summaryKey: 'Uso permitido mayoritario',
    values: mayorityUseOptions,
  },
  {
    documentKey: 'trustInfo',
    type: 'radio',
    summaryKey:
      'La información urbanística (clasificación/uso)  que reportas está contrastada',
    values: trustInfoOptions,
  },
  {
    documentKey: 'localTypology',
    type: 'radio',
    summaryKey: 'Tipología del local',
    values: localTypologyOptions,
  },
  {
    documentKey: 'bestLocalActivity',
    type: 'radio',
    summaryKey: 'Mejor actividad para el local',
    values: bestLocalActivityOptions,
  },
  {
    documentKey: 'useChange',
    type: 'radio',
    summaryKey: '¿Valorarías un cambio de uso?',
    values: radioOptions,
  },
  {
    documentKey: 'smokeScape',
    type: 'radio',
    summaryKey: '¿Tiene salida de humos?',
    values: smokeScapeOptions,
  },
];