/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ListSortComponent } from './list-sort.component';
import { activitiesEffect } from './list-filter.effect';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

export const ListFilterComponent = ({
  customFilters,
  onChangeFilter,
  cleanFilters,
  leadsArray,
  filterModel,
}) => {
  const classes = useStyles();
  const [commercialList, setcommercialList] = useState([]);
  const [activityList, setActivityList] = useState([]);

  const handleSelectChangeAssignedTo = (event) => {
    onChangeFilter({ ['assignedTo']: event.target.innerHTML });
  };
  const handleSelectChangeTypeActivity = (event) => {
    onChangeFilter({ ['typeActivity']: event.target.innerHTML });
  };
  const handleTextChange = (event) => {
    onChangeFilter({ [event.target.id]: event.target.value });
  };

  useEffect(activitiesEffect(setActivityList), []);

  useEffect(() => {
    if (!!leadsArray) {
      let list = [];
      Object.keys(leadsArray).forEach((key) => {
        if (
          leadsArray[key].hasOwnProperty('assignedTo') &&
          !list.includes(leadsArray[key].assignedTo.toLowerCase()) &&
          leadsArray[key].assignedTo
        )
          list.push(leadsArray[key].assignedTo.toLowerCase());
      });
      setcommercialList(list);
    }
  }, [leadsArray]);

  const CleanFiltersBtn = () => {
    if (
      !!customFilters &&
      (customFilters.name || customFilters.phone1 || customFilters.assignedTo || customFilters.typeActivity)
    ) {
      return (
        <div
          style={{
            float: 'right',
            margin: '0px 8px',
          }}
          onClick={() => {
            cleanFilters();
          }}
        >
          <span style={{ cursor: 'pointer' }}>Limpiar</span>
        </div>
      );
    } else {
      return false;
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      {!!customFilters ? (
        <h3
          style={{
            margin: '0px 8px',
            float: 'left',
          }}
        >
          Filtrar
        </h3>
      ) : (
        ''
      )}

      <CleanFiltersBtn />

      <form className={classes.root} noValidate autoComplete="off">
        {!!customFilters ? (
          <Autocomplete
            id="combo-box-commercial"
            options={commercialList}
            getOptionLabel={(option) => option}
            onChange={handleSelectChangeAssignedTo}
            value={customFilters.assignedTo}
            disableClearable
            style={{ width: '95%' }}
            renderInput={(params) => (
              <TextField {...params} label="Comercial" variant="outlined" />
            )}
          />
        ) : (
          ''
        )}
        {!!customFilters ? (
          <Autocomplete
            id="combo-box-activities"
            data-testid="typeActivity"
            options={activityList}
            getOptionLabel={(option) => option}
            onChange={handleSelectChangeTypeActivity}
            value={customFilters.typeActivity}
            disableClearable
            style={{ width: '95%' }}
            renderInput={(params) => (
              <TextField {...params} label="Actividad" variant="outlined" />
            )}
          />
        ) : (
          ''
        )}
        {!!customFilters ? (
          <TextField
            label="Nombre y apellidos"
            id="name"
            variant="filled"
            size="small"
            value={customFilters.name}
            style={{
              width: '95%',
            }}
            inputProps={{
              style: {
                padding: '22px 14px 5px 14px',
              },
            }}
            onChange={handleTextChange}
          />
        ) : (
          ''
        )}
        {!!customFilters ? (
          <TextField
            label="Teléfono"
            id="phone1"
            variant="filled"
            size="small"
            value={customFilters.phone1}
            style={{
              width: '95%',
            }}
            inputProps={{
              style: {
                padding: '22px 14px 5px 14px',
              },
            }}
            onChange={handleTextChange}
          />
        ) : (
          ''
        )}
        <ListSortComponent
          filterModel={filterModel}
          onChangeFilter={onChangeFilter}
        />
      </form>
    </div>
  );
};
