import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { Container } from '@material-ui/core';

import { viewStyles } from './view.styles';

const useStyles = makeStyles(viewStyles);

export const ViewContentComponent = ({ children, align }) => {
  const classes = useStyles();

  return (
    <Container align={align} className={classes.container}>
      {children}
    </Container>
  );
};
