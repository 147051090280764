const clientEffect = (getData, isSyncing, clientId, setClient) => () => {
  if (!isSyncing) {
    return getData({ clientId: clientId, document: 'clients' }).then((res) => {
      setClient({ ...res.docs[0] });
    });
  }
};

const updateClientEffect = (clientId, fetchApi) => () => {
  // TODO remove fetch Api when it is not necessary to use endpoint to update SAP
  fetchApi.request('get', `/client/${clientId}`);
};

export { clientEffect, updateClientEffect };
