export const stepStyles = {
    amountContainer: {
        padding: '10px',
        marginTop: '20px',
        backgroundColor: '#efeeee',
        textAlign: 'left',
    },
    infoPicturesText: {
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
        marginTop: '20px',
        marginBottom: '20px',
        padding: '1rem',
        textAlign: 'left'
    },
    inputFile: {
        display: 'none'
    },
    buttonImage: {
        height: '120px',
        width: '100%',
        border: '1px solid #005442',
        borderRadius: '4%',
        marginTop: '20px',
    },
    iconImage: {
        fontSize: '40px',
        color: '#90b8b3'
    },
    imageContainer: {
        marginTop: '20px',
        textAlign: 'left'
    }
}