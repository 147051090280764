import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { withStyles, IconButton, Icon } from '@material-ui/core';
import { LABELS } from './constants/constants';
import { wizardStyles } from '../wizard/components/wizard.styles';
import { useHistory } from 'react-router-dom';
import StepperPromotionCL from '../../pages/checklistsPromotion/components/stepper/stepperPromotionCL.component';

const WizardView = withStyles(wizardStyles)(
  ({
    title,
    classes,
    fields,
    onNext,
    onBack,
    onSubmit,
    haveBack = false,
    isPromotion = null,
    progress = 0,
  }) => {
    const history = useHistory();
    return (
      <>
        {isPromotion && (
          <StepperPromotionCL
            progress={progress}
            title={isPromotion}
            steps={8}
          />
        )}
        <Container
          className={
            isPromotion ? classes.containerPromotion : classes.container
          }
        >
          <Box className={classes.header}>
            <Typography variant="h5" component="h2" className={classes.title}>
              {title}
            </Typography>

            <IconButton
              data-testid="close-cancel"
              size="small"
              color="inherit"
              onClick={() => history.goBack()}
            >
              <Icon>close</Icon>
            </IconButton>
          </Box>

          {isPromotion && isPromotion.message && (
            <Box>
              <Typography variant="body1">
                {isPromotion.message}
              </Typography>
            </Box>
          )}

          <Box flex={1}>{fields}</Box>

          <Box className={classes.buttons}>
            {haveBack === false ? (
              <Button
                data-testid="button-cancel"
                color="primary"
                onClick={() => history.goBack()}
              >
                {LABELS.CANCEL}
              </Button>
            ) : (
              <Button
                data-testid="button-back"
                color="primary"
                onClick={onBack}
              >
                {LABELS.BACK}
              </Button>
            )}
            {onSubmit !== null && (
              <Button
                data-testid="button-submit"
                id="next-send-button"
                color="primary"
                variant="contained"
                onClick={onSubmit}
              >
                {LABELS.SEND}
              </Button>
            )}
            {onNext !== null && (
              <Button
                data-testid="button-next"
                id="next-send-button"
                color="primary"
                variant="contained"
                onClick={onNext}
              >
                {LABELS.NEXT}
              </Button>
            )}
          </Box>
        </Container>
      </>
    );
  }
);
WizardView.defaultProps = {
  title: '',
  fields: null,
  onNext: null,
  // onCancel: null,
  onBack: null,
  onSubmit: null,
};
export default withStyles(wizardStyles)(WizardView);
