export const expansionPanelStyles = (theme) => ({
  accordion: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderTop: 'none',
  },
  expanded: {
    '&$expanded': {
      margin: 0,
    },
  },
});
