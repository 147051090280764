const leadsEffect = (getData, isSyncing, setModel, setFavorites) => () => {
  if (!isSyncing) {
    const favorites = getData('_local/favorites').then((res) => {
      if (res) setFavorites({ ...res.data });
    });
    const managements = getData({ document: 'managements' }).then((res) => {
      if (res) setModel(res.docs);
    });

    return { favorites, managements };
  }
};

const updateLeadsEffect = (fetchApi) => () => {
  const isLeadsView = window.location.hash === '#/leads';

  // TODO: remove fetch Api when it is not necessary to use endpoint to update SAP
  if (isLeadsView) fetchApi.request('get', '/management');
};

export { leadsEffect, updateLeadsEffect };
