import React, { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { useHistory } from 'react-router-dom';
import { withStyles, IconButton, Icon } from '@material-ui/core';

import { prev, stepCounter, next } from '../../../utils';

import { LABELS } from '../constants';
import { wizardStyles } from './wizard.styles';
import { useWizardContext } from '../contexts';

export const WizardView = withStyles(wizardStyles)(
  ({
    hasCounter,
    title,
    classes,
    children,
    onNext,
    disableOnNext,
    nextLabel,
    hidePrev,
  }) => {
    const history = useHistory();
    const [counter, setCounter] = useState('');
    const { step, setStep, total } = useWizardContext();

    const handlePrevious = () =>
      step ? setStep(prev(step)) : history.goBack();
    const handleNext = () => (onNext ? onNext() : next(step));

    useEffect(
      () => setCounter(hasCounter ? stepCounter(step, total) : ''),
      [hasCounter, step, total]
    );

    return (
      <Container className={classes.container}>
        <Box className={classes.header}>
          <Typography variant="h5" component="h2" className={classes.title}>
            {title} {hasCounter ? counter : ''}
          </Typography>

          <IconButton size="small" color="inherit" onClick={history.goBack}>
            <Icon>close</Icon>
          </IconButton>
        </Box>

        <Box flex={1}>{children}</Box>

        <Box className={classes.buttons}>
          {hidePrev ? (
            ''
          ) : (
            <Button color="primary" onClick={handlePrevious}>
              {step ? LABELS.BACK : LABELS.CANCEL}
            </Button>
          )}

          <Button
            id="next-send-button"
            color="primary"
            variant="contained"
            disabled={disableOnNext}
            onClick={handleNext}
          >
            {nextLabel || LABELS.NEXT}
          </Button>
        </Box>
      </Container>
    );
  }
);
