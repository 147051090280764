import {mapisv2PendingRequestsDB} from '../../../core/pending-requests-db'
const detailActivitiesEffect =
    (getData, isSyncing, model, setActivities) => () => {
        if (!model.clientId) {
            return;
        }
        if (!isSyncing) {
            return getData({
                document: 'activities',
                managementId: model.managementId,
            }).then(async(res) => {
                let data = res.docs;
                data = data
                    .filter((element) => !!element && !element._deleted)
                    .sort((a, b) => new Date(b.date) - new Date(a.date));
                let pendingActivities = await mapisv2PendingRequestsDB.pendingRequests.toArray();
                pendingActivities = pendingActivities.filter(act => act.document.document === 'activities' && act.document.managementId === model.managementId)
                                    .map(act => {return {...act.document}})
                setActivities([...data, ...pendingActivities]);
            });
        }
    };

const updateDetailActivitiesEffect = (managementId, clientId, fetchApi) => () => {
    // TODO remove fetch Api when it is not necessary to use endpoint to update SAP
    if (managementId)
        fetchApi.request('get', `/management/${managementId}/activity${clientId ? `?clientId=${clientId}`: ''}`);
};

export {updateDetailActivitiesEffect, detailActivitiesEffect};
