import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { getSpanishCurrentDate } from '../../utils';

export const renderOptions = (state, translationItems, classes) => {
    let value = {};
    let valueText = '';
    let translationItem = {};

    return Object.keys(state).map((k, index) => {   
        if (k.indexOf('Other') !== -1) return null;
        translationItem = translationItems.find((p) => p.documentKey === k);
        if (state[k] === 'other') {
            valueText = state[`${k}Other`];
        } else {
            if (
                translationItem?.type === 'radio' ||
                translationItem?.type === 'dropdown'
            )
                value = translationItem?.values.find((v) => v.value === state[k]);
            else value = state[k];

            if (typeof value === 'object') {
                valueText = value.label ? value.label : value.text;
            } else if (typeof value === 'string') {
                if (k === 'createdAt') {
                    valueText = getSpanishCurrentDate(value);
                }else if(k === 'recommendedPrice' || k === "publishedPrice" || k === "price"){
                    value = new Intl.NumberFormat('de-DE').format(value);
                    valueText = value && value.length > 0 ? value + '€' : 'No informado';
                } else valueText = value.length > 0 ? value : 'No informado';
            } else {
                if (typeof value !== 'undefined') valueText = 'No informado';
                else valueText = undefined;
            }
        }

        return (
            <>
                {translationItem && typeof valueText !== 'undefined' ? (
                    <Box key={translationItem.documentKey} className="itemBox">
                        <Typography color="textSecondary">
                            {translationItem?.summaryKey}
                        </Typography>
                        <Typography
                            className={valueText === 'No informado' ? 'noInformed' : ''}
                        >
                            {valueText}
                        </Typography>
                    </Box>
                ) : null}
            </>
        );
    });
};
