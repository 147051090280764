import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';

import { withStyles } from '@material-ui/core';

import { useHistory } from 'react-router-dom';

import { activitiesStyles } from './activities.styles';
import { activitiesTableValuesEffect } from '../effects';
import { ActivitiesFormComponent } from './activities-form.component';

export const ActivitiesComponent = withStyles(activitiesStyles)(
  ({ classes }) => {
    const history = useHistory();
    const [tableValues, setTableValues] = useState([]);

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    
    useEffect(activitiesTableValuesEffect(setTableValues), []);

    return (
      <Box className={classes.root}>
        <div className={classes.header}>
          <h5 className={classes.title}>Añadir nueva actividad</h5>
          <CloseIcon onClick={history.goBack} />
        </div>

        <ActivitiesFormComponent classes={classes} tableValues={tableValues} />
      </Box>
    );
  }
);
