import React from 'react';

import { Validation, withForm } from '../../../core';

import { WizardView } from '../../../shared/wizard';
import { useSendPicsForm } from '../../hooks/send-pics-form';
import { FORM_CONFIG } from '../../constants';
import { Input } from '../../../ui';
import { TextAreaInput } from '../../../elements';
import { useSendPicsContext } from '../../contexts';

export const SendPicsStepTwoFormComponent = withForm(
  {
    email: [Validation.Email],
    phone: [Validation.Number],
  },
  false
)((formProps) => {
  const { onSubmit } = useSendPicsContext();
  const { form, handleChange, handleSubmit, validators } = useSendPicsForm(
    formProps,
    onSubmit
  );

  return (
    <WizardView
      {...FORM_CONFIG.STEP_TWO.VIEW}
      hidePrev={true}
      onNext={handleSubmit}
    >
      <div
        className="description"
        style={{
          padding: '10px',
          marginTop: '20px',
          background: '#efeeee',
          textAlign: 'left',
        }}
      >
        <div>
          <strong>Información</strong>
          <p>
            Estos datos no son obligatorios. En caso de dejarlos vacíos, se
            autorellenan con los datos de contacto del API.
          </p>
        </div>
      </div>
      <br />
      <Input
        id="input-name"
        fullWidth
        value={form.name}
        onChange={handleChange}
        {...FORM_CONFIG.STEP_TWO.FIELDS.name}
      />
      <Input
        id="input-phone"
        fullWidth
        value={form.phone}
        onChange={handleChange}
        {...FORM_CONFIG.STEP_TWO.FIELDS.telephone}
        error={validators.phone.hasError}
        errormessage={validators.phone.message}
      />
      <Input
        id="input-email"
        fullWidth
        value={form.email}
        onChange={handleChange}
        {...FORM_CONFIG.STEP_TWO.FIELDS.email}
        error={validators.email.hasError}
        errormessage={validators.email.message}
      />
      <div
        className="description"
        style={{
          padding: '10px',
          marginTop: '20px',
          background: '#efeeee',
          textAlign: 'left',
        }}
      >
        <div>
          <strong>Información</strong>
          <p>
            Aquí puede introducir la descripción propuesta, o los comentarios
            que considere
          </p>
        </div>
      </div>
      <br />
      <TextAreaInput
        id="text-decription"
        name="description"
        variant="filled"
        label="Comentarios"
        value={form.description}
        onChange={handleChange}
      />
    </WizardView>
  );
});
