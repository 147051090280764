import { DialogMessage } from '../../../elements/dialog';

import { sendLogs } from '../../../core/logger';

export const openSendLogsModalAction = (openModal) => () => {
  openModal({
    Component: DialogMessage,
    view: 'send-logs',
    data: {
      title: 'Enviar datos de diagnóstico',
      message:
        'Puedes enviar los datos de diagnóstico desde este punto, cuando se te solicite desde soporte. Si tienes alguna incidencia, puedes llamar al Contact Center, y desde allí, en caso necesario, te dirigirán a esta opción',
      btnConfirmLabel: 'Enviar',
      btnCloseLabel: 'Cancelar',
      onClose: (hasConfirm) => (hasConfirm ? sendLogs() : ''),
    },
  });
};
