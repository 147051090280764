import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from '../../../../core/hooks/use-selector';
import { setScreenFiveData } from '../../../../store/action';
import { renderElementForm } from '../../utils';
import { EXTRA_ELEMENTS_FORMS, FORM_ELEMENTS } from '../../../../shared/checklists-wizard/constants/picturesData/form-elements';
import WizardView from '../../../../shared/checklists-wizard/step-base.component'
import { withStyles } from '@material-ui/styles';
import { stepStyles } from '../../stepStyles';

/** View screen 5 : Fotografías del inmueble 2/2 */
const Step5PictureData = withStyles(stepStyles)(({ classes, ...props }) => {
  const { onChangeStepHandler, haveBack = true } = props;
  const state = useSelector((s) => s.screenFiveData);

  const dispatch = useDispatch();
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: state,
  });

  const watchAllFields = watch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formElementsHTML = FORM_ELEMENTS.map((e) =>
    renderElementForm({ register, setValue }, e, watchAllFields, errors)
  );

  const onSubmitHandler = (data) => {
    setScreenFiveData(dispatch, data);
    onChangeStepHandler(5);
  };

  const onBackWizard = () => {
    onChangeStepHandler(3);
  };

  const extraFormElementsHTML = EXTRA_ELEMENTS_FORMS.map((e) =>
    renderElementForm({ register, setValue }, e, watchAllFields, errors)
  );

  const fields = (
    <>
      <div className={classes.infoPicturesText}>
        <div>
          <p>
            <strong>Información</strong>
          </p>
          <p>
            Estos datos no son obligatorios. En caso de dejarlos vacíos se
            autorellenarán con los datos de contacto del API
          </p>
        </div>
      </div>
      {formElementsHTML}
      <div className={classes.infoPicturesText}>
        <div>
          <p>
            <strong>Información</strong>
          </p>
          <p>
            Aquí puede introducir la descripción propuesta o los comentarios que
            considere
          </p>
        </div>
      </div>
      {extraFormElementsHTML}
    </>
  );

  return (
    <WizardView
      title="Fotografías del inmueble (2/2)"
      fields={fields}
      onNext={handleSubmit(onSubmitHandler)}
      onBack={onBackWizard}
      haveBack={haveBack}
    />
  );
});

export default Step5PictureData;
