import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from '../../../../core/hooks/use-selector';
import { setScreenFiveData } from '../../../../store/action';
import WizardView from '../../../../shared/checklists-wizard/step-base.component'
import { stepStyles } from '../../stepStyles';
import { LIMIT } from '../../../../send-pics/constants';
import { DialogMessage } from '../../../../elements/dialog/components';
import { useModalContext } from '../../../../ui/modal/contexts';
import { ImageUploader } from '../../../../ui/image-uploader/components/image-uploader.component';

/** View screen 4 : Fotografías del inmueble 1/2 */
const Step4Pictures = withStyles(stepStyles)(({ classes, ...props }) => {
  const { onChangeStepHandler, haveBack = true } = props;

  const state = useSelector((s) => s.screenFourData);

  const {
    actions: { open: openModal },
  } = useModalContext();

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onClickNextHandler = () => {
    if (state.send.length <= 0) {
      openModal({
        Component: DialogMessage,
        view: 'add-checklist',
        data: {
          title: 'Advertencia sobre las imágenes',
          message: 'No has adjuntado ninguna imagen. ¿Confirmas el envío?',
          btnConfirmLabel: 'Aceptar',
          onClose: (hasConfirm) => {
            if (hasConfirm) {
              setScreenFiveData(dispatch, {});
              onChangeStepHandler(5);
            }
          },
        },
      });
    } else {
      onChangeStepHandler(4);
    }
  };

  const fields = (
    <>
      <ImageUploader
        limit={LIMIT}
        isCheckList={true}
      />
    </>
  );
  return (
    <WizardView
      title="Fotografías del inmueble (1/2)"
      fields={fields}
      onNext={onClickNextHandler}
      onBack={() => onChangeStepHandler(2)}
      haveBack={haveBack}
    />
  );
});

export default Step4Pictures;
