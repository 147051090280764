import CityIcon from 'mdi-react/CityIcon';
import StoreIcon from 'mdi-react/StoreIcon';
import GarageIcon from 'mdi-react/GarageIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import FactoryIcon from 'mdi-react/FactoryIcon';
import MapPlusIcon from 'mdi-react/MapPlusIcon';
import WarehouseIcon from 'mdi-react/WarehouseIcon';
import ImageFilterHdrIcon from 'mdi-react/ImageFilterHdrIcon';
import OfficeBuildingIcon from 'mdi-react/OfficeBuildingIcon';
import HomeCityOutlineIcon from 'mdi-react/HomeCityOutlineIcon';
import MegaphoneOutlineIcon from 'mdi-react/MegaphoneOutlineIcon';
import HandshakeIcon from 'mdi-react/HandshakeIcon';
import BellIcon from 'mdi-react/BellIcon';
import PowerIcon from 'mdi-react/PowerIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import { PlaylistAddCheckOutlined } from '@material-ui/icons';

export const SKELETEON_ROWS_NUM = 5;
export const SKELETEON_BLINK_SECS = 2;

export let ICON_MAP = {
  homework: HomeCityOutlineIcon,
  apartment: DomainIcon,
  factory: FactoryIcon,
  store: StoreIcon,
  cityvIcon: CityIcon,
  checkIcon: PlaylistAddCheckOutlined,
  garage: GarageIcon,
  warehouse: WarehouseIcon,
  ground: ImageFilterHdrIcon,
  various: MapPlusIcon,
  campaign: MegaphoneOutlineIcon,
  handshake: HandshakeIcon,
  bell: BellIcon,
  power: PowerIcon,
  phone: PhoneIcon,
  default: OfficeBuildingIcon,
  leaderboardIcon: LeaderboardOutlinedIcon,
  no: 'i',
};
