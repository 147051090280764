import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { getPropertyId, renderElementForm } from '../../../../shared/checklists-wizard/utils';
import { FORM_ELEMENTS_PROPERTY } from '../../../../shared/checklists-wizard/constants/property/form-elements';
import {
  getOptionalElementsByPropertyType,
  getOptionalElementsForm,
} from './functions';
import { useSelector, useDispatch } from '../../../../core/hooks/use-selector';
import { setScreenOneData } from '../../../../store/action';

import WizardView from '../../../../shared/checklists-wizard/step-base.component';
import { useLocation } from 'react-router-dom';
import FetchApi from '../../../../core/fetch-api';

/** View screen 1 : Inmueble*/
const Step1Property = (props) => {
  const { onChangeStepHandler } = props;
  const state = useSelector((s) => s.screenOneData);
  const dispatch = useDispatch();
  const history = useLocation()

  // const propertyId = history.pathname.slice(12)

  const {
    getValues,
    register,
    setValue,
    handleSubmit,
    watch,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: state,
  });

  const watchAllFields = watch();

  useEffect(() => {
    FetchApi.refreshToken();
    window.scrollTo(0, 0);
  }, []);

  const formElementsHTML = FORM_ELEMENTS_PROPERTY.map((e) =>
    renderElementForm({ register, setValue }, e, watchAllFields, errors)
  );

  const optionalElementsHTML = getOptionalElementsForm(
    watchAllFields,
    {
      getValues,
      register,
      setValue,
      unregister,
    },
    errors
  );

  const optionalElementsByPropertyType = getOptionalElementsByPropertyType(
    watchAllFields,
    {
      getValues,
      register,
      setValue,
      unregister,
    },
    errors
  );

  const onSubmitHandler = (data) => {
    setScreenOneData(dispatch, data);
    onChangeStepHandler(data.visited === '2' ? 2 : 1);
  };

  const fields = (
    <>
      {formElementsHTML}
      {optionalElementsHTML}
      {optionalElementsByPropertyType}
    </>
  );

  return (
    <WizardView
      title={`Inmueble ${getPropertyId(history.pathname)}`}
      fields={fields}
      onNext={handleSubmit(onSubmitHandler)}
      haveBack={false}
    />
  );
};

export default Step1Property;
