import React from 'react';

import { FormItem, SelectInput, CheckboxInput } from '../../../elements';

const NUM_LIMIT = 10;
const NUM_OPTIONS = [...Array(NUM_LIMIT)].map((item, i) => {
  return { value: i, label: i };
});

export const Category01 = (props) => {
  return (
    <FormItem>
      <SelectInput
        style={{ verticalAlign: 'middle' }}
        label="Núm. habitaciones"
        value={props.state['chambers'] || ''}
        options={NUM_OPTIONS}
        optionValue="value"
        optionLabel="label"
        onChange={props.handleChange('chambers')}
        variant="filled"
      />
    </FormItem>
  );
};

export const Category02 = (props) => {
  return <></>;
};

export const Category03 = (props) => {
  return (
    <>
      <FormItem>
        <CheckboxInput
          label="Falso techo"
          value={props.state['fakeCeiling']}
          onChange={props.handleChange('fakeCeiling')}
          variant="filled"
        />
      </FormItem>
      <FormItem>
        <CheckboxInput
          label="Suelo técnico"
          value={props.state['technicalFloor']}
          onChange={props.handleChange('technicalFloor')}
          variant="filled"
        />
      </FormItem>
      <FormItem>
        <SelectInput
          label="Dist. num. plantas"
          value={props.state['localNumberPlants'] || ''}
          options={NUM_OPTIONS}
          optionValue="value"
          optionLabel="label"
          onChange={props.handleChange('localNumberPlants')}
          variant="filled"
        />
      </FormItem>
    </>
  );
};

export const Category04 = (props) => {
  return (
    <>
      <FormItem>
        <CheckboxInput
          label="Falso techo"
          value={props.state['fakeCeiling']}
          onChange={props.handleChange('fakeCeiling')}
          variant="filled"
        />
      </FormItem>
      <FormItem>
        <CheckboxInput
          label="Suelo técnico"
          value={props.state['technicalFloor']}
          onChange={props.handleChange('technicalFloor')}
          variant="filled"
        />
      </FormItem>
      <FormItem>
        <SelectInput
          label="Dist. num. plantas"
          value={props.state['localNumberPlants'] || ''}
          options={NUM_OPTIONS}
          optionValue="value"
          optionLabel="label"
          onChange={props.handleChange('localNumberPlants')}
          variant="filled"
        />
      </FormItem>
    </>
  );
};

export const Category05 = (props) => {
  return (
    <FormItem>
      <SelectInput
        label="Muelles de carga"
        value={props.state['loadDock'] || ''}
        options={NUM_OPTIONS}
        optionValue="value"
        optionLabel="label"
        onChange={props.handleChange('loadDock')}
        variant="filled"
      />
    </FormItem>
  );
};

export const Category06 = (props) => {
  return <></>;
};

export const Category07 = (props) => {
  return <></>;
};

export const Category08 = (props) => {
  return <></>;
};
