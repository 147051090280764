import { getCurrentDate } from './utils';

/** All actions for reducer */
export const actionTypes = {
  SET_SCREEN_1_DATA: 'SET_SCREEN_1_DATA',
  SET_SCREEN_2_DATA: 'SET_SCREEN_2_DATA',
  SET_SCREEN_3_DATA: 'SET_SCREEN_3_DATA',
  SET_SCREEN_4_DATA: 'SET_SCREEN_4_DATA',
  SET_SCREEN_5_DATA: 'SET_SCREEN_5_DATA',
  SET_KPI_DATA: 'SET_KPI_DATA',
  SET_TYPE_LOGS: 'SET_TYPE_LOGS',
  SET_UID: 'SET_UID',
  SET_PROMOTION_DATA: 'SET_PROMOTION_DATA'
};
/** Values initial with first render (reducer)*/
export const initialStateMapis = {
  screenOneData: { createdAt: getCurrentDate() },
  screenTwoData: {},
  screenThreeData: {},
  screenFourData: { send: [], view: [] },
  screenFiveData: {},
  kpiData: {},
  typeLogs: false,
  uid: '',
  promotionData: {
    screenGeneral: { createdAt: getCurrentDate() },
    urs: {},
    environment: {},
    pictures: { send: [], view: [] },
    picturesData: {}
  }
};

/** Reducer checklists : This function is called from the component indicating which actionTypes it wants to modify or access.
 * This reducer has two children type and payload:
 * action : This action has two children type and payload. And it tells you what data to replace in the payload
 * state: the first rendering is not given the value of the state and takes as value the initial state */
export const mapisReducer = (state = initialStateMapis, action) => {
  switch (action.type) {
    case actionTypes.SET_SCREEN_1_DATA:
      return { ...state, screenOneData: { ...action.payload } };
    case actionTypes.SET_SCREEN_2_DATA:
      return { ...state, screenTwoData: { ...action.payload } };
    case actionTypes.SET_SCREEN_3_DATA:
      return { ...state, screenThreeData: { ...action.payload } };
    case actionTypes.SET_SCREEN_4_DATA:
      return { ...state, screenFourData: { ...action.payload } };
    case actionTypes.SET_SCREEN_5_DATA:
      return { ...state, screenFiveData: { ...action.payload } };
    case actionTypes.SET_KPI_DATA:
      return { ...state, kpiData: { ...action.payload } };
    case actionTypes.SET_TYPE_LOGS:
      return { ...state, typeLogs: { ...action.payload } };
    case actionTypes.SET_UID:
      return { ...state, uid: action.payload };
    case actionTypes.SET_PROMOTION_DATA:
      return { ...state, promotionData: { ...action.payload } }
    default:
      return state;
  }
};
