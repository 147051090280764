import React, { useReducer, useState } from 'react';

import { modalReducer } from './reducers';
import { ModalProvider } from './contexts';
import { DEFAULT_STATE } from './constants';
import { ModalComponent } from './components';

export const Modal = ({ children }) => {
  const [hasBackdrop, setHasBackdrop] = useState(false);
  const [state, dispatch] = useReducer(modalReducer, DEFAULT_STATE);

  const actions = {
    toggleBackdrop: (toggle) => setHasBackdrop(toggle),
    open: (payload) => dispatch({ payload, type: 'OPEN' }),
    close: () => dispatch({ payload: { type: state.type }, type: 'CLOSE' }),
  };

  return (
    <ModalProvider value={{ actions, ...state, hasBackdrop }}>
      <ModalComponent />
      {children}
    </ModalProvider>
  );
};
