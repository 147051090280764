import LogsBuffer from './logs-buffer';
/** Function to send logs and be analyzed by client */
export const addLogs = (event, actionId, url, action, apiId, data) => {
  LogsBuffer.sendLogsReqRes({
    operationName: 'SetLogDnaEvent',
    status: 200,
    variables: {
      dto: {
        data: data, // info logs
        type: 'Frontend',
        event: event, // description action
        actionId: actionId, // enum data
        action: action, // view or title
        createdAt: Date.now(),
        url: url, // url view, description url
        apiId: apiId, // id api
      },
    },
  });
};
