import React from 'react';
import { Button, CircularProgress, Icon } from '@material-ui/core';
const ImageUploaderInputFile = ({
  classes,
  imagesToDisplay,
  attachs,
  handleChangeUpload,
  errorMessage,
  isLoading,
  limit,
}) => {
  return (
    <>
      <div className={classes.amountContainer}>
        <div>
          <strong>Cantidad: </strong>
          {imagesToDisplay.length} / {limit}
        </div>
      </div>
      {Object.keys(attachs).length < limit ? (
        <input
          accept="image/*"
          data-testid="update-image"
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple
          type="file"
          name="files[]"
          onChange={handleChangeUpload}
        />
      ) : (
        ''
      )}
      <label htmlFor="raised-button-file">
        <Button
          component="span"
          style={{
            height: '120px',
            width: '100%',
            border: '1px solid #005442',
            borderRadius: '4%',
            marginTop: '20px',
          }}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Icon style={{ fontSize: '40px', color: '#90b8b3' }}>
              photo_camera
            </Icon>
          )}
        </Button>
      </label>
      {errorMessage ? (
        <div
          style={{
            margin: 0,
            fontSize: '0.75rem',
            marginTop: '3px',
            textAlign: 'left',
            fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
            fontWeight: 400,
            lineHeight: 1.66,
            letterSpacing: '0.03333em',
            color: '#f44336',
          }}
        >
          {errorMessage}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default ImageUploaderInputFile;
