import React from 'react';
import { useSelector } from '../../../../../core/hooks/use-selector';
import { withStyles, Box } from '@material-ui/core';
import styles from './styles';
import { Check } from '@material-ui/icons';

const RegistrationUnitsSummaryItem = ({ classes }) => {
    const { urs } = useSelector((s) => s.promotionData);

    return urs
        ? Object.values(urs).map((ur) => {
            return <Box key={ur.propertyId} className={classes.boxContainer}>
                <Check className={classes.checkIcon} />
                <span className={classes.spanId}>UR: {ur.propertyId} </span>
                <span className={classes.spanAddress}>{ur.address}</span>
            </Box>
        })
        : null;
};

export default withStyles(styles)(RegistrationUnitsSummaryItem);