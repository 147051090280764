export const oAuthLoginStyles = (theme) => ({
    logo: {
        marginTop: -50,
        marginBottom: 50,
        textAlign: theme.wrappers.alignCenter,
    },
    watermark: {
        position: 'absolute',
        bottom: 0,
        right: 6,
        fontWeight: 600,
        color: '#ffffff',
        fontSize: 14,
        opacity: 0.2,
    }
});
