import { uuidv4 } from '../../utils';
import { Validation } from '../../core/validations/validators';

export const HAS_FIRMED_VALUES = [
  { label: 'Sí', value: 1 },
  { label: 'No', value: 0 },
];

export const ACTIVITIES_FORM = {
  motive: [Validation.Required],
  reason: [],
  comment: [],
  hasFirmed: [],
  attachments: [],
};

export const parsedData = (tableValues, model) => (data) => {
  const tableValue = tableValues.find(
    (element) => element.value === data.motive
  );
  const activityId = uuidv4();

  return {
    activityId,
    comment: data.comment,
    document: 'activities',
    motiveId: data.motive,
    clientId: model.clientId,
    _id: activityId,
    attachments:
      data.attachments && data.attachments.length ? data.attachments : [],
    managementId: model.managementId,
    reason: data.reason,
    reasonId: data.reasonId,
    name: tableValue ? tableValue.label : '',
    hasFirmed: data.motive === '06' ? !!data.hasFirmed : null,
  };
};

export const NOT_INTERESTED_MOTIVE = '05';
export const CLOSE_MOTIVES = ['05'];
export const RESERVE_MOTIVES_OR_THINKING = ['02', '11'];
export const NOT_INTERESTED_CLOSE_REASONS = ['01', '02', '03', '04'];
export const isCaixa = (propertyId) =>
  !!propertyId.match(/^(0731|0001|9001|1754|1724)/);
