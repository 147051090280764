import { actionTypes } from './reducer';

/** Actions called through the dispatch, one per screen of the checkLists - Valuation.
 *  They receive the actionTypes of the reducer as a parameter and modify the payload with the data of the form to change. */

export const setScreenOneData = (dispatch, data) => {
  dispatch({
    type: actionTypes.SET_SCREEN_1_DATA,
    payload: data,
  });
};

export const setScreenTwoData = (dispatch, data) => {
  dispatch({
    type: actionTypes.SET_SCREEN_2_DATA,
    payload: data,
  });
};

export const setScreenThreeData = (dispatch, data) => {
  dispatch({
    type: actionTypes.SET_SCREEN_3_DATA,
    payload: data,
  });
};

export const setScreenFourData = (dispatch, data) => {
  dispatch({
    type: actionTypes.SET_SCREEN_4_DATA,
    payload: data,
  });
};

export const setScreenFiveData = (dispatch, data) => {
  dispatch({
    type: actionTypes.SET_SCREEN_5_DATA,
    payload: data,
  });
};

export const setKpiData = (dispatch, data) => {
  dispatch({
    type: actionTypes.SET_KPI_DATA,
    payload: data,
  });
};

export const setTypeLogs = (dispatch, data) => {
  dispatch({
    type: actionTypes.SET_TYPE_LOGS,
    payload: data,
  });
};

export const setUid = (dispatch, data) => {
  dispatch({
    type: actionTypes.SET_UID,
    payload: data,
  });
};

export const setPromotionData = (dispatch, data) => {
  dispatch({
    type: actionTypes.SET_PROMOTION_DATA,
    payload: data,
  });
};