export const LABELS = {
    BACK: 'Atrás',
    CANCEL: 'Cancelar',
    NEXT: 'Siguiente',
    SEND: 'Enviar'
};


export const radioOptions = [
    { label: 'Sí', value: '1' },
    { label: 'No', value: '2' },
];