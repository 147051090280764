const styles = (theme) => ({
    header: {
        padding: '10px',
        backgroundColor: '#fff'
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    title: {
        textTransform: 'none'
    },
    divider: {
        height: theme.spacing(0.0625),
        marginLeft: -theme.spacing(2),
        margin: theme.forms.control.margin,
        width: theme.wrappers.fullViewPortWidth,
    },
    positionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    positionEmoji: {
        fontSize: '28px',
        margin: '10px 15px 10px 0',
        color: '#225343'
    },
    positionTitle: {
        fontWeight: 600
    },
    positionRanking: {
        fontWeight: '500',
        fontSize: '14px',
        color: 'rgba(0, 0, 0, .5)',
    },
    main: {
        padding: '8px 8px 0'
    },
    boxContainer: {
        backgroundColor: 'white',
        borderRadius: '8px',
        margin: '0 0 8px 0',
        padding: '10px',
    },
    boxTitle: {
        fontWeight: 600,
        textTransform: 'uppercase',
        margin: '0 0 6px 0',
        fontSize: '1rem'
    },
    boxDescription: {
        fontWeight: 400,
        color: 'rgba(0, 0, 0, .4)',
        lineHeight: '16px',
        margin: '0 0 16px 0',
        fontSize: '0.9rem'
    },
    objectiveBox: {
        color: 'white',
        borderRadius: '3px',
        height: '20px'
    },
    objectiveGreen: {
        backgroundColor: '#4CAF50',
    },
    objectiveYellow: {
        backgroundColor: '#FF9800',
    },
    objectiveRed: {
        backgroundColor: '#C62828',
    },
    offlineContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px'
    }
});

export default styles;