export const accordionStyles = (theme) => ({
    descriptionAccordion: {
        fontWeight: 500
    },
    iconContainer: {
        margin: '0 16px 0 0'
    },
    buttonContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
    },
    savePriceButtonHidden: {
        visibility: 'hidden'
    }
});