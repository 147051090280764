import React from 'react';

import Box from '@material-ui/core/Box';
import MaterialListItem from '@material-ui/core/ListItem';
import MaterialListItemText from '@material-ui/core/ListItemText';
import MaterialListItemIcon from '@material-ui/core/ListItemIcon';

import { withStyles, Checkbox } from '@material-ui/core';
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list';

import { ICON_MAP } from '../constants';
import { listStyles } from './list.styles';
import Paper from '@material-ui/core/Paper';
import { ListItemImage } from '../../../elements/lists/components/list.component';

export const ListItem = withStyles(listStyles)(
  ({ classes, data, index, style }) => {
    let element = data.model[index];

    return (
      <Paper
        id={`promotion-${index}`}
        elevation={0}
        style={{ ...style, backgroundColor: 'transparent' }}
        key={index}
      >
        <SwipeableListItem>
          <Paper
            elevation={1}
            onClick={() => data.config.onClick(element)}
            className={classes.listContainer}
          >
            <MaterialListItem className={classes.pb0}>
            <div style={{ display: 'flex',width:'100%',justifyContent:'space-between' }}>
              <MaterialListItemText
                primary={
                  <>
                    <Box className={classes.listItemBox}>
                      {data.config.hasSelectableListItem && (
                        <Checkbox
                          id={`checkbox-${index}`}
                          checked={data.config.selected._id === element._id}
                          className={classes.listItemCheckbox}
                        />
                      )}

                      {data.config.title.parsedTo
                        ? data.config.title.parsedTo(
                            element[data.config.title.attr],
                            element
                          )
                        : element[data.config.title]}
                    </Box>
                  </>
                }
                secondary={
                  data.config.subtitle.parsedTo
                    ? data.config.subtitle.parsedTo(
                        element[data.config.subtitle.attr],
                        element
                      )
                    : element[data.config.subtitle]
                }
              />
            <ListItemImage wow={element.isWow}/>
            </div>
            </MaterialListItem>
            <MaterialListItem className={classes.pt0}>
              <MaterialListItemIcon className={classes.listItemIcon}>
                {ICON_MAP[element[data.config.icon]] || ICON_MAP['default']}
              </MaterialListItemIcon>

              <MaterialListItemText
                inset
                className={classes.pl0}
                primary={element[data.config.iconLabel].toUpperCase()}
              />

              <MaterialListItemText
                inset
                align="right"
                color="primary"
                primary={
                  data.config.lastData.parsedTo
                    ? data.config.lastData.parsedTo(
                        element[data.config.lastData.attr],
                        element
                      )
                    : element[data.config.lastData]
                }
              />
            </MaterialListItem>
          </Paper>
        </SwipeableListItem>
      </Paper>
    );
  }
);
