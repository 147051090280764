import React from 'react';

import { InputLabel, MenuItem, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FilledInput } from '@mui/material';

export const SelectPromotion = ({ ...props }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`label-urs${props.index}`} shrink={props.value ? true : false}>
        * Seleccione una Unidad Registral
      </InputLabel>
      <Select className={props.classes.selectUr}
        variant="filled"
        value={props.value}
        labelId={`label-urs${props.index}`}
        input={<FilledInput />}
        onChange={props.onChange}
      >
        <MenuItem key="common_room" value="Zona común">
          Zona común
        </MenuItem>
        {Object.entries(props.urs).map((ur) => (
          <MenuItem key={ur[0]} value={ur[1].propertyId}>
            {ur[1].propertyId}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText id="my-helper-text" error={props.error}>
        {props.error ? props.errormessage : props.fieldMessage}
      </FormHelperText>
    </FormControl>
  );
};
