import React from 'react';

import MoneyIcon from '@material-ui/icons/Money';
import ErrorIcon from '@material-ui/icons/Error';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';

import { Box, Typography } from '@material-ui/core';

import { RentalStatus } from '../../shared/detail/enums';

export const PROPERTY_BASIC_INFO = [
  {
    icon: <HomeWorkOutlinedIcon />,
    label: 'Tipo de propiedad',
    attr: 'categoryText',
  },
  {
    icon: <LocationOnIcon />,
    label: 'Dirección de la propiedad',
    parsedTo: (data) =>
      `${data.address} ${data.locality ? `${data.address ? ', ' : ''} ${data.locality}` : ''
      }`,
  },
  {
    icon: <LocalOfferOutlinedIcon />,
    label: 'Tipo de gestión',
    attr: 'rentalStatus',
  },
  {
    icon: <MoneyIcon />,
    label: 'Precio',
    parsedTo: (data) => {
      if (
        data.rentalStatus === RentalStatus.Sale ||
        data.rentalStatus === RentalStatus.Rent
      ) {
        return data.rentalStatus === RentalStatus.Sale ? (
          data.salePrice ? (
            `${Number(data.salePrice).currencyFormat('€')}`
          ) : (
            <i>No informado</i>
          )
        ) : data.rentPrice ? (
          `${Number(data.rentPrice).currencyFormat('€')}`
        ) : (
          <i>No informado</i>
        );
      } else {
        return (
          <>
            <span>En venta: {Number(data.salePrice).currencyFormat('€')}</span>
            <br />
            <span>
              En alquiler: {Number(data.rentPrice).currencyFormat('€')}
            </span>
          </>
        );
      }
    },
  },
];

export const PROPERTY_TYPOLOGY_INFO = {
  '01': [
    {
      force: true,
      icon: <MeetingRoomIcon />,
      children: [
        {
          label: 'Superficie',
          parsedTo: (data) =>
            data.surface ? (
              `${Number(data.surface).numberFormat(2, 0, '.', ',')} m²`
            ) : (
              <i>No informado</i>
            ),
        },
        {
          label: 'Hab.',
          attr: 'chambers',
        },
        {
          label: 'Baños',
          attr: 'toilets',
        },
      ],
    },
  ],
  '02': [
    {
      label: '% participación ámbito',
      parsedTo: (data) =>
        `${Number(data.percentage).numberFormat(2, 0, '.', ',')}`,
    },
    {
      label: 'Clasificación urbanística',
      attr: 'urbanQualification',
    },
    {
      label: 'Num viviendas',
      attr: 'totalLivingPlace',
    },
    {
      label: 'Superfície de suelo',
      parsedTo: (data) =>
        `${Number(data.surface).numberFormat(2, 0, '.', ',')} m²`,
    },
    {
      label: 'Uso principal',
      attr: 'mainUse',
    },
  ],
  '03': [
    {
      force: true,
      icon: <MeetingRoomIcon />,
      children: [
        {
          label: 'Superficie',
          parsedTo: (data) =>
            data.surface ? (
              `${Number(data.surface).numberFormat(2, 0, '.', ',')} m²`
            ) : (
              <i>No informado</i>
            ),
        },
        {
          label: 'Hab.',
          attr: 'chambers',
        },
      ],
    },
    {
      label: 'Falso techo',
      parsedTo: (data) => (data.fakeCeiling === '01' ? 'Sí' : 'No'),
    },
    {
      label: 'Metros de fachada',
      parsedTo: (data) =>
        `${Number(data.facadeMeters).numberFormat(2, 0, '.', ',')}`,
    },
    {
      label: 'Salida de humos',
      parsedTo: (data) => (data.smokeOutlet === '01' ? 'Sí' : 'No'),
    },
    {
      label: 'Suelo técnico',
      parsedTo: (data) => (data.technicalFloor === '01' ? 'Sí' : 'No'),
    },
  ],
  '04': [
    {
      force: true,
      icon: <MeetingRoomIcon />,
      children: [
        {
          label: 'Superficie',
          parsedTo: (data) =>
            data.surface ? (
              `${Number(data.surface).numberFormat(2, 0, '.', ',')} m²`
            ) : (
              <i>No informado</i>
            ),
        },
        {
          label: 'Hab.',
          attr: 'chambers',
        },
      ],
    },
    {
      label: 'Falso techo',
      attr: 'fakeCeiling',
      parsedTo: (data) => (data.fakeCeiling === '01' ? 'Sí' : 'No'),
    },
    {
      label: 'Suelo técnico',
      attr: 'technicalFloor',
      parsedTo: (data) => (data.technicalFloor === '01' ? 'Sí' : 'No'),
    },
  ],
  '05': [
    {
      force: true,
      icon: <MeetingRoomIcon />,
      children: [
        {
          label: 'Superficie',
          parsedTo: (data) =>
            data.surface ? (
              `${Number(data.surface).numberFormat(2, 0, '.', ',')} m²`
            ) : (
              <i>No informado</i>
            ),
        },
        {
          label: 'Hab.',
          attr: 'chambers',
        },
      ],
    },
    {
      label: 'Muelles de Carga',
      attr: 'loadDock',
    },
  ],
  '06': [
    {
      icon: <MeetingRoomIcon />,
      label: 'Superficie',
      parsedTo: (data) =>
        data.surface ? (
          `${Number(data.surface).numberFormat(2, 0, '.', ',')} m²`
        ) : (
          <i>No informado</i>
        ),
    },
  ],
  '07': [
    {
      icon: <MeetingRoomIcon />,
      label: 'Superficie',
      parsedTo: (data) =>
        data.surface ? (
          `${Number(data.surface).numberFormat(2, 0, '.', ',')} m²`
        ) : (
          <i>No informado</i>
        ),
    },
  ],
  '08': [
    {
      icon: <MeetingRoomIcon />,
      label: 'Superficie',
      parsedTo: (data) =>
        data.surface ? (
          `${Number(data.surface).numberFormat(2, 0, '.', ',')} m²`
        ) : (
          <i>No informado</i>
        ),
    },
  ],
};

export const DEFAULT_PROPERTIES = {
  prefix: '',
  filter: {
    paging: {
      limit: '200',
      page: '1',
    },
  },
  list: [],
  property: {},
  config: {},
  listItem: { selected: {} },
};

export const PROPERTIES_FORM = {
  city: [],
  address: [],
  chambers: [],
  zipCodeId: [],
  salePrice: [],
  rentPrice: [],
  reference: [],
  myFootprint: [],
  propertyWow: [],
  commercialType: [],
  provinceId: [],
  localityId: [],
  propertyType: [],
};

export const COMMERCIAL_TYPE_VALUES = [
  { label: 'Comprar', value: 1 },
  { label: 'Alquilar', value: 3 },
];
export const PROPERTIES_LIST_ITEM_CONFIG = (clientId) => ({
  containerStyle: {
    width: 'calc(100% - 16px)',
    margin: '0 auto',
    paddingTop: '8px',
  },
  maxHeight: `calc(100vh - ${clientId ? '190px' : '122px'})`,
  icon: 'type',
  title: {
    parsedTo: (element) => <Typography variant="h5">Ref: {element}</Typography>,
    attr: 'reference',
  },
  type: 'managements',
  subtitle: {
    parsedTo: (element, data) =>
      `${element}${data.locality ? ` - ${data.locality}` : ''}`,
    attr: 'address',
  },
  iconLabel: 'categoryText',
  lastData: {
    attr: 'price',
    parsedTo: (value) =>
      value ? (
        <Box display="flex" justifyContent="flex-end">
          <LocalOfferOutlinedIcon
            color="primary"
            style={{ marginRight: '10px' }}
          />{' '}
          {Number(value).currencyFormat('€')}
        </Box>
      ) : (
        <small>
          <i>Precio no informado</i>
        </small>
      ),
  },
});

export const ACTION_BAR = {
  title: 'Crear un nuevo servicio',
  button: {
    label: 'Generar servicio',
  },
};

export const PROPERTIES_FILTER_MODAL_DATA = {
  Icon: ErrorIcon,
  title: 'Error de validación',
  message:
    'Debe introducir Ref. Inmueble, o bien tipo de Inmueble/Provincia/Población.',
};

export const PROPERTIES_FILTER_ERROR_REQUEST = {
  Icon: ErrorIcon,
  title: 'Error del request',
  message:
    'Hubo un error en la petición de filtro.',
};
