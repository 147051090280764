import React from 'react';

import Box from '@material-ui/core/Box';
import MaterialList from '@material-ui/core/List';
import AutoSizer from 'react-virtualized-auto-sizer';

import { FixedSizeList } from 'react-window';
import { SwipeableList } from '@sandstreamdev/react-swipeable-list';

import { ListItem } from './index';
import { NoData } from '../../../ui';
import { ListHeader } from './list-header.component';
import { DEFAULT_CONFIG, BOX_LIST_CONFIG } from '../constants';

export const List = ({
  data,
  maxHeight,
  headerConfig,
  containerStyle,
  itemSize,
  ...props
}) => (
  <>
    {headerConfig ? <ListHeader {...headerConfig} total={data.length} /> : ''}

    {data.length ? (
      <MaterialList style={{ paddingTop: 0, ...containerStyle }}>
        <Box height={maxHeight || BOX_LIST_CONFIG.height}>
          <SwipeableList>
            <AutoSizer>
              {({ width, height }) => (
                <FixedSizeList
                  width={width}
                  height={height}
                  itemCount={data.length}
                  itemData={{ model: data, config: props }}
                  itemSize={itemSize || DEFAULT_CONFIG.itemSize}
                >
                  {ListItem}
                </FixedSizeList>
              )}
            </AutoSizer>
          </SwipeableList>
        </Box>
      </MaterialList>
    ) : (
      <NoData />
    )}
  </>
);
