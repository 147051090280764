import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { EXTRA_ELEMENTS_FORMS, FORM_ELEMENTS } from '../constants/picturesData/form-elements';
import { getPropertyId, renderElementForm } from '../utils';

export const usePicturesData = (
  state,
  history,
  classes
) => {
  const {
    getValues,
    register,
    setValue,
    handleSubmit,
    watch,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: state,
  });

  const id = getPropertyId(history.pathname);

  const watchAllFields = watch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formElementsHTML = FORM_ELEMENTS.map((e) =>
    renderElementForm({ register, setValue }, e, watchAllFields, errors)
  );

  const extraFormElementsHTML = EXTRA_ELEMENTS_FORMS.map((e) =>
    renderElementForm({ register, setValue }, e, watchAllFields, errors)
  );

  const fields = (
    <>
      <div className={classes.infoPicturesText}>
        <div>
          <p>
            <strong>Información</strong>
          </p>
          <p>
            Estos datos no son obligatorios. En caso de dejarlos vacíos se
            autorellenarán con los datos de contacto del API
          </p>
        </div>
      </div>
      {formElementsHTML}
      <div className={classes.infoPicturesText}>
        <div>
          <p>
            <strong>Información</strong>
          </p>
          <p>
            Aquí puede introducir la descripción propuesta o los comentarios que
            considere
          </p>
        </div>
      </div>
      {extraFormElementsHTML}
    </>
  );

  return { fields, handleSubmit, id, getValues };
};
