import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  getOptionalElementsByPropertyType,
  getOptionalElementsForm,
} from '../../../pages/checklists-steps/steps/Property/functions';
import { getPropertyId, renderElementForm } from '../utils';

export const useInitCheckList = (
  state,
  options,
  history,
  isOptionalElementsHTML = false,
  isOptionalElementsByPropertyType = false
) => {
  const {
    getValues,
    register,
    setValue,
    handleSubmit,
    watch,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: state,
  });

  const id = getPropertyId(history.pathname);

  const watchAllFields = watch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formElementsHTML = options.map((e) =>
    renderElementForm({ register, setValue }, e, watchAllFields, errors, state)
  );

  const optionalElementsHTML = getOptionalElementsForm(
    watchAllFields,
    {
      getValues,
      register,
      setValue,
      unregister,
    },
    errors
  );

  const optionalElementsByPropertyType = getOptionalElementsByPropertyType(
    watchAllFields,
    {
      getValues,
      register,
      setValue,
      unregister,
    },
    errors
  );

  const fields = (
    <>
      {formElementsHTML}
      {isOptionalElementsHTML ? optionalElementsHTML : null}
      {isOptionalElementsByPropertyType ? optionalElementsByPropertyType : null}
    </>
  );
  return { fields, handleSubmit, id, getValues };
};
