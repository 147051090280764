import { radioOptions } from '../../constants';
import { OCCUPATION_RISK_VALUES } from '../../securityMaintenance/form-elements';

export const TRANSLATIONS_ITEMS = [
  {
    documentKey: 'antiOccupation',
    type: 'radio',
    summaryKey: '¿Puerta antiokupa instalada?',
    values: radioOptions,
  },
  {
    documentKey: 'requireActions',
    type: 'radio',
    summaryKey: '¿Requiere actuaciones frente a terceros?',
    values: radioOptions,
  },
  {
    documentKey: 'occupationRisk',
    type: 'radio',
    summaryKey: '¿Cómo valoras el riesgo de ocupación?',
    values: OCCUPATION_RISK_VALUES,
  },
  {
    documentKey: 'vandalized',
    type: 'radio',
    summaryKey: '¿Está vandalizado?',
    values: radioOptions,
  },
  {
    documentKey: 'perishableClean',
    type: 'radio',
    summaryKey: 'Necesario limpieza de perecederos',
    values: radioOptions,
  },
  {
    documentKey: 'equipmentClean',
    type: 'radio',
    summaryKey: 'Necesario limpieza de enseres',
    values: radioOptions,
  },
  {
    documentKey: 'pests',
    type: 'radio',
    summaryKey: 'Plagas',
    values: radioOptions,
  },
];
