/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import ImageUploaderImage from '../../../../ui/image-uploader/components/image-uploader-image.component';
import ImageUploaderInputFile from '../../../../ui/image-uploader/components/image-uploader-inputFile.component';
import { useDispatch, useSelector } from '../../../../core/hooks/use-selector';
import { TextAreaInput } from '../../../../elements/input/components/text-area-input-custom.component';
import {
  getImage,
  getValidImages,
  resizeAndSaveImage,
} from '../../../../shared/checklists-wizard/utils/image-uploader.utils';
import { setPromotionData } from '../../../../store/action';
import { sendPicturesStyles } from '../../../../ui/image-uploader/components/image-uploader.style';
import { attachsReducer } from '../../../../ui/image-uploader/reducers';
import { SelectPromotion } from '../../../../ui/select/select-promotion.component';

export const ImageUploaderPromotion = withStyles(sendPicturesStyles)(
  ({ classes, ...props }) => {
    const { form, errorMessage, limit = 1, urs = {}, errors } = props;
    const [attachs, setAttachs] = useReducer(attachsReducer, {});
    const [wasDeleting, setWasDeleting] = useState(false);
    const [isLoading, setLoadingState] = useState(false);
    const [imagesToDisplay, setImagesToDisplay] = useState([]);
    const [attachmentsSend, setAttachmentsSend] = useState([]);
    const [selectedAccordion, setSelectedAccordion] = useState(null);
    const dispatch = useDispatch();

    let stateGeneral = useSelector((s) => s.promotionData);
    let state = {};
    state = useSelector((s) => s.promotionData);
    state = state.pictures;
    const setImages = async (attachments) => {
      setWasDeleting(true);

      const images = Object.entries(attachments).map(async (attachment) => {
        const imageData = await getImage(attachment[1].data);
        return {
          src: imageData,
          name: attachment[0],
          description: attachment[1].description,
          ur: attachment[1].ur,
        };
      });
      let resolvingImages = await Promise.all(images);
      let resolvedImages = [];

      resolvingImages.forEach((value, index) => {
        resolvedImages.push({
          ...value,
          description:
            value.description ?? state.send[index]
              ? state.send[index][1].description
              : '',
          ur:
            urs[value.ur] ?? state.send[index]
              ? urs[state.send[index][1].ur] ||
                state.send[index][1].ur === 'Zona común'
                ? state.send[index][1].ur
                : ''
              : Object.keys(urs).length > 0
                ? ''
                : 'Zona común',
        });
      });

      setImagesToDisplay(resolvedImages);
      setLoadingState(false);

      const attachments1 = [];
      const send = attachments;
      const keys = Object.values(send);
      keys.forEach((v, i) => {
        const key = Object.keys(attachments);
        const imageSendData = [
          { ...v, name: key[i] },
          {
            description:
              v.description ?? state.send[i]
                ? state.send[i][1].description
                : '',
            ur:
              urs[v.ur] ?? state.send[i]
                ? urs[state.send[i][1].ur] ||
                  state.send[i][1].ur === 'Zona común'
                  ? state.send[i][1].ur
                  : ''
                : Object.keys(urs).length > 0
                  ? ''
                  : 'Zona común',
          },
        ];
        attachments1.push(imageSendData);
      });
      setAttachmentsSend(attachments1);

      setPromotionData(dispatch, {
        ...stateGeneral,
        pictures: { send: attachmentsSend, view: resolvedImages },
      });
    };

    useEffect(() => {
      if (Object.keys(attachs).length <= 0 && state.send.length > 0) {
        if (!wasDeleting) {
          const payload = {};
          state.send.forEach((image) => {
            payload[image[0].name] = {
              type: image[0].type,
              data: image[0].data,
              description: image[1].description,
              ur: image[1].ur,
            };
          });
          setAttachs({ payload: { ...payload } });
        }
      }
      if (Object.keys(attachs).length > 0) setImages(attachs);
    }, [attachs]);

    useEffect(() => {
      setPromotionData(dispatch, {
        ...stateGeneral,
        pictures: { send: attachmentsSend, view: imagesToDisplay },
      });
    }, [imagesToDisplay, attachmentsSend]);

    const handleChangeUpload = (e) => {
      const files = e.target.files;
      if (files.length > 0) {
        if (files.length + Object.keys(attachs).length <= limit) {
          const images = getValidImages(files, form, limit);
          if (images.length > 0) {
            setLoadingState(true);
            images.forEach((file, i) => {
              resizeAndSaveImage(file, attachs, setAttachs, limit);
            });
          }
        }
      }
    };

    const handleRemoveAttachment = (index, imageName) => {
      setWasDeleting(true);

      const aux = [
        ...imagesToDisplay.map((i) => {
          return { ...i };
        }),
      ];
      aux.splice(index, 1);

      const aux2 = [
        ...state.send.map((i) => {
          return { ...i };
        }),
      ];
      aux2.splice(index, 1);

      const aux3 = [
        ...state.view.map((i) => {
          return { ...i };
        }),
      ];
      aux3.splice(index, 1);

      setPromotionData(dispatch, {
        ...stateGeneral,
        pictures: { send: { aux2 }, view: { aux } },
      });

      state.send = aux2;
      state.view = aux3;
      setImagesToDisplay(aux);
      setAttachmentsSend(aux2);

      for (let key in attachs) {
        if (key === imageName) {
          const images = imagesToDisplay.filter(
            (image) => image.name !== imageName
          );
          setImagesToDisplay(images);
          delete attachs[imageName];
          setAttachs({ payload: attachs });
        }
      }
    };

    const handleChangeInf = (e, value, i, isDescription) => {
      setImagesToDisplay(
        imagesToDisplay.map((item, key) => {
          if (i === key) {
            if (isDescription) return { ...item, description: value };
            else return { ...item, ur: value.props.value };
          }
          return item;
        })
      );

      setAttachmentsSend(
        attachmentsSend.map((item, key) => {
          if (i === key) {
            if (isDescription) item[1] = { ...item[1], description: value };
            else item[1] = { ...item[1], ur: value.props.value };
          }
          return item;
        })
      );
    };

    return (
      <>
        <ImageUploaderInputFile
          classes={classes}
          imagesToDisplay={imagesToDisplay}
          attachs={attachs}
          handleChangeUpload={handleChangeUpload}
          errorMessage={errorMessage}
          isLoading={isLoading}
          limit={limit}
        />
        <div className={classes.imageContainer}>
          {imagesToDisplay.length > 0 &&
            imagesToDisplay.map((image, i) => {
              return (
                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  <Accordion
                    key={`accordionImage-${i}`}
                    data-testid={`accordionImage-${i}`}
                    expanded={selectedAccordion === `accordionImage-${i}`}
                    onChange={() =>
                      setSelectedAccordion(
                        selectedAccordion === `accordionImage-${i}`
                          ? ''
                          : `accordionImage-${i}`
                      )
                    }
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <div>
                        <ImageUploaderImage
                          handleRemoveAttachment={handleRemoveAttachment}
                          image={image}
                          index={i}
                        />
                      </div>
                      <div className={classes.infAccordion}>
                        <Typography variant="h6">
                          UR: {image.ur ? image.ur : 'No seleccionado'}
                        </Typography>
                        {errors &&
                          (image.ur === '' || image.description === '') && (
                            <Typography variant="body2" className={classes.errorMessage}>
                              Por favor, revise los datos antes de continuar
                            </Typography>
                          )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={classes.fieldsAccordion}>
                        <SelectPromotion
                          key={`ur-${i}`}
                          error={errors && image.ur === ''}
                          errormessage="El campo es obligatorio"
                          value={image.ur ?? ''}
                          index={i}
                          onChange={(e, value) =>
                            handleChangeInf(e, value, i, false)
                          }
                          urs={urs}
                          classes={classes}
                        />
                        <TextAreaInput
                          key={`${image.src}-${i}`}
                          inputProps={{
                            maxLength: 150,
                            'data-testid': `textarea-${i}`,
                          }}
                          error={errors && image.description === ''}
                          errormessage="El campo es obligatorio"
                          variant="filled"
                          placeholder={`Descripción fotografía ${i + 1}`}
                          label={`* Descripción fotografía ${i + 1}`}
                          value={image.description}
                          onChange={(e, value) =>
                            handleChangeInf(e, value, i, true)
                          }
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            })}
        </div>
      </>
    );
  }
);
