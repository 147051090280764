import React, { useReducer } from 'react';

import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import { LogDna } from '../core/log-dna';
import { UserContext } from '../core/user';
import { TableValues } from '../core/table-values';
import { PouchDB } from '../core/pouch-db/services';
import { ConnectionStatus } from '../core/connection-status';

import { Leads } from '../pages/leads';
import { Properties } from '../properties';
import { NavBar } from '../shared/nav-bar';
import { OAuthComponent } from '../pages/o-auth';
import { Notifications } from '../pages/notifications';

import { PROPERTIES_ROUTES } from './constants';
import { Checklists } from '../pages/checklists/components';
/** Custom hook to have a general context in the application */
import MapisContext from '../core/hooks/use-selector';
import { initialStateMapis, mapisReducer } from '../store/reducer';
// import { CheckListsOld } from '../check-lists/components/check-lists.component';
import KpisRouting from '../pages/kpis';

export const BootstrapRouter = ({ state, dispatch }) => (
  <MapisContext.Provider
    value={useReducer(mapisReducer, initialStateMapis)}
  >
    <UserContext.Provider value={{ state, dispatch }}>
      <HashRouter>
        <LogDna>
          {!state.accessToken ? (
            <OAuthComponent />
          ) : (
            <ConnectionStatus>
              <PouchDB>
                <TableValues>
                  <NavBar />
                  <Switch>
                    <Route path="/leads" component={Leads} />
                    <Route path="/notifications" component={Notifications} />
                    {/* // ! Commented - 25/10/2022 */}
                    <Route path="/checklists" component={Checklists} />
                    {/* <Route path="/checklists-old" component={CheckListsOld} /> */}
                    <Route path={PROPERTIES_ROUTES} component={Properties} />
                    <Route path="/kpis" component={KpisRouting} />
                    <Redirect from="*" to="/leads" />
                  </Switch>
                </TableValues>
              </PouchDB>
            </ConnectionStatus>
          )}
        </LogDna>
      </HashRouter>
    </UserContext.Provider>
  </MapisContext.Provider>
);
