import LogsBuffer from '../../logs-buffer/logs-buffer';
import { getViewFromLocation, MODAL_VIEW_LABEL } from '../constants';

export const logDnaEffect = (modal, history, fetchApi, userState,typeLogs) => () => {
  let unlisten;
// console.log('entro');
  if (modal.isOpen) {
    const data = {
      operationName: 'SetLogDnaEvent',
      variables: {
        dto: {
          view: MODAL_VIEW_LABEL[modal.view] || modal.view,
          type: 'VirtualPageView',
          event: 'OpenModal',
          actionId: 'OPEN_MODAL',
          action: 'Apertura de un diálogo',
          createdAt: Date.now(),
          url: history.location.pathname,
          apiId: userState.enrollment,
        },
      },
    };
    LogsBuffer.addLog({ status: 200, ...data });
  } 
  else if (!typeLogs){
    unlisten = history.listen((location) => {
      const view = getViewFromLocation({ location });
      const data = {
        operationName: 'SetLogDnaEvent',
        variables: {
          dto: {
            ...view,
            type: 'View',
            event: 'Navigation',
            actionId: 'NAVIGATION',
            action: 'Navegación por la aplicación',
            createdAt: Date.now(),
            url: location.pathname,
            apiId: userState.enrollment,
          },
        },
      };
      LogsBuffer.addLog({ status: 200, ...data });
    });
  }

  return () => unlisten && unlisten();
};
