import React from 'react';
import {
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
  radioGroup: {
    margin: '0 0 0 8px',
  },
}));

export const ListSortComponent = ({ filterModel, onChangeFilter }) => {
  const classes = useStyles();

  const handleSortChange = (event) => {
    onChangeFilter({
      sort: event.target.value,
    });
  };
  const handleAscDescChange = (event, value) => {
    onChangeFilter({
      direction: event.target.value,
    });
  };

  return (
    <div>
      <h3
        style={{
          margin: '0px 8px',
          float: 'left',
        }}
      >
        Ordenar
      </h3>

      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-sortValue-native-simple">
          Ordenar por
        </InputLabel>
        {filterModel?.filterType === 'leads' ? (
          <Select native value={filterModel.sort} onChange={handleSortChange}>
            <option value={'name'}>Nombre</option>
            <option value={'address'}>Dirección</option>
            <option value={'typeText'}>Tipo</option>
            <option value={'expireAt'}>Fecha de caducidad</option>
            <option value={'transferAt'}>Fecha de traspaso</option>
            <option value={'assignedTo'}>Comercial</option>
          </Select>
        ) : (
          <Select native value={filterModel?.sort} disabled>
            <option value={'createdAt'}>Fecha creación</option>
          </Select>
        )}
      </FormControl>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="order"
          name="order"
          value={filterModel.direction}
          defaultValue="asc"
          onChange={handleAscDescChange}
          className={classes.radioGroup}
        >
          <FormControlLabel
            value="asc"
            control={<Radio color="primary" />}
            label="Ascendente"
            labelPlacement="end"
          />
          <FormControlLabel
            value="desc"
            control={<Radio color="primary" />}
            label="Descendente"
            labelPlacement="end"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};
