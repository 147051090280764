import React from 'react';
import { useSelector } from '../../../../../core/hooks/use-selector';
import { renderOptions } from '../../../../../shared/checklists-wizard/constants/summary/utils';
import { TRANSLATIONS_ITEMS as enviromentTranslations } from '../../../../../shared/checklists-wizard/constants/summary/environmentSummaryItem/constants';
import { useStyles } from '../style';

export const EnviromentSummaryItem = () => {
  const state = useSelector((s) => s.screenThreeData);
  const classes = useStyles();

  return (
    <div className={classes.summaryContainer}>
      {renderOptions(state, enviromentTranslations)}
    </div>
  );
};
