import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from '../../../../core/hooks/use-selector';
import { useInitCheckList } from '../../../../shared/checklists-wizard/hooks/useInitCheckList';
import { setPromotionData } from '../../../../store/action';
import WizardView from '../../../../shared/checklists-wizard/step-base.component';
import { FORM_ELEMENTS } from '../../../../shared/checklists-wizard/constants/environment/form-elements';

/** View screen 4 : Entorno */
const Step4Environment = (props) => {
  const { onChangeStepHandler, haveBack = true } = props;
  const state = useSelector((s) => s.promotionData);
  const dispatch = useDispatch();
  const history = useLocation();

  const { fields, handleSubmit, id: promotionId, getValues } = useInitCheckList(
    state.environment,
    FORM_ELEMENTS,
    history
  );

  const onSubmitHandler = (data) => {
    setPromotionData(dispatch, { ...state, environment: data });
    onChangeStepHandler(4);
  };

  /** Back step */
  const onBackWizard = () => {
    const data = getValues();
    setPromotionData(dispatch, { ...state, environment: data });

    onChangeStepHandler(!state.childrenData || state.childrenData.length === 0
      ? 0
      : 2);
  };

  return (
    <WizardView
      title="Entorno"
      fields={fields}
      onNext={handleSubmit(onSubmitHandler)}
      onBack={onBackWizard}
      haveBack={haveBack}
      isPromotion={{ id: state.item.id, address: state.item.address }}
      progress={4}
    />
  );
};
export default Step4Environment;
