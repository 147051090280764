import React, { useMemo } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { withStyles } from '@material-ui/core';

import { selectStyles } from './select.styles';

export const Select = withStyles(selectStyles)(({ classes, ...props }) =>
  useMemo(() => {
    const value =
      typeof props.value === 'object' ? props.value.value : props.value || '';

    const onChange = (event, child) => {
      event.target.value = ['value', 'label'].filterObject(child.props);
      props.onChange(event);
    };

    return (
      <FormControl className={classes.root}>
        <InputLabel>{props.label}</InputLabel>

        <MaterialSelect
          name={props.name}
          value={value}
          variant={props.variant}
          onChange={onChange}
        >
          {props.values && props.values.length > 0 ? (
            <MenuItem value="" label="" />
          ) : (
            <MenuItem key={0} value="Cargando" label="Cargando">
              CARGANDO...
            </MenuItem>
          )}

          {(props.values || []).map((element, key) => (
            <MenuItem key={key} value={element.value} label={element.label}>
              {element.label}
            </MenuItem>
          ))}
        </MaterialSelect>
      </FormControl>
    );
  }, [props, classes])
);
