import { withStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import consoleLog from '../../../core/console-log/consoleLog';
import { infoItemStyles } from './info-item.styles';

export const InfoItem = withStyles(infoItemStyles)(
  ({ classes, model, info, parentKey }) => {
    try {
      return (
        <List>
          {info.map((element, key) => {
            return (Object.keys(element).length &&
            (model[element.attr] || (!element.attr && element.parsedTo))) ||
            element.labelAttr ||
            element.force ? (
              <div key={key}>
                <ListItem key={key + '-main'} className={classes.listItem}>
                  <ListItemIcon className={classes.icon}>
                    {element.icon}
                  </ListItemIcon>
                  {!element.children ? (
                    element.labelAttr ? (
                      <ListItemText
                        className={classes.listItemText}
                        primary={
                          model[element.labelAttr]
                            ? model[element.labelAttr]
                            : element.attr.parsedTo
                            ? element.attr.parsedTo(model)
                            : model[element.attr]
                        }
                        secondary={
                          model[element.labelAttr]
                            ? element.attr.parsedTo
                              ? element.attr.parsedTo(model)
                              : model[element.attr]
                            : ''
                        }
                      />
                    ) : (
                      <ListItemText
                        primary={`${element.label} ${
                          element.showKey ? parentKey + 1 : ''
                        }`}
                        secondary={
                          element.custom
                            ? element.custom(model)
                            : element.attr
                            ? element.parsedTo
                              ? element.parsedTo(model[element.attr], model)
                              : model[element.attr]
                            : element.parsedTo
                            ? element.parsedTo(model)
                            : ''
                        }
                      />
                    )
                  ) : (
                    ''
                  )}
                  {element.children &&
                    element.children.map((element, key) => (
                      <ListItemText
                        key={key}
                        primary={`${element.label}`}
                        secondary={
                          element.attr
                            ? element.parsedTo
                              ? element.parsedTo(model[element.attr])
                              : model[element.attr]
                            : element.parsedTo
                            ? element.parsedTo(model)
                            : ''
                        }
                      />
                    ))}
                  {element.secondary && element.secondary.attr ? (
                    <ListItemSecondaryAction
                      className={classes.listItemSecondaryAction}
                    >
                      {element.secondary.parsedTo
                        ? element.secondary.parsedTo(
                            model[element.secondary.attr]
                          )
                        : element.secondary.attr}
                    </ListItemSecondaryAction>
                  ) : (
                    ''
                  )}
                </ListItem>

                {element.extraInfo && model[element.extraInfo.attr] ? (
                  <ListItem key={key + '-extra'} className={classes.extraInfo}>
                    <ListItemIcon className={classes.icon}>
                      {element.extraInfo.icon}
                    </ListItemIcon>
                    <ListItemText primary={model[element.extraInfo.attr]} />
                  </ListItem>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )
          }
            
          )}
        </List>
      );
    } catch (err) {
      consoleLog(
        'error',
        'Error al intentar renderizar el componente info-item:',
        err
      );
      window.history.back();
      return null;
    }
  }
);
