/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';

import { Redirect, Switch, Route, useParams } from 'react-router-dom';

import { Clients } from '../../../clients';
import { LeadProvider } from '../contexts';
import { PromoProvider } from '../../../promo/contexts';
import { PromoDetail } from '../../../promo';
import { Properties } from '../../../properties';
import { Activities } from '../../../activities';
import { ManagementDetail } from '../../../management';

export const LeadsRouterComponent = ({ model }) => {
  const { type, leadId } = useParams();
  const [data] = useState(
    model?.find((element) => element.managementId === leadId)
  );

  return useMemo(
    () =>
      data ? (
        <LeadProvider value={data}>
          <PromoProvider value={data}>
            <Switch>
              <Route
                exact
                path="/leads/promo/:leadId"
                component={PromoDetail}
              />
              <Route
                exact
                path="/leads/management/:leadId"
                component={ManagementDetail}
              />
              <Route
                path="/leads/:type/:leadId/properties/:propertyId"
                component={Properties}
              />
              <Route
                path="/leads/:type/:leadId/activities/add"
                component={Activities}
              />
              <Route
                exact
                path="/leads/:type/:leadId/clients/:clientId"
                component={Clients}
              />

              <Redirect from="*" to={`leads/${type}/${leadId}`} />
            </Switch>
          </PromoProvider>
        </LeadProvider>
      ) : (
        <Redirect from="*" to="/leads" />
      ),
    [data]
  );
};
