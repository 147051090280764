import React from 'react';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import AssistantPhotoOutlinedIcon from '@material-ui/icons/AssistantPhotoOutlined';

import { ListTypeIconComponent } from '../../../elements/lists/components';

const COMMON_NOTIFICATIONS_LIST_ITEM_CONFIG = {
  item: {
    title: 'name',
    description: ['address', 'locality'],
    type: { label: 'typeText', icon: 'type' },
    date: { type: 'range', key: ['transferAt', 'expireAt'] },
    dateExpireAt: { type: 'range', key: ['transferAt', 'expireAt'] },
    extra: { type: 'dot', key: 'isRead' },
  },
};

const NOTIFICATIONS_CAMPAIGN_LIST_ITEM = {
  item: {
    title: 'description',
    description: [''],
    type: { icon: 'no' },
    date: { type: 'exact', key: 'createdAt' },
    extra: { type: 'dot', key: 'isRead' },
  },
  hasHiddenExtra: true,
  hasTwoLinesTitle: true,
};

const NOTIFICATIONS_PROPERTY_LIST_ITEM = {
  item: {
    title: {
      parsedTo: (element) => `Ref: ${element.reference}`,
    },
    description: ['description'],
    type: { label: 'typeText', icon: 'type' },
    types: [
      {
        data: [
          {
            label: {
              key: 'categoryText',
            },
            customIcon: (element) => (
              <ListTypeIconComponent type={element.category} />
            ),
          },
          {
            label: {
              key: 'typeText',
            },
            icon: <AssistantPhotoOutlinedIcon />,
          },
        ],
      },
      {
        data: [
          {
            label: {
              key: 'locality',
            },
            icon: <LocationOnIcon />,
          },
          {
            label: {
              key: 'salePrice',
              parsedTo: (data) =>
                data ? Number(data).currencyFormat('€') : '0 €',
            },
            icon: <LocalOfferOutlinedIcon />,
          },
        ],
      },
    ],
    date: { type: 'exact', key: 'createdAt' },
    dateExpireAt: { type: 'exact', key: 'checklistExpireAt' },
    extra: { type: 'dot', key: 'isRead' },
  },
  hasHiddenExtra: true,
  hasTwoLinesTitle: false,
};

const CHECKLISTS_PROPERTY_LIST_ITEM = {
  item: {
    title: {
      parsedTo: (element) => `Ref: ${element.reference}`,
    },
    description: ['description'],
    type: { label: 'typeText', icon: 'type' },
    types: [
      {
        label: {
          key: 'categoryText',
        },
        customIcon: (element) => (
          <ListTypeIconComponent type={element.category} />
        ),
      },
      {
        label: {
          key: 'typeText',
        },
        icon: <AssistantPhotoOutlinedIcon />,
      },
      {
        label: {
          key: 'salePrice',
          parsedTo: (data) =>
            data ? Number(data).currencyFormat('€') : '0 €',
        },
        icon: <LocalOfferOutlinedIcon />,
      },
    ],
    address: {
      labels: [
        {
          key: 'address',
        },
        {
          key: 'codpos',
        },
        {
          key: 'locality',
        },
        {
          key: 'province',
        },
      ],
      icon: <LocationOnIcon />
    },
    date: { type: 'exact', key: 'checklistAssignedAt' },
    dateExpireAt: { type: 'exact', key: 'checklistExpireAt' },
    extra: { type: 'dot', key: 'isRead' },
  },
  hasHiddenExtra: true,
  hasTwoLinesTitle: false,
};

export const NOTIFICATIONS_LIST_FILTER = {
  hasFavs: false,
  hasSort: true,
  hasHeader: false,
  fields: [
    {
      label: 'Ordenar por',
      value: 'sort',
      options: [
        {
          value: 'isRead',
          label: 'Nuevas',
        },
        {
          value: 'createdAt',
          label: 'Fecha creación',
        },
      ],
    },
  ],
};

export const NOTIFICATIONS_LIST_ITEMS = {
  service: COMMON_NOTIFICATIONS_LIST_ITEM_CONFIG,
  campaign: NOTIFICATIONS_CAMPAIGN_LIST_ITEM,
  'inactivity-service': COMMON_NOTIFICATIONS_LIST_ITEM_CONFIG,
  property: NOTIFICATIONS_PROPERTY_LIST_ITEM,
};

export const CHECKLISTS_LIST_ITEMS = {
  service: COMMON_NOTIFICATIONS_LIST_ITEM_CONFIG,
  campaign: NOTIFICATIONS_CAMPAIGN_LIST_ITEM,
  'inactivity-service': COMMON_NOTIFICATIONS_LIST_ITEM_CONFIG,
  property: CHECKLISTS_PROPERTY_LIST_ITEM,
};

export const DEFAULT_FILTERS = {
  sort: 'createdAt',
  direction: 'asc',
  collapsed: true,
  filterType: 'notif',
};

export const NOTIFICATIONS_VALIDITY_DAYS = {
  SERVICE: {
    EXPIRE_AT: 7,
  },
  INACTIVITY_SERVICE: {
    VALID_AT: 3,
    EXPIRE_AT: 10,
  },
};
