import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useDispatch } from '../../core/hooks/use-selector';
import ControlPanel from './ControlPanel';
import { getKpiData } from './utils';
import { setKpiData } from "../../store/action";
import View from './view';
import { useConnectionStatus } from '../../core/connection-status';
import { SnackBarContext } from '../../ui/snack-bar';

const KpisRouting = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { online } = useConnectionStatus();
  const {
    actions: { open: openSnackBar },
  } = useContext(SnackBarContext);

  useEffect(() => {
    getKpiData((data) => setKpiData(dispatch, data), openSnackBar);
  }, []);

  return (
    <>
      {online ?
        <Switch>
          <Route path={`${path}/:view`} component={View} />
          <Route path="/" component={ControlPanel} />
        </Switch>
        : <Route path="/" component={ControlPanel} />}
    </>
  );
};

export default KpisRouting;
