export const navBarStyles = (theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(0),
  },
  appBar: {
    boxShadow: 'none',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
});
