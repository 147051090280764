export const picturesSummaryStyles = (theme) => ({
    imagesContainer: {
        marginTop: '16px',
    },
    summaryInf: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 0 0 16px'
    },
    imageContainer: {
        display: 'flex'
    },

});