import React from 'react';

import AddIcon from '@material-ui/icons/Add';
import RoomIcon from '@material-ui/icons/Room';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import WhatsappIcon from 'mdi-react/WhatsappIcon';
import PersonIcon from '@material-ui/icons/Person';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';

import { isMobileNumber } from '../../../utils';

export const URL_PREFIX_LEADS = '/leads/:type/:leadId';
export const URL_PREFIX_PROPERTIES = '/properties/:propertyId/promo';

export const CLIENT_BUTTONS = ({ history, url }) => [
  {
    key: 'phone1',
    icon: <PhoneIcon style={{ color: '#fff' }} />,
    ariaLabel: 'Llamar',
    onClick: (value) => (window.location = `tel:${value}`),
  },
  {
    key: 'whatsapp',
    icon: <WhatsappIcon style={{ color: '#fff' }} />,
    onClick: (model) => {
      let value = isMobileNumber(model.phone1) || isMobileNumber(model.phone2);

      // window.location = `https://wa.me/${value}`;

      window.open(`https://wa.me/${value}`, '_blank');
    },
    isVisible: (model) =>
      !!(isMobileNumber(model.phone1) || isMobileNumber(model.phone2)),
  },
  {
    key: 'email',
    icon: <MailIcon style={{ color: '#fff' }} />,
    ariaLabel: 'Enviar email',
    onClick: (value) => (window.location = `mailTo:${value}`),
  },
  {
    ariaLabel: 'Ver detalles',
    isVisible: (model) => !!model.clientId,
    icon: <PersonIcon style={{ color: '#fff' }} />,
    onClick: (model) => history.push(`${url}/clients/${model.clientId}`),
  },
  {
    ariaLabel: 'Añadir UR a servicio',
    icon: <AddIcon style={{ color: '#fff' }} />,
    isVisible: (model) => model.status === 'in-progress' && !!model.clientId,
    onClick: (model) =>
      history.push(`/properties-filter?clientId=${model.clientId}`),
  },
];

export const CONFIG = {
  icon: 'type',
  title: {
    parsedTo: (element) => `Ref: ${element}`,
    attr: 'reference',
  },
  type: 'managements',
  subtitle: {
    parsedTo: (element, data) =>
      `${element}${data.locality ? `, ${data.locality}` : ''}`,
    attr: 'address',
  },
  iconLabel: 'categoryText',
  lastData: {
    attr: 'price',
    parsedTo: (element, { salePrice, rentPrice }) => {
      const price = element || salePrice || rentPrice;

      return price ? (
        Number(price).currencyFormat('€')
      ) : (
        <small>
          <i>No informado</i>
        </small>
      );
    },
  },
};

export const DEFAULT_INFO = [
  {
    icon: <DateRangeIcon />,
    label: 'Fecha Oportunidad',
    attr: 'transferAt',
    parsedTo: (element, data) =>
      `${data.transferAt.dateFormat()} - ${data.expireAt.dateFormat()}`,
  },
  {
    icon: <LocalOfferIcon />,
    label: 'Precio',
    attr: 'price',
    parsedTo: (element) => `${Number(element).currencyFormat('€')}`,
  },
  {
    icon: <RoomIcon />,
    label: 'Dirección de la propiedad',
    // parsedTo : (element) => `${element.address}`
    parsedTo: (data) =>
    `${data.address} ${
      data.locality ? `${data.address ? ', ' : ''} ${data.locality}` : ''
    }`,
  },
];

export const ACTIVITIES_INFO = [
  {
    icon: <QueryBuilderIcon />,
    attr: {
      parsedTo: (element) =>
        element.hasAttachments || element.hasFirmed
          ? `${element.name}: Comprobante 
                    ${
                      element.hasAttachments ||
                      element.attachments?.length !== 0
                        ? ''
                        : 'no'
                    } adjuntado y 
                    ${element.hasFirmed ? '' : 'no'} firmado`
          : element.name,
    },
    labelAttr: 'reason',
    secondary: {
      attr: 'date',
      parsedTo: (element) =>
        element ? element.dateFormat() : <small>Procesando...</small>,
    },
    extraInfo: {
      icon: <CommentOutlinedIcon />,
      attr: 'comment',
    },
  },
];
