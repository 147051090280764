import React, { useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { View } from '../../shared/view';
import { InfoItem } from '../../elements/info-item';
import { PouchDBContext } from '../../core/pouch-db';
import { BoxContent } from '../../shared/box-content';
import { CLIENT_INFO, PREFERENCES_INFO } from '../constants';
import {
  clientEffect,
  clientPreferencesEffect,
  updateClientEffect,
  updateClientPreferencesEffect,
} from '../effects';
import FetchApi from '../../core/fetch-api';

export const ClientsComponent = () => {
  const { clientId } = useParams();
  const [client, setClient] = useState(null);
  const { getData, isSyncing } = useContext(PouchDBContext);
  const [preferences, setPreferences] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0)
 }, [])
  useEffect(updateClientEffect(clientId, FetchApi), []);
  useEffect(clientEffect(getData, isSyncing, clientId, setClient), [
    clientId,
    isSyncing,
  ]);

  useEffect(updateClientPreferencesEffect(clientId, FetchApi), []);
  useEffect(
    clientPreferencesEffect(getData, isSyncing, clientId, setPreferences),
    [clientId, isSyncing]
  );

  return (
    <View model={client} title="Datos y Preferencias del cliente">
      {client && <InfoItem model={client} info={CLIENT_INFO} />}

      {preferences && (
        <BoxContent
          title="Preferencias"
          model={preferences}
          info={PREFERENCES_INFO}
        />
      )}
    </View>
  );
};
