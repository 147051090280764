import React, { useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { withStyles } from '@material-ui/core';

import { detailStyles } from './detail.styles';
import {
  detailPropertyEffect,
  updateDetailPropertyEffect,
} from '../effects/detail-properties.effect';

import { usePromoContext } from '../../../promo/contexts';
import { PouchDBContext } from '../../../core/pouch-db/contexts';
import { DetailHeaderComponent } from './detail-header.component';
import { DetailContentComponent } from './detail-content.component';
import { URL_PREFIX_LEADS, URL_PREFIX_PROPERTIES } from '../constants';
import FetchApi from '../../../core/fetch-api';

export const DetailComponent = withStyles(detailStyles)(
  ({ classes, extraInfo = {}, list, doNotCallUpdateProperty }) => {
    const model = usePromoContext();
    const [localModel, setLocalModel] = useState();
    const [localData, setLocalData] = useState();
    const { getData, isSyncing } = useContext(PouchDBContext);
    const { url = '' } =
      useRouteMatch(URL_PREFIX_LEADS) ||
      useRouteMatch(URL_PREFIX_PROPERTIES) ||
      {};

      useEffect(() => {
         window.scrollTo(0, 0)
      }, [])
    
    useEffect(
      updateDetailPropertyEffect(model, FetchApi, doNotCallUpdateProperty),
      []
    );
    useEffect(detailPropertyEffect(getData, isSyncing, model, setLocalModel,setLocalData), [
      getData,
      model.propertyId,
      model.promoId,
      model.isWow,
      model.data,
      isSyncing,
    ]);

    return (
      <div className={classes.detail}>
        <DetailHeaderComponent
          classes={classes}
          model={localModel || model}
          url={url}
        />
        <DetailContentComponent
          url={url}
          classes={classes}
          model={localModel || model}
          localData = {localData}
          extraInfo={extraInfo}
          list={list}
          isClickable={
            localModel
              ? !localModel.propertyId.match(/^[P,p]/)
              : !model.propertyId.match(/^[P,p]/)
          }
        />
      </div>
    );
  }
);
