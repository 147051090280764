import { addLogs } from "../../../core/logs-buffer/addLogs";

export const sendPicsHandleSubmit =
  (openSnackBar, idCloudant, form, fetchApi, user = '', isOffline = false, attemptsCounter = 1, totalAttemptsCounter = 6) => {
    const title = `new send-pics - ${isOffline ? 'offline' : 'online'}`;

    // If there're images.
    if (form.length > 0) {
      // Log saying gonna create send-pics
      addLogs(
        title,
        'WRITE_TRACES',
        `/check-list/${idCloudant}/send-pics`,
        `Petición de crear send-pics. Intento: ${attemptsCounter}`,
        user,
        `Petición con ${form.length} imágenes`
      );
      return fetchApi
        .sendImages('post', `/check-list/${idCloudant}/send-pics`, form)
        .then((res) => {
          if (res && res.status === 200) {
            // User pop-up saying all is ok
            openSnackBar({
              type: 'success',
              visible: true,
              title: 'Notificación fotos',
              message: 'Las fotos se han enviado correctamente',
            });
            // Log saying send-pics created successfully
            addLogs(
              title,
              'WRITE_TRACES',
              `/check-list/${idCloudant}/send-pics`,
              `Petición de crear send-pics: success`,
              user,
              `Se han insertado ${form.length} imágenes`
            );
          } else {
            addLogs(
              title,
              'WRITE_TRACES',
              `/check-list/${idCloudant}/send-pics`,
              `Petición de crear send-pics: error, con respuesta ${res?.status}, en el intento ${attemptsCounter}`,
              user,
              `Se han insertado ${form.length} imágenes`
            );
            if (attemptsCounter < totalAttemptsCounter) {
              attemptsCounter++;
              // console.log(`attempt -> ${attemptsCounter}/${totalAttemptsCounter}`);
              setTimeout(() => sendPicsHandleSubmit(openSnackBar, idCloudant, form, fetchApi, user, isOffline, attemptsCounter++), 1000);
            } else if (attemptsCounter === totalAttemptsCounter) {
              // User pop-up saying all is ok
              openSnackBar({
                type: 'error',
                visible: true,
                title: 'Notificación fotos',
                message: 'Las fotos no se han podido enviar correctamente',
              });
              // Log saying send-pics created successfully
              addLogs(
                title,
                'WRITE_TRACES',
                `/check-list/${idCloudant}/send-pics`,
                `Petición de crear send-pics: error`,
                user,
                `Se ha superado el límite de intentos. Intentos ${attemptsCounter}/${totalAttemptsCounter}`
              );
            }
          }
        }).catch((err) => {
          // User pop-up saying there's an error
          openSnackBar({
            type: 'error',
            visible: true,
            title: 'Error al enviar fotos',
            message: 'Las fotos no se han podido enviar correctamente',
          });
          // Log saying there's an error
          addLogs(
            title,
            'WRITE_TRACES',
            `/check-list/${idCloudant}/send-pics`,
            `Petición de crear send-pics: error`,
            user,
            err
          );
        })
    } else {
      // Log saying there isn't image
      addLogs(
        title,
        'WRITE_TRACES',
        `/check-list/${idCloudant}/send-pics`,
        'Petición de crear send-pics',
        user,
        'No hay imágenes.'
      );
    }
  };