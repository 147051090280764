import React, { useContext, useEffect, useState } from 'react';
import { FORM_ELEMENTS_PROMOTION } from '../../../../shared/checklists-wizard/constants/property/form-elements';
import { useSelector, useDispatch } from '../../../../core/hooks/use-selector';
import WizardView from '../../../../shared/checklists-wizard/step-base.component';
import { useLocation } from 'react-router-dom';
import { setPromotionData } from '../../../../store/action';
import { getChildrenPromotion } from '../../utils/getPromotion';
import { useModalContext } from '../../../../ui/modal/contexts';
import { DialogLoading, DialogMessage } from '../../../../elements/dialog/components';
import { useInitCheckList } from '../../../../shared/checklists-wizard/hooks/useInitCheckList';
import FetchApi from '../../../../core/fetch-api';
import { PouchDBContext } from '../../../../core/pouch-db';
import { useConnectionStatus } from '../../../../core/connection-status';

/** View screen 1 : Promotion*/
const Step1Promotion = (props) => {
  const { onChangeStepHandler } = props;
  const state = useSelector((s) => s.promotionData);
  const { getData } = useContext(PouchDBContext);
  const [isLoadingState, setLoadingState] = useState(false);
  const [isSubmitState, setSubmitState] = useState(false);
  const [screenGeneral, setScreenGeneral] = useState();
  const dispatch = useDispatch();
  const history = useLocation();
  const { online } = useConnectionStatus();
  const {
    actions: { open: openModal, close: closeModal },
  } = useModalContext();

  const { fields, handleSubmit, id: promotionId } = useInitCheckList(state.screenGeneral, FORM_ELEMENTS_PROMOTION, history, false, true);

  useEffect(() => {
    FetchApi.refreshToken();

    if (!state.childrenData)
      getChildrenPromotion(promotionId, dispatch, state, getData, setLoadingState, online);
  }, []);

  useEffect(() => {
    // If user click submit
    if (isSubmitState) {
      setPromotionData(dispatch, { ...state, screenGeneral: screenGeneral });

      // If getting URs finished
      if (!isLoadingState) {
        // Close loading modal
        closeModal();

        if (state.childrenData.length === 0) {
          // Open no urs modal
          openModal({
            Component: DialogMessage,
            view: 'add-checklist',
            data: {
              title: 'Esta promoción no contiene URs',
              message: 'Solo se rellenarán los datos de la promoción.',
            },
          });
          onChangeStepHandler(3);
        } else {
          onChangeStepHandler(1);
        }
      } else {
        // Open loading modal
        openModal({
          Component: DialogLoading,
          view: 'urs-loading'
        });
      }
    }
  }, [isLoadingState, isSubmitState]);

  const onSubmitHandler = (data) => {
    setScreenGeneral(data);

    setSubmitState(true);
  };

  return (
    <WizardView
      title='Datos de la promoción'
      fields={fields}
      onNext={handleSubmit(onSubmitHandler)}
      haveBack={false}
      isPromotion={{ id: state.item.id, address: state.item.address }}
      progress={1}
    />
  );
};

export default Step1Promotion;
