import React from 'react';

import { Box, Typography, Button } from '@material-ui/core';

export const ActionBar = ({
  title,
  button: { label, onClick, isDisabled },
}) => (
  <Box
    display="flex"
    justifyContent="space-between"
    bgcolor="white.main"
    padding="16px"
    alignItems="center"
  >
    <Typography variant="subtitle1" color="primary">
      {title}
    </Typography>
    <Button
      disabled={isDisabled}
      onClick={onClick}
      color="primary"
      variant="outlined"
    >
      {label}
    </Button>
  </Box>
);
