import {
    AREA_LOCATION,
    PREVAILING_ENVIRONMENT,
    QUALITY_AREA,
} from '../../../../../shared/checklists-wizard/constants/environment/form-elements';

export const TRANSLATIONS_ITEMS = [
    {
        documentKey: 'prevailingEnvironment',
        type: 'radio',
        summaryKey: '¿Cuál es el entorno predominante?',
        values: PREVAILING_ENVIRONMENT,
    },
    {
        documentKey: 'qualityArea',
        type: 'radio',
        summaryKey: 'Calidad de la zona',
        values: QUALITY_AREA,
    },
    {
        documentKey: 'areaLocation',
        type: 'radio',
        summaryKey: 'Ubicación de la zona',
        values: AREA_LOCATION,
    },
    {
        documentKey: 'environmentDescription',
        type: 'textarea',
        summaryKey: 'Descripción del entorno y del edificio',
        values: '',
    },
    {
        documentKey: 'marketingDescription',
        type: 'textarea',
        summaryKey: 'Descripción comercialización del activo',
        values: '',
    },
];
