import tableValues from '../../json-table-values.json';

export const tableValuesEffect = (setTableValues) => () => {
  setTableValues({
    payload: {
      ...tableValues.reduce(
        (prev, curr) => ({ ...prev, [curr.type.toCamelCase()]: curr.data }),
        {}
      ),
    },
  });
};
