export const appMonitorFallbackStyles = (theme) => ({
  container: {
    textAlign: 'center',
    color: '#005442',
    padding: theme.spacing(3),
  },
  icon: {
    fontSize: '60px',
  },
  header: {
    padding: '5%',
    marginBottom: 10,
    width: '90%',
    display: 'inline-flex',
    textAlign: 'center',
    background:
      'linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(0,84,66,1) 0%, rgba(238,238,238,1) 100%)',
  },
  content: {
    textAlign: 'left',
  },
  img: {
    maxWidth: '50%',
    maxHeight: '100%',
  },
});
