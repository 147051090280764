import React from 'react';

import { Button } from '@material-ui/core';

export const PropertyFormButtons = (props) => {
  return (
    <div style={{ padding: '0 18px' }}>
      <span style={{ float: 'right' }}>
        <Button
          id="save-button-property"
          style={{
            background: '#005442',
            color: '#fff',
          }}
          type="submit"
        >
          GUARDAR
        </Button>
      </span>
      <span style={{ float: 'right', marginRight: 10 }}>
        <Button
          id="cancel-button-property"
          node="button"
          style={{
            color: '#005442',
          }}
          onClick={props.handleClose}
        >
          CANCELAR
        </Button>
      </span>
    </div>
  );
};
