const goToPropertyAction =
  (history) =>
    ({ propertyId }) => {
      const propertyType = !propertyId.match(/^[P,p]/) ? '' : '/promo';
      history.push(`/properties/${propertyId}${propertyType}`);
    };

const propertiesListItemAction = (properties, setProperties) => (model) =>
  setProperties({
    payload: {
      listItem: {
        ...properties.listItem,
        selected: properties.listItem.selected === model._id ? null : model,
      },
    },
  });

export const propertiesListItemOnClickAction = (
  clientId,
  history,
  properties,
  setProperties
) =>
  clientId
    ? propertiesListItemAction(properties, setProperties)
    : goToPropertyAction(history);
