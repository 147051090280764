/** Tab default value */
export const DEFAULT_FILTERS = {
  filterKey: 'checklistStatus',
  filterValue: 'new',
  sort: 'checklistExpireAt',
  direction: 'asc',
  filterType: 'checklists',
};
/** Tabs filtered and sort asc with checkListStatus */
export const CHECKLISTS_LIST_FILTER = {
  hasFavs: false,
  hasSort: true,
  hasHeader: true,
  fields: [
    {
      label: 'Filtrar',
      value: 'filterValue',
      options: [
        {
          value: 'new',
          label: 'Pendientes',
        },
        {
          value: 'in-progress',
          label: 'Gestionadas',
        },
      ],
    },
  ],
};
