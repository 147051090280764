import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from '../../../../core/hooks/use-selector';
import { useHistory } from 'react-router-dom';
import { PouchDBContext } from '../../../../core/pouch-db';
import { UserContext } from '../../../../core/user';
import { formSummary } from './constants';
import { onCancelPromotionWizard } from '../../../../shared/checklists-wizard/utils';
import WizardView from '../../../../shared/checklists-wizard/step-base.component';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from '../../../../ui/expansion-panel';
import { IconButton, Typography } from '@material-ui/core';
import { uuidv4 } from '../../../../utils';
import {
    FORM_ELEMENTS_PROPERTY as PROPERTIES_FORM_ELEMENTS,
    OPTIONAL_ELEMENTS_A,
    OPTIONAL_ELEMENTS_B,
    OPTIONAL_ELEMENTS_C,
    OPTIONAL_ELEMENTS_RESIDENCIAL,
    OPTIONAL_ELEMENTS_SUELO,
    OPTIONAL_ELEMENTS_TERCIARIO,
    OPTIONAL_ELEMENTS_WIP
} from '../../../../shared/checklists-wizard/constants/property/form-elements';
import { TRANSLATIONS_ITEMS as PROPERTIES_TRANSLATIONS_ITEMS } from '../../../../shared/checklists-wizard/constants/summary/propertySummaryItem/constants';
import { SnackBarContext } from '../../../../ui/snack-bar';
import { sendPicsHandleSubmit } from '../../../checklists/effects/send-pics-handle-submit.callback';
import FetchApi from '../../../../core/fetch-api';

const Step7Summary = (props) => {
    const { onChangeStepHandler } = props;
    const state = useSelector((s) => s.promotionData);
    const dispatch = useDispatch();
    const history = useHistory();
    const { setData, getData, putData } = useContext(PouchDBContext);
    const { state: userState } = useContext(UserContext);
    const [expanded, setExpanded] = useState(0);
    const {
        actions: { open: openSnackBar }
    } = useContext(SnackBarContext);

    useEffect(() => {
        FetchApi.refreshToken();
        window.scrollTo(0, 0);
        return () => {
            if (!onChangeStepHandler) onCancelPromotionWizard(dispatch, history);
        }
    }, []);

    const handleClick = (panel) => (_event, newExpanded) => setExpanded(newExpanded && panel);

    const onEdit = (step) => {
        onChangeStepHandler(step);
    }

    const fields = (
        <>
            {formSummary.map((element) => {
                return (
                    <div key={element.index}>
                        <ExpansionPanel
                            square
                            key={element.index}
                            expanded={expanded === element.activeIndex}
                            onChange={handleClick(element.activeIndex)}
                        >
                            <ExpansionPanelSummary>
                                <CheckCircleIcon />
                                <Typography variant='h6'>{element.title}</Typography>
                                <IconButton
                                    data-testid={`panel-${element.index}`}
                                    edge="end"
                                    size='small'
                                    style={{
                                        visibility: onChangeStepHandler ? 'visible' : 'hidden'
                                    }}
                                    onClick={() => onEdit(element.index)}
                                >
                                    <EditIcon />
                                </IconButton>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
                                <element.Component />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                )
            })}
        </>
    );

    const onClickSendHandler = () => {
        FetchApi.refreshToken();

        let aux, temp, idCloudant;
        let picturesDataImage = [];
        let picturesDataDescription = [];

        state.pictures.send.forEach((image) => {
            picturesDataImage.push(image[0]);
            picturesDataDescription.push(image[1]);
        });

        let checklist = {
            _id: uuidv4(),
            document: 'check-list',
            checklistStatus: 'in-progress',
            checklistCreatedAt: new Date(),
            checklistAssignedAt: state.item.checklistAssignedAt,
            propertyId: state.item.id,
            ...state.environment,
            ...state.picturesData,
            urs: state.urs,
            hasAttachments: state.pictures.send.length > 0
                ? true
                : false,
            attachments: picturesDataDescription,
            ticketId: state.item.ticketId,
            deadline: state.item.deadline,
        };

        Object.keys(state.screenGeneral).forEach((k) => {
            temp =
                PROPERTIES_FORM_ELEMENTS.find((e) => e.name === k) ||
                OPTIONAL_ELEMENTS_A(() => { }).find((e) => e.name === k) ||
                OPTIONAL_ELEMENTS_B(() => { }).find((e) => e.name === k) ||
                OPTIONAL_ELEMENTS_C(() => { }).find((e) => e.name === k) ||
                OPTIONAL_ELEMENTS_RESIDENCIAL(() => { }).find((e) => e.name === k) ||
                OPTIONAL_ELEMENTS_SUELO(() => { }).find((e) => e.name === k) ||
                OPTIONAL_ELEMENTS_TERCIARIO(() => { }).find((e) => e.name === k) ||
                OPTIONAL_ELEMENTS_WIP(() => { }).find((e) => e.name === k);
            if (temp && temp.type === 'dropdown') {
                aux = PROPERTIES_TRANSLATIONS_ITEMS.find((i) => i.documentKey === k);
                aux = aux.values.find((v) => v.value === state.screenGeneral[k]);
                if (aux) {
                    checklist[k] = aux;
                }
            } else {
                checklist[k] = state.screenGeneral[k];
            }
        });

        let checklistWithImages = {
            ...checklist,
            item: state.item,
            attachments: picturesDataImage,
            attachmentsData: picturesDataDescription
        };

        setData(
            checklist,
            'CHECKLIST',
            openSnackBar,
            () => {
                openSnackBar({
                    type: 'success',
                    visible: true,
                    title: `Creación de documento de tipo ${checklist.document}`,
                    message: `Se ha guardado correctamente el documento`,
                });

                idCloudant = `${userState.enrollment}:${checklist._id}:${checklist.document}`;

                getData({
                    document: history.location.pathname.indexOf('properties') > -1 ? 'properties' : state.item.document,
                    propertyId: state.item.id
                }).then((res) => {
                    const document = res.docs[0];
                    document.checklistStatus = 'in-progress';
                    document.propertyId = state.item.id;
                    document.checklistCreatedAt = new Date();
                    putData(document, 'UPDATE_PROPERTY');
                    sendPicsHandleSubmit(openSnackBar, idCloudant, picturesDataImage, FetchApi)
                }).finally(() => {
                    history.location.pathname.indexOf('properties') > -1
                        ? history.push('/leads')
                        : history.push('/checklists');
                });
            },
            null,
            () => {
                history.location.pathname.indexOf('properties') > -1
                    ? history.push('/leads')
                    : history.push('/checklists')
            },
            checklistWithImages
        );
    };
    const onBackWizard = () => {
        if (onChangeStepHandler) {
            onChangeStepHandler(state.pictures.send.length === 0 ? 4 : 5)
        } else {
            onCancelPromotionWizard(dispatch);
            history.location.pathname.indexOf('properties') > -1
                ? history.push('/leads')
                : history.push('/checklists')
        }
    };

    return (
        <WizardView
            title='Resumen'
            fields={fields}
            onSubmit={onChangeStepHandler ? onClickSendHandler : null}
            onBack={onBackWizard}
            haveBack={true}
            isPromotion={{ id: state.item?.id, address: state.item?.address }}
            progress={7}
        />
    )
}

export default Step7Summary