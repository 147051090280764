import StackTrace from 'stacktrace-js';
import LogsBuffer from '../../logs-buffer/logs-buffer';

import { getViewFromLocation } from '../constants';

export const stackTraceHandler = (history) => (event) => {
  if (event.error.hasBeenCaught) {
    return false;
  }
  const sendStackTrace = (resource = event.error) => {
    const view = getViewFromLocation(history);
    const url =
      history && history.location
        ? history.location.pathname
        : window.location.pathname;

    LogsBuffer.addLog({
      ...view,
      operationName: 'SetLogDnaEvent',
      resource,
      type: 'Frontend',
      event: 'Stacktrace',
      createdAt: Date.now(),
      url,
    });
  };

  event.error.hasBeenCaught = true;

  StackTrace.fromError(event.error)
    .then((callback) => {
      const resource = {
        message: event.error.message,
        stacktrace: callback.map(
          ({ columnNumber, lineNumber, fileName }) =>
            `${fileName}:${columnNumber}:${lineNumber}`
        ),
      };

      sendStackTrace(resource);
    })
    .catch(sendStackTrace);
};

export const LogDnaStackTraceService = (history) => ({
  getHandler: () => stackTraceHandler(history),
  initErrorHandler: () => {
    window.addEventListener('error', stackTraceHandler(history), false);
  },
});
