import { sendPicsHandleSubmit } from '../../../pages/checklists/effects/send-pics-handle-submit.callback';
import { getCurrentDate } from '../../../store/utils';
import { isDocumentValid, todayActivities } from '../../../utils';
import consoleLog from '../../console-log/consoleLog';
import StorageService from '../../fetch-api/storage-service';
import { mapisv2PendingRequestsDB } from '../../pending-requests-db';
import FetchApi from '../../fetch-api';
import { addLogs } from '../../logs-buffer/addLogs';

/**
 * UseEffect init app: pouch-db.service
 * When starting, it checks the connection if it is online, it deletes the invalid documents from local,
 * and sends those that have remained in the offline queue through the 'addToPendingRequest' and sent
 * with the 'sendPendingRequest' to remote
 */
const DOCUMENTS_TO_DELETE = [
  'alert',
  'clients',
  'activities',
  'incidences',
  'properties',
  'preferences',
  'notifications',
];

const updateId = (obj, user) => {
  const arrayId = obj['_id'].split(':');
  return `${user}:${arrayId[1]}:${obj.document}`;
};

const updateCreatedAt = () => todayActivities();

const newId = (obj, user) => ({
  _id: `${user}:${obj['_id']}:${obj.document}`,
});

/** Add header all fetch POUCH, type managements 'DOES_NOT_APPLY' and CL "@isConsolidated"= TRUE*/
const metadata = (user, action, createdAt, uid = null) => {
  return {
    '@user': user,
    '@isConsolidated':
      action === 'CHECKLIST'
        ? true
        : action === 'DOES_NOT_APPLY'
          ? true
          : false,
    '@actionID': action || 'UNKNOWN',
    '@deviceID': window.localStorage.getItem('wuid_v2')
      ?? uid
      ?? '@deviceID - not found',
    createdAt: createdAt || todayActivities(),
  };
};

const removeUndefineds = (query) => {
  Object.keys(query).forEach((key) => {
    if (query[key] === undefined) {
      delete query[key];
    }
  });
};

const deleteOldData = (PouchDBInstance) => {
  PouchDBInstance.getAll().then((docs) => {
    const { rows } = docs;

    const showDocumentDeleted = [];
    rows.forEach((document) => {
      if (
        !isDocumentValid(document.doc) &&
        DOCUMENTS_TO_DELETE.includes(document.doc.document) &&
        document._deleted !== true && document.doc.type !== 'property'
      ) {
        showDocumentDeleted.push(document.id);
        PouchDBInstance.deleteItem(document.doc._id, document.doc._rev);
      }
    });
    consoleLog(
      'log',
      'Datos eliminados de forma rutinaria',
      showDocumentDeleted
    );
  });
};

const addToPendingRequest = async (openSnackbar, toSend) => {
  if (
    window &&
    window.navigator &&
    !window.navigator.onLine &&
    !toSend._id.includes('_local/')
  ) {
    try {
      await mapisv2PendingRequestsDB.pendingRequests.add({ document: toSend });
      openSnackbar({
        type: 'success',
        visible: true,
        title: `Creación de documento de tipo ${toSend.document}`,
        message: `Documento agregado a peticiones pendientes. Se subirá cuando recupere la conexión a Internet`,
      });
    } catch (error) {
      openSnackbar({
        type: 'error',
        visible: true,
        title: `Creación de documento de tipo ${toSend.document}`,
        message: `Hubo un error al guardar en pendientes el documento con id ${toSend._id}`,
      });
      console.error(`Error al subir el documento: ${JSON.stringify(error)}`);
    }
  }
};

const sendPendingRequest = async (PouchDBInstance, openSnackbar, history) => {
  const pendingRequests =
    await mapisv2PendingRequestsDB.pendingRequests.toArray();
  pendingRequests.forEach((element) => {

    // New variable for create checklist document with attachment empty
    let elementFiltered;
    if (element.document.document === 'check-list') {
      elementFiltered = { ...element.document, attachments: [] }

      elementFiltered.attachments = element.document.attachmentsData ?? [];

      // Filter variable for dont save "item" field.
      elementFiltered = Object.entries(elementFiltered).filter(
        ([key, value]) => {
          if (key !== 'item') {
            return { key: value };
          }
        }
      );

      // Convert to object.
      elementFiltered = Object.fromEntries(elementFiltered);
    }

    // Log saying gonna create document
    addLogs(
      `new ${element.document.document} - offline`,
      'WRITE_TRACES',
      history.location.pathname,
      `Petición de crear ${element.document.document}`,
      element.document['@user'],
      element.document
    );

    PouchDBInstance.remoteDB
      .post(elementFiltered ?? element.document)
      .then(() => {
        // Log saying document created successfully
        addLogs(
          `new ${element.document.document} - offline`,
          'WRITE_TRACES',
          history.location.pathname,
          `Respuesta de crear ${element.document.document}: success`,
          element.document['@user'],
          element.document
        );

        if (element.document.document === 'check-list') {
          let idCloudant = elementFiltered._id;

          // Send images to WebService
          sendPicsHandleSubmit(
            openSnackbar,
            idCloudant,
            element.document.attachments,
            FetchApi,
            elementFiltered['@user'],
            true
          );

          // Log saying gonna update parent document
          addLogs(
            'new check-list - offline',
            'WRITE_TRACES',
            history.location.pathname,
            `Petición de actualizar ${element.document.item.document}`,
            element.document['@user'],
            element.document
          );

          // Get document to update it
          PouchDBInstance.localDB
            .find({
              selector: {
                document: element.document.item.document,
                propertyId: element.document.propertyId,
              },
            })
            .then((res) => {
              let documents = res.docs;

              documents = documents.sort((a, b) => {
                return new Date(b.checklistAssignedAt) - new Date(a.checklistAssignedAt)
              });

              let document = documents[0];

              document.checklistStatus = 'in-progress';
              document.attachments = [];
              document.checklistCreatedAt = getCurrentDate();
              document['@action'] = 'UPDATE_PROPERTY';

              // Send update
              PouchDBInstance.localDB.post(document);

              // Log saying document updated success
              addLogs(
                'new check-list - offline',
                'WRITE_TRACES',
                history.location.pathname,
                `Respuesta de actualizar ${element.document.item.document}: success`,
                document['@user'],
                document
              );
            });
        } else if (element.document.document === 'activities') {
          PouchDBInstance.localDB
            .find({
              selector: {
                document: 'managements',
                managementId: element.document.managementId,
              },
            })
            .then((res) => {
              const document = res.docs[0];
              document.typeActivity = element.document.name;
              document.createdAtFull = new Date().toISOString();
              document['@action'] = 'UPDATE_PROPERTY';
              PouchDBInstance.localDB.post(document);
            });
        }

        // User pop-up saying all is ok
        openSnackbar({
          type: 'success',
          visible: true,
          title: `Subida a remoto del documento tipo ${element.document.document}`,
          message: `El documento pendiente ha sido subido satisfactoriamente.`,
        });

        mapisv2PendingRequestsDB.pendingRequests.delete(element.id);
      })
      .catch((err) => {
        consoleLog(
          'error',
          'Error al subir a cloudant el documento pendiente: ' +
          JSON.stringify(err)
        );

        // User pop-up saying there's an error
        openSnackbar({
          type: 'error',
          visible: true,
          title: `Subida a remoto del documento de tipo ${element.document.document}`,
          message: `Hubo un error al subir el documento`,
        });

        // Log saying there's an error
        addLogs(
          `new ${element.document.document} - offline`,
          'WRITE_TRACES',
          history.location.pathname,
          `Respuesta de crear ${element.document.document}: error`,
          element.document['@user'],
          err
        );
      });
  });
};

const check403 = (err) => {
  if (err && err.status === 403) {
    StorageService.save('reducer_v2', JSON.stringify({ auth: {} }));
    window.location.href = window.location.origin;
  }
};

export {
  metadata,
  newId,
  updateId,
  updateCreatedAt,
  removeUndefineds,
  deleteOldData,
  addToPendingRequest,
  sendPendingRequest,
  check403,
};
