import FetchApi from '../fetch-api';

const arrayLogs = [];
let timeStarted = false;

const LogsBuffer = {
  addLog: (logToAdd) => {
    arrayLogs.push(logToAdd);
    if (!timeStarted) {
      timeStarted = true;
      setTimeout(() => {
        LogsBuffer.sendLogs();
      }, process.env.REACT_APP_SECONDS_TO_SEND_LOGS * 1000);
    }
  },
  sendLogs: () => {
    if (window && window.navigator && window.navigator.onLine) {
      FetchApi.request('post', '/traces', arrayLogs).then((res) => {
        if (res && res.status === 200) {
          timeStarted = false;
          arrayLogs.length = 0;
        } else {
          setTimeout(() => LogsBuffer.sendLogs(), 5000);
        }
      });
    } else {
      setTimeout(() => LogsBuffer.sendLogs(), 5000);
    }
  },
  sendLogsReqRes: (logToAdd, attemptsCounter = 0, totalAttemptsCounter = 5) => {
    FetchApi.request('post', '/traces', [logToAdd])
      .then((res) => {
        if (res && res.status !== 200) {
          if (attemptsCounter < totalAttemptsCounter) {
            attemptsCounter++;

            setTimeout(() => LogsBuffer.sendLogsReqRes(logToAdd, attemptsCounter++), 1000);
          }
        }
      })
      .catch((err) => {
        if (attemptsCounter < totalAttemptsCounter) {
          attemptsCounter++;

          setTimeout(() => LogsBuffer.sendLogsReqRes(logToAdd, attemptsCounter++), 1000);
        }
      });

    // Attempts loop
    // if (window && window.navigator && window.navigator.onLine) {
    //   FetchApi.request('post', '/traces', [logToAdd]);
    // } else {
    //   setTimeout(() => LogsBuffer.sendLogsReqRes(logToAdd), 2000);
    // }
  },
};

export default LogsBuffer;