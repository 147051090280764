import { EnviromentSummaryItem } from './EnviromentSummaryItem/EnviromentSummaryItem';
import { PropertySummaryItem } from './PropertySummaryItem/PropertySummaryItem';
import { SecurityMaintenanceSummaryItem } from './SecurityMaintenanceSummaryItem/SecurityMaintenanceSummaryItem';
import { PicturesSummaryItem } from './PicturesSummaryItem/PicturesSummaryItem';

export const formSummary = [
  {
    title: 'Inmueble',
    activeIndex: 0,
    index: 0,
    Component: PropertySummaryItem,
  },
  {
    title: 'Seguridad y Mantenimiento',
    activeIndex: 1,
    index: 1,
    Component: SecurityMaintenanceSummaryItem,
  },
  {
    title: 'Entorno',
    activeIndex: 2,
    index: 2,
    Component: EnviromentSummaryItem,
  },
  {
    title: 'Fotos',
    activeIndex: 3,
    index: 3,
    Component: PicturesSummaryItem,
  },
];
