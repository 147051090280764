import React from 'react';
import { Button } from '@material-ui/core';
import {
  Divider,
  Form,
  Checkbox,
  Input,
  Dropdown,
  Radio,
} from 'semantic-ui-react';

export const getFormElements = (classes, tableValues, externalData) => {
  return [
    {
      component: (register, setValue) => {
        const options = [
          { value: 1, label: 'Venta' },
          { value: 3, label: 'Alquiler' },
        ];
        return (
          <Form.Group>
            {options.map((op) => {
              return (
                <Form.Field>
                  <Radio
                    {...register('commercialType')}
                    checked={externalData.commercialType == op.value}
                    label={op.label}
                    value={op.value}
                    name="commercialType"
                    onChange={(e, { value }) =>
                      setValue('commercialType', value)
                    }
                  />
                </Form.Field>
              );
            })}
          </Form.Group>
        );
      },
      name: 'commercialType',
    },
    {
      component: () => <Divider className={classes.divider} />,
      name: 'divider',
    },
    {
      component: (register, setValue) => {
        return (
          <div className='checkbox'>
            <label>Inmueble WOW</label>
            <Checkbox
              {...register('propertyWow')}
              onChange={(e, { checked }) => {
                setValue('propertyWow', checked);
              }}
              toggle
            />
          </div>
        );
      },
      name: 'propertyWow',
    },
    {
      component: () => <Divider className={classes.divider} />,
      name: 'divider',
    },
    {
      component: (register, setValue) => {
        return (
          <div className='checkbox'>
            <label>Mi perímetro</label>
            <Checkbox
              {...register('myFootprint')}
              onChange={(e, { checked }) => {
                setValue('myFootprint', checked);
              }}
              toggle
            />
          </div>
        );
      },
      name: 'myFootprint',
    },
    {
      component: () => <Divider className={classes.divider} />,
      name: 'divider',
    },
    {
      component: (register, setValue) => {
        return (
          <>
            <label>Ref. inmueble</label>
            <Input
              {...register('reference')}
              onChange={(e, { value }) => setValue('reference', value)}
              placeholder="Ref. inmueble"
            />
          </>
        );
      },
      name: 'reference',
    },
    {
      component: (register, setValue) => {
        const options = tableValues.propertyType?.map((pt) => {
          return {
            text: pt.label,
            value: pt.value,
          };
        });
        return (
          <>
            <label>Tipo de inmueble</label>
            <Dropdown
              {...register('propertyType')}
              search
              selection
              fluid
              multiple
              clearable
              onChange={(e, { value }) => setValue('propertyType', value)}
              options={options}
            />
          </>
        );
      },
      name: 'propertyType',
    },

    {
      component: (register, setValue) => {
        const options = tableValues.chambers?.map((pt) => {
          return {
            text: pt.label,
            value: pt.value,
          };
        });
        return (
          <>
            <label>Hab.</label>
            <Dropdown
              {...register('chambers')}
              search
              selection
              fluid
              multiple
              clearable
              onChange={(e, { value }) => setValue('chambers', value)}
              options={options}
            />
          </>
        );
      },
      name: 'chambers',
    },
    {
      component: (register, setValue) => {
        const options = externalData.provinces?.map((pt) => {
          return {
            text: pt.label,
            value: pt.value,
          };
        });
        return (
          <>
            <label>Provincia</label>
            <Dropdown
              {...register('provinceId')}
              search
              selection
              fluid
              clearable
              onChange={(e, { value }) => setValue('provinceId', value)}
              options={options}
            />
          </>
        );
      },
      name: 'provinceId',
    },
    {
      component: (register, setValue) => {
        const options = externalData.localities?.map((pt) => {
          return {
            text: pt.label,
            value: pt.value,
          };
        });
        return (
          <>
            <label>Localidad</label>
            <Dropdown
              {...register('localityId')}
              search
              selection
              fluid
              clearable
              onChange={(e, { value }) => setValue('localityId', value)}
              options={options}
            />
          </>
        );
      },
      name: 'localityId',
    },
    {
      component: (register, setValue) => {
        const options = externalData.zipcodes?.map((pt) => {
          return {
            text: pt.label,
            value: pt.value,
          };
        });
        return (
          <>
            <label>Código Postal</label>
            <Dropdown
              {...register('zipCodeId')}
              search
              selection
              fluid
              clearable
              onChange={(e, { value }) => setValue('zipCodeId', value)}
              options={options}
            />
          </>
        );
      },
      name: 'zipCodeId',
    },
    {
      component: (register, setValue) => {
        return (
          <>
            <label>Dirección</label>
            <Input
              {...register('address')}
              onChange={(e, { value }) => setValue('address', value)}
              placeholder="Dirección:"
            />
          </>
        );
      },
      name: 'address',
    },
    {
      component: () => <Divider className={classes.divider} />,
      name: 'divider',
    },
    {
      component: (register, setValue) => {
        const options = tableValues.salePrices?.map((pt) => {
          return {
            text: pt.label,
            value: pt.value,
          };
        });
        return (
          externalData.commercialType == '1' && (
            <>
              <label>Precio de venta</label>
              <Dropdown
                {...register('salePrice')}
                search
                selection
                fluid
                clearable
                onChange={(e, { value }) => setValue('salePrice', value)}
                options={options}
              />
            </>
          )
        );
      },
      name: 'salePrice',
    },
    {
      component: (register, setValue) => {
        const options = tableValues.rentPrices?.map((pt) => {
          return {
            text: pt.label,
            value: pt.value,
          };
        });
        return (
          externalData.commercialType == '3' && (
            <>
              <label>Precio de alquiler</label>
              <Dropdown
                {...register('rentPrice')}
                search
                selection
                fluid
                clearable
                onChange={(e, { value }) => setValue('rentPrice', value)}
                options={options}
              />
            </>
          )
        );
      },
      name: 'rentPrice',
    },
  ];
};

export const renderButtonsForm = (externalData) => {
  return [
    {
      Button: Button,
      props: {
        color: 'primary',
        onClick: externalData.history.goBack,
        label: 'Cancelar',
      },
    },
    {
      Button: Button,
      props: {
        type: 'submit',
        color: 'primary',
        variant: 'contained',
        label: 'Buscar',
        onClick: externalData.onSubmit,
      },
    },
  ];
};
