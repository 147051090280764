import React, { useMemo } from 'react';

import { ThemeProvider } from '@material-ui/core/styles';

import { theme } from '../../styles';
import { Modal } from '../../ui/modal';
import { SnackBar } from '../../ui/snack-bar';
import { userReducer } from '../../core/user';
import { BootstrapRouter } from '../bootstrap.routing';
import { usePersistReducer } from '../../core/persist-reducer';

export const BootstrapComponent = () => {
  const [userState, userDispatch] = usePersistReducer(userReducer, {}, 'auth');

  const RouterComponent = useMemo(
    () => <BootstrapRouter state={userState} dispatch={userDispatch} />,
    [userState, userDispatch]
  );

  return (
    <ThemeProvider theme={theme}>
      <Modal>
        <SnackBar>{RouterComponent}</SnackBar>
      </Modal>
    </ThemeProvider>
  );
};
