import { isChecklistExpired, isChecklistCreated, isChecklistDuplicated, isPublished } from '../../notifications/utils';

/** Get data by cloudant, sync indexdb and remote  */
export const checklistsEffect =
  (getData, setChecklists, isSyncing, deleteData, FetchApi) => () => {
    if (!isSyncing) {
      return getData({ document: 'notifications' }).then(async (res) => {
        let data = res.docs;


        let checklists = await getData({ document: "check-list", checklistStatus: "in-progress" }).then(async (res) => {
          return res.docs;
        });

        let properties = await getData({ document: "properties" }).then(async (res) => {
          return res.docs;
        });

        filtersChecklistsEffect(data, setChecklists, deleteData, checklists, properties);
      });
    }
  };

const filtersChecklistsEffect = (rawNotifications, setChecklists, deleteData, checklists, properties) => {
  let dataInProgress = properties.filter(element => element.checklistStatus === "in-progress");
  let notifications = rawNotifications;

  notifications = notifications.filter((element) => isChecklistExpired(element, deleteData));
  notifications = notifications.filter((element) => isChecklistCreated(dataInProgress, element, deleteData));

  // Order notifications by "checklistAssignedAt"
  notifications = notifications.sort((a, b) => {
    return new Date(b.checklistAssignedAt) - new Date(a.checklistAssignedAt);
  });
  // Delete notifications which are duplicated in order
  notifications = notifications.filter((element, index, self) => isChecklistDuplicated(element, index, self));

  // Filter notifications for baja/vendida/publicable 
  notifications = notifications.filter((element) => isPublished(element, properties, deleteData));

  // Added check-lists proactive 
  checklists.map(checklist => {
    // Delete notification if there is a checklist in-progress
    notifications = notifications.filter(notification => notification.propertyId !== checklist.propertyId);

    // Get property details
    let checklistProperty = 
    properties.filter(property => property.propertyId === checklist.propertyId)[0] 
    ?? rawNotifications.filter(notification => notification.propertyId === checklist.propertyId)[0]?.urProperty;

    notifications.push({
      ...checklist,
      reference: checklistProperty?.reference ?? checklist.propertyId,
      description: checklistProperty?.description ?? checklistProperty?.urPropertyDetail?.description,
      categoryText: checklistProperty?.categoryText,
      salePrice: checklistProperty?.salePrice,
      address: checklistProperty?.address,
      locality: checklistProperty?.locality,
      codpost: checklistProperty?.codpost,
      province: checklistProperty?.province,
    });
  });

  notifications = notifications.filter((element) => isChecklistExpired(element, deleteData));

  notifications = notifications.map((element) => {
    element.typeText = 'Servicio';
    return element;
  });

  notifications = notifications.map((notification) => {
    if (
      notification &&
      notification.urProperty &&
      notification.urProperty.urPropertyDetail
    ) {
      const result = {
        ...notification.urProperty.urPropertyDetail,
        ...notification.urProperty,
        ...notification,
      };
      delete result.metadata;
      delete result.recordedDomainEvents;
      delete result.urProperty;
      delete result.urPropertyDetail;
      return result;
    }

    return notification;
  });

  if (notifications) setChecklists(notifications);
}
