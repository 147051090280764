export const MENU_LIST = [
  {
    path: '/leads',
    label: 'Gestiones',
    icon: 'handshake',
    default: true,
  },
  {
    path: '/properties-filter',
    label: 'Listado Inmuebles',
    icon: 'homework',
    offlineMsg:
      'Para acceder a esta función, es necesario disponer de conexión',
  },
  // ! Commented - 25/10/2022
  {
    path: '/checklists',
    label: 'Checklists',
    icon: 'checkIcon',
  },
  {
    label: 'Notificaciones',
    icon: 'bell',
    children: [
      {
        path: '/notifications',
        search: 'type=service',
        label: 'Nuevas gestiones',
        icon: 'no',
      },
      {
        path: '/notifications',
        search: 'type=inactivity-service',
        label: 'Nuevas gestiones de más de 48h',
        icon: 'no',
      },
      {
        path: '/notifications',
        search: 'type=campaign',
        label: 'Nuevos comunicados',
        icon: 'no',
      },
      {
        path: '/notifications',
        search: 'type=property',
        label: 'Nuevas unidades registrales',
        icon: 'no',
      },
    ],
  },
  {
    path: '/kpis',
    label: 'Seguimiento KPIs',
    icon: 'leaderboardIcon',
    offlineMsg:
      'Para acceder a esta función, es necesario disponer de conexión',
  },
];
