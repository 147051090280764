import React from 'react';
import { Icon } from '@material-ui/core';
const ImageUploaderImage = ({ handleRemoveAttachment, image, index }) => {
  return (
    <>
      <span
        data-testid="remove-image"
        key={index}
        style={{
          position: 'relative',
          padding: '4px 4px 4px 0',
          cursor: 'pointer',
        }}
        onClick={() => handleRemoveAttachment(index, image.name)}
      >
        <img
          src={image.src}
          key={image.src}
          alt="error"
          style={{
            height: '80px',
            width: '80px',
            position: 'relative',
          }}
        />
        <div
          style={{
            position: 'absolute',
            height: '80px',
            width: '80px',
            top: '-62px',
            left: '0px',
            backgroundColor: '#000000',
            opacity: 0.4,
          }}
        >
          <Icon
            style={{
              color: '#FFFFFF',
              fontSize: '34px',
              position: 'absolute',
              top: '20px',
              left: '23px',
            }}
          >
            delete_outline
          </Icon>
        </div>
      </span>
    </>
  )
}

export default ImageUploaderImage;