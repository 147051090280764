export const TRANSLATIONS_ITEMS = [
    {
        documentKey: 'name',
        type: 'input',
        summaryKey: 'Nombre',
        values: '',
      },
      {
        documentKey: 'phone',
        type: 'input',
        summaryKey: 'Teléfono',
        values: '',
      },
      {
        documentKey: 'email',
        type: 'input',
        summaryKey: 'Email',
        values: '',
      },
      {
        documentKey: 'comment',
        type: 'input',
        summaryKey: 'Comentario',
        values: '',
      },
];