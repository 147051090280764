import React from 'react';

import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const PREFERENCES_LABEL = {
  price: 'Precio',
  locality: 'Localidad',
  chambers: 'Habitaciones',
  categoryText: 'Tipo de inmueble',
  description: 'Descripción',
  contactMode: 'Modo de contacto',
};

export const PREFERENCES_INFO = [
  {
    force: true,
    showKey: true,
    label: 'Preferencia',
    icon: <CheckCircleOutlineIcon />,
    custom: (data) =>
      Object.keys(data).map(
        (e, key) =>
          [
            '_id',
            '_rev',
            'document',
            'clientId',
            'type',
            '_revisions',
            'createdAt',
            'commercialType',
            'category',
          ].indexOf(e) < 0 &&
          data[e] && (
            <span key={key}>
              <span>
                {PREFERENCES_LABEL[e] || e}:{' '}
                {typeof data[e] === 'object' ? data[e].label : data[e]}
              </span>
              <br />
            </span>
          )
      ),
  },
];

export const CLIENT_INFO = [
  {
    icon: <PermIdentityIcon />,
    label: 'Nombre',
    attr: 'name',
  },
  {
    icon: <PhoneIcon />,
    label: 'Teléfono 1',
    attr: 'phone1',
  },
  {
    icon: <PhoneIcon />,
    label: 'Teléfono 2',
    attr: 'phone2',
  },
  {
    icon: <MailOutlineIcon />,
    label: 'E-mail',
    attr: 'email',
  },
];
