const styledBy = (property, mapping) => (props) => mapping[props[property]];

export const inputStyles = {
  root: {
    margin: '10px 0',
    width: '100%',
    border: 'none',
    borderRadius: 4,
    background: styledBy('background', {
      default: '#fff',
      blocked: '#efefef',
    }),
  },
  field: {
    borderRadius: 4,
  },
  input: {
    margin: 0,
    borderBottom: 'none',
    borderRadius: 'inherit',
    color: styledBy('color', {
      red: 'red',
      blue: 'blue',
    }),
  },
  label: {
    color: styledBy('color', {
      red: 'red',
      blue: 'blue',
    }),
  },
};
