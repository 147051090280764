import React from 'react';
import { Box, MobileStepper, Typography } from '@mui/material';

/** Stepper progress and title - CL Promotion */
const StepperPromotionCL = ({ progress = 0, title = {}, steps = 0 }) => {
  return (
    <>
      <div className='stepper-promotion'>
        <MobileStepper
          className='stepper-promotion__content'
          variant="progress"
          steps={steps}
          position="static"
          activeStep={progress}
          sx={{
            maxWidth: '100%',
            flexGrow: 1,
            padding: '0',
          }}
        />
      </div>
      <Box className='stepper-title'>
        <Typography data-testid="stepper-title-text" className='stepper-title__text'>
          {title?.id} {title?.address}
        </Typography>
      </Box>
    </>
  );
};

export default StepperPromotionCL;
