import React from 'react';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';
import NumberFormat from 'react-number-format';

import { inputStyles } from './input.style';

export const InputCustom = withStyles(inputStyles)(
  ({ classes, background, color, ...props }) => {
    return (
      <FormControl className={classes.root}>
        {props.currencyFormat === true ? (
          <NumberFormat
          fullWidth={props.fullWidth}
          name={props.name}
          value={
            props.value === undefined || props.value.charAt(0) === '0'
              ? ''
              : props.value
          }
          variant={props.variant}
          customInput={TextField}
          allowLeadingZeros={false}
          allowNegative={false}
          suffix={'€'}
          thousandSeparator={'.'}
          decimalSeparator={false}
          label={props.label}
          onValueChange={(value) => {
            props.onChange(null, value.value);
          }}
          inputProps={{
            'data-testid': 'input-currencyFormat',
          }}
          />
        ) : (
          <TextField
            {...props}
            select={false}
            type={props.type}
            value={props.value || ''}
            variant={props.variant}
            onChange={(e) => props.onChange(null, e.target.value)}
            className={classes.field}
            label={props.label}
            outlined={props.outlined ? 'true' : 'false'}
            InputLabelProps={{ className: classes.label }}
            InputProps={{ className: classes.input, ...props.InputProps }}
            inputProps={{
              'data-testid': 'input-notCurrencyFormat'
            }}
          />
        )}
      </FormControl>
    );
  }
);
