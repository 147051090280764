const getListValues = (list) => (list && list.length)
  ? list
  : null;

const parsedData = (data) => ({
  address: data.address,
  reference: data.reference,
  myFootprint: !!data.myFootprint,
  propertyWow: !!data.propertyWow,
  zipCodeId: data.zipCodeId,
  provinceId: data.provinceId,
  localityId: data.localityId,
  chambers: getListValues(data.chambers),
  rentPrice: getListValues(data.rentPrice),
  salePrice: getListValues(data.salePrice),
  commercialType: `0${data.commercialType}`,
  propertyType: getListValues(data.propertyType),
  order: data.order,
  paging_page: data.paging?.page,
  paging_limit: data.paging?.limit,
});

export const getPropertiesAction =
  (fetchApi, properties, setProperties) => async (filter) => {
    const dto = parsedData({ ...properties.filter, ...filter });
    if (!dto.myFootprint) delete dto.myFootprint;
    if (!dto.propertyWow) delete dto.propertyWow;

    const list = await fetchApi
      .search('get', '/urproperty', dto)
      .then((res) => {
        if (res && res.status === 200) return res.results;
        return [];
      });

    list.forEach(
      (element) =>
      (element.price =
        dto.commercialType === '03' ? element.rentPrice : element.salePrice)
    );

    setProperties({ payload: { list, filter } });
  };
