import React, { useReducer } from 'react';

import { snackBarReducer } from './reducers';
import { SnackBarProvider } from './contexts';
import { SnackBarComponent } from './components';
import { DEFAULT_STATE, OPEN, CLOSE } from './constants';

export const SnackBar = ({ children }) => {
  const [state, dispatch] = useReducer(snackBarReducer, DEFAULT_STATE);

  const actions = {
    open: (payload) => dispatch({ payload, ...OPEN }),
    close: (payload) => dispatch({ payload, ...CLOSE }),
  };

  return (
    <SnackBarProvider value={{ actions, ...state }}>
      <SnackBarComponent />
      {children}
    </SnackBarProvider>
  );
};
