import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
} from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import React from 'react';
import { Input, RadioGroup, Switch, Typeahead } from '../../ui';
import { LocationFormComponent } from '../components/location-form';

export const getPropertiesFilterElements = (
  { classes, form, handleChange, validators },
  { tableValues, history, loading }
) => {
  const handleCommercialTypeChange = (event) => {
    handleChange({
      target: {
        name: { 3: 'salePrice', 1: 'rentPrice' }[event.target.value],
        value: [],
      },
    });
    handleChange(event);
  };

  return {
    fields: [
      {
        Field: RadioGroup,
        props: {
          name: 'commercialType',
          values: [
            { value: 1, label: 'Venta' },
            { value: 3, label: 'Alquiler' },
          ],
          className: classes.formControl,
          value: form.commercialType,
          onChange: handleCommercialTypeChange,
        },
      },
      {
        Field: Divider,
        props: {
          className: classes.divider,
        },
      },
      {
        Field: Switch,
        props: {
          name: 'myFootprint',
          label: 'Mi perímetro',
          onChange: handleChange,
          checked: form.myFootprint,
        },
      },
      {
        Field: Switch,
        props: {
          name: 'propertyWow',
          label: 'Inmueble WOW',
          onChange: handleChange,
          checked: form.propertyWow,
        },
      },
      {
        Field: Divider,
        props: {
          className: classes.divider,
        },
      },
      {
        Field: Input,
        props: {
          name: 'reference',
          variant: 'filled',
          label: 'Ref. inmueble',
          value: form.reference,
          onChange: handleChange,
        },
      },
      {
        Field: Typeahead,
        props: {
          config: {
            variant: 'filled',
            label: 'Tipo de inmueble',
            multiple: true,
            className: classes.control,
            error: validators.propertyType.hasError,
            errormessage: validators.propertyType.message,
          },
          model: {
            name: 'propertyType',
            value: form.propertyType,
            values: tableValues.propertyType,
          },
          onChange: handleChange,
        },
      },
      {
        Field: Typeahead,
        props: {
          config: {
            label: 'Hab.',
            variant: 'filled',
            multiple: true,
          },
          model: {
            name: 'chambers',
            value: form.chambers,
            values: tableValues.chambers,
          },
          onChange: handleChange,
        },
      },
      {
        Field: LocationFormComponent,
        props: {
          form,
          handleChange,
          validators,
        },
      },
      {
        Field: Input,
        props: {
          name: 'address',
          variant: 'filled',
          label: 'Dirección',
          value: form.address,
          onChange: handleChange,
        },
      },
      {
        Field: Divider,
        props: {
          className: classes.divider,
        },
      },
      {
        Field: Typeahead,
        props: {
          config: {
            label: 'Precio venta',
            variant: 'filled',
            multiple: true,
          },
          model: {
            name: 'salePrice',
            hidden: form.commercialType === 3,
            value: form.salePrice,
            values: tableValues.salePrices,
          },
          onChange: handleChange,
        },
      },
      {
        Field: Typeahead,
        props: {
          config: {
            variant: 'filled',
            multiple: true,
            label: 'Precio alquiler',
          },
          model: {
            name: 'rentPrice',
            value: form.rentPrice,
            hidden: form.commercialType === 1,
            values: tableValues.rentPrices,
          },
          onChange: handleChange,
        },
      },
    ],
    buttons: [
      {
        Button: Button,
        props: {
          color: 'primary',
          onClick: history.goBack,
          label: 'Cancelar',
        },
      },
      {
        Button: Button,
        props: {
          type: 'submit',
          color: 'primary',
          disabled: loading ? true : null,
          variant: 'contained',
          label: loading ? (
            <CircularProgress size={25} color="primary" />
          ) : (
            'Buscar'
          ),
        },
      },
    ],
  };
};

export const getPropertiesHeaderElements = (history, clientId) => ({
  buttons: [
    {
      Button: IconButton,
      Icon: TuneIcon,
      props: {
        onClick: () =>
          history.push(
            `/properties-filter${clientId ? `?clientId=${clientId}` : ''}`
          ),
      },
    },
  ],
});
