import React, { useEffect, useState } from 'react';

import { useQuery } from '../../../core/hooks';
import { usePouchDbContext } from '../../../core/pouch-db';

import { notificationsEffect } from '../effects';
import { LoadingMessageComponent } from '../../../shared/loading';
import { NotificationsListComponent } from './notifications-list.component';
import FetchApi from '../../../core/fetch-api';

export const NotificationsComponent = () => {
  const { type } = useQuery();
  const { getData, deleteData, isSyncing } = usePouchDbContext();
  const [notifications, setNotifications] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0)
 }, [])
  useEffect(
    notificationsEffect(getData, type, setNotifications, isSyncing, deleteData),
    [getData, type, isSyncing]
  );
  useEffect(() => {
    FetchApi.refreshToken();
  }, [type]);

  return notifications ? (
    <NotificationsListComponent data={notifications} type={type} />
  ) : (
    <LoadingMessageComponent resource="notificaciones" />
  );
};
