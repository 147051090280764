import '@sandstreamdev/react-swipeable-list/dist/styles.css';

import React, { useContext } from 'react';

import { Card, CardContent, Icon } from '@material-ui/core';
import {
  SwipeableListItem,
  SwipeableList,
} from '@sandstreamdev/react-swipeable-list';

import './list.component.scss';

import { NoData } from '../../../elements/no-data/components';
import { ListTypeIconComponent } from './list-type-icon.component';
import { ModalContext } from '../../../ui/modal/contexts';
import { DialogCommercial } from '../../dialog/components';
import { LOGO_IS_WOW } from '../../../__mock__/base-64.isWow.mock';
import { LOGO_IS_PRICING } from '../../../__mock__/base-64.isPricing.mock';

const ListItemSwipeComponent = (props) => {
  return (
    <div
      className="swipeable-list-item__container"
      style={{
        backgroundColor: props.backgroundColor,
        color: props.color,
        justifyContent: `flex-${props.position}`,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        padding: '30px',
        borderRadius: '4px',
      }}
    >
      <Icon>{props.icon}</Icon>
    </div>
  );
};

const ListItemTitleComponent = ({
  element,
  config,
  hasTwoLinesTitle,
  isChecklist,
}) => {
  /** Ref. is red if tab is new and  checklistExpireAt < now*/
  const isExpire = (checklistExpireAt, checklistStatus, deadline, isChecklist) => {
    let expire = false;

    if (deadline) {
      let year = element.deadline.substr(0, 4);
      let month = element.deadline.substr(4, 2) - 1;
      let day = element.deadline.substr(6, 2);

      expire = new Date(year, month, day).getTime() < new Date().getTime();
    } else {
      expire = new Date(checklistExpireAt).getTime() < new Date().getTime();
    }

    return isChecklist && checklistStatus === 'new' && expire;
  };

  return (
    <span
      style={
        hasTwoLinesTitle
          ? {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            color: isExpire(
              element.checklistExpireAt,
              element.checklistStatus,
              element.deadline,
              isChecklist
            )
              ? '#F68895'
              : 'black',
          }
          : {
            flex: 1,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: isExpire(
              element.checklistExpireAt,
              element.checklistStatus,
              element.deadline,
              isChecklist
            )
              ? '#F68895'
              : 'black',
          }
      }
    >
      {config.title.parsedTo
        ? config.title.parsedTo(element)
        : element[config.title]}
    </span>
  );
};

const ListItemExtraComponent = ({ element, config }) => {
  if (!config.hasOwnProperty('extra')) return <></>;
  let extraContent;
  switch (config.extra.type) {
    case 'fav':
      extraContent = element[config.extra.key] ? (
        <i
          className="material-icons"
          style={{
            fontSize: '18px',
            color: '#006666',
            paddingTop: '5px',
          }}
        >
          star
        </i>
      ) : (
        <></>
      );
      break;
    case 'dot':
      extraContent = !element[config.extra.key] ? (
        <i
          className="material-icons"
          style={{
            fontSize: '14px',
            color: '#F6931D',
            paddingTop: '5px',
          }}
        >
          fiber_manual_record
        </i>
      ) : (
        <></>
      );
      break;
    default:
      extraContent = <></>;
  }
  return extraContent;
};

const ListItemDescriptionComponent = ({ element, config }) => {
  return (
    <div
      style={{
        fontWeight: '500',
        fontSize: '12px',
        color: 'rgba(0,0,0,.4)',
        textOverflow: 'ellipsis',
        // whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    >
      {config.description instanceof Array
        ? config.description.map((subtitleItem) =>
          element[subtitleItem] === ''
            ? `${element[subtitleItem]}`
            : `${element[subtitleItem]}. `
        )
        : element[config.description] === ''
          ? `${element[config.description]}`
          : `${element[config.description]}. `}
    </div>
  );
};

const ListItemTypeComponent = ({ element, isChecklist, config }) => {
  return config.types ? isChecklist ? (
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    }}>
      {config.types.map((type, key) => {
        if (element[type.label.key]) return (
          <span
            key={key}
            style={{ display: 'flex' }}
          >
            <span style={{ marginRight: 5 }}>
              {type.icon || type.customIcon(element)}
            </span>
            <span>
              {type.label.parsedTo
                ? type.label.parsedTo(element[type.label.key])
                : element[type.label.key]}
            </span>
          </span>
        )
      })}
    </div>
  ) : (
    <>
      {config.types.map((row, key) => (
        <div key={key}>
          {row.data.map((field, fKey) => (
            <span
              key={fKey}
              style={{ marginRight: '15px', display: 'flex', flexGrow: 1 }}
            >
              <div style={{ marginRight: 10 }}>
                {field.icon || field.customIcon(element)}
              </div>
              <span>
                {field.label.parsedTo
                  ? field.label.parsedTo(element[field.label.key])
                  : element[field.label.key]}
              </span>
            </span>
          ))}
        </div>
      ))
      }
    </>
  ) : (
    <span
      style={{
        marginRight: '15px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flex: 1,
      }}
    >
      <ListTypeIconComponent
        style={{ marginRight: '10px', verticalAlign: 'sub' }}
        type={config.type.icon}
      />
      <span style={{ fontSize: '15px', fontWeight: '400' }}>
        {element[config.type.label]}
      </span>
    </span>
  );
};

const formatAddress = (element, address) => {
  let addressFormated = '';

  address.map((add, index) => {
    if (element[add.key]) {
      addressFormated = addressFormated + element[add.key] + ', ';
    }
  })

  return addressFormated.substring(0, addressFormated.length - 2);
}

const ListAddressComponent = ({ element, isChecklist, config }) => {
  return config.address && isChecklist ? (
    <span style={{ display: 'flex' }}>
      {config.address.icon}
      {formatAddress(element, config.address.labels)}
    </span>
  ) : null
}

const ListItemDateComponent = ({ element, config }) => {
  let dateDeadline = null;
  let isPricing = false
  if (element.ticketId && element.deadline) {
    isPricing = true;
    let year = element.deadline.substr(0, 4);
    let month = element.deadline.substr(4, 2) - 1;
    let day = element.deadline.substr(6, 2);

    dateDeadline = new Date(year, month, day).toString();
  }

  return (
    element && (element[config.date.key[0]] === undefined || element[config.date.key[1]] === undefined)
    ? (<></>)
    : config.date && config.date.type === 'range' ? (
      <span>
        {`${(element[config.date.key[0]] || '').dateFormat()} - ${(
          element[config.date.key[1]] || ''
        ).dateFormat()}`}
      </span>
    ) : (
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <span>
          {`${element[config.date.key] && element[config.date.key].dateFormat()}
          ${element.isChecklist ? `- ${isPricing ? dateDeadline.dateFormat() : element[config.dateExpireAt.key] &&
              element[config.dateExpireAt.key].dateFormat()}` : ``}`}
        </span>
      </div>
    )
  )
}

const ListItemComponent = (props) => {
  let name = '';
  let surName = '';
  let assignedTo = false;
  const {
    actions: { open: openModal },
  } = useContext(ModalContext);
  const { config, element, onClick, onSwipeRight, onSwipeLeft, isChecklist } =
    props;

  if (element.assignedTo) {
    const names = element.assignedTo.split(' ');

    if (names.length > 1) {
      surName = element.assignedTo.split(' ')[1];
      name = `${element.assignedTo.split(' ')[0][0]}.`;
    } else {
      name = element.assignedTo.split(' ')[0];
    }
  }

  return (
    <SwipeableListItem
      style={{ marginBottom: '10px' }}
      swipeRight={
        props.config.swipeRight && {
          content: (
            <ListItemSwipeComponent {...config.swipeRight} position="start" />
          ),
          action: () => onSwipeRight(element),
        }
      }
      swipeLeft={
        props.config.swipeLeft && {
          content: (
            <ListItemSwipeComponent {...config.swipeLeft} position="end" />
          ),
          action: () => onSwipeLeft(element),
        }
      }
    >
      <Card
        style={{
          cursor: 'pointer',
          // TODO: isPricing tests, delete and should be added when client says the exact indicator of this parameter
          // border: (isChecklist && element.isPricing)
          //   ? '2px solid yellow'
          //   : '',
        }}
        onClick={() => {
          if (!assignedTo) {
            onClick(element);
          } else {
            assignedTo = false;
          }
        }}
      >
        <CardContent>
          {/* Title, description and wow */}
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            {/* Block 1: Title, description */}
            <div>
              {/* Title */}
              <div
                className={config.hasTwoLinesTitle ? 'title-2-rows' : 'title'}
                style={{ display: 'flex' }}
              >
                <ListItemTitleComponent
                  element={element}
                  config={config.item}
                  hasTwoLinesTitle={config.hasTwoLinesTitle}
                  isChecklist={isChecklist} 
                />
                {element.assignedTo ? (
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: '500',
                      marginTop: 3,
                      marginRight: 10,
                      marginLeft: 10,
                      color: '#005442',
                    }}
                    onClick={() => {
                      assignedTo = true;
                      openModal({
                        Component: DialogCommercial,
                        data: { text: element.assignedTo },
                      });
                    }}
                  >
                    {name} {surName}
                  </span>
                ) : (
                  ''
                )}

                {!config.hasHiddenExtra && (
                  <ListItemExtraComponent
                    element={element}
                    config={config.item}
                  />
                )}
              </div>
              {/* Description */}
              <ListItemDescriptionComponent
                element={element}
                config={config.item}
              />
            </div>
            {/* Block 2: Wow */}

            <ListItemImage wow={element.isWow} pricing={element.ticketId} />
          </div>
          {/* Type and date */}
          <div
            style={{
              fontSize: '15px',
              fontWeight: '400',
              lineHeight: '27px',
              color: 'rgb(0, 84, 66)',
              display: 'flex',
              flexDirection: isChecklist ? 'column' : 'row',
              margin: '0px 0px 14px 0px',
            }}
          >
            <ListItemTypeComponent element={element} isChecklist={isChecklist} config={config.item} />
            <ListAddressComponent element={element} isChecklist={isChecklist} config={config.item} />
            <ListItemDateComponent element={element} isChecklist={isChecklist} config={config.item} />
          </div>
        </CardContent>
      </Card>
    </SwipeableListItem>
  );
};

export const ListComponent = (props) => {
  return !props.listModel.length ? (
    <NoData />
  ) : (
    <SwipeableList scrollStartThreshold={1} threshold={0.25}>
      {props.listModel.map((element, i) => {
        return (
          <ListItemComponent
            key={i}
            config={props.listConfig}
            element={{
              ...element,
              isChecklist: props.isChecklist,
              isFavorite: props.extraModel
                ? props.extraModel[element._id]
                : element.isFavorite,
            }}
            onClick={props.onClick}
            onSwipeRight={props.onSwipeRight}
            onSwipeLeft={props.onSwipeLeft}
            isChecklist={props.isChecklist}
          />
        );
      })}
    </SwipeableList>
  );
};

export const ListItemImage = ({ wow = false, pricing = null }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',

    }}
  >
    {(wow === 'true' || wow === true) && (
      <div
        style={{
          width: '50px',
          height: '50px',
        }}
      >
        <img
          alt="indicador de que tiene oferta o Wow"
          src={LOGO_IS_WOW}
          // src="/images/logo_wow.png"
          style={{
            width: '100%',
            minWidth: '50px',
            height: 'auto',
          }}
        />
      </div>
    )}
    {pricing != null && (
      <div
        style={{
          width: '50px',
          height: '50px',
        }}
      >
        <img
          alt="indicador de que es de tipo Pricing"
          src={LOGO_IS_PRICING}
          // src="/images/logo_pricing.png"
          style={{
            width: '100%',
            minWidth: '50px',
            height: 'auto',
          }}
        />
      </div>
    )}
  </div >
);
