export const radioGroupStyles = (theme) => ({
  root: {
    width: theme.wrappers.fullWidth,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
  },
  formLabel: {
    color: theme.colors.black,
    display: theme.wrappers.display.flex,
    alignItems: theme.wrappers.alignItems.center,
    justifyContent: theme.wrappers.justifyContent.spaceBetween,
    width: theme.wrappers.percentWidth(100),
  },
  iconSize: {
    fontSize: theme.spacing(2),
  },
  helperText: {
    marginTop: theme.sizes.invert(theme.spacing(0.625)),
  },
});
