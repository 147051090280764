import React from 'react';

import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  withStyles,
} from '@material-ui/core';

import { withForm } from '../../../core';

import { RadioGroup } from '../../../ui';
import { COMMERCIAL_TYPE_VALUES } from '../../constants';
import { propertiesCommercialTypeStyles } from './properties-commercial-type.styles';

export const PropertiesCommercialTypeDialog = withStyles(
  propertiesCommercialTypeStyles
)(
  withForm({ commercialType: [] })(
    ({ classes, close, onModalClose, form, handleChange }) => {
      const onSubmit = (e) => {
        e.preventDefault();
        onModalClose('0' + form.commercialType);
        close();
      };

      return (
        <>
          <DialogTitle id="alert-dialog-title">
            El cliente está interesado en
          </DialogTitle>

          <form onSubmit={onSubmit}>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <RadioGroup
                  value={form.commercialType}
                  name="commercialType"
                  onChange={handleChange}
                  values={COMMERCIAL_TYPE_VALUES}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className={classes.button} onClick={close}>
                Cerrar
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{ color: '#fff' }}
                className={classes.button}
                type="submit"
              >
                Aceptar
              </Button>
            </DialogActions>
          </form>
        </>
      );
    }
  )
);
