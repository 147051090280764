import React from 'react';

import { withForm } from '../../../core';
import { OAUTH_LOGIN_FORM } from '../constants';
import { OAuthConsumer } from '../../../pages/o-auth';
import { Input, Password, Button } from './../../../ui';
import { addLogs } from '../../../core/logs-buffer/addLogs';

export const OAuthLoginFormComponent = withForm(OAUTH_LOGIN_FORM)(
  ({
    form,
    validators,
    loading,
    handleChange,
    onSubmit,
    dispatch,
    feedback,
  }) => (
    <OAuthConsumer>
      {({ signIn }) => (
        <form noValidate autoComplete="off">
          <Input
            id="enrollment"
            value={form.enrollment}
            error={validators.enrollment.hasError}
            errormessage={validators.enrollment.message}
            variant="outlined"
            name="enrollment"
            label="Usuario"
            onChange={handleChange}
          />
          <Password
            outlined
            value={form.password}
            error={validators.password.hasError}
            errormessage={validators.password.message}
            name="password"
            label="Contraseña"
            onChange={handleChange}
          />
          <Button
            id="signIn-button"
            loading={loading}
            color="primary"
            onClick={onSubmit(
              signIn,
              (payload) => {
                dispatch({
                  payload: {
                    ...payload.results,
                    enrollment: form.enrollment?.toLowerCase()?.trim(),
                  },
                  type: 'LOGIN',
                });
                addLogs(
                  'login version',
                  'WRITE_TRACES',
                  'login',
                  'Versión de la aplicación',
                  form.enrollment?.toLowerCase()?.trim(),
                  `v${process.env.REACT_APP_VERSION}`
                );
              },
              (error) =>
                feedback({
                  message: 'Usuario o contraseña incorrecta',
                  visible: true,
                  type: 'error',
                })
            )}
          >
            ACCEDER
          </Button>
        </form>
      )}
    </OAuthConsumer>
  )
);
