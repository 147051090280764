import React, { Component } from 'react';
import { createHashHistory } from 'history';

import { setLoggerConfig } from '../../logger';
import { LogDnaStackTraceService } from '../../log-dna';

import { TEST_COOKIE, COOKIE_DISABLED_MSG } from '../constants';
import { AppMonitorErrorFallback } from './app-monitor-error-fallback.component';

export class AppMonitor extends Component {
  constructor() {
    super();

    const history = createHashHistory();
    const logDnaStackTrace = LogDnaStackTraceService(history);

    logDnaStackTrace.initErrorHandler();
    setLoggerConfig(history);

    this.state = { hasError: false };
    this.handler = logDnaStackTrace.getHandler();
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.handler({ error, info });
  }

  isCookieEnabled() {
    let cookieEnabled = navigator.cookieEnabled;

    if (!cookieEnabled) {
      document.cookie = TEST_COOKIE;
      cookieEnabled = document.cookie.indexOf(TEST_COOKIE) !== -1;
    }

    return cookieEnabled;
  }

  render = () =>
    this.isCookieEnabled() ? this.cookieEnabledRender() : COOKIE_DISABLED_MSG;
  cookieEnabledRender = () =>
    this.state.hasError ? <AppMonitorErrorFallback /> : this.props.children;
}
