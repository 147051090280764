import { useState } from 'react';

export const withForm = (validations, isPromise = true) => {
  const formData = {};
  const errorsData = {};

  Object.keys(validations).forEach((key) => {
    formData[key] = '';
    errorsData[key] = {};
  });

  return (callback) => (props) => {
    let errors = {};

    const [form, setForm] = useState(formData);
    const [loading, toggleLoading] = useState(false);
    const [validators, setValidators] = useState(errorsData);

    const handleChange = ({ target: { name, value} }) => {
      setForm({ ...form, [name]: value });
      form[name] = value;
      hasErrors();
    };

    const resetForm = () => {
      Object.keys(validations).forEach((key) => {
        formData[key] = '';
        errorsData[key] = {};
      });

      setForm(formData);
      setValidators(errorsData);
    };

    const onSubmit = (request, callback, catchCallback) => (event) => {
      if (event) {
        event.preventDefault();
      }

      toggleLoading(true);

      if (!hasErrors()) {
        if (isPromise) {
          return request(form)
            .then((data) => {
              toggleLoading(false);
              if (
                data &&
                data.status !== 200 &&
                form &&
                form.enrollment &&
                form.password
              ) {
                catchCallback && catchCallback();
              } else {
                callback(data);
              }
            })
            .catch((err) => {
              toggleLoading(false);

              catchCallback && catchCallback(err);

              return Promise.reject(err);
            });
        } else {
          request(callback(form));
          setTimeout(
            () => (catchCallback ? catchCallback() : () => ({})),
            1000
          );
        }
      } else {
        toggleLoading(false);
      }
    };

    const hasErrors = () => {
      let hasError = false;

      Object.keys(validations).forEach((key) => {
        errors = { [key]: { errors: {}, message: '' }, ...errors };
        for (const validation of validations[key]) {
          errors[key] = {
            errors: { ...validation(form[key]), ...errors[key].errors },
          };
        }

        errors[key] = {
          hasError: Object.keys(errors[key].errors).length > 0,
          ...errors[key],
        };

        if (!hasError) {
          hasError = errors[key].hasError;
        }

        if (errors[key].hasError) {
          errors[key].message =
            errors[key].errors[Object.keys(errors[key].errors)[0]];
        }
      });

      setValidators(errors || errorsData);

      return hasError;
    };

    return callback({
      form,
      setForm,
      loading,
      handleChange,
      onSubmit,
      validators,
      resetForm,
      ...props,
    });
  };
};
