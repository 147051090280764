export const propertiesRoutingEffect =
  (leadId, tableValues, leadType, setProperties, promoId) => () => {
    setProperties({
      payload: {
        tableValues,
        prefix:
          leadType && leadId
            ? `/leads/${leadType}/${leadId}`
            : promoId
            ? `/properties/${promoId}/promo`
            : '',
      },
    });
  };
