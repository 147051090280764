import React from 'react';

import * as Typologies from './typologies.component';

export const PropertyTypologyForm = (props) => {
  switch (props.state['category']) {
    case '01':
      return <Typologies.Category01 {...props} />;
    case '02':
      return <Typologies.Category02 {...props} />;
    case '03':
      return <Typologies.Category03 {...props} />;
    case '04':
      return <Typologies.Category04 {...props} />;
    case '05':
      return <Typologies.Category05 {...props} />;
    case '06':
      return <Typologies.Category06 {...props} />;
    case '07':
      return <Typologies.Category07 {...props} />;
    case '08':
      return <Typologies.Category08 {...props} />;
    default:
      return <></>;
  }
};
