import React, { Component } from 'react';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import {
  PropertyTypologyForm,
  PropertyFormButtons,
  PropertyDescriptionForm,
} from './index';
import { FEEDBACK } from '../constants';
import FetchApi from '../../../core/fetch-api';

export class PropertyForm extends Component {
  constructor(props) {
    super(props);
    this.state = { formValues: props.model };
  }

  componentDidMount() {
    FetchApi.refreshToken();
  }

  handleChange = (key) => (e) => {
    this.setState({
      formValues: { ...this.state.formValues, [key]: e.target.value },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const result = this.props
      .putData(
        { ...this.state.formValues, document: 'update_property' },
        'UPDATE_PROPERTY'
      )
      .then((res) => {
        if (this.props.testJest) this.props.testJest(res);
        this.props.openSnackBar({
          title: `${this.props.propertyId} ${FEEDBACK.TITLE_EDITION_PENDING}`,
          ...FEEDBACK.EDITION_PENDING,
        });
      });

    this.props.closeModal();

    return result;
  };

  handleClose = () => {
    this.props.closeModal();
  };

  render() {
    const { classes } = this.props;
    const showForm =
      !!this.state.formValues.category && +this.state.formValues.category < 7;
    return (
      <>
        <Box className={classes.header}>
          <Typography variant="h5" component="h2" className={classes.title}>
            Editar propiedad
          </Typography>
          <IconButton size="small" color="inherit" onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={this.handleSubmit} style={{ textAlign: 'left' }}>
          <div style={{ padding: showForm ? '18px' : '0px' }}>
            <PropertyTypologyForm
              state={this.state.formValues}
              tableValues={this.props.tableValues}
              handleChange={this.handleChange}
            />
          </div>

          <PropertyDescriptionForm
            border={showForm}
            state={this.state.formValues}
            handleChange={this.handleChange}
          />
          <div style={{ height: '120px' }}>
            <PropertyFormButtons handleClose={this.props.closeModal} />
          </div>
        </form>
      </>
    );
  }
}
