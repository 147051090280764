import React, { useContext, useEffect, useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { formSummary } from './constants';
import { useDispatch, useSelector } from '../../../../core/hooks/use-selector';
import { SnackBarContext } from '../../../../ui/snack-bar';
import { PouchDBContext } from '../../../../core/pouch-db';
import { useHistory, useParams } from 'react-router-dom';
import { uuidv4 } from '../../../../utils';
import { getCurrentDate, getPropertyId, onCancelWizard } from '../../utils';
import WizardView from '../../../../shared/checklists-wizard/step-base.component'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '../../../../ui/expansion-panel';
import { TRANSLATIONS_ITEMS as PROPERTIES_TRANSLATIONS_ITEMS } from '../../../../shared/checklists-wizard/constants/summary/propertySummaryItem/constants';
import {
  FORM_ELEMENTS_PROPERTY as PROPERTIES_FORM_ELEMENTS,
  OPTIONAL_ELEMENTS_A,
  OPTIONAL_ELEMENTS_B,
  OPTIONAL_ELEMENTS_C,
  OPTIONAL_ELEMENTS_RESIDENCIAL,
  OPTIONAL_ELEMENTS_SUELO,
  OPTIONAL_ELEMENTS_TERCIARIO,
  OPTIONAL_ELEMENTS_WIP,
} from '../../../../shared/checklists-wizard/constants/property/form-elements';
import { sendPicsHandleSubmit } from '../../../checklists/effects/send-pics-handle-submit.callback';
import FetchApi from '../../../../core/fetch-api';
import { UserContext } from '../../../../core/user';
import { addLogs } from '../../../../core/logs-buffer/addLogs';

/** View screen 6 : Resumen */
const Step6Summary = ({ onChangeStepHandler, haveBack = true }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const {
    actions: { open: openSnackBar },
  } = useContext(SnackBarContext);

  const { setData, getData, putData } = useContext(PouchDBContext);
  const { state: userState } = useContext(UserContext);

  const history = useHistory();

  const [expanded, setExpanded] = useState(0);

  const state = useSelector((s) => s);

  const totalAttemptsChecklist = 6;
  let attemptsChecklists = 1;

  useEffect(() => {
    FetchApi.refreshToken();
    window.scrollTo(0, 0);
    return () => {
      if (!onChangeStepHandler) onCancelWizard(dispatch, history);
    };
  }, []);

  const handleClick = (panel) => (_event, newExpanded) =>
    setExpanded(newExpanded && panel);

  /** Next step */
  const onEdit = (step) => {
    onChangeStepHandler(step);
  };

  const propertyIdSend =
    history.location.pathname.indexOf('properties') > -1
      ? getPropertyId(history.location.pathname)
      : params.checklistId;

  /**
  * Check if checklist is just created
  */
  const checkChecklistCreation = (idCloudant, checklist, checklistWithImages) => {
    getData({
      _id: idCloudant
    })
      .then((res) => {
        if (res.docs.length > 0) {
          // Log saying found the checklist
          addLogs(
            'new check-list - online',
            'WRITE_TRACES',
            history.location.pathname,
            `Petición de comprobar check-list: success. Intento: ${attemptsChecklists}`,
            checklist['@user'],
            res.docs
          );
          sendPicsAndUpdateDocument(idCloudant, checklist, checklistWithImages);
        } else {
          addLogs(
            'new check-list - online',
            'WRITE_TRACES',
            history.location.pathname,
            `Petición de comprobar check-list: error, con respuesta ${res?.status}, en el intento ${attemptsChecklists}`,
            checklist['@user'],
            res.docs
          );
          if (attemptsChecklists < totalAttemptsChecklist) {
            attemptsChecklists++;

            // Try to get checklist again.
            setTimeout(() => checkChecklistCreation(idCloudant, checklist, checklistWithImages), 1000);

          } else if (attemptsChecklists === totalAttemptsChecklist) {
            // Log saying doesnt found the checklist
            addLogs(
              'new check-list - online',
              'WRITE_TRACES',
              history.location.pathname,
              `Petición de comprobar check-list: error`,
              checklist['@user'],
              `Se ha superado el límite de intentos. Intentos ${attemptsChecklists}/${totalAttemptsChecklist}`
            );
          }
        }
      })
      .catch((err) => {
        if (attemptsChecklists < totalAttemptsChecklist) {
          attemptsChecklists++;

          // Try to get checklist again.
          setTimeout(() => checkChecklistCreation(idCloudant, checklist, checklistWithImages), 1000);
        } else {
          // Log saying doesnt found the checklist
          addLogs(
            'new check-list - online',
            'WRITE_TRACES',
            history.location.pathname,
            `Petición de comprobar check-list: error`,
            checklist['@user'],
            `Hubo un error en la consulta & se ha superado el límite de intentos. Intentos ${attemptsChecklists}/${totalAttemptsChecklist}. Error: ${err}`
          );
        }
      });
  }

  /**
  * Send pictures to WebService and update parent document
  */
  const sendPicsAndUpdateDocument = (idCloudant, checklist, checklistWithImages) => {
    // Send images to WebService
    sendPicsHandleSubmit(
      openSnackBar,
      idCloudant,
      state.screenFourData.send,
      FetchApi,
      checklist['@user']
    );

    // Variable to know type of document
    let documentToUpdate = history.location.pathname.indexOf('properties') > -1
      ? 'properties'
      : state.screenOneData.item.document;

    // Log saying gonna update parent document
    addLogs(
      'new check-list - online',
      'WRITE_TRACES',
      history.location.pathname,
      `Petición de actualizar ${documentToUpdate}`,
      checklist['@user'],
      checklistWithImages
    );

    // Get document to update it
    getData({
      document: documentToUpdate,
      propertyId: propertyIdSend,
    })
      .then((res) => {
        let documents = res.docs;

        documents = documents.sort((a, b) => {
          return (
            new Date(b.checklistAssignedAt) -
            new Date(a.checklistAssignedAt)
          );
        });

        let document = documents[0];

        document.checklistStatus = 'in-progress';
        document.propertyId = propertyIdSend;
        document.checklistCreatedAt = new Date();

        // Send update
        putData(document, 'UPDATE_PROPERTY', state.uid);

        // Log saying document updated success
        addLogs(
          'new check-list - online',
          'WRITE_TRACES',
          history.location.pathname,
          `Petición de actualizar ${documentToUpdate}: success`,
          document['@user'],
          document
        );
      })
      .catch((err) => {
        // Log saying document updated error
        addLogs(
          'new check-list - online',
          'WRITE_TRACES',
          history.location.pathname,
          `Petición de actualizar documento: error`,
          document['@user'],
          err
        );
      });
  }

  const onClickSendHandler = () => {
    FetchApi.refreshToken();

    let idCloudant;
    let aux, temp;
    let oldChecklist = {
      _id: uuidv4(),
      document: 'check-list',
      checklistStatus: 'in-progress',
      checklistCreatedAt: new Date(),
      checklistAssignedAt: state.screenOneData.item.checklistAssignedAt,
      // TODO: isPrincing commented 'cause isPricing project is paused
      // isPricing: state.screenOneData.item.isPricing,
      hasAttachments: false,
      propertyId: propertyIdSend,
      ...state.screenTwoData,
      ...state.screenThreeData,
      ...state.screenFiveData,
      // attachments: JSON.stringify(state.screenFourData),
      attachments: [],
      ticketId: state.screenOneData.item.ticketId,
      deadline: state.screenOneData.item.deadline,
    };
    oldChecklist.hasAttachments = state.screenFourData.send.length > 0 ? true : false;

    let checklistWithImages = {
      ...oldChecklist,
      attachments: state.screenFourData.send,
    };

    Object.keys(state.screenOneData).forEach((k) => {
      temp =
        PROPERTIES_FORM_ELEMENTS.find((e) => e.name === k) ||
        OPTIONAL_ELEMENTS_A(() => { }).find((e) => e.name === k) ||
        OPTIONAL_ELEMENTS_B(() => { }).find((e) => e.name === k) ||
        OPTIONAL_ELEMENTS_C(() => { }).find((e) => e.name === k) ||
        OPTIONAL_ELEMENTS_RESIDENCIAL(() => { }).find((e) => e.name === k) ||
        OPTIONAL_ELEMENTS_SUELO(() => { }).find((e) => e.name === k) ||
        OPTIONAL_ELEMENTS_TERCIARIO(() => { }).find((e) => e.name === k) ||
        OPTIONAL_ELEMENTS_WIP(() => { }).find((e) => e.name === k);
      if (temp && temp.type === 'dropdown') {
        aux = PROPERTIES_TRANSLATIONS_ITEMS.find((i) => i.documentKey === k);
        aux = aux.values.find((v) => v.value === state.screenOneData[k]);
        if (aux) {
          oldChecklist[k] = aux;
          checklistWithImages[k] = aux;
        }
      } else {
        oldChecklist[k] = state.screenOneData[k];
        checklistWithImages[k] = state.screenOneData[k];
      }
    });

    // Filter variable for dont save "item" field.
    let checklistFiltered = Object.entries(oldChecklist).filter(
      ([key, value]) => {
        if (key !== 'item' && key !== 'pictures') {
          return { key: value };
        }
      }
    );

    let checklist = Object.fromEntries(checklistFiltered);

    // Log saying gonna create checklist
    addLogs(
      'new check-list - online',
      'WRITE_TRACES',
      history.location.pathname,
      'Petición de crear check-list',
      checklist['@user'],
      checklistWithImages
    );

    setData(
      checklist, // no images
      'CHECKLIST',
      openSnackBar,
      () => {
        // User pop-up saying all is ok
        openSnackBar({
          type: 'success',
          visible: true,
          title: `Creación de documento de tipo ${checklist.document}`,
          message: `Se ha guardado correctamente el documento`,
        });

        // Log saying checklist created successfully
        addLogs(
          'new check-list - online',
          'WRITE_TRACES',
          history.location.pathname,
          `Petición de crear check-list: success`,
          checklist['@user'],
          checklist
        );

        idCloudant = `${userState.enrollment}:${checklist._id}:${checklist.document}`;

        // Log saying gonna check checklist
        addLogs(
          'new check-list - online',
          'WRITE_TRACES',
          history.location.pathname,
          `Petición de comprobar check-list`,
          checklist['@user'],
          checklist
        );

        checkChecklistCreation(idCloudant, checklist, checklistWithImages);

        // Route user to start
        history.location.pathname.indexOf('properties') > -1
          ? history.push('/leads')
          : history.push('/checklists');
      },
      null,
      () => {
        // Route user to start
        history.location.pathname.indexOf('properties') > -1
          ? history.push('/leads')
          : history.push('/checklists');
      },
      checklistWithImages, // obj con imágenes
      state.uid
    );
  };

  /** Back step */
  const onBackWizard = () => {
    if (onChangeStepHandler) {
      onChangeStepHandler(state.screenFourData.send.length === 0 ? 3 : 4);
    } else {
      onCancelWizard(dispatch);
      history.location.pathname.indexOf('properties') > -1
        ? history.push('/leads')
        : history.push('/checklists');
    }
  };

  const fields = (
    <>
      {formSummary.map((element) => {
        return (
          <div key={element.index}>
            <ExpansionPanel
              square
              key={element.index}
              expanded={expanded === element.activeIndex}
              onChange={handleClick(element.activeIndex)}
            >
              <ExpansionPanelSummary>
                <CheckCircleIcon />
                <Typography variant="h6">{element.title}</Typography>
                <IconButton
                  data-testid={`panel-${element.index}`}
                  edge="end"
                  size="small"
                  style={{
                    visibility: onChangeStepHandler ? 'visible' : 'hidden',
                  }}
                  onClick={() => onEdit(element.index)}
                >
                  <EditIcon />
                </IconButton>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
                <element.Component />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        );
      })}
    </>
  );

  return (
    <WizardView
      title={`Resumen: ${getPropertyId(history.location.pathname)}`}
      fields={fields}
      onSubmit={onChangeStepHandler ? onClickSendHandler : null}
      onBack={onBackWizard}
      haveBack={haveBack}
    // onCancel={() => onCancelWizard(dispatch, history)}
    />
  );
};

export default Step6Summary;