import React from 'react';

import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { withStyles } from '@material-ui/core';
import { switchStyles } from './switch.styles';

export const SwitchComponent = withStyles(switchStyles)(
  ({ classes, label, checked, onChange, name }) => {
    return (
      <FormControl fullWidth component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            className={classes.formControlLabel}
            value="start"
            control={
              <Switch
                color="primary"
                checked={!!checked}
                onChange={() => onChange({ target: { name, value: !checked } })}
              />
            }
            label={label || ''}
            labelPlacement="start"
          />
        </FormGroup>
      </FormControl>
    );
  }
);
