import { radioOptions } from "../constants";

export const OCCUPATION_RISK_VALUES = [
  { label: 'Alto', value: '1' },
  { label: 'Medio', value: '2' },
  { label: 'Bajo', value: '3' },
];

export const FORM_ELEMENTS = [
  {
    name: 'antiOccupation',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: {required: true},
    label: '¿Puerta antiokupa instalada?',
    options: radioOptions,
    inline: true,
    props: {},
  },
  {
    name: 'requireActions',
    type: 'radio',
    required: false,
    requiredText: false,
    label: '¿Requiere actuaciones frente a terceros?',
    options: radioOptions,
    inline: true,
    props: {},
  },
  {
    name: 'occupationRisk',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: {required: true},
    label: '¿Cómo valoras el riesgo de ocupación?',
    inline: true,
    options: OCCUPATION_RISK_VALUES,
    props: {},
  },
  {
    name: 'vandalized',
    type: 'radio',
    required: false,
    requiredText: false,
    label: '¿Está vandalizado?',
    options: radioOptions,
    inline: true,
    props: {},
  },
  {
    name: 'perishableClean',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: {required: true},
    label: 'Necesario limpieza de perecederos',
    options: radioOptions,
    inline: true,
    props: {},
  },
  {
    name: 'equipmentClean',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: {required: true},
    label: 'Necesario limpieza de enseres',
    options: radioOptions,
    inline: true,
    props: {},
  },
  {
    name: 'pests',
    type: 'radio',
    required: false,
    requiredText: false,
    label: 'Plagas',
    options: radioOptions,
    inline: true,
    props: {},
  },
];
