import FetchApi from '../../core/fetch-api';
import tableValues from '../../core/json-table-values.json';

export const activitiesTableValuesEffect = (setTableValues) => () => {
  FetchApi.refreshToken();
  const { data: model } = tableValues.find(
    (element) => element.type === 'activities-tree'
  );

  setTableValues(model);
};
