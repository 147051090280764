import AddCircleIcon from '@material-ui/icons/AddCircle';
import { addLogs } from '../../core/logs-buffer/addLogs';

import { DialogMessage } from '../../elements/dialog';
import {
  NOT_INTERESTED_CLOSE_REASONS,
  RESERVE_MOTIVES_OR_THINKING,
  CLOSE_MOTIVES,
  parsedData,
} from '../constants';
import { uploadAttachmentsEffect } from '../effects';

export const activitiesFormHandleSubmitCallback = (actions, models) => (e) => {
  const {
    onSubmit,
    openModal,
    setData,
    feedback,
    setCustomErrors,
    openSnackBar,
    getData,
    putData,
    uid
  } = actions;
  const { history, tableValues, handleChange, form, hasToFirm, model } = models;

  const hasFirmedError = /^$|\s+/.test(form.hasFirmed);
  const attachmentsError = !Object.keys(form.attachments).length;

  const isCloseActivity = () => {
    const close =
      (CLOSE_MOTIVES.includes(form.motive) &&
        NOT_INTERESTED_CLOSE_REASONS.includes(form.reasonId)) ||
      RESERVE_MOTIVES_OR_THINKING.includes(form.motive);
    return close;
  };

  const catchCallback = () => {
    const data = {
      message:
        'No ha sido posible crear la actividad, inténtelo de nuevo más tarde',
      visible: true,
      type: 'error',
    };
    feedback(data);
    // console.log(data.type);
    /** Log after fetch : error */
    addLogs(
      'new activities',
      'WRITE_TRACES',
      history.location.pathname,
      `Respuesta de crear activities: ${data.type}`,
      model['@user'],
      data
    );
  };

  const successCallback = (data) => {
    getData({ document: 'managements' }).then((res) => {
      if (res) {
        const management = res.docs.find(
          (d) => d.managementId === data.managementId
        );
        management.typeActivity = data.name;
        // management.createdAtTime = new Date();

        switch (data.motiveId) {
          case '06': // Visita Realizada
            if (data.reasonId === '12' || data.reasonId === '14') {
              // Si Visita Realizada es Cliente interesado, potencial oferta || Se lo pensará
              // cerrar.
              putData(management, null, uid); // management @isConsolidated = false
              break;
            }
          case '01': // Oferta Presentada por Cliente
          case '05': // No interesado
          case '08': // Cliente no válido
          case '09': // Ilocalizable
          case '10': // Duplicado
          case '11': // Inmueble Reservado/Vendido/Alquilado
            putData(management, 'DOES_NOT_APPLY', uid); // management @isConsolidated = true
            break;
          default:
            putData(management, null, uid); // management @isConsolidated = false
            break;
        }
        // console.log('success');
        /** Log after fetch : success*/
        addLogs(
          'new activities',
          'WRITE_TRACES',
          history.location.pathname,
          `Respuesta de crear activities: success`,
          model['@user'],
          management
        );
      }
    });
  };

  const submit = (event) =>
    uploadAttachmentsEffect(
      onSubmit(
        (data) => {
          data.createdAtFull = new Date().toISOString();
          /** Log before fetch */
          addLogs(
            'new activities',
            'WRITE_TRACES',
            history.location.pathname,
            'Petición de crear activities',
            model['@user'],
            data
          );
          setData(
            { ...data, document: 'activities' },
            'ADD_ACTIVITY',
            openSnackBar,
            () => successCallback(data),
            catchCallback,
            null,
            null,
            uid
          );
        },
        parsedData(tableValues, model),
        () => {
          if (isCloseActivity()) {
            openModal({
              Component: DialogMessage,
              view: 'add-lead',
              data: {
                title: 'Crear un nuevo servicio',
                message: '¿Quieres crear un nuevo servicio?',
                Icon: AddCircleIcon,
                btnConfirmLabel: 'Nuevo servicio',
                onClose: (hasConfirm) =>
                  hasConfirm
                    ? history.push(
                      `/properties-filter?clientId=${model.clientId}`
                    )
                    : history.goBack(),
              },
            });
          } else {
            history.goBack();
          }
        }
      ),
      model.managementId,
      handleChange,
      form,
      openSnackBar,
      event
    );

  if (hasToFirm && (hasFirmedError || attachmentsError)) {
    setCustomErrors({
      hasFirmed: hasFirmedError ? { message: 'Este campo es requerido' } : {},
      attachments: attachmentsError
        ? { message: 'Es obligatorio añadir una captura del comprobante' }
        : {},
    });
  } else {
    submit(e);
  }
};
