const styles = (theme) => ({
    boxContainer: {
        display: 'flex',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap'
    },
    checkIcon: {
        margin: '0 0.5em 0 0'
    },
    spanId: {
        fontWeight: 800,
        margin: '0 0.5em 0 0'
    },
    spanAddress: {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    }
});

export default styles;