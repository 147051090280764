import React, { useState } from 'react';

import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import MaterialRadioGroup from '@material-ui/core/RadioGroup';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { withStyles, Popover, FormHelperText } from '@material-ui/core';

import { radioGroupStyles } from './radio-group.styles';

export const RadioGroup = withStyles(radioGroupStyles)(
  ({ classes, ...props }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const onChange = ({ target: { value, name } }) =>
      props.onChange({ target: { value: +value, name } });

    return (
      <>
        <FormControl className={`${classes.root} ${props.className}`}>
          <FormLabel
            className={classes.formLabel}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            {props.label}{' '}
            {props.info ? (
              <InfoOutlinedIcon className={classes.iconSize} />
            ) : (
              ''
            )}
          </FormLabel>

          <MaterialRadioGroup
            name={props.name}
            value={'' + props.value || ''}
            onChange={onChange}
            row
            aria-label={props.name}
          >
            {props.values.map((element, index) => (
              <FormControlLabel
                key={index}
                value={'' + element.value}
                label={element.label}
                control={<Radio color="primary" />}
              />
            ))}
          </MaterialRadioGroup>
          <FormHelperText
            error={!!props.errorMessage}
            className={classes.helperText}
          >
            {props.errorMessage}
          </FormHelperText>
        </FormControl>

        {props.info && (
          <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            className={classes.popover}
            classes={{ paper: classes.paper }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            {props.info}
          </Popover>
        )}
      </>
    );
  }
);
