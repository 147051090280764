const clientPreferencesEffect =
  (getData, isSyncing, clientId, setPreferences) => () => {
    if (!isSyncing) {
      return getData({ clientId: clientId, document: 'preferences' }).then(
        (res) => {
          const data = res.docs.map((element) => {
            const keys = Object.keys(element);
            keys.forEach((key) => {
              if (key.substring(0, 1) === '@') delete element[key];
            });

            return element;
          });

          setPreferences(data);
        }
      );
    }
  };

const updateClientPreferencesEffect = (clientId, fetchApi) => () => {
  // TODO remove fetch Api when it is not necessary to use endpoint to update SAP
  fetchApi.request('get', `/client/preference/${clientId}`);
};

export { clientPreferencesEffect, updateClientPreferencesEffect };
