import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ListHeaderComponent, NoData } from '../../../elements';
import { ListComponent } from '../../../elements/lists/components/list.component';
import { ListFilterComponent } from './list-filter.component';
import { useStyles } from './useStyles.checklistsComponent';

export const SwipeableListComponent = (props) => {
  const [customFilters, setCustomFilters] = useState({
    fromDate: null,
    toDate: null,
    urpromo: null,
    orderDir: 'asc',
  });
  const classes = useStyles();

  const [notifications, setNotifications] = useState(props.model);

  useEffect(() => {
    let aux = [];
    if (props.model && props.model.length > 0) {
      aux = [
        ...props.model.map((e) => {
          return { ...e };
        })
      ];

      let currentDate;

      if (customFilters.fromDate !== null && customFilters.toDate !== null) {
        aux = aux.filter((n) => {
          currentDate = moment(n.checklistAssignedAt, 'YYYY-MM-DD');
          return (
            currentDate.isSameOrAfter(customFilters.fromDate) &&
            currentDate.isSameOrBefore(customFilters.toDate)
          );
        });
      } else if (
        customFilters.fromDate !== null &&
        customFilters.toDate === null
      ) {
        aux = aux.filter((n) => {
          currentDate = moment(n.checklistAssignedAt, 'YYYY-MM-DD');
          return currentDate.isSameOrAfter(customFilters.fromDate);
        });
      } else if (
        customFilters.fromDate === null &&
        customFilters.toDate !== null
      ) {
        aux = aux.filter((n) => {
          currentDate = moment(n.checklistAssignedAt, 'YYYY-MM-DD');

          return currentDate.isSameOrBefore(customFilters.toDate);
        });
      }
      if (customFilters.urpromo !== null) {
        aux = aux.filter(
          (n) => n.propertyId?.indexOf(customFilters.urpromo) !== -1
        );
      }

      aux = aux.sort((a, b) => {
        a = moment(a.checklistAssignedAt, 'YYYY-MM-DD');
        b = moment(b.checklistAssignedAt, 'YYYY-MM-DD');
        if (customFilters.orderDir === 'asc') {
          return a.valueOf() - b.valueOf();
        } else {
          return b.valueOf() - a.valueOf();
        }
      });
    }

    setNotifications(aux);
  }, [customFilters, props.model]);

  const onChangeCustomFilters = (filters) => {
    setCustomFilters({ ...customFilters, ...filters });
  };

  const cleanFilters = () => {
    setCustomFilters({
      fromDate: null,
      toDate: null,
      urpromo: null,
      orderDir: 'asc',
    });
  };

  return (
    <>
      {props.hasOwnProperty('filterConfig') && (
        <ListHeaderComponent props={props}>
          <ListFilterComponent
            customFilters={customFilters}
            onChangeFilter={onChangeCustomFilters}
            cleanFilters={cleanFilters}
            leadsArray={props.leadsArray}
            filterModel={props.filterModel}
          />
        </ListHeaderComponent>
      )}
      <div id="div-list-content" className={classes.listContent}>
        {props.model && props.model?.length ? (
          <ListComponent
            {...props}
            listModel={notifications}
            isChecklist={props.isChecklist}
          />
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
};
