export const PREVAILING_ENVIRONMENT = [
  { label: 'Residencia plurifamiliar', value: '1' },
  { label: 'Residencia unifamiliar', value: '2' },
  { label: 'Industrial', value: '3' },
  { label: 'Oficinas', value: '4' },
  { label: 'Comercial', value: '6' },
  { label: 'Dotacional', value: '7' },
  { label: 'Agrícola', value: '8' },
  { label: 'Agrícola regadío', value: '9' },
  { label: 'Ganadero', value: '10' },
  { label: 'Otros', value: '11' },
];

export const QUALITY_AREA = [
  { label: 'Prime', value: '1' },
  { label: 'Secundaria', value: '2' },
  { label: 'Aislada cercana a núcleos consolidados', value: '3' },
  { label: 'Aislada alejada de núcleos consolidados', value: '4' },
  { label: 'Zona degradada', value: '5' },
];

export const AREA_LOCATION = [
  { label: 'Calle principal', value: '1' },
  { label: 'Esquinera', value: '2' },
  { label: 'Calle secundaria', value: '3' },
  { label: 'Sin vial', value: '4' },
  { label: 'Calle peatonal', value: '5' },
  { label: 'Vial sin asfaltar', value: '6' },
];

export const FORM_ELEMENTS = [
  {
    name: 'prevailingEnvironment',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: '¿Cuál es el entorno predominante?',
    inline: false,
    options: PREVAILING_ENVIRONMENT,
    props: {},
  },
  {
    name: 'qualityArea',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Calidad de la zona',
    inline: false,
    options: QUALITY_AREA,
    props: {},
  },
  {
    name: 'areaLocation',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Ubicación de la zona',
    inline: false,
    options: AREA_LOCATION,
    props: {},
  },
  {
    name: 'environmentDescription',
    type: 'textarea',
    required: false,
    requiredText: false,
    label: 'Descripción del entorno y del edificio',
    props: {
      maxLength: 150,
    },
  },
  {
    name: 'marketingDescription',
    type: 'textarea',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Descripción comercialización del activo',
    props: {
      maxLength: 250,
    },
  },
];
