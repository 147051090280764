const detailClientEffect = (getData, isSyncing, model, setClient) => () => {
  if (!model.clientId) {
    return;
  }

  if (!isSyncing) {
    return getData({ clientId: model.clientId, document: 'clients' }).then(
      (res) => {
        if (res && res.docs)
          setClient({ ...res.docs[0], status: model.status });
      }
    );
  }
};

const updateDetailClientEffect = (clientId, fetchApi) => () => {
  // TODO remove fetch Api when it is not necessary to use endpoint to update SAP
  if (clientId) fetchApi.request('get', `/client/${clientId}`);
};

export { updateDetailClientEffect, detailClientEffect };
