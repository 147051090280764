export const propertiesListStyles = (theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    minHeight: theme.wrappers.fullHeight,
    background: theme.palette.white.main,
    display: theme.wrappers.display.flex,
    flexDirection: theme.wrappers.flexDirection.column,
  },
  formControl: {
    margin: theme.spacing(0),
    width: theme.wrappers.fullWidth,
  },
  divider: {
    height: theme.spacing(0.0625),
    marginLeft: -theme.spacing(2),
    margin: theme.forms.control.margin,
    width: theme.wrappers.fullViewPortWidth,
  },
  buttons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: theme.wrappers.fullWidth,
    display: theme.wrappers.display.flex,
    justifyContent: theme.wrappers.justifyContent.end,
  },
});
