export const pricesSummaryStyles = (theme) => ({
    boxContainer: {
        '&:first-child': {
            margin: 0,
        },
        marginTop: '16px',
    },
    AccordionSummaryContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    AccordionDetailsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    securityTitle: {
        marginTop: '16px !important',
    }
});