import React from 'react';
import classNames from 'classnames';

export const getSubtitleOfView = (objective, classes) => {
    switch (objective) {
        case 'VERDE':
            return <div className={classes.statusWrapper}>
                <span className={classes.statusItem}>
                    <div className={classNames(classes.circleStatus, classes.circleStatusGreen)}></div>
                </span>
                <span className={classes.statusItem}>
                    En objetivo
                </span>
            </div>
        case 'AMARILLO':
            return <div className={classes.statusWrapper}>
                <span className={classes.statusItem}>
                    <div className={classNames(classes.circleStatus, classes.circleStatusYellow)}></div>
                </span>
                <span className={classes.statusItem}>
                    Cerca de objetivo
                </span>
            </div>
        case 'ROJO':
            return <div className={classes.statusWrapper}>
                <span className={classes.statusItem}>
                    <div className={classNames(classes.circleStatus, classes.circleStatusRed)}></div>
                </span>
                <span className={classes.statusItem}>
                    Lejos de objetivo
                </span>
            </div>
        default:
            return null
    }
}