import React from 'react';

import { Container, withStyles } from '@material-ui/core';

import { oAuthStyles } from './o-auth.styles';
import { OAuthLoginComponent } from '../../../o-auth';

export const OAuthComponent = withStyles(oAuthStyles)(({ classes }) => (
  <Container className={classes.root}>
    <OAuthLoginComponent />
  </Container>
));
