import React from 'react';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import { withStyles, Checkbox } from '@material-ui/core';

import { checkBoxStyles } from './check-box.styles';

export const CheckBox = withStyles(checkBoxStyles)(
  ({ classes, checked, label }) => (
    <>
      <Checkbox
        checked={checked}
        classes={classes}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
      />
      {label}
    </>
  )
);
