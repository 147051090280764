/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { ConnectionStatusProvider } from './contexts';

export const ConnectionStatus = ({ children }) => {
  const [detector, setDetector] = useState(navigator.onLine);

  const ifEvent = () => {
    if (navigator.onLine) {
      setDetector(true);
    } else {
      setDetector(false);
    }
  };

  useEffect(() => {
    window.addEventListener('offline', ifEvent);
    window.addEventListener('online', ifEvent);
  }, []);

  return (
    <ConnectionStatusProvider value={{ online: detector }}>
      {children}
    </ConnectionStatusProvider>
  );
};
