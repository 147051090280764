import { createMuiTheme } from '@material-ui/core';

const NAVBAR_HEIGHT_PIXELS = 64;
const NAVBAR_PROMOTION_HEIGHT_PIXELS = 112;
const CONTAINER_MARGIN_PIXELS = 16;

export const theme = createMuiTheme({
  overrides: {
    MuiFormControlLabel: {
      root: {
        color: '#000000',
      },
      label: {
        opacity: 0.5,
      },
    },
    MuiAlert: {
      root: {
        borderRadius: 0,
      },
      filledSuccess: {
        backgroundColor: '#4caf88',
      },
    },
    MuiSnackbar: {
      root: {
        zIndex: 9999,
      },
    },
    MuiButton: {
      root: {
        fontWeight: 400,
      },
    },
  },
  palette: {
    primary: {
      main: '#005442',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#005442',
      contrastText: '#FFF',
    },
    white: {
      main: '#FFFFFF',
      T30: '#FFFFFF4D',
    },
    black: 'black',
    transparent: 'transparent',
  },
  forms: {
    control: {
      margin: '10px 0',
    },
  },
  sizes: {
    invert: (value) => `-${value}`,
  },
  wrappers: {
    border: (color) => `1px solid ${color}`,
    position: {
      absolute: 'absolute',
    },
    visibility: {
      hidden: 'hidden',
    },
    fullWidth: '100%',
    fullHeight: '100%',
    fullContentHeight: `calc(100% - ${NAVBAR_HEIGHT_PIXELS}px)`,
    fullContentHeightPromotion: `calc(100% - ${NAVBAR_PROMOTION_HEIGHT_PIXELS}px)`,
    fullViewPortWidth: `calc(100% + ${CONTAINER_MARGIN_PIXELS * 2}px)`,
    percentWidth: (val) => `${val}%`,
    gradient: (color1, color2) =>
      `linear-gradient(180deg, ${color1} 0%, ${color2} 100%)`,
    display: {
      flex: 'flex',
      block: 'block',
      absolute: 'absolute',
    },
    flexDirection: {
      column: 'column',
    },
    justifyContent: {
      end: 'flex-end',
      spaceBetween: 'space-between',
    },
    alignItems: {
      end: 'end',
      center: 'center',
    },
    backgroundSizeCover: 'cover',
    backgroundImage: `url('/header-detail.jpg')`,
    alignCenter: 'center',
    iconSize: '24px',
    boxContentPadding: '10px 24px',
    detailTitlePadding: '20px 10px 10px 10px',
    detailIconButtonPadding: '0 14px',
  },
  colors: {
    white: {
      main: '#FFFFFF',
      T30: '#FFFFFF4D',
    },
    black: {
      man: '#000000',
      T00: '#00000000',
    },
  },
  detail: {
    height: '180px',
    image: `url('/header-detail.jpg')`,
    offset: (navBarHeight, detailHeaderHeight) =>
      `calc(100vh - ${navBarHeight} - ${detailHeaderHeight})`,
  },
  navBar: {
    height: `${NAVBAR_HEIGHT_PIXELS}px`,
  },
  font: {
    weight: {
      100: 100,
      500: 500,
    },
    style: {
      italic: 'italic',
    },
    size: {
      16: 16,
      18: 18,
      20: 20,
    },
  },
});
