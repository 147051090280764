export const propertiesStyles = (theme) => ({
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: theme.wrappers.fullWidth,
    backgroundColor: theme.palette.primary.main,

    '&:focus': {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
  header: {
    display: theme.wrappers.display.flex,
    justifyContent: theme.wrappers.justifyContent.spaceBetween,
    alignItems: theme.wrappers.alignItems.end,
    padding: theme.spacing(2),
  },
  title: {
    color: theme.palette.primary.main,
  },
});
