/* eslint-disable react-hooks/exhaustive-deps */
import Button from '@material-ui/core/Button';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from '../../core/hooks/use-selector';
import { PouchDBContext } from '../../core/pouch-db';
import { withForm } from '../../core/validations';
import { SelectInput, TextAreaInput } from '../../elements';
import { DialogMessage } from '../../elements/dialog';
import { LeadContext } from '../../pages/leads/contexts';
import { setTypeLogs } from '../../store/action';
import { RadioGroup } from '../../ui';
import { ImageUploader } from '../../ui/image-uploader';
import { useModalContext } from '../../ui/modal/contexts';
import { SnackBarContext } from '../../ui/snack-bar';
import { activitiesFormHandleSubmitCallback } from '../callbacks';
import { ACTIVITIES_FORM, HAS_FIRMED_VALUES } from '../constants';
import { activitiesFormEffect, activitiesFormReasonEffect } from '../effects';
import { getHasFirmedChangeByMotive } from '../utils';

export const ActivitiesFormComponent = withForm(
  ACTIVITIES_FORM,
  false
)(
  ({
    form,
    onSubmit,
    handleChange,
    tableValues,
    classes,
    validators,
    resetForm,
  }) => {
    const history = useHistory();
    const model = useContext(LeadContext);
    const [reasons, setReasons] = useState([]);
    const { setData, getData, putData, setIsSyncing } = useContext(PouchDBContext);
    const [hasToFirm, setHasToFirm] = useState(false);
    const {
      actions: { open: openModal },
    } = useModalContext();
    const [customErrors, setCustomErrors] = useState({
      hasFirmed: {},
      attachments: {},
    });
    const {
      actions: { open: feedback },
    } = useContext(SnackBarContext);
    const {
      actions: { open: openSnackBar },
    } = useContext(SnackBarContext);

    const dispatch = useDispatch();

    const uid = useSelector((s) => s.uid);

    useEffect(() => {
      setTypeLogs(dispatch, true)
    }, [])

    const handleMotiveChange = useCallback((event) => {
      handleChange(event);
      handleChange(getHasFirmedChangeByMotive(event));
    }, []);

    const getCustomErrors = () => {
      setCustomErrors({
        hasFirmed: /^$|\s+/.test(form.hasFirmed)
          ? customErrors.hasFirmed
          : {},
        attachments: /^$|\s+/.test(form.attachments)
          ? customErrors.attachments
          : {},
      });
    }

    const handleErrors = useCallback(
      (event) => {
        getCustomErrors()
        handleChange(event);
      },
      [form]
    );

    const handleErrorsInputSelect = useCallback(
      (event) => {
        getCustomErrors()
        activitiesFormReasonEffect(reasons, handleChange, event)
      },
      [form]
    );

    const handleSubmit = useCallback(
      activitiesFormHandleSubmitCallback(
        {
          onSubmit,
          openModal,
          setData,
          feedback,
          setCustomErrors,
          openSnackBar,
          getData,
          putData,
          uid
        },
        { history, tableValues, handleChange, form, hasToFirm, model }
      ),
      [form, hasToFirm, history, tableValues, reasons, hasToFirm, model]
    );

    useEffect(resetForm, [history]);
    useEffect(
      activitiesFormEffect(form, handleChange, tableValues, setReasons),
      [form.motive]
    );
    useEffect(() => {
      setHasToFirm(form.motive === '06');
    }, [form, model]);

    const onClickHandler = async () => {
      let isAnotherActivityCreated = false;
      const managementId = model.managementId;
      const typeActivity = form.motive;

      await getData({
        document: 'activities',
        managementId: managementId,
        motiveId: typeActivity
      }).then((res) => {
        const activities = res.docs;

        activities.map((activity) => {
          if (activity.date) {
            if (new Date(activity.date).toDateString() === new Date().toDateString())
              isAnotherActivityCreated = true;
          } else {
            if (new Date(activity.createdAt).toDateString() === new Date().toDateString())
              isAnotherActivityCreated = true;
          }
        })
      });

      if (isAnotherActivityCreated) {
        openModal({
          Component: DialogMessage,
          view: 'add-lead',
          data: {
            title: 'Crear una nueva actividad',
            message: 'Ya tenemos un registro por esta actividad en las últimas 24 horas, ¿deseas crearla de nuevo?',
            btnConfirmLabel: 'No',
            btnCloseLabel: 'Nueva actividad',
            onClose: (hasConfirm) => {
              if (hasConfirm) {
                history.goBack()
              } else {
                setIsSyncing(true);
                handleSubmit();
              }
            }
          },
        });
      } else {
        setIsSyncing(true);
        handleSubmit();
      }
    };

    return (
      <>
        <div className={classes.form}>
          <SelectInput
            label="Selecciona una acción"
            value={form.motive}
            name="motive"
            margin="normal"
            variant="filled"
            optionValue="value"
            optionLabel="label"
            options={tableValues}
            onChange={handleMotiveChange}
            errorMessage={validators.motive.message}
          />
          {reasons.length ? (
            <SelectInput
              label="Seleccione un motivo"
              value={form.reasonId}
              name="reason"
              blankOptions={false}
              options={reasons}
              margin="normal"
              variant="filled"
              disabled={reasons.length === 1}
              optionValue="value"
              optionLabel="label"
              onChange={handleErrorsInputSelect}
            />
          ) : (
            ''
          )}

          <TextAreaInput
            name="comment"
            variant="filled"
            label="Comentario"
            value={form.comment}
            onChange={handleErrors}
            className={classes.comment}
          />

          {hasToFirm ? (
            <>
              <RadioGroup
                name="hasFirmed"
                value={form.hasFirmed}
                onChange={handleErrors}
                values={HAS_FIRMED_VALUES}
                errorMessage={customErrors.hasFirmed.message}
                label="El cliente ha firmado el comprobante de visita"
              />

              <ImageUploader
                form={form}
                onChange={handleErrors}
                errorMessage={customErrors.attachments.message}
              />
            </>
          ) : (
            ''
          )}
        </div>
        <div className={classes.actions}>
          <span className={classes.cancel} onClick={history.goBack}>
            CANCELAR
          </span>
          <Button
            data-cy="addActivity"
            variant="contained"
            color="primary"
            onClick={() => onClickHandler()}
          >
            AÑADIR
          </Button>
        </div>
      </>
    );
  }
);
