import { IMAGE_UPLOADER_CONFIG } from "../../../_incidences/constants";

/** Functions image-uploaders */
export const getImage = (file) => {
    return new Promise((resolve, reject) => {
      let result = '';
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        result = e.target.result;
        resolve(result);
      };
      reader.onerror = (e) => {
        reject(e);
      };
    });
  };

  export const getValidImages = (files,form,limit) => {
    let filesList = [];
    Object.values(files).forEach((file) => {
      if (typeof file === 'object') filesList.push(file);
    });
    const currentUploadedImages = form
      ? Object.values(form['attachments'] || []).length
      : filesList.length;
    let availableImagesToUpload = limit - currentUploadedImages;
    let validImages = [];
    filesList.forEach((file, i) => {
      if (availableImagesToUpload >= 0) {
        availableImagesToUpload = --availableImagesToUpload;
        const isValidType = IMAGE_UPLOADER_CONFIG.TYPES.some(
          (permitedType) => file.type.split('/')[1] === permitedType
        );
        if (isValidType) {
          validImages.push(file);
        }
      }
    });

    return validImages;
  };

  export const dataURLToBlob = (dataURL) => {
    const BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      const parts = dataURL.split(',');
      const contentType = parts[0].split(':')[1];
      const raw = parts[1];

      return new Blob([raw], { type: contentType });
    }

    const parts = dataURL.split(BASE64_MARKER);
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;

    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  };

  export const resizeAndSaveImage = (file,attachs,setAttachs,limit) => {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      const image = new Image();
      image.src = readerEvent.target.result;
      image.onload = async () => {
        let canvas = document.createElement('canvas'),
          max_width_size = IMAGE_UPLOADER_CONFIG.MAX_WIDTH_SIZE,
          width = image.width,
          height = image.height;
        if (width > height) {
          if (width > max_width_size) {
            height *= max_width_size / width;
            width = max_width_size;
          }
        } else {
          if (height > max_width_size) {
            width *= max_width_size / height;
            height = max_width_size;
          }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        const dataUrl = canvas.toDataURL('image/jpeg');
        const resizedImage = dataURLToBlob(dataUrl);

        if (Object.keys(attachs).length <= limit) {
          const attachments = {
            [file.name]: {
              type: file.type,
              data: resizedImage,
            },
          };

          setAttachs({ payload: attachments });
        }
      };
    };
    reader.readAsDataURL(file);
  };

