import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FORM_ELEMENTS } from '../../../../shared/checklists-wizard/constants/securityMaintenance/form-elements';
import WizardView from '../../../../shared/checklists-wizard/step-base.component'
import { renderElementForm } from '../../utils';
import { useSelector, useDispatch } from '../../../../core/hooks/use-selector';
import { setScreenTwoData } from '../../../../store/action';

/** View screen 2 : Seguridad y mantenimiento */
const Step2SecurityMaintenance = (props) => {
  const { onChangeStepHandler, haveBack = true } = props;
  const state = useSelector((s) => s.screenTwoData);
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: state });

  const dispatch = useDispatch();

  /** This method (react hook form) will watch specified inputs and return their values.
   *  It is useful to render input value and for determining what to render by condition*/
  const watchAllFields = watch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /** Render variables and tabs with validations*/
  const formElementsHTML = FORM_ELEMENTS.map((e) =>
    renderElementForm({ register, setValue }, e, watchAllFields, errors)
  );

  /** Next step */
  const onSubmitHandler = (data) => {
    setScreenTwoData(dispatch, data);
    onChangeStepHandler(2);
  };

  /** Back step */
  const onBackWizard = () => {
    onChangeStepHandler(0);
  };

  const fields = <>{formElementsHTML}</>;

  return (
    <WizardView
      title="Seguridad y mantenimiento"
      fields={fields}
      onNext={handleSubmit(onSubmitHandler)}
      onBack={onBackWizard}
      haveBack={haveBack}
    />
  );
};

export default Step2SecurityMaintenance;
