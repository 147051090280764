import React from 'react';
import './detail-comission.component.scss';

export const CommissionDetailContent = ({
  comissionCommercialType,
  commissionApiImport,
  commissionApiPerc,
  commissionOtherImport,
  commissionOtherPerc,
}) => {
  return (
    <>
      {/* If management/property isn't "alquiler" and comission exists */}
      {comissionCommercialType !== undefined && comissionCommercialType !== '03' && (
        <div className='comission-detail-content'>
          <CommissionDetailItem
            origin='Comision Origen API (*)'
            commissionImport={((commissionApiImport && commissionApiImport !== undefined) ? commissionApiImport : '0') + ' €'}
            commissionPerc={((commissionApiPerc && commissionApiPerc !== undefined) ? commissionApiPerc : '0') + ' %'}
          />
          <CommissionDetailItem
            origin='Comision otro Origen (*)'
            commissionImport={((commissionOtherImport && commissionOtherImport !== undefined) ? commissionOtherImport : '0') + ' €'}
            commissionPerc={((commissionOtherPerc && commissionOtherPerc !== undefined) ? commissionOtherPerc : '0') + ' %'}
          />
          <p className='comission-detail-content__text'>La comisión aplicable en las operaciones de venta intermediadas por el colaborador será, en todo caso, la de la fecha efectiva de subida de la oferta de compra junto con los documentos requeridos por parte de Servihabitat.</p>
        </div>
      )}
    </>
  );
};


export const CommissionDetailItem = ({ origin, commissionImport, commissionPerc }) => {
  return (
    <div className='comission-detail-item'>
      <div>
        <span>{origin}</span>
      </div>
      <div className='comission-detail-item comission-detail-item__child'>
        <span className='comission-detail-item__text'>{commissionImport}</span>
        <span className='comission-detail-item__text'>{commissionPerc}</span>
      </div>
    </div>
  );
};

export const BiddingPeriodText = ({ isProperty, data }) => {
  const condition =
    // isProperty &&
    (
      (data.wowDateIni ||
        data.wowDateIni !== undefined ||
        data.wowDateIni !== '') &&
      (data.wowDateEnd &&
        data.wowDateEnd !== undefined &&
        data.wowDateEnd !== '') &&
      (data.wowTimeEnd &&
        data.wowTimeEnd !== undefined &&
        data.wowTimeEnd !== '')
    )
  return (
    <>
      {condition
        ? <p className='comission-biddingText'>{`INMUEBLE WOW EN PERIODO DE PUJAS DESDE EL ${data.wowDateIni} AL ${data.wowDateEnd} A LAS ${data.wowTimeEnd}`}</p>
        : ''
      }
    </>
  );
};