import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import {
  Cancel,
  CheckCircle,
  ExpandMore as ExpandMoreIcon,
  RadioButtonUnchecked,
} from '@material-ui/icons';
import { renderElementForm } from '../../shared/checklists-wizard/utils';
import { Box, withStyles } from '@material-ui/core';
import { accordionStyles } from './accordion.styles';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import { LABELS } from '../../pages/checklistsPromotion/steps/constants';

export const AccordionCustom = withStyles(accordionStyles)(
  ({
    classes,
    name,
    title,
    description,
    options,
    optionalOptions,
    functions,
    currentValues,
    isError,
    state,
  }) => {
    const [selectedAccordion, setSelectedAccordion] = useState(null);
    const [accordionComplete, setAccordionComplete] = useState(
      Object.keys(currentValues).length > 2 ? true : false
    );
    const {
      getValues,
      register,
      setValue,
      handleSubmit,
      watch,
      unregister,
      formState: { errors, submitCount },
    } = useForm({ defaultValues: currentValues });
    const watchAllFields = watch();

    useEffect(() => {
      if (Object.entries(errors).length > 0)
        functions.setValue(name, {});
    }, [submitCount])

    const saveData = (data) => {
      functions.setValue(name, data);
      setSelectedAccordion(null);
      setAccordionComplete(true);
    };

    return (
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Accordion
          key={name}
          data-testid={'accordion-' + name}
          expanded={selectedAccordion === name}
          onChange={() =>
            setSelectedAccordion(selectedAccordion === name ? '' : name)
          }
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.iconContainer}>
              {isError ? (
                <Cancel style={{ color: 'red' }} />
              ) : accordionComplete ? (
                <CheckCircle color="primary" data-testid="accordion-checked" />
              ) : (
                <RadioButtonUnchecked
                  color="primary"
                  data-testid="accordion-unchecked"
                />
              )}
            </div>
            <div className="header-content">
              <Typography>UR: {title}</Typography>
              <Typography sx={{ fontWeight: 500 }}>{description}</Typography>
              {isError && Object.keys(currentValues).length <= 2 && (
                <Typography variant="body2" sx={{ color: 'red' }}>
                  Por favor, revise los datos antes de continuar
                </Typography>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="content">
              <div>
                {options?.map((option) =>
                  renderElementForm(
                    { register, setValue },
                    option,
                    watchAllFields,
                    errors
                  )
                )}
                {optionalOptions[0]?.map((optionalOption) =>
                  getValues().visited === '2'
                    ? renderElementForm(
                      { register, setValue },
                      optionalOption,
                      watchAllFields,
                      errors
                    )
                    : Object.keys(getValues()).find(
                      (element) => element === optionalOption.name
                    )
                      ? unregister(optionalOption.name, optionalOption.rules)
                      : null
                )}
                {optionalOptions[1]?.map((optionalOption) =>
                  getValues().visited === '1'
                    ? renderElementForm(
                      { register, setValue },
                      optionalOption,
                      watchAllFields,
                      errors
                    )
                    : Object.keys(getValues()).find(
                      (element) => element === optionalOption.name
                    )
                      ? unregister(optionalOption.name, optionalOption.rules)
                      : null
                )}
              </div>

              <div className={classes.buttonContainer}>
                <Button
                  data-testid="save-price"
                  id="save-price-button"
                  // className='save-price-button'
                  className={
                    state && Object.keys(state).length <= 1
                      ? classes.savePriceButtonHidden
                      : 'save-price-button'
                  }
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit(saveData)}
                >
                  {LABELS.SAVE}
                </Button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
);
