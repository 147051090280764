import React from 'react';

import ErrorIcon from '@material-ui/icons/Error';

import { Container, withStyles } from '@material-ui/core';
import { appMonitorFallbackStyles } from './app-monitor-error-fallback.styles';

export const AppMonitorErrorFallback = withStyles(appMonitorFallbackStyles)(
  ({ classes }) => (
    <>
      <div className={classes.header}>
        <img
          className={classes.img}
          src="/images/logo-white.png"
          alt="Logo servihabitat"
        />
      </div>

      <Container className={classes.container} maxWidth="sm">
        <ErrorIcon className={classes.icon} fontSize="large" />
        <div className={classes.content}>
          <h2>Upsss! Algo ha ido mal</h2>

          <small>
            <p>
              Vuelva a intentarlo más tarde. Si quiere reportar un problema,
              llame al Contact Center.
            </p>
          </small>
        </div>
      </Container>
    </>
  )
);
