import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PouchDBContext } from '../../../core/pouch-db';
import { LoadingMessageComponent } from '../../../shared/loading';
import { ChecklistsListComponent } from './checklists-list.component';
import { DEFAULT_FILTERS } from '../constants';
import { ChecklistsProvider } from '../contexts';
import ChecklistStepsWizard from '../../checklists-steps';
import { checklistsEffect } from '../effects'
import Step6Summary from '../../checklists-steps/steps/Summary';
import PromotionStepsWizard from '../../checklistsPromotion';
import Step7Summary from '../../checklistsPromotion/steps/summary';

/** Page that renders one component or another depending on the route, and provides the context of this evolutionary. */
export const Checklists = () => {
  const [checklists, setChecklists] = useState();
  const [filteredModel, setFilteredModel] = useState();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const { getData, deleteData, isSyncing } = useContext(PouchDBContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /** Get data and sync offline and online */
  useEffect(checklistsEffect(getData, setChecklists, isSyncing, deleteData), [
    getData,
    isSyncing,
  ]);

  /** Filtered and sort data with key :'new' (pendientes) or 'in-progress' (gestionadas) */
  useEffect(() => {
    if (checklists) {
      setFilteredModel(
        checklists
          .filterByKey(filters.filterKey, filters.filterValue)
          .sortByKey(filters.sort, filters.direction)
      );
    }
  }, [filters, checklists]);

  /** If there are changes modify state and render with useeffect */
  const onChangeFilters = (values) => {
    setFilters({ ...filters, ...values });
  };

  return filteredModel ? (
    <ChecklistsProvider
      value={{
        model: filteredModel,
        getModel: checklistsEffect(getData, isSyncing, setChecklists, deleteData),
      }}
    >
      <Switch>
        <Route
          exact
          path="/checklists"
          render={() => (
            <ChecklistsListComponent
              filters={filters}
              onChangeFilters={onChangeFilters}
            />
          )}
        />
        <Route path="/checklists/:checklistId/summary" component={Step6Summary} />
        <Route path="/checklists/promotion/:checklistId/summary" component={Step7Summary} />
        <Route path="/checklists/promotion/:checklistId" component={PromotionStepsWizard} />
        <Route path="/checklists/:checklistId" component={ChecklistStepsWizard} />
      </Switch>
    </ChecklistsProvider>
  ) : (
    <LoadingMessageComponent resource="las checklists" />
  );
};
