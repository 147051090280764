const consoleLog = (type, text, text2) => {
  if (
    process.env.REACT_APP_CONSOLELOG === 'info' ||
    (process.env.REACT_APP_CONSOLELOG === 'error' && type === 'error')
  ) {
    if (text2) {
      console[type](text, text2);
    } else {
      console[type](text);
    }
  }
};

export default consoleLog;
