// ! Duplicate code of ../pages/checklists-steps/utils.js,
// ! it gave error in reduce if file function is imported checklists-steps/utils

// TODO Fix reducer error and have this function in one place.

export const getCurrentDate = () => {
    const now = new Date();
    const [day, month, year] = [
        now.getDate().toString().padStart(2, '0'),
        (now.getMonth() + 1).toString().padStart(2, '0'),
        now.getFullYear(),
    ];
    return `${year}-${month}-${day}`;
};