import React, { useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Icon, IconButton, Typography } from '@material-ui/core';

import { PouchDBContext } from '../../../core/pouch-db';

import { CLIENT_BUTTONS } from '../constants';
import {
  updateDetailClientEffect,
  detailClientEffect,
  detailHeaderEffect,
} from '../effects';
import FetchApi from '../../../core/fetch-api';
import LogsBuffer from '../../../core/logs-buffer/logs-buffer';
import { UserContext } from '../../../core/user';

export const DetailHeaderComponent = ({ classes, model, url }) => {
  const history = useHistory();
  const { getData, isSyncing } = useContext(PouchDBContext);
  const { state: userState } = useContext(UserContext);
  const [client, setClient] = useState({});
  const [headerClass, setHeaderClass] = useState({});

  useEffect(updateDetailClientEffect(model.clientId, FetchApi), []);
  useEffect(detailClientEffect(getData, isSyncing, model, setClient), [
    getData,
    isSyncing,
  ]);
  useEffect(detailHeaderEffect(client.clientId, classes, setHeaderClass), [
    client.clientId,
  ]);

  return (
    <div className={headerClass}>
      <div className={classes.gradient}>
        <div className={classes.titleWrapper}>
          <IconButton
            aria-label="Atrás"
            className={classes.back}
            onClick={() => {
              FetchApi.request('get', '/management');
              history.goBack();
            }}
          >
            <Icon className={classes.icon}>keyboard_arrow_left</Icon>
          </IconButton>
          <Typography
            className={classes.title}
            variant="h5"
            align="center"
            noWrap
          >
            {model.name}
          </Typography>
        </div>
        <div className={classes.buttonsWrapper}>
          {client &&
            CLIENT_BUTTONS({ history, url }).map((button, key) =>
              client[button.key] ||
                (button.isVisible && button.isVisible(client)) ? (
                <IconButton
                  key={key}
                  aria-label={button.ariaLabel}
                  className={classes.iconButton}
                  onClick={() => {
                    let location = history.location.pathname.split('/');
                    let id = location.pop();
                    let url = history.location.pathname;
                    switch (button.key) {
                      case 'phone1':
                      case 'email':
                        let viewName =
                          button.key === 'phone1' ? 'phone' : 'email';
                        LogsBuffer.addLog({
                          type: 'Frontend',
                          event: 'Navigation',
                          createdAt: Date.now(),
                          url: url,
                          view: viewName,
                          resourceId: id,
                          apiId: userState.enrollment,
                        });
                        button.onClick(client[button.key]);
                        break;
                      case 'whatsapp':
                        LogsBuffer.addLog({
                          type: 'Frontend',
                          event: 'Navigation',
                          createdAt: Date.now(),
                          url: url,
                          view: button.key,
                          resourceId: id,
                          apiId: userState.enrollment,
                        });
                        button.onClick(client, history);
                        break;
                      default:
                        button.onClick(
                          button.key ? client[button.key] : client,
                          history
                        );
                        break;
                    }
                  }}
                >
                  {button.icon}
                </IconButton>
              ) : (
                ''
              )
            )}
        </div>
      </div>
    </div>
  );
};
