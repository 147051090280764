import React from 'react';

export const DescriptionCard = ({ title, children, border = true }) => {
  return (
    <div
      style={{
        padding: '18px',
        borderTop: border ? '1px solid #E6E6E6' : '0px',
      }}
    >
      {title && (
        <div
          style={{ padding: '0 0 8px 0', color: '#005442', fontSize: '20px' }}
        >
          {title}
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};
