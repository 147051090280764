export const propertiesCommercialTypeStyles = (theme) => ({
  button: {
    color: theme.palette.secondary.main,
  },
  bold: {
    fontSize: 15,
    marginTop: '-8px',
    fontWeight: '600',
    color: theme.palette.black,
  },
});
