export const noDataStyle = (theme) => ({
  root: {
    paddingTop: 50,
    textAlign: theme.wrappers.alignCenter,
  },
  icon: {
    fontSize: 100,
    color: '#d1d1d1',
  },
  text: {
    fontSize: 16,
    fontWeight: 400,
    color: '#a1a1a1',
  },
});
