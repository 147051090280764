import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { getViewOfObjective } from '../../pages/kpis/ControlPanel/utils';

const useKPIsAccordion = (panes = [], classes) => {
  const [selectedAccordion, setSelectedAccordion] = useState(null);

  return panes.length > 0 ? (
    <div className="kpis-accordion">
      {selectedAccordion == null ? setSelectedAccordion(panes[0].name) : ''}
      {panes?.map(({ name, header, content }) => {
        return (
          <Accordion
            className="container"
            key={name}
            data-testid={'accordion-' + name}
            expanded={selectedAccordion === name}
            onChange={() =>
              setSelectedAccordion(selectedAccordion === name ? '' : name)
            }
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className="header-content">
                <Typography>{header?.title?.toUpperCase()}</Typography>
                {header.badge ? getViewOfObjective(header.badge.color, classes) : ''}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="content">
                {content?.map((c) => {
                  return (
                    <div>
                      <Typography className="label">{c.label}</Typography>
                      <Typography className="value">{c.value}</Typography>
                    </div>
                  );
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  ) : null;
};

export default useKPIsAccordion;
