import log from 'loglevel';

const pattern = 'persist:';

const StorageService = {
  save(key, value) {
    try {
      return window.localStorage.setItem(`${pattern}${key}`, value);
    } catch (error) {
      log.error(`StorageService setItem Error: ${error.message}`);
      return null;
    }
  },

  get(key) {
    try {
      return window.localStorage.getItem(`${pattern}${key}`);
    } catch (error) {
      log.error(`StorageService getItem Error: ${error.message}`);
      return null;
    }
  },

  delete(key) {
    try {
      return window.localStorage.removeItem(`${pattern}${key}`);
    } catch (error) {
      log.error(`StorageService Error: ${error.message}`);
      return null;
    }
  },

  clear() {
    try {
      return window.localStorage.clear();
    } catch (error) {
      log.error(`StorageService Error: ${error.message}`);
      return null;
    }
  },
};

export default StorageService;
