export const controlPanelFields = (state) => {

    return {
        title: 'Seguimiento KPIs',
        data: {
            date: state.fechaHora,
            ranking: state.ranking,
            boxes: [
                {
                    title: 'Ritmo Operativo',
                    description: 'Niveles de cumplimiento en el reporte del seguimiento de clientes y uso de MAPIs',
                    objective: state.ritmoPromedio,
                    path: '/kpis/rhythm_operative'
                },
                {
                    title: 'Conocimiento de Producto',
                    description: 'Niveles de cumplimiento en la realización de checklists por entrada de producto: nuevo código postal o puesta en publicación',
                    objective: state.conocimientoPromedio,
                    path: '/kpis/product_knowledge'
                },
                {
                    title: 'Impacto en Producto',
                    description: 'Niveles de cumplimiento en los KPIs que indican avance en el proceso de venta: visitas, aportación propia total y aportación en producto no residencial',
                    objective: state.impactoPromedio,
                    path: '/kpis/product_impact'
                },
                {
                    title: 'Ranking',
                    description: 'Información KPIs Ranking (complementarios a los anteriores)',
                    path: '/kpis/ranking'
                }
            ]
        }
    }
};