import React from 'react';

import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

export const SelectInput = ({ blankOptions = true, ...props }) => {
  return (
    <FormControl style={{ width: '100%' }}>
      <TextField
        style={{ width: '100%', ...props.style }}
        select
        label={props.label}
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        required={props.required}
        disabled={props.disabled}
        SelectProps={{
          native: true,
        }}
        margin={props.margin}
        variant="filled"
      >
        {blankOptions ? <option value={''} disabled></option> : ''}
        {(props.options || []).map((element, key) => {
          return (
            <option key={key} value={element[props.optionValue]}>
              {element[props.optionLabel]}
            </option>
          );
        })}
      </TextField>
      <FormHelperText
        id="my-helper-text"
        style={{ marginTop: '-5px' }}
        error={!!props.errorMessage}
      >
        {props.errorMessage}
      </FormHelperText>
    </FormControl>
  );
};
