import React from 'react';

import { ListDetailItem } from './index';

import './list-detail.component.scss';

export const ListDetail = ({ config = [], model, tableValues }) => {
  const checkItemExistence = (item, model) => {
    if (item.hasOwnProperty('fields') && item.fields.length > 0) {
      return true;
    } else if (item.key instanceof Array) {
      return item.key.some((key) => model && model[key] && model[key] !== '');
    } else {
      return model && model[item.key] && model[item.key] !== '';
    }
  };

  return (
    <ul style={{ padding: 0 }}>
      {config
        .filter((item) => checkItemExistence(item, model))
        .filter((item) => item.key !== 'possessionStatus')
        .map((item, i) => {
          return (
            <li
              key={i}
              style={{
                display: 'flex',
                flexGrow: 1,
                margin: '20px 0 10px',
              }}
            >
              <ListDetailItem
                item={item}
                model={model}
                tableValues={tableValues}
              />
            </li>
          );
        })}
    </ul>
  );
};
