import FetchApi from '../../core/fetch-api';

export const uploadAttachmentsEffect = async (
  onSubmit,
  managementId,
  handleChange,
  form,
  openSnackBar,
  event
) => {
  const keys = Object.keys(form.attachments);
  if (form.attachments && keys && keys.length) {
    const attachments = [];
    keys.forEach((x) => attachments.push({ ...form.attachments[x], name: x }));

    return FetchApi.sendImages(
      'post',
      `/management/${managementId}/activity/attachments`,
      attachments
    ).then((res) => {
      if (res && res.status === 200) {
        const { results } = res;
        const urls = results.map((x) => x.url);
        handleChange({ target: { name: 'attachments', value: urls } });

        onSubmit(event);
      } else if (
        res &&
        res.results &&
        res.results.message === 'Failed to fetch'
      ) {
        openSnackBar({
          type: 'error',
          visible: true,
          title: 'Error al subir fotos',
          message:
            'Actividad no creada, necesita conexión a internet para poder subir fotos',
        });
      } else {
        openSnackBar({
          type: 'error',
          visible: true,
          title: 'Error al subir fotos',
          message:
            'Las fotos adjuntas no se han podido subir y por lo tanto no se ha creado la actividad',
        });
      }
    });
  } else {
    onSubmit(event);
  }
};
