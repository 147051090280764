import React from 'react';

const getValue = (item, model) => {
  if (
    item.hasOwnProperty('options') &&
    item.options instanceof Array &&
    item.options.length > 0
  ) {
    const option = item.options.find(
      (option) => option.value === model[item.key]
    );
    return option.label;
  } else if (item.hasOwnProperty('type')) {
    if (item.type === 'currency' && !isNaN(Number(model[item.key]))) {
      return Number(model[item.key]).currencyFormat('€');
    } else if (item.type === 'number' && !isNaN(Number(model[item.key]))) {
      return Number(model[item.key]).numberFormat(2, 0, '.', ',');
    }
  } else {
    return model[item.key];
  }
};

export const DescriptorItem = ({ item, model }) => {
  return (
    <span
      style={{
        display: 'grid',
        marginTop: '-5px',
        width: '70%',
      }}
    >
      <small
        style={{
          color: 'rgba(0,0,0, .6)',
          fontWeight: '500',
          fontSize: '12px',
          height: '12px',
        }}
      >
        {item.label}
      </small>
      {item.key instanceof Array ? (
        item.key.map((item, i) => {
          return (
            <span key={i} style={{ fontSize: '16px' }}>
              {model[item]}
            </span>
          );
        })
      ) : (
        <span style={{ fontSize: '16px' }}>{getValue(item, model)}</span>
      )}
    </span>
  );
};
