import { useContext, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { logDnaEffect } from '../effects';
import { ModalContext } from '../../../ui/modal/contexts';
import FetchApi from '../../fetch-api';
import {UserContext} from '../../user';
import { useSelector } from '../../hooks/use-selector';

export const LogDnaComponent = ({ children }) => {
  const history = useHistory();
  const modal = useContext(ModalContext);
  const { state: userState } = useContext(UserContext);
  const typeLogs = useSelector((s) => s.typeLogs);

  useEffect(logDnaEffect(modal, history, FetchApi, userState,typeLogs), [history, modal.isOpen]);

  return children;
};
