import React from 'react';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export const OAuthRecoverDialogComponent = ({ close }) => (
  <>
    <DialogTitle>Recuperar o cambiar la contraseña</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Para recuperar o cambiar la contraseña deberá dirigirse a la aplicación
        de escritorio WAPI y efectuar esta acción directamente desde allí.
        <br />
        <br />
        Ante cualquier duda llámenos al teléfono 942 049 002.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={close}>Cerrar</Button>
    </DialogActions>
  </>
);
