import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core';

import { OAuthRecoverDialogComponent } from '.';
import { ModalConsumer } from '../../../ui/modal/contexts';
import { oAuthRecoverStyles } from './o-auth-recover.styles';

export const OAuthRecoverComponent = withStyles(oAuthRecoverStyles)(
  ({ classes }) => (
    <ModalConsumer>
      {({ actions: { open, close } }) => (
        <Box>
          <Button
            className={classes.button}
            onClick={() =>
              open({
                Component: () => <OAuthRecoverDialogComponent close={close} />,
              })
            }
          >
            Recuperar o cambiar la contraseña
          </Button>
        </Box>
      )}
    </ModalConsumer>
  )
);
