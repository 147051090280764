export const viewStyles = (theme) => ({
  root: {
    height: theme.spacing(9),
    padding: theme.spacing(0),
    minHeight: theme.spacing(10),
    backgroundSize: theme.wrappers.backgroundSizeCover,
    backgroundImage: theme.wrappers.backgroundImage,
  },
  list: {
    minHeight: theme.spacing(9),
    height: theme.wrappers.fullHeight,
    background: theme.wrappers.gradient(
      theme.colors.black.T00,
      theme.palette.secondary.main
    ),
  },
  back: {
    minWidth: theme.spacing(4),
    zIndex: 100,
    color: theme.palette.white.main,
  },
  iconButton: {
    zIndex: 9,
    color: theme.palette.white.main,
  },
  title: {
    fontSize: theme.font.size['16'],
    textAlign: theme.wrappers.alignCenter,
    color: theme.palette.white.main,
  },
  subtitle: {
    fontSize: theme.font.size['18'],
    textAlign: theme.wrappers.alignCenter,
    color: theme.palette.white.main,
  },
  container: {
    minHeight: theme.wrappers.fullWidth,
    backgroundColor: theme.palette.white.main,
    paddingTop: '8px',
  },
});
