import React, { Component } from 'react';

import { IncidencesModal } from '../components';
import { INCIDENCES_CONFIG, FEEDBACK } from '../constants';
import { today, uuidv4 } from '../../utils';
import tableValues from '../../core/json-table-values.json';
import FetchApi from '../../core/fetch-api';

const initialState = {
  types: [],
  isLoading: true,
  steps: [],
  currentStep: 0,
  formValues: {},
};

export class Incidences extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const data = tableValues;
    if (data && data.length > 0) {
      const types = data.find(
        (element) => element.type === 'incidence-tree'
      ).data;
      this.setState({
        types,
        isLoading: false,
          formValues: { ['type']: '01' },
          steps: INCIDENCES_CONFIG.find(
            (element) => element.type === '01'
          ).steps,
        
      });
    }
  }

  nextStep(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ currentStep: this.state.currentStep + 1 });
  }

  prevStep(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ currentStep: this.state.currentStep - 1 });
  }

  sendAttachments(incidence, attachments) {
    FetchApi.sendImages(
      'post',
      `/urproperty/${incidence.propertyId}/incidence/attachments`,
      attachments
    ).then((res) => {
      if (res && res.status === 200) {
        const { results } = res;
        const urls = results.map((x) => x.url);
        incidence.attachments = urls;
        this.props.setData(incidence, 'ADD_GENERAL_INCIDENCE', this.props.openSnackBar, () => this.props.openSnackBar(FEEDBACK.CREATION_PENDING));
      } else if (
        res &&
        res.results &&
        res.results.message === 'Failed to fetch'
      ) {
        this.props.openSnackBar({
          type: 'error',
          visible: true,
          title: 'Error al subir fotos',
          message:
            'Incidencia no creada, necesita conexión a internet para poder subir fotos',
        });
      }
    });
  }

  async onSubmit() {
    let incidence;
    const arrayPropertyId = this.props.propertyId.split(':');
    if (this.state.formValues.type === '01') {
      incidence = {
        _id: uuidv4(),
        document: 'incidences',
        createdAt: today(),
        propertyId:
          arrayPropertyId.length > 1 ? arrayPropertyId[1] : arrayPropertyId[0],
        type: this.state.formValues.keyType,
        typeText: this.state.types
          .find((element) => element.value === this.state.formValues.type)
          .children.find(
            (element) => element.value === this.state.formValues.keyType
          ).label,
        key: this.state.formValues.keyIncidence,
        keyText: this.state.types
          .find((element) => element.value === this.state.formValues.type)
          .children.find(
            (element) => element.value === this.state.formValues.keyType
          )
          .children.find(
            (element) => element.value === this.state.formValues.keyIncidence
          ).label,
        quantity: 1,
        name: this.state.formValues.name,
        email: this.state.formValues.email,
        phone: this.state.formValues.phone,
        description: this.state.formValues.description,
        canAccess: this.state.formValues.canAccess === 'true',
      };

      this.props.setData(incidence, 'ADD_KEY_INCIDENCE', this.props.openSnackBar);
    } else {
      incidence = {
        _id: uuidv4(),
        document: 'incidences',
        createdAt: today(),
        category: 'mails',
        propertyId:
          arrayPropertyId.length > 1 ? arrayPropertyId[1] : arrayPropertyId[0],
        type: this.state.formValues.type,
        typeText: this.state.types.find(
          (element) => element.value === this.state.formValues.type
        ).label,
        typology: this.state.formValues.typology,
        typologyText: this.state.types
          .find((element) => element.value === this.state.formValues.type)
          .children.find(
            (element) => element.value === this.state.formValues.typology
          ).label,
        name: this.state.formValues.name,
        email: this.state.formValues.email,
        phone: this.state.formValues.phone,
        description: this.state.formValues.description,
        canAccess: this.state.formValues.canAccess === 'true',
      };

      let attachments = [];
      if (
        this.state.formValues._attachments &&
        Object.keys(this.state.formValues._attachments).length > 0
      ) {
        const keys = Object.keys(this.state.formValues._attachments);
        keys.forEach((x) =>
          attachments.push({
            ...this.state.formValues._attachments[x],
            name: x,
          })
        );

        this.sendAttachments(incidence, attachments);
      } else {
        incidence.attachments = [];
        this.props.setData(incidence, 'ADD_GENERAL_INCIDENCE', this.props.openSnackBar, () => {
          this.props.openSnackBar(FEEDBACK.CREATION_PENDING);
        });
      }
    }

    this.handleResetForm();
  }

  onChange = (key, value) => (event) => {
    if (value !== undefined) {
      this.setState({
        formValues: {
          ...this.state.formValues,
          [key]: { ...this.state.formValues[key], ...value },
        },
      });
    } else {
      if (key === 'type') {
        this.setState({
          formValues: { [key]: event.target.value },
          steps: INCIDENCES_CONFIG.find(
            (element) => element.type === event.target.value
          ).steps,
        });
      } else {
        this.setState({
          formValues: {
            ...this.state.formValues,
            [key]: event.target.value,
          },
        });
      }
    }
  };

  handleResetForm = () => {
    this.props.closeModal();
    this.setState({ ...initialState, types: this.state.types });
  };

  render() {
    return (
      <IncidencesModal
        {...this.props}
        state={this.state}
        steps={this.state.steps}
        currentStep={this.state.currentStep}
        next={this.nextStep.bind(this)}
        prev={this.prevStep.bind(this)}
        submit={this.onSubmit.bind(this)}
        reset={this.handleResetForm.bind(this)}
        onChange={this.onChange.bind(this)}
      />
    );
  }
}
