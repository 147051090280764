import React from 'react';
import { useSelector } from '../../../../../core/hooks/use-selector';
import { renderOptions } from '../../../../../shared/checklists-wizard/constants/summary/utils';
import { TRANSLATIONS_ITEMS as enviromentTranslations } from '../../../../../shared/checklists-wizard/constants/summary/environmentSummaryItem/constants';

const EnvironmentSummaryItem = () => {
    const { environment } = useSelector((s) => s.promotionData);

    return <>{renderOptions(environment, enviromentTranslations)}</>;
};

export default EnvironmentSummaryItem;