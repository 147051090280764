/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import FetchApi from '../../../core/fetch-api';

import { WizardProvider } from '../contexts';

export const WizardComponent = ({ stepViews, total }) => {
  const [step, setStep] = useState(0);
  const StepView = stepViews[step];

  useEffect(() => {
    FetchApi.refreshToken();
  }, [step]);

  return useMemo(
    () =>
      StepView ? (
        <WizardProvider
          value={{ step, setStep, total: total || stepViews.length }}
        >
          <StepView />
        </WizardProvider>
      ) : (
        ''
      ),
    [StepView, step, stepViews.length]
  );
};
