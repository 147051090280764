import React, { useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';

import { withStyles } from '@material-ui/core';

import './nav-bar.component.scss';

import { navBarStyles } from './nav-bar.styles';
import { NavBarDrawer } from './nav-bar-drawer.component';
import { useConnectionStatus } from '../../../core/connection-status';

export const NavBar = withStyles(navBarStyles)(({ classes }) => {
  const [open, toggleOpen] = useState(false);
  const { online } = useConnectionStatus();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            id="navbar-button"
            edge="start"
            className={classes.menuButton}
            onClick={() => toggleOpen(!open)}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" className={classes.title}></Typography>
          <IconButton edge="end" color="inherit" aria-label="connectivity">
            {online ? <CloudQueueIcon /> : <CloudOffIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>

      <NavBarDrawer open={open} toggleOpen={toggleOpen} />
    </div>
  );
});
