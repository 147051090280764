import { useLocation } from 'react-router-dom';

export const useQuery = () => {
  let search = useLocation().search.substring(1);

  return !search
    ? {}
    : JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
};
