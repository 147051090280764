import { Icon, makeStyles } from '@material-ui/core';
import React from 'react';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    height: '80px',
    width: '80px',
    top: '-62px',
    left: '0px',
    backgroundColor: '#000000',
    opacity: 0.4,
  },
  iconDelete: {
    color: '#FFFFFF',
    fontSize: '34px',
    position: 'absolute',
    top: '20px',
    left: '23px',
  },
  handlerDelete: {
    position: 'relative',
    padding: '4px 4px 4px 0',
    cursor: 'pointer',
  },
  img : {
    height: '80px',
    width: '80px',
    position: 'relative',
    objectFit: 'cover'
  }

}));

/** Component: Image after using input file on the pictures screen. They can be seen and erased */
const Picture = (props) => {
  const { src, canDelete, name, deleteHandler } = props;
  const classes = useStyles();
  const deleteIcon = canDelete && (
    <div
    className={classes.root}
    >
      <Icon
        data-testid="icon-delete-img"
        className={classes.iconDelete}
      >
        delete_outline
      </Icon>
    </div>
  );

  return (
    <span
      className={classes.handlerDelete}
      data-testid="handler-delete-img"
      onClick={canDelete ? () => deleteHandler(name) : null}
    >
      <img
        className={classes.img}
        src={src}
        alt="error"
      />
      {deleteIcon}
    </span>
  );
};

Picture.defaultProps = {
  src: '',
  name: '',
  canDelete: false,
  deleteHandler: () => {},
};

export default Picture;
