const styledBy = (property, mapping) => (props) => mapping[props[property]];

export const boxContentStyles = (theme) => ({
  root: {
    marginTop: 10,
    marginBottom: styledBy('onlyTitle', {
      true: 5,
      false: 10,
    }),
    borderBottomLeftRadius: styledBy('onlyTitle', {
      true: 0,
    }),
    borderBottomRightRadius: styledBy('onlyTitle', {
      true: 0,
    }),
    backgroundColor: theme.palette.white.main,
  },
  message: {
    padding: 5,
    display: theme.wrappers.display.block,
    textAlign: theme.wrappers.alignCenter,
  },
  header: {
    minHeight: theme.spacing(6),
    padding: theme.wrappers.boxContentPadding,
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    fontSize: theme.font.size['18'],
  },
  subheader: {
    fontSize: theme.font.size['20'],
  },
  iconButton: {
    marginTop: theme.spacing(1.5),
    color: theme.palette.white.main,
  },
});
