export const expansionPanelStyles = (theme) => ({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    borderTop: 0,
    boxShadow: 'none',
    width: 'calc(100% + 32px)',
    marginLeft: '-16px !important',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
});
