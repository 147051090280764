import React from 'react';

import { SelectInput, TextAreaInput, CheckboxInput } from '../../elements';
import { IncidencesFormButtons } from './incidences-form-buttons.component';

const TypologyFields = (props) => {
  if (!props.state.formValues.type) return <></>;
  let typeChildrenOptions = [];
  if (props.state.formValues['type']) {
    typeChildrenOptions = props.state.types.find(
      (element) => element.value === props.state.formValues['type']
    ).children;
  }
  switch (props.state.formValues.type) {
    case '01': {
      let subtypeChildrenOptions = [];
      if (props.state.formValues['keyType'] && typeChildrenOptions.length > 0) {
        subtypeChildrenOptions = typeChildrenOptions.find(
          (element) => element.value === props.state.formValues['keyType']
        ).children;
      }
      return (
        <>
          <SelectInput
            label="Tipo de llave"
            value={props.state.formValues['keyType'] || ''}
            options={typeChildrenOptions}
            optionValue="value"
            optionLabel="label"
            onChange={props.onChange('keyType')}
            variant="filled"
            required={true}
            disabled={!props.state.formValues['type']}
            margin="normal"
          />
          <SelectInput
            label="Incidencia"
            value={props.state.formValues['keyIncidence'] || ''}
            options={subtypeChildrenOptions}
            optionValue="value"
            optionLabel="label"
            onChange={props.onChange('keyIncidence')}
            variant="filled"
            required={true}
            disabled={!props.state.formValues['keyType']}
            margin="normal"
          />
          <CheckboxInput
            label="¿Has podido acceder al inmueble?"
            value={props.state.formValues['canAccess'] || ''}
            onChange={props.onChange('canAccess')}
            variant="filled"
            required={true}
            margin="normal"
            isBool
          />
        </>
      );
    }
    default: {
      return (
        <>
          <SelectInput
            label="Tipología"
            value={props.state.formValues['typology'] || ''}
            options={typeChildrenOptions}
            optionValue="value"
            optionLabel="label"
            onChange={props.onChange('typology')}
            variant="filled"
            required={true}
            disabled={!props.state.formValues['type']}
            margin="normal"
          />
        </>
      );
    }
  }
};

export const IncidencesDataForm = (props) => {
  return (
    <form
      onSubmit={props.next}
      style={{
        minHeight: '85%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <div style={{ flexGrow: 1, flexShrink: 0 }}>
        <SelectInput
          style={{display:'none'}}
          label="Tipo"
          value={props.state.formValues['type'] || ''}
          options={props.state.types}
          optionValue="value"
          optionLabel="label"
          onChange={props.onChange('type')}
          variant="filled"
          required={true}
          disabled={true}
          margin="normal"
        />
        <TypologyFields {...props} />
        <TextAreaInput
          name="description"
          label="Descripción"
          value={props.state.formValues['description']}
          variant="filled"
          onChange={props.onChange('description')}
          margin="normal"
        />
      </div>
      <div style={{ flexShrink: 0 }}>
        <IncidencesFormButtons {...props} />
      </div>
    </form>
  );
};
