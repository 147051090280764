import React from 'react';
import { useSelector } from '../../../../../core/hooks/use-selector';
import { renderOptions } from '../../../../../shared/checklists-wizard/constants/summary/utils';
import { TRANSLATIONS_ITEMS as securityTranslations } from '../../../../../shared/checklists-wizard/constants/summary/securityMaintanceSummaryItem/constants';
import { useStyles } from '../style';

export const SecurityMaintenanceSummaryItem = () => {
  const state = useSelector((s) => s.screenTwoData);
  const classes = useStyles();

  return (
    <div className={classes.summaryContainer}>
      {renderOptions(state, securityTranslations)}
    </div>
  );
};
