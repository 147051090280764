import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import { PouchDBContext } from '../../../core/pouch-db/contexts';
import { LeadsProvider } from '../contexts';
import { updateLeadsEffect, leadsEffect } from '../effects';
import { DEFAULT_FILTERS, CUSTOM_FILTERS } from '../constants';
import { LeadsListComponent } from './leads-list.component';
import { LeadsRouterComponent } from './leads-router.component';
import FetchApi from '../../../core/fetch-api';

export const LeadsComponent = () => {
  const [model, setModel] = useState();
  const [filteredModel, setFilteredModel] = useState();
  const [favorites, setFavorites] = useState({});
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [customFilters, setCustomFilters] = useState(CUSTOM_FILTERS);
  const { getData, isSyncing } = useContext(PouchDBContext);

  const onChangeFilters = (values) => {
    Object.keys(values).forEach((key) => {
      switch (key) {
        case 'name':
        case 'phone1':
        case 'assignedTo':
        case 'typeActivity':
          setCustomFilters({ ...customFilters, ...values });
          break;
        default:
          setFilters({ ...filters, ...values });
          break;
      }
    });
  };

  const cleanFilters = () => {
    setCustomFilters({ ...CUSTOM_FILTERS });
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(updateLeadsEffect(FetchApi), []);
  useEffect(leadsEffect(getData, isSyncing, setModel, setFavorites), [
    isSyncing,
  ]);

  useEffect(() => {
    if (model) {
      setFilteredModel(
        model
          .filter((elem) => !filters.isFavorite || favorites[elem._id])
          .filterByKey(filters.filterKey, filters.filterValue)
          .filterBySimilarValue(customFilters)
          .sortByKey(filters.sort, filters.direction)
      );
    }
  }, [filters, favorites, model, customFilters]);

  return (
    <LeadsProvider
      value={{
        model: filteredModel,
        getModel: leadsEffect(getData, isSyncing, setModel, setFavorites),
      }}
    >
      <Switch>
        <Route
          exact
          path="/leads"
          render={() => (
            <LeadsListComponent
              leadsArray={model}
              favorites={favorites}
              filters={filters}
              onChangeFilters={onChangeFilters}
              cleanFilters={cleanFilters}
              customFilters={customFilters}
            />
          )}
        />

        <Route
          path="/leads/:type/:leadId"
          render={() => <LeadsRouterComponent model={model} />}
        />
      </Switch>
    </LeadsProvider>
  )
};
