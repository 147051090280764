import PropTypes from 'prop-types';
import {
  createContext as createContextOrig,
  createElement,
  useContext as useContextOrig,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import {
  unstable_NormalPriority as NormalPriority,
  unstable_runWithPriority as runWithPriority,
} from 'scheduler';

// eslint-disable-next-line symbol-description
const CONTEXT_VALUE = Symbol();
// eslint-disable-next-line symbol-description
const ORIGINAL_PROVIDER = Symbol();
const isSSR =
  typeof window === 'undefined' ||
  // eslint-disable-next-line require-unicode-regexp
  /ServerSideRendering/.test(window.navigator && window.navigator.userAgent);

const useIsomorphicLayoutEffect = isSSR ? useEffect : useLayoutEffect;

const runWithNormalPriority = runWithPriority
  ? thunk => runWithPriority(NormalPriority, thunk)
  : thunk => thunk();

export const context = createContext(null);

function createProvider(ProviderOrig) {
  const ContextProvider = ({ value, children }) => {
    const valueRef = useRef(value);
    const versionRef = useRef(0);
    const [resolve, setResolve] = useState(null);
    if (resolve) {
      resolve(value);
      setResolve(null);
    }
    const contextValue = useRef();
    if (!contextValue.current) {
      const listeners = new Set();
      const update = (thunk, options) => {
        batchedUpdates(() => {
          versionRef.current += 1;
          const action = {
            n: versionRef.current,
          };
          if (options?.suspense) {
            action.n *= -1; // this is intentional to make it temporary version
            action.p = new Promise(r => {
              setResolve(() => v => {
                action.v = v;
                delete action.p;
                r(v);
              });
            });
          }
          listeners.forEach(listener => listener(action));
          thunk();
        });
      };
      contextValue.current = {
        [CONTEXT_VALUE]: {
          v: valueRef,
          n: versionRef,
          l: listeners,
          u: update,
        },
      };
    }
    useIsomorphicLayoutEffect(() => {
      valueRef.current = value;
      versionRef.current += 1;
      runWithNormalPriority(() => {
        contextValue.current[CONTEXT_VALUE].l.forEach(listener => {
          listener({ n: versionRef.current, v: value });
        });
      });
    }, [value]);
    return createElement(
      ProviderOrig,
      { value: contextValue.current },
      children
    );
  };
  ContextProvider.propTypes = {
    value: PropTypes.any,
    children: PropTypes.any,
  };
  return ContextProvider;
}

const identity = x => x;

export function createContext(defaultValue) {
  const context = createContextOrig({
    [CONTEXT_VALUE]: {
      v: { current: defaultValue },
      n: { current: -1 },
      l: new Set(),
      u: f => f(),
    },
  });
  context[ORIGINAL_PROVIDER] = context.Provider;
  context.Provider = createProvider(context.Provider);
  delete context.Consumer; // no support for Consumer
  return context;
}

export function useDispatch() {
  return useSelector();
}

export function useSelector(selector) {
  const contextValue = useContextOrig(context)[CONTEXT_VALUE];
  if (typeof process === 'object' && process.env.NODE_ENV !== 'production') {
    if (!contextValue) {
      throw new Error('useSelector requires special context');
    }
  }
  const isDispatcher = !selector;
  if (!selector) {
    selector = v => v[1];
  }
  const {
    v: { current: value },
    n: { current: version },
    l: listeners,
  } = contextValue;

  let selected = selector(value);
  if (!isDispatcher) {
    selected = selector(value[0]);
  }

  const [state, dispatch] = useReducer(
    (prev, action) => {
      if (!action) {
        return [value, selected];
      }
      if ('p' in action) {
        throw action.p;
      }
      if (action.n === version) {
        if (Object.is(prev[1], selected)) {
          return prev;
        }
        return [value, selected];
      }
      try {
        if ('v' in action) {
          if (Object.is(prev[0], action.v)) {
            return prev;
          }
          const nextSelected = selector(action.v);
          if (Object.is(prev[1], nextSelected)) {
            return prev;
          }
          return [action.v, nextSelected];
        }
      } catch (e) {
        // ignored
      }
      return [...prev];
    },
    [value, selected]
  );
  if (!Object.is(state[1], selected)) {
    dispatch();
  }
  useIsomorphicLayoutEffect(() => {
    listeners.add(dispatch);
    return () => {
      listeners.delete(dispatch);
    };
  }, [listeners]);
  return state[1];
}

export function useContext(context) {
  return useSelector(context, identity);
}

export function useContextUpdate(context) {
  const contextValue = useContextOrig(context)[CONTEXT_VALUE];
  if (typeof process === 'object' && process.env.NODE_ENV !== 'production') {
    if (!contextValue) {
      throw new Error('useContextUpdate requires special context');
    }
  }
  const { u: update } = contextValue;
  return update;
}

export const BridgeProvider = ({ context, value, children }) => {
  const { [ORIGINAL_PROVIDER]: ProviderOrig } = context;
  if (typeof process === 'object' && process.env.NODE_ENV !== 'production') {
    if (!ProviderOrig) {
      throw new Error('BridgeProvider requires special context');
    }
  }
  return createElement(ProviderOrig, { value }, children);
};

BridgeProvider.propTypes = {
  context: PropTypes.any,
  value: PropTypes.any,
  children: PropTypes.any,
};

export const useBridgeValue = context => {
  const bridgeValue = useContextOrig(context);
  if (typeof process === 'object' && process.env.NODE_ENV !== 'production') {
    if (!bridgeValue[CONTEXT_VALUE]) {
      throw new Error('useBridgeValue requires special context');
    }
  }
  return bridgeValue;
};

export default context;
