import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from '../../../../core/hooks/use-selector';
import { setPromotionData } from '../../../../store/action';
import WizardView from '../../../../shared/checklists-wizard/step-base.component';
import { withStyles } from '@material-ui/styles';
import { stepStyles } from '../../../checklists-steps/stepStyles';
import { usePicturesData } from '../../../../shared/checklists-wizard/hooks/usePicturesData';

/** View screen 6 : Fotografías del inmueble 2/2 */
const Step6PicturesData = withStyles(stepStyles)(({ classes, ...props }) => {
  const { onChangeStepHandler, haveBack = true } = props;
  const state = useSelector((s) => s.promotionData);
  const history = useLocation();
  const dispatch = useDispatch();
  const { fields, handleSubmit, id: promotionId, getValues } = usePicturesData(
    state.picturesData,
    history,
    classes
  );

  const onSubmitHandler = (data) => {
    setPromotionData(dispatch, { ...state, picturesData: data });
    onChangeStepHandler(6);
  };

  /** Back step */
  const onBackWizard = () => {
    const data = getValues();
    setPromotionData(dispatch, { ...state, picturesData: data });

    onChangeStepHandler(4);
  };

  return (
    <WizardView
      title="Fotografías del inmueble (2/2)"
      fields={fields}
      onNext={handleSubmit(onSubmitHandler)}
      onBack={onBackWizard}
      haveBack={haveBack}
      isPromotion={{ id: state.item.id, address: state.item.address }}
      progress={6}
    />
  );
});

export default Step6PicturesData;
