import React from 'react';

import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

export const DialogOffline = ({ close, offlineMsg }) => (
  <>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {offlineMsg}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={close}>Cerrar</Button>
    </DialogActions>
  </>
);
