import React from 'react';
import { Icon } from '@material-ui/core';

export const FormItem = (props) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      {props.icon && (
        <div
          style={{
            display: 'inline-block',
            width: '40px',
            color: '#90b8b3',
          }}
        >
          <Icon>{props.icon}</Icon>
        </div>
      )}
      <div
        style={{
          display: 'inline-block',
          width: props.icon ? 'calc(100% - 40px)' : '100%',
        }}
      >
        {props.children}
      </div>
    </div>
  );
};
