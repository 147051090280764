/* eslint-disable array-callback-return */
import Pouch from 'pouchdb';

import { createLogger, INFO, stdSerializers } from 'browser-bunyan';
import { ConsoleFormattedStream } from '@browser-bunyan/console-formatted-stream';

import { PouchStream, LogDnaStream } from '../streams';

export const LoggerFactory = {
  history: {},
  logger: null,
  pouch: new Pouch(`logger_v${process.env.REACT_APP_VERSION}`, {
    auto_compaction: true,
  }),
  streamGetters: {
    'log-dna': () => new LogDnaStream(LoggerFactory.history),
    'pouch-db': (data) => {
      const pouchStream = LoggerFactory.logger.streams.shift();
      const expirationDays = data.expirationDays || 14;

      LoggerFactory.pouch.allDocs({ include_docs: true }).then(({ rows }) => {
        rows.map(({ doc }) => {
          if (doc.time && doc.time.daysUntilNow() >= expirationDays) {
            LoggerFactory.pouch.remove(doc);
          }
        });
      });

      return pouchStream.stream;
    },
  },
  getDefaultStreams: () => [
    {
      level: INFO,
      stream: new PouchStream(LoggerFactory.pouch),
    },
    ...(process.env.REACT_APP_ENVIRONMENT === 'development'
      ? [
          {
            level: INFO,
            stream: new ConsoleFormattedStream({ logByLevel: true }),
          },
        ]
      : []),
  ],
  sendLogs: async () => {
    let reducer = window.localStorage.getItem('persist:reducer_v2');
    reducer = await JSON.parse(reducer);
    if (reducer && reducer.auth && reducer.auth.accessToken) {
      const { userName, userKey } = reducer.auth;
      const dbName = process.env.REACT_APP_LOGGERDB;
      const remote = new Pouch(
        `https://${userName}:${userKey}@${process.env.REACT_APP_CLOUDANT_HOST}/${dbName}`
      );

      LoggerFactory.pouch.replicate.to(remote);
    } else {
      LoggerFactory.logger.error =
        'Error al obtener los parametros necesarios para la conexion con base de datos. Consultar "presist:reducer" en Local Storage';
    }
  },
  setLoggerStreams: (streams) => {
    streams.map(({ type, data }) => {
      if (LoggerFactory.streamGetters[type]) {
        LoggerFactory.logger.addStream({
          level: data.level,
          stream: LoggerFactory.streamGetters[type](data),
        });
      }
    });
  },
  setLoggerConfig: (history) => {
    LoggerFactory.history = history;
  },
  getLogger: () => {
    LoggerFactory.logger =
      LoggerFactory.logger ||
      createLogger({
        src: true,
        name: 'logger',
        serializers: stdSerializers,
        streams: LoggerFactory.getDefaultStreams(),
      });

    return LoggerFactory.logger;
  },
};
