export const snackBarStyles = (theme) => ({
  alert: {
    width: theme.wrappers.fullWidth,
  },
  snackBar: {
    top: theme.spacing(0),
    left: theme.spacing(0),
    width: theme.wrappers.fullWidth,
  },
});
