import {
  SendPicsStepOneFormComponent,
  SendPicsStepTwoFormComponent,
} from '../components';

import { today } from '../../utils';

export const EXCLUSIVE_VALUES = [
  { label: 'Sí', value: 1 },
  { label: 'No', value: 2 },
];

export const LIMIT = 25;

export const STRESSED_VALUES = [
  { label: 'Normal', value: 1 },
  { label: 'Estresado', value: 2 },
];

export const EXTENDED_VALUES = [
  { label: 'Sí', value: 1 },
  { label: 'No', value: 2 },
  { label: 'No aplica', value: 3 },
];

export const OCCUPATION_RISK_VALUES = [
  { label: 'Alto', value: 1 },
  { label: 'Medio', value: 2 },
  { label: 'Bajo', value: 3 },
];

export const STEP_VIEWS = [
  () => SendPicsStepOneFormComponent(),
  () => SendPicsStepTwoFormComponent(),
];

export const STRESSED_ENUM_LABEL = {
  2: 'Estresado',
  1: 'Normal',
};

const EXTENDED_ENUM_LABEL = {
  1: 'Sí',
  2: 'No',
  3: 'No aplica',
};

const EXCLUSIVE_ENUM_LABEL = {
  1: 'Sí',
  2: 'No',
};

const OCCUPATION_ENUM_LABEL = {
  1: 'Alto',
  2: 'Medio',
  3: 'Bajo',
};

export const REVIEW_ACCORDION = [
  {
    key: 'PROPERTY',
    isOpen: true,
    mapValues: (key, value) =>
      ({
        createdAt: today(),
        price: (+value).currencyFormat('€'),
        visited: EXCLUSIVE_ENUM_LABEL[value],
        stressedPrice: STRESSED_ENUM_LABEL[value],
        correctPics: EXTENDED_ENUM_LABEL[value],
        correctDescription: EXCLUSIVE_ENUM_LABEL[value],
        recommendedPrice: value ? (+value).currencyFormat('€') : value,
      }[key]),
  },
  {
    key: 'ACTIVITIES',
    mapValues: (key, value) =>
      ({
        occupied: EXTENDED_ENUM_LABEL[value],
        occupationRisk: OCCUPATION_ENUM_LABEL[value],
      }[key]),
    isOpen: false,
  },
  {
    key: 'MAINTENANCE',
    isOpen: false,
    mapValues: (key, value) =>
      ({
        perishableClean: EXTENDED_ENUM_LABEL[value],
        equipmentClean: EXTENDED_ENUM_LABEL[value],
        pests: EXTENDED_ENUM_LABEL[value],
      }[key]),
  },
  {
    key: 'SECURITY',
    isOpen: false,
    mapValues: (key, value) =>
      ({
        antiOccupation: EXTENDED_ENUM_LABEL[value],
        requireActions: OCCUPATION_ENUM_LABEL[value],
      }[key]),
  },
  {
    key: 'ADAPTATIONS',
    isOpen: false,
    mapValues: (key, value) =>
      ({
        vandalized: EXTENDED_ENUM_LABEL[value],
      }[key]),
  },
];

export { FORM_CONFIG } from './check-lists-form.constants';
