import React, { useCallback, useContext, useState } from 'react';
import * as serviceWorker from '../../../serviceWorker'

import Typography from '@material-ui/core/Typography';

import { useHistory } from 'react-router-dom';
import {
  Divider,
  List,
  MenuItem,
  ListItemIcon,
  SwipeableDrawer,
} from '@material-ui/core';

import { UserContext } from '../../../core/user/contexts';
import { useConnectionStatus } from '../../../core/connection-status';

import { DialogOffline } from './dialogs';

import { MENU_LIST } from '../constants';
import { Menu } from './nav-bar-menu.component';
import { DialogContact } from '../../../elements/dialog';
import { ModalContext } from '../../../ui/modal/contexts';
import { ListTypeIconComponent } from '../../../elements/lists';
import { openSendLogsModalAction } from '../actions/open-sendlogs-modal.action';
import FetchApi from '../../../core/fetch-api';

export const NavBarDrawer = ({ open, toggleOpen }) => {
  const history = useHistory();
  const [selected, setSelected] = useState();
  const { online } = useConnectionStatus();
  const {
    state: { enrollment },
  } = useContext(UserContext);
  const {
    actions: { open: openModal, close: closeModal },
  } = useContext(ModalContext);
  const onCLickVersionNumber = useCallback(openSendLogsModalAction(openModal), [
    openModal,
  ]);

  return (
    <SwipeableDrawer
      open={open}
      onClose={() => toggleOpen(false)}
      onOpen={() => toggleOpen(true)}
    >
      <div
        className="drawer"
        role="presentation"
        style={{
          display: 'flex',
          height: 'calc(var(--vh, 1vh) * 100)',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        onClick={() => toggleOpen(false)}
      >
        <div className="drawer-content">
          <div className="drawer-header">
            <div className="drawer-header-image"></div>
            <img
              className="drawe-header-logo"
              src="/images/logo-white.png"
              style={{
                margin: '0 auto',
                width: '200px',
                position: 'absolute',
                top: '3rem',
                left: '2rem',
              }}
              alt=""
            />
            <Typography>API {enrollment.toUpperCase()}</Typography>
          </div>
          <Menu
            selectedMenuIndex={selected}
            menu={MENU_LIST}
            handleNavigateTo={(pathname, index, search, offlineMsg) => {
              if (offlineMsg && !online) {
                openModal({
                  Component: () => (
                    <DialogOffline close={closeModal} offlineMsg={offlineMsg} />
                  ),
                });
              } else {
                setSelected(index);
                history.push({ pathname, search });
              }
            }}
          />
          <Divider />
          <List component="div">
            <MenuItem
              button
              onClick={() => {
                FetchApi.refreshToken();
                openModal({
                  Component: DialogContact,
                  view: 'Telefono de contacto',
                });
              }}
            >
              <ListItemIcon>
                <ListTypeIconComponent type="phone" />
              </ListItemIcon>
              <Typography>Teléfono Contact Center</Typography>
            </MenuItem>
            <MenuItem
              button
              onClick={() => {
                localStorage.clear();
                // serviceWorker.unregister()
                if ('caches' in window) {
                  caches.keys().then((names) => {
                    // Delete all the cache files        
                    names.forEach(name => {
                      caches.delete(name);
                    })
                  })
                }
                window.location.hash = '';
                setTimeout(() => window.location.reload());
              }}
            >
              <ListItemIcon>
                <ListTypeIconComponent
                  type="power"
                  style={{ fill: '#F07F0A' }}
                />
              </ListItemIcon>
              <Typography>Salir</Typography>
            </MenuItem>
          </List>
        </div>
        <div
          className="drawer-footer MuiTypography-body1"
          style={{ alignSelf: 'flex-end', padding: '10px', color: '#00000099' }}
          onClick={onCLickVersionNumber}
        >
          <span>v{process.env.REACT_APP_VERSION}</span>
        </div>
      </div>
    </SwipeableDrawer>
  );
};
