import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { NotificationType } from '../enums';
import { SwipeableListComponent } from '../../../elements/lists';
import {
  NOTIFICATIONS_LIST_FILTER,
  NOTIFICATIONS_LIST_ITEMS,
  DEFAULT_FILTERS,
} from '../constants';

export const NotificationsListComponent = ({ data, type }) => {
  const history = useHistory();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [model, setModel] = useState(data);

  const onChangeFilters = (values) => {
    setFilters({ ...filters, ...values });
  };

  const handleSelectItem = (item) => {
    if (
      type === NotificationType.Service ||
      type === NotificationType.InactivtyService
    ) {
      const leadType = item.promoId ? 'promo' : 'management';

      history.push(`leads/${leadType}/${item.managementId}`);
    } else if (type === NotificationType.Property) {
      if (item.propertyId.includes('P')) {
        history.push(`properties/${item.propertyId}/promo`);
      } else {
        history.push(`properties/${item.propertyId}`);
      }
    }
  };

  useEffect(() => {
    setModel(
      data.filter(() => data).sortByKey(filters.sort, filters.direction)
    );
  }, [filters, data]);

  return model ? (
    <SwipeableListComponent
      model={model || data}
      filterModel={filters}
      style={{ paddingTop: 0 }}
      onClick={handleSelectItem}
      onChangeFilter={onChangeFilters}
      listConfig={NOTIFICATIONS_LIST_ITEMS[type]}
      filterConfig={NOTIFICATIONS_LIST_FILTER}
    />
  ) : (
    ''
  );
};
