import React from 'react';

import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  withStyles,
} from '@material-ui/core';

import { dialogContactStyles } from './dialog-contact.styles';

export const DialogCommercial = withStyles(dialogContactStyles)(
  ({ classes, close, text }) => (
    <>
      <DialogTitle id="alert-dialog-title">Datos Comercial</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Comercial:
        </DialogContentText>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.bold}
        >
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} className={classes.button}>
          Ok
        </Button>
      </DialogActions>
    </>
  )
);
