import React from 'react';
import {
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
} from '@material-ui/core';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/es';
import {
  ButtonsActionBar,
  CleanFiltersBtn,
} from './buttonsActionBar.component';
import { useStyles } from './useStyles.checklistsComponent';

const locale = 'es-ES';
/** Filter UR/PROMO, dates, and  order*/
export const ListFilterComponent = ({
  customFilters,
  onChangeFilter,
  cleanFilters,
}) => {
  const classes = useStyles();

  const handleTextChange = (key, value) => {
    onChangeFilter({ [key]: value });
  };

  return (
    <div className={classes.containerTitle}>
      {!!customFilters ? <h3 className={classes.title}>Filtrar</h3> : ''}

      <CleanFiltersBtn cleanFilters={cleanFilters} />

      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          value={customFilters.urpromo ? customFilters.urpromo : ''}
          onChange={(event) =>
            handleTextChange('urpromo', event.nativeEvent.target.value)
          }
          label="UR/Promo"
          data-testid="UR/Promo"
          variant="filled"
        />
        <LocalizationProvider locale={locale} dateAdapter={AdapterMoment}>
          <DatePicker
            inputFormat="DD/MM/yyyy"
            placeholderText="Desde"
            label="Desde"
            value={customFilters.fromDate}
            onChange={(newValue) => handleTextChange('fromDate', newValue)}
            renderInput={(params) => (
              <TextField
                variant="filled"
                {...params}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': 'date-picker-fromDate',
                }}
              />
            )}
            maxDate={customFilters.toDate ? customFilters.toDate : undefined}
            components={{
              ActionBar: ButtonsActionBar,
            }}
            inputProps={{ 'aria-label': 'date1' }}
            PaperProps={{
              sx: {
                '& .MuiPickersDay-root': {
                  '&.Mui-selected': {
                    transition: 'none',
                    backgroundColor: '#005442',
                  },
                },
              },
            }}
          />

          <DatePicker
            inputFormat="DD/MM/yyyy"
            label="Hasta"
            value={customFilters.toDate}
            onChange={(newValue) => handleTextChange('toDate', newValue)}
            renderInput={(params) => (
              <TextField
                variant="filled"
                {...params}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': 'date-picker-endDate',
                }}
              />
            )}
            minDate={
              customFilters.fromDate ? customFilters.fromDate : undefined
            }
            components={{
              ActionBar: ButtonsActionBar,
            }}
            PaperProps={{
              sx: {
                '& .MuiPickersDay-root': {
                  '&.Mui-selected': {
                    backgroundColor: '#005442',
                  },
                },
              },
            }}
          />
        </LocalizationProvider>
        <div>
          <h3 className={classes.formControlTitle}>Ordenar</h3>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel htmlFor="filled-sortValue-native-simple">
              Ordenar por
            </InputLabel>
            <Select native value={'checklistAssignedAt'} disabled>
              <option value={'checklistAssignedAt'}>Fecha asignación</option>
            </Select>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="order"
                name="order"
                value={customFilters.orderDir}
                defaultValue="asc"
                onChange={(e) => handleTextChange('orderDir', e.target.value)}
                className={classes.radioGroup}
              >
                <FormControlLabel
                  value="asc"
                  control={<Radio color="primary" />}
                  label="Ascendente"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="desc"
                  control={<Radio color="primary" />}
                  label="Descendente"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </FormControl>
        </div>
      </form>
    </div>
  );
};
