import React from 'react';
import OptionalWIP from './OptionalWIP';
import {
  OPTIONAL_ELEMENTS_A,
  OPTIONAL_ELEMENTS_B,
  OPTIONAL_ELEMENTS_C,
  OPTIONAL_ELEMENTS_RESIDENCIAL,
  OPTIONAL_ELEMENTS_SUELO,
  OPTIONAL_ELEMENTS_TERCIARIO,
  OPTIONAL_ELEMENTS_WIP,
} from '../../../../shared/checklists-wizard/constants/property/form-elements';
import OptionalResidencial from './OptionalResidencial';
import OptionalSuelo from './OptionalSuelo';
import OptionalTerciario from './OptionalTerciario';
import OptionalA from './OptionalA';
import OptionalB from './OptionalB';
import OptionalC from './OptionalC';

export const getOptionalElementsForm = (currentValues, functions, errors) => {
  if (
    typeof currentValues.visited !== 'undefined' &&
    currentValues.visited === "1" &&
    typeof currentValues.newPublish !== 'undefined' &&
    currentValues.newPublish === "1"
  ) {
    return <OptionalA inputs={OPTIONAL_ELEMENTS_A} functions={functions} currentValues={currentValues} unregister={functions.unregister} errors={errors} />;
  } else if (
    typeof currentValues.visited !== 'undefined' &&
    currentValues.visited === "1" &&
    typeof currentValues.newPublish !== 'undefined' &&
    currentValues.newPublish === "2"
  ) {
    return <OptionalB inputs={OPTIONAL_ELEMENTS_B} functions={functions} currentValues={currentValues} unregister={functions.unregister} errors={errors} />;
  } else if (
    typeof currentValues.visited !== 'undefined' &&
    currentValues.visited === "2"
  ) {
    return <OptionalC inputs={OPTIONAL_ELEMENTS_C} functions={functions} currentValues={currentValues} unregister={functions.unregister} errors={errors} />;
  } else {
    return [];
  }
};


export const getOptionalElementsByPropertyType = (currentValues, functions, errors) => {
  if (currentValues.propertyTypeCL === '1') return <OptionalResidencial inputs={OPTIONAL_ELEMENTS_RESIDENCIAL} functions={functions} currentValues={currentValues} unregister={functions.unregister} errors={errors} />;
  if (currentValues.propertyTypeCL === '2') return <OptionalSuelo inputs={OPTIONAL_ELEMENTS_SUELO} functions={functions} currentValues={currentValues} unregister={functions.unregister} errors={errors} />;
  if (currentValues.propertyTypeCL === '3') return <OptionalTerciario inputs={OPTIONAL_ELEMENTS_TERCIARIO} functions={functions} currentValues={currentValues} unregister={functions.unregister} errors={errors} />;
  if (currentValues.propertyTypeCL === '4') return <OptionalWIP inputs={OPTIONAL_ELEMENTS_WIP} functions={functions} currentValues={currentValues} unregister={functions.unregister} errors={errors} />;
  if (!currentValues.propertyTypeCL) return []
};
