import { uuidv4 } from '../../../utils';

export class PouchDBStream {
  constructor(pouchDB) {
    this.pouch = pouchDB;
  }

  write(args) {
    this.pouch.post({ ...args, _id: uuidv4(), document: 'logger' });
  }
}
