export const LOCATION_FIELDS_CONFIG = {
  provinceId: {
    name: 'provinceId',
    label: 'Provincia',
    variant: 'filled',
  },
  localityId: {
    name: 'localityId',
    label: 'Población',
    variant: 'filled',
  },
  zipCodeId: {
    name: 'zipCodeId',
    label: 'Código Postal',
    variant: 'filled',
    textfieldinputprops: { maxLength: 5 },
  },
};

export const LOCATION_FORM = {
  province: [],
  population: [],
  zipCode: [],
};
