import ErrorIcon from '@material-ui/icons/Error';

import { uuidv4 } from '../../utils';
import { ACTION_BAR } from '../constants';
import { DialogMessage } from '../../elements/dialog';
import { PropertiesCommercialTypeDialog } from '../components/dialog';

let managementId;

const openModalError = (openModal) =>
  openModal({
    Component: DialogMessage,
    data: {
      Icon: ErrorIcon,
      title: 'Error en la creación',
      message: 'Ha habido un problema al enviar el servicio',
    },
  });

const updateManagement = async (
  dto,
  toggleBackdrop,
  openModal,
  setData,
  history,
  testJest,
  uid
) => {
  toggleBackdrop(true);
  managementId = uuidv4();
  const newManagement = {
    ...dto,
    _id: managementId,
    managementId,
    document: 'create_management',
  };

  const result = setData(newManagement, 'CREATE_MANAGEMENT', null, (res) => {
    if (res && res.ok) {
      if (testJest) testJest(res);
      history.push('/leads');
    } else {
      openModalError(openModal);
    }
  }, () => { openModalError(openModal) }, null, null, uid);
  toggleBackdrop(false);
  return result;
};

export const propertiesActionBarEffect =
  (
    clientId,
    listItem,
    toggleBackdrop,
    openModal,
    setProperties,
    setData,
    history,
    testJest,
    uid
  ) =>
    () => {
      const generateService = (commercialType, testJest, uid) => {
        const arrayPropertyId = listItem.selected._id.split(':');
        const propertyId =
          arrayPropertyId.length > 1 ? arrayPropertyId[1] : arrayPropertyId[0];
        return updateManagement(
          { propertyId, commercialType, clientId },
          toggleBackdrop,
          openModal,
          setData,
          history,
          testJest,
          uid
        );
      };

      setProperties({
        payload: {
          clientId,
          listItem: { ...listItem, hasSelectableListItem: !!clientId },
          actionBar: {
            button: {
              ...ACTION_BAR.button,
              isDisabled: !(clientId && listItem.selected._id),
              onClick: () => {
                if (
                  listItem.selected.commercialType === '02' ||
                  !listItem.selected.commercialType
                ) {
                  openModal({
                    Component: PropertiesCommercialTypeDialog,
                    data: { onModalClose: generateService },
                  });
                } else {
                  generateService(listItem.selected.commercialType, null, uid);
                }
              },
            },
          },
        },
      });

      if (testJest)
        return generateService(listItem.selected.commercialType, testJest, uid);
    };
