import React from 'react';

import EmojiFoodBeverageOutlinedIcon from '@material-ui/icons/EmojiFoodBeverageOutlined';

import { withStyles } from '@material-ui/core';

import { noDataStyle } from './no-data.style';

export const NoData = withStyles(noDataStyle)(({ classes }) => (
  <div className={classes.root}>
    <EmojiFoodBeverageOutlinedIcon className={classes.icon} />
    <br />
    <strong className={classes.text}>No hay datos disponibles</strong>
  </div>
));
