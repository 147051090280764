import React from 'react';

import {
  DialogContent,
  DialogActions,
  Button,
  withStyles,
  DialogTitle,
  Box,
} from '@material-ui/core';

import { dialogContactStyles } from './dialog-contact.styles';

export const DialogMessage = withStyles(dialogContactStyles)(
  ({
    classes,
    close,
    message,
    title,
    onClose,
    btnConfirmLabel,
    Icon,
    btnCloseLabel = 'No',
  }) => (
    <>
      <DialogTitle data-testid="alert-dialog-title" id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent id="alert-dialog-description">
        {Icon && (
          <Box className={classes.iconBox}>
            <Icon className={classes.icon} />
          </Box>
        )}

        {message}
      </DialogContent>
      <DialogActions>
        {onClose && (
          <Button
            onClick={(e) => {
              onClose(false);
              close(e);
            }}
            className={classes.button}
          >
            {btnCloseLabel}
          </Button>
        )}

        {
          <Button
            variant="contained"
            color="primary"
            style={{ color: '#fff' }}
            onClick={(e) => {
              if (onClose) {
                onClose(true);
              }
              close(e);
            }}
            className={classes.button}
          >
            {onClose ? btnConfirmLabel : 'Ok'}
          </Button>
        }
      </DialogActions>
    </>
  )
);
