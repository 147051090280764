import {
  setPromotionData,
  setScreenFiveData,
  setScreenFourData,
  setScreenOneData,
  setScreenThreeData,
  setScreenTwoData,
} from '../../../store/action';
import { getAttachments, getCurrentDate } from '../../checklists-steps/utils';
import { formatDocument } from '../components/utils';
import { TRANSLATIONS_ITEMS as propertyTranslations } from '../../../shared/checklists-wizard/constants/summary/propertySummaryItem/constants';
import { TRANSLATIONS_ITEMS as securityTranslations } from '../../../shared/checklists-wizard/constants/summary/securityMaintanceSummaryItem/constants';
import { TRANSLATIONS_ITEMS as enviromentTranslations } from '../../../shared/checklists-wizard/constants/summary/environmentSummaryItem/constants';
import { TRANSLATIONS_ITEMS as picturesDataTranslations } from '../../../shared/checklists-wizard/constants/summary/picturesSummaryItem/constants';
import { IMAGE_NOT_FOUND } from '../../../__mock__/base-64-not-image.mock-client';
import { DialogLoading } from '../../../elements/dialog/components';

export const handleSelectItem = (
  item,
  history,
  dispatch,
  getData,
  openSnackBar,
  openModal,
  closeModal
) => {
  // If we're in leads page, fill checklist
  if (history.location.pathname.indexOf('properties') > -1) {
    // Old checklists:
    // history.push(`${item.propertyId}/check-list`);
    // New Checklists:
    history.push(`/checklists/${item.propertyId}`);
    setScreenOneData(dispatch, {
      createdAt: getCurrentDate(),
      item: {
        document: 'properties',
        checklistAssignedAt: item.checklistAssignedAt,
        // TODO: isPrincing commented 'cause isPricing project is paused
        // isPricing: item.isPricing ? true : false
      }
    });
  } else {
    // If we aren't in leads page & the checklist is new, fill checklist
    if (item.checklistStatus === 'new') {
      // If we aren't in leads page, the checklist is new & is a promotion, fill checklist
      if (item.propertyId?.charAt(0) === 'P') {
        history.push(`checklists/promotion/${item.propertyId}`);
        setPromotionData(dispatch, {
          screenGeneral: {
            createdAt: getCurrentDate(),
          },
          item: {
            document: item.document,
            id: item.propertyId,
            address: item.address,
            checklistAssignedAt: item.checklistAssignedAt,
            ticketId: item.ticketId ?? null,
            deadline: item.deadline ?? null
          },
          pictures: { send: [], view: [] },
        });
      } else {
        history.push(`checklists/${item.propertyId}`);
        setScreenOneData(dispatch, {
          createdAt: getCurrentDate(),
          item: {
            document: item.document,
            checklistAssignedAt: item.checklistAssignedAt,
            ticketId: item.ticketId ?? null,
            deadline: item.deadline ?? null
          },
        });
      }
    } else {
      // Open loading modal
      openModal({
        Component: DialogLoading,
        view: 'urs-loading'
      });

      // If we aren't in leads page, the checklist is in-progress & is a promotion, laod checklist
      if (item.propertyId?.charAt(0) !== 'P') {
        // Si no es una promoción
        getData({ propertyId: item.propertyId, document: 'check-list' }).then(
          (res) => {
            if (res.docs?.length > 0) {
              res.docs.sort(function (a, b) {
                if (a.checklistCreatedAt < b.checklistCreatedAt) {
                  return 1;
                }
                if (a.checklistCreatedAt > b.checklistCreatedAt) {
                  return -1;
                }
                return 0;
              });
              const document = formatDocument(res.docs[0]);

              let aux,
                screenOne = {},
                screenTwo = {},
                screenThree = {},
                screenFive = {},
                finalValue = '';
              // pictures = [];

              Object.keys(document).forEach((key) => {
                if (key === 'createdAt') {
                  finalValue = document[key];
                  screenOne[key] = finalValue;
                } else {
                  if (typeof document[key] === 'object') {
                    finalValue = document[key].value?.toString();
                  } else {
                    finalValue = document[key]?.toString();
                  }
                  aux = propertyTranslations.find((p) => p.documentKey === key);
                  if (aux) {
                    screenOne[key] = finalValue;
                  } else {
                    aux = securityTranslations.find((p) => p.documentKey === key);
                    if (aux) {
                      screenTwo[key] = document[key];
                    } else {
                      aux = enviromentTranslations.find(
                        (p) => p.documentKey === key
                      );
                      if (aux) {
                        screenThree[key] = document[key];
                      } else {
                        aux = picturesDataTranslations.find(
                          (p) => p.documentKey === key
                        );
                        if (aux) {
                          screenFive[key] = document[key];
                        }
                      }
                    }
                  }
                }
              });
              getAttachments(
                (base64Pictures) => {
                  if (Array.isArray(base64Pictures)) {
                    while (document.attachments.length > base64Pictures.length) {
                      base64Pictures.push(JSON.parse(IMAGE_NOT_FOUND));
                    }
                  }
                  setScreenOneData(dispatch, screenOne);
                  setScreenTwoData(dispatch, screenTwo);
                  setScreenThreeData(dispatch, screenThree);
                  setScreenFourData(
                    dispatch,
                    Array.isArray(base64Pictures)
                      ? base64Pictures
                      : document.attachments.length > 0
                        ? { nonConnection: true }
                        : []
                  );
                  setScreenFiveData(dispatch, screenFive);
                  // push to screen
                  history.push(`checklists/${item.propertyId}/summary`);

                  // Close loading modal
                  closeModal();
                },
                document.attachments,
                openSnackBar
              );
            }
          }
        );
      } else {
        // Si es una promoción
        getData({ propertyId: item.propertyId, document: 'check-list' }).then((res) => {
          if (res.docs?.length > 0) {
            let picturesDataImage = [];
            let picturesDataUr = [];
            let picturesDataDescription = [];

            // Ordena por "checklistCreatedAt" las checklists de la notificacion
            res.docs.sort(function (a, b) {
              if (a.checklistCreatedAt < b.checklistCreatedAt) {
                return 1;
              }
              if (a.checklistCreatedAt > b.checklistCreatedAt) {
                return -1;
              }
              return 0;
            });

            const document = formatDocument(res.docs[0]);

            let aux;
            let promotionData = {
              screenGeneral: { createdAt: getCurrentDate() },
              urs: {},
              item: {},
              environment: {},
              pictures: { send: [], view: [] },
              picturesData: {}
            };
            let finalValue = '';

            Object.keys(document).forEach((key) => {
              if (key === 'createdAt') {
                finalValue = document[key];
                promotionData['screenGeneral'][key] = finalValue;
              } else {
                if (typeof document[key] === 'object') {
                  finalValue = document[key].value?.toString();
                } else {
                  finalValue = document[key]?.toString();
                }
                aux = propertyTranslations.find((p) => p.documentKey === key);
                if (aux) {
                  promotionData['screenGeneral'][key] = finalValue;
                } else {
                  aux = enviromentTranslations.find(
                    (p) => p.documentKey === key
                  );
                  if (aux) {
                    promotionData['environment'][key] = document[key];
                  } else {
                    aux = picturesDataTranslations.find(
                      (p) => p.documentKey === key
                    );
                    if (aux) {
                      promotionData['picturesData'][key] = document[key];
                    }
                  }
                }
              }
            });

            promotionData['item'] = {
              id: item.propertyId,
              address: item.address
            };

            promotionData['urs'] = document.urs;

            document.attachments.forEach((imageData) => {
              if (imageData.url) picturesDataImage.push(imageData.url);
              picturesDataUr.push(imageData.ur);
              picturesDataDescription.push(imageData.description);
            });

            getAttachments((base64Images) => {
              let base64Pictures = [];

              if (Array.isArray(base64Images)) {
                while (picturesDataImage.length > base64Images.length) {
                  base64Images.push(JSON.parse(IMAGE_NOT_FOUND).src);
                };

                base64Pictures = base64Images.map((value, key) => {
                  return {
                    src: (value === JSON.parse(IMAGE_NOT_FOUND).src)
                      ? value
                      : `data:image/jpeg;base64,${value}`,
                    ur: picturesDataUr[key],
                    description: picturesDataDescription[key]
                  };
                });
              } else if (document.attachments.length > 0) {
                document.attachments.forEach((value, key) => {
                  base64Pictures.push({
                    src: JSON.parse(IMAGE_NOT_FOUND).src,
                    ur: picturesDataUr[key],
                    description: picturesDataDescription[key]
                  });
                });
              }

              promotionData['pictures'] = base64Pictures.length > 0
                ? base64Pictures
                : picturesDataImage.length > 0
                  ? { nonConnection: true }
                  : []

              setPromotionData(dispatch, promotionData);

              history.push(`checklists/promotion/${item.propertyId}/summary`);

              // Close loading modal
              closeModal();
            },
              picturesDataImage,
              openSnackBar
            );
          }
        });
      }
    }
  }
};