import { useEffect } from 'react';
import { next } from '../../utils';
import { useSendPicsContext } from '../contexts';
import { formEffect } from '../effects/send-pics-form.effect';
import { useWizardContext } from '../../shared/wizard/contexts';

export const useSendPicsForm = ({ form, handleChange, onSubmit, setForm, validators }, send) => {
  const { step, setStep } = useWizardContext();
  const { formValue, selectValues, handleSendPicsChange } =
    useSendPicsContext();

  useEffect(formEffect(setForm, formValue), [formValue]);

  const handleSubmit = onSubmit(
    () => Promise.resolve(),
    send
      ? (data) => {
        handleSendPicsChange(data);
        setTimeout(() => send(data), 1000);
      }
      : (data) => {
        handleSendPicsChange(data);
        setStep(next(step));
      }
  );

  return { form, handleChange, handleSubmit, selectValues, validators };
};
