/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { PouchDBContext } from '../../../core/pouch-db/contexts';
import { SwipeableListComponent } from '../../../elements/lists';
import { useLeadsContext } from '../contexts';
import { LEADS_LIST_FILTER, LEADS_LIST_ITEM } from '../constants';
import LogsBuffer from '../../../core/logs-buffer/logs-buffer';
import FetchApi from '../../../core/fetch-api';
import {UserContext} from '../../../core/user';

export const LeadsListComponent = ({
  leadsArray,
  favorites,
  filters,
  onChangeFilters,
  cleanFilters,
  customFilters,
}) => {
  const history = useHistory();
  const { model, getModel } = useLeadsContext();
  const { putData } = useContext(PouchDBContext);
  const { state: userState } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0)
 }, [])
 
  const handleSwipeRight = (item) => {
    let action = item._id.setToggle(favorites);
    let actionId =
      action[item._id] === 1
        ? 'listado_marcafavorito'
        : 'listado_desmarcafavorito';

    FetchApi.refreshToken();
    LogsBuffer.addLog({
      view: actionId,
      type: 'Frontend',
      url: history.location.pathname,
      createdAt: Date.now(),
      event: 'Navigation',
      apiId: userState.enrollment,
    });
    putData({
      _id: '_local/favorites',
      document: '_local/favorites',
      data: action,
    });
    onChangeFilters(filters);
  };

  const handleSwipeLeft = (item) => {
    FetchApi.refreshToken();
    LogsBuffer.addLog({
      view: 'listado_phone',
      type: 'Frontend',
      url: history.location.pathname,
      createdAt: Date.now(),
      event: 'Navigation',
      apiId: userState.enrollment,
    });
    window.location.href = `tel://${item.phone1}`;
  };

  const handleSelectItem = (item) => {
    // const type = item.promoId ? 'promo' : 'management';
    const type = item.propertyId?.charAt(0) === 'P' ? 'promo' : 'management'; // COMMENT WHEN SAP FIXES IT
    window.localStorage.scroll_v2 = document.documentElement.scrollTop;
    history.push(`leads/${type}/${item._id.split(':')[1]}`);
  };

  useEffect(() => {
    window.scrollTo(0, window.localStorage.scroll_v2);
  }, [window.localStorage.scroll_v2]);

  useEffect(getModel, []);

  return (
    <SwipeableListComponent
      model={model}
      extraModel={favorites}
      filterModel={filters}
      style={{ paddingTop: 0 }}
      onClick={handleSelectItem}
      listConfig={LEADS_LIST_ITEM}
      onSwipeLeft={handleSwipeLeft}
      onSwipeRight={handleSwipeRight}
      onChangeFilter={onChangeFilters}
      filterConfig={LEADS_LIST_FILTER}
      customFilters={customFilters}
      cleanFilters={cleanFilters}
      leadsArray={leadsArray}
    />
  );
};
