export const DEFAULT_CONFIG = {
  _id: '_local/config',
  document: '_local/config',
  revs: false,
  _rev: '0-1',
};

export const DEFAULT_FAVORITES = {
  _id: '_local/favorites',
  document: '_local/favorites',
  data: {},
  _rev: '0-1',
};

export const DEFAULT_PENDING = {
  _id: '_local/pendingRequest',
  document: '_local/pendingRequest',
  data: [],
  _rev: '0-1',
};
