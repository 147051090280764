export const infoItemStyles = (theme) => ({
  icon: {
    color: theme.palette.primary.main,
  },
  listItem: {
    padding: '0 16px',
  },
  extraInfo: {
    paddingTop: 0,
  },
  listItemSecondaryAction: {
    top: theme.spacing(2),
  },
  listItemText: {
    '&>span': {
      width: '85%',
      display: 'flex',
    },
  },
});
