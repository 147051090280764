export const TYPES = {
  CENTERED: 'centered',
  HALF_VIEW: 'fullView',
  FULL_VIEW: 'fullView',
};

export const DEFAULT_STATE = {
  Component: '',
  isOpen: false,
  hasBackdrop: false,
  isFullScreen: false,
  type: TYPES.CENTERED,
};

export const FULLSCREEN_TYPES = [TYPES.HALF_VIEW, TYPES.FULL_VIEW];
