import FetchApi from '../../../core/fetch-api';
import { setPromotionData } from '../../../store/action';

/**
 * Get URs from local Cloudant.
 * Save data in context.
 * @param {string} id 
 * @param {*} dispatch 
 * @param {*} state 
 * @param {*} getData 
 */
const getChildrenFromCloudant = (id, dispatch, state, getData) => {
  getData({ document: 'properties', promotionId: id }).then((res) => {
    // If promo is pricing then filter urs if are pricing too
    if (state.item.ticketId) {
      let childrenDataPricing = res.docs.filter(d => d.ticketId);
      setPromotionData(dispatch, { ...state, childrenData: childrenDataPricing });
    } else {
      const urs = res.docs.filter(ur => ur.baja === "false" && ur.vendida === "false" && ur.publicable === "true")
      setPromotionData(dispatch, { ...state, childrenData: urs });
    }
  })
}

/**
 * Get URs from WebService when is online and 
 * local Cloudant when is offline.
 * Save data in context.
 * @param {string} id 
 * @param {*} dispatch 
 * @param {*} state 
 * @param {*} getData 
 * @param {boolean} setLoadingState 
 */
export const getChildrenPromotion = (id, dispatch, state, getData, setLoadingState, online) => {
  setLoadingState(true);

  // If checklist promotion is pricing take it from Cloudant
  if (state.item.ticketId) {
    getChildrenFromCloudant(id, dispatch, state, getData);
    setLoadingState(false);
  } else {
    FetchApi.request('get', '/urproperty/promotion/' + id).then(async data => {
      if (data.status === 200) {
        const promises = data.results.map(async property => {
          const res = await getData({ document: 'properties', propertyId: property.propertyId });
          if (res.docs[0].baja === "false" && res.docs[0].vendida === "false" && res.docs[0].publicable === "true") {
            return property;
          } else {
            return null;
          }
        });

        const resolvedProperties = await Promise.all(promises);
        const filteredProperties = resolvedProperties.filter(property => property !== null);

        setPromotionData(dispatch, { ...state, childrenData: filteredProperties });
      }
      else if (data.status === 500) {
        getChildrenFromCloudant(id, dispatch, state, getData);
      }
      else
        setPromotionData(dispatch, { ...state, childrenData: [] });

      setLoadingState(false);
    }).catch((error) => {
      if (!online) {
        getChildrenFromCloudant(id, dispatch, state, getData);
      } else {
        setPromotionData(dispatch, { ...state, childrenData: [] });
      }
      setLoadingState(false);
    });
  }
}
