export const DEFAULT_FILTERS = {
  filterKey: 'status',
  filterValue: 'new',
  sort: 'expireAt',
  direction: 'asc',
  filterType: 'leads',
};
export const CUSTOM_FILTERS = {
  name: '',
  phone1: '',
  assignedTo: null,
  typeActivity: ''
};
export const LEADS_LIST_FILTER = {
  hasFavs: true,
  hasSort: true,
  hasHeader: true,
  fields: [
    {
      label: 'Filtrar',
      value: 'filterValue',
      options: [
        {
          value: 'new',
          label: 'Sin tratar',
        },
        {
          value: 'in-progress',
          label: 'En curso',
        },
      ],
    },
    {
      label: 'Ordenar por',
      value: 'sort',
      options: [
        {
          value: 'name',
          label: 'Nombre',
        },
        {
          value: 'address',
          label: 'Dirección',
        },
        {
          value: 'typeText',
          label: 'Tipo',
        },
        {
          value: 'expireAt',
          label: 'Fecha de caducidad',
        },
        {
          value: 'transferAt',
          label: 'Fecha de traspaso',
        },
        {
          value: 'assignedTo',
          label: 'Comercial',
        },
      ],
    },
  ],
};

export const LEADS_LIST_ITEM = {
  swipeRight: {
    backgroundColor: '#F6931D',
    color: '#FFFFFF',
    icon: 'star',
  },
  swipeLeft: {
    backgroundColor: '#00B2A9',
    color: '#FFFFFF',
    icon: 'phone',
  },
  item: {
    title: 'name',
    description: ['address', 'locality'],
    type: { label: 'typeText', icon: 'type' },
    date: { type: 'range', key: ['transferAt', 'expireAt'] },
    dateExpireAt: { type: 'range', key: ['transferAt', 'expireAt'] },
    extra: { type: 'fav', key: 'isFavorite' },
  },
};
