export const formatDocument = (props) => {
    return {
        // Property
        createdAt: props.createdAt,
        visited: props.visited,
        keyWorks: props.keyWorks,
        propertyTypeCL: props.propertyTypeCL,
        newPublish: props.newPublish,
        isCommercialPromotion: props.isCommercialPromotion,
        // Property - Optional A
        recommendedPrice: props.recommendedPrice,
        salePriceEstimated: props.salePriceEstimated,
        commentPrice: props.commentPrice,
        urStatus: props.urStatus,
        CAPEXText: props.CAPEXText,
        // Property - Optional B
        publishedPrice: props.publishedPrice,
        stressedPrice: props.stressedPrice,
        correctPics: props.correctPics,
        correctDescription: props.correctDescription,
        // Property - Optional C
        reasonToNotAccess: props.reasonToNotAccess,
        // Property - Residencial
        garages: props.garages,
        airConditioner: props.airConditioner,
        elevator: props.elevator,
        penthouseWithTerrace: props.penthouseWithTerrace,
        groundFloorWithGarden: props.groundFloorWithGarden,
        greenAreas: props.greenAreas,
        sportsAreas: props.sportsAreas,
        communalSwimmingPool: props.communalSwimmingPool,
        // Property - WIP
        percentageConstruction: props.percentageConstruction,
        // Property - Suelo
        clasificationUrbanistic: props.clasificationUrbanistic,
        mayorityUse: props.mayorityUse,
        trustInfo: props.trustInfo,
        // Property - Terciario
        bestLocalActivity: props.bestLocalActivity,
        localTypology: props.localTypology,
        useChange: props.useChange,
        smokeScape: props.smokeScape,

        // SecurityMaintenance
        antiOccupation: props.antiOccupation,
        requireActions: props.requireActions,
        occupationRisk: props.occupationRisk,
        vandalized: props.vandalized,
        perishableClean: props.perishableClean,
        equipmentClean: props.equipmentClean,
        pests: props.pests,

        // Environment
        prevailingEnvironment: props.prevailingEnvironment,
        qualityArea: props.qualityArea,
        areaLocation: props.areaLocation,
        environmentDescription: props.environmentDescription,
        marketingDescription: props.marketingDescription,
        attachments: props.attachments,

        // PicturesData
        name: props.name,
        phone: props.phone,
        email: props.email,
        // PicturesData - Extra
        comment: props.comment,

        // Extra
        bestLocalActivity: props.bestLocalActivity,
        checklistCreatedAt: props.checklistCreatedAt,
        checklistStatus: props.checklistStatus,
        document: props.document,
        localTypology: props.localTypology,
        propertyId: props.propertyId,
        smokeScape: props.smokeScape,
        useChange: props.useChange,

        // Promotion
        urs: props.urs,
    };
}