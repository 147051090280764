import {
  FilledInput,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { InputCustom } from '../../ui/input/inputCustom';
import { RadioGroupCustom } from '../../ui/radio-group/radio-group-custom';
import { TextAreaInput } from '../../elements/input/components/text-area-input-custom.component';
import { CheckBoxCustom } from '../../ui/check-box/check-box-custom.component';
import { AccordionCustom } from '../../ui/accordion/accordion-custom.component';
import {
  setPromotionData,
  setScreenFiveData,
  setScreenFourData,
  setScreenOneData,
  setScreenThreeData,
  setScreenTwoData,
} from '../../store/action';
import moment from 'moment';
import FetchApi from '../../core/fetch-api';

export const onCancelWizard = (dispatch) => {
  setScreenOneData(dispatch, { createdAt: getCurrentDate() });
  setScreenTwoData(dispatch, {});
  setScreenThreeData(dispatch, {});
  setScreenFourData(dispatch, { send: [], view: [] });
  setScreenFiveData(dispatch, {});
};

export const onCancelPromotionWizard = (dispatch) => {
  setPromotionData(dispatch, {
    screenGeneral: { createdAt: getCurrentDate() },
    urs: {},
    environment: {},
    pictures: { send: [], view: [] },
  });
}

export const getHTMLFormElement = (functions, input, currentValues, errors, state) => {
  let html = null;
  switch (input.type) {
    case 'dropdown':
      html = (
        <>
          <InputLabel id={`label-${input.name}`}>
            {input.required ? `* ${input.label}` : input.label}
          </InputLabel>
          <Select
            style={{
              width: '100%',
              marginTop: '8px',
              marginBottom: '24px',
            }}
            variant="filled"
            value={currentValues[input.name] ?? ''}
            {...functions.register(input.name, { ...input.rules })}
            labelId={`label-${input.name}`}
            input={<FilledInput />}
          >
            {input.props.options &&
              input.props.options.map((pt) => (
                <MenuItem key={pt.label} value={pt.value}>
                  {pt.label}
                </MenuItem>
              ))}
          </Select>
        </>
      );
      break;
    case 'input':
      html = (
        <InputCustom
          {...functions.register(input.name, input.rules)}
          label={input.required ? `* ${input.label}` : input.label}
          fullWidth
          variant="filled"
          currencyFormat={input.currencyFormat ?? false}
          InputProps={{ ...input.props }}
          value={currentValues[input.name]}
          onChange={(e, value) => functions.setValue(input.name, value)}
        />
      );
      break;
    case 'radio':
      html = (
        <>
          <RadioGroupCustom
            {...functions.register(input.name, input.rules)}
            value={currentValues[input.name] ?? ''}
            values={input.options}
            label={input.required ? `* ${input.label}` : input.label}
            info={input.info}
            direction={input.inline}
            onChange={(e, value) => {
              functions.setValue(input.name, value);
            }}
          />
          {input.hasCustomOption && currentValues[input.name] === 'other' && (
            <FormControlLabel
              style={{
                marginLeft: '.5rem',
                marginTop: '-1rem',
                marginBottom: '0.5rem',
              }}
              control={
                <TextField
                  {...functions.register(
                    `${input.name}Other`,
                    input.customOptionRules
                  )}
                  disabled={currentValues[input.name] !== 'other'}
                  variant="outlined"
                  value={currentValues[`${input.name}Other`] ?? ''}
                  onChange={(e) => {
                    functions.setValue(`${input.name}Other`, e.target.value);
                  }}
                />
              }
            />
          )}
        </>
      );
      break;
    case 'textarea':
      html = (
        <TextAreaInput
          {...functions.register(input.name, input.rules)}
          inputProps={{ ...input.props }}
          variant="filled"
          placeholder={input.label}
          label={input.required ? `* ${input.label}` : input.label}
          value={currentValues[input.name] ?? ''}
          onChange={(e, value) => functions.setValue(input.name, value)}
        />
      );
      break;
    case 'checkbox':
      html = (
        <CheckBoxCustom
          label={input.label}
          address={input.address}
          functions={functions}
          childrens={input.childrens}
          currentValues={currentValues}
        />
      );
      break;
    case 'accordionPromotion':
      html = (
        <>
          <AccordionCustom
            name={input.name}
            title={input.title}
            description={input.description}
            options={input.options}
            optionalOptions={input.optionalOptions}
            functions={functions}
            currentValues={currentValues[input.name]}
            isError={input.error}
            state={state}
          />
        </>
      );
      break;
    case 'label':
      html = (
        <>
          <Typography variant='h6'>
            {input.label}
          </Typography>
        </>
      );
      break;
    default:
      html = null;
  }
  const errorInputName = errors[input.name]
    ? errors[input.name]
    : errors[`${input.name}Other`]
      ? errors[`${input.name}Other`]
      : null;
  if (errorInputName !== null) {
    let message = 'El campo es obligatorio';
    if (errorInputName.type === 'isPhone') {
      message = 'El formato del teléfono no es correcto.';
    } else if (errorInputName.type === 'isValidEmail') {
      message = 'El formato del email no es correcto.';
    }
    html = (
      <>
        {html}
        <p style={{ color: 'red' }} data-testid="error-message">{message}</p>
      </>
    );
  }
  return html;
};

export const getCurrentDate = () => {
  const now = new Date();
  const [day, month, year] = [
    now.getDate().toString().padStart(2, '0'),
    (now.getMonth() + 1).toString().padStart(2, '0'),
    now.getFullYear(),
  ];
  return `${year}-${month}-${day}`;
};

export const getSpanishCurrentDate = (date) => {
  const momentDate = moment(date, 'YYYY-MM-DD');
  return `${momentDate.format('DD-MM-YYYY')}`;
};

export const renderElementForm = (functions, e, currentValues, errors, state) => {
  return (
    <FormControl key={e.name} fullWidth>
      {getHTMLFormElement(functions, e, currentValues, errors, state)}
    </FormControl>
  );
};

export const getAttachments = async (callback, data, openSnackBar) => {
  let response = [{
    results: []
  }]
  if (data && data.length > 0) {
    response = await FetchApi.request('post', '/pictures', data, openSnackBar)
  }
  callback(response?.results)
};

export const getPropertyId = (location) => {
  let regExp = ''
  if (location.indexOf('promotion') > -1) {
    regExp = new RegExp(/[P][0-9]{4}-[0-9]{8,9}/);
  } else {
    regExp = new RegExp(/[0-9]{4}-[0-9]{8,9}/);
  }
  return regExp.exec(location)[0]
}
