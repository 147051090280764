/* eslint-disable no-extend-native */
import consoleLog from './core/console-log/consoleLog';
import {
  DEFAULT_CONFIG,
  DEFAULT_FAVORITES,
  DEFAULT_PENDING,
} from './core/pouch-db/constants/constants';
import FetchApi from './core/fetch-api';

Array.prototype.unique = function (key) {
  const a = this.concat();

  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i][key] === a[j][key]) {
        a.splice(j--, 1);
      }
    }
  }

  return a;
};

export function next(number) {
  return number + 1;
}

export function prev(number) {
  return number - 1;
}

export const wuid = async () => {
  if (crypto && crypto.subtle) {
    const { userAgent, productSub, appCodeName, appVersion } = window.navigator;

    const str = userAgent + productSub + appCodeName + appVersion;
    const buf = await crypto.subtle.digest(
      'SHA-256',
      new TextEncoder('utf-8').encode(str)
    );

    const uuid = Array.prototype.map
      .call(new Uint8Array(buf), (x) => '00' + x.toString(16).slice(-2))
      .join('');

    window.localStorage.wuid_v2 = uuid;
    if (!uuid)
      consoleLog(
        'error',
        'utils.js: ¡El wuid_v2 está vacío! (tiene objeto crypto)'
      );

    return uuid;
  } else {
    const uuid = uuidv4();
    if (!uuid) consoleLog('error', 'utils.js: ¡El wuid_v2 está vacío!');

    return uuid;
  }
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });
};

export const getPrevVersion = () => {
  const [digitOne] = process.env.REACT_APP_VERSION.split('.');

  return `${+digitOne - 1}.0`;
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const stepCounter = (stepId, total) => {
  return `${stepId + 1}/${total}`;
};

export const isMobileNumber = (phone) => {
  if (phone) {
    const result = phone
      .replace(/[^0-9]+/g, '')
      .replace(/^34/, '')
      .match(/^[6-8]\d{8}$/);

    return result ? `34${result[0]}` : '';
  }
};

Array.prototype.merge = function (b) {
  for (let i = 0; i < b.length; i++) {
    let index = this.findIndex((element) => element._id === b[i]._id);

    if (index >= 0) {
      this[index] = { ...b[i], ...this[index] };
    }
  }

  return this;
};

String.prototype.setToggle = function (data) {
  data[this] = 1 - (data[this] | 0);

  return data;
};

Array.prototype.replace = function (b, key) {
  const index = this.findIndex((e) => e[key] === b[key]);

  if (index >= 0) {
    this[index] = b;
  } else {
    this.push(b);
  }

  return this;
};

Array.prototype.sortByKey = function (key, sort = 'asc') {
  const data = this.sort(function (a, b) {
    const bKey = !b[key] ? '_' : b[key];
    const aKey = !a[key] ? '_' : a[key];

    let y = bKey.toUpperCase ? bKey.toUpperCase() : bKey;
    let x = aKey.toUpperCase ? aKey.toUpperCase() : aKey;

    return x < y ? -1 : x > y ? 1 : 0;
  });

  return sort === 'asc' ? data : data.reverse();
};

Array.prototype.filterObject = function (object) {
  return Object.keys(object)
    .filter((key) => this.includes(key))
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});
};

// Leads custom filter by value (name, phone1)
Array.prototype.filterBySimilarValue = function (params) {
  return Object.keys(params).some((key) => !!params[key])
    ? this.filter((element) => {
      let find = true;
      Object.keys(params).forEach((key) => {
        if (params[key]) {
          let paramsArray = params[key]
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            .split(/\s+/);

          find =
            find &&
            paramsArray.every((item) => {
              return (element[key] || '')
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .includes(item);
            });
        }
      });
      return find;
    })
    : this;
};

Array.prototype.filterByKey = function (key, value, noFilter) {
  return noFilter
    ? this
    : this.filter((element) => (element[key] || !!element[key]) === value);
};

String.prototype.toPascalCase = function () {
  return this.match(/[a-z]+/gi)
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    })
    .join('');
};

String.prototype.dateFormat = function (characters = 3) {
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  const date = new Date(this);

  const dia = date.getDate();
  const mes = date.getMonth();

  return months[mes] ? ` ${dia} ${months[mes].slice(0, characters)}` : '';
};

Number.prototype.numberFormat = function (n, x, s, c) {
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
    num = this.toFixed(Math.max(0, ~~n));

  return (c ? num.replace('.', c) : num)
    .replace(new RegExp(re, 'g'), '$&' + (s || ','))
    .slice(0, -3);
};

Number.prototype.currencyFormat = function (currency) {
  return this.numberFormat(2, 0, '.', ',') + ' ' + currency;
};

Array.prototype.reduceByKey = function (key, initialValue = {}) {
  return this.reduce((temp, current) => {
    return {
      ...temp,
      [current[key]]: [...(temp[current[key]] || []), current],
    };
  }, initialValue);
};

String.prototype.isValidDate = function () {
  return new Date(this).getTime() > new Date().getTime();
};

String.prototype.getTime = function () {
  return new Date(this).getTime();
};

String.prototype.daysUntilNow = function () {
  return Math.round((Date.now() - new Date(this)) / (1000 * 60 * 60 * 24));
};

String.prototype.addDays = function (days) {
  var result = new Date(this);
  result.setDate(result.getDate() + days);

  return result;
};

Array.prototype.findQuery = function (object, initialQuery = {}) {
  return this.reduce((temp, current) => {
    return { ...temp, [current]: { $eq: object[current] } };
  }, initialQuery);
};

export function today() {
  const now = new Date();
  const [day, month, year] = [
    now.getDate().toString().padStart(2, '0'),
    (now.getMonth() + 1).toString().padStart(2, '0'),
    now.getFullYear(),
  ];
  return `${day}/${month}/${year}`;
}

export function todayActivities() {
  const now = new Date();
  const [day, month, year] = [
    now.getDate().toString().padStart(2, '0'),
    (now.getMonth() + 1).toString().padStart(2, '0'),
    now.getFullYear(),
  ];
  return `${year}/${month}/${day}`;
}

String.prototype.toCamelCase = function () {
  return this.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

function addDaysToDate(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);

  return result;
}

export const isDocumentValid = (doc, validityDays = 15) =>
  doc.checklistAssignedAt
    ? doc.checklistAssignedAt
      ? new Date() < addDaysToDate(doc.checklistAssignedAt, validityDays)
      : new Date() < addDaysToDate('2020/10/01', validityDays)
    : doc.createdAt
      ? new Date() < addDaysToDate(doc.createdAt, validityDays)
      : new Date() < addDaysToDate('2020/10/01', validityDays)

if (window && window.IDBFactory)
  IDBFactory.prototype.clearPouchDBs = async function () {
    const dbs = (await this.databases()).filter((db) => /_pouch/.test(db.name));

    await FetchApi.refreshToken();

    for (let j = 0; j < dbs.length; j++) {
      let db;
      try {
        db = await this.openAsync(dbs[j].name);

        const objectStores = db.getObjectStores('readwrite');

        for (let i = 0; i < objectStores.length; i++) {
          const pendingRequest =
            objectStores[i].name === 'local-store'
              ? (await objectStores[i].getAsync('_local/pendingRequest')) ||
              DEFAULT_PENDING
              : undefined;
          const favorites =
            objectStores[i].name === 'local-store'
              ? (await objectStores[i].getAsync('_local/favorites')) ||
              DEFAULT_FAVORITES
              : undefined;
          const config =
            objectStores[i].name === 'local-store'
              ? (await objectStores[i].getAsync('_local/config')) ||
              DEFAULT_CONFIG
              : undefined;

          await objectStores[i].clearAsync();

          if (pendingRequest) {
            await objectStores[i].putAsync(pendingRequest);
          }

          if (favorites) {
            await objectStores[i].putAsync(favorites);
          }

          if (config) {
            config.revs = false;
            await objectStores[i].putAsync(config);
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        if (db) {
          db.close();
        }
      }
    }

    return true;
  };

if (window && window.IDBFactory)
  IDBFactory.prototype.openAsync = async function (dbName, version) {
    return new Promise((resolve, reject) => {
      const request = this.open(dbName, version);

      request.onsuccess = (event) => {
        resolve(request.result);
      };

      request.onerror = (error) => {
        reject(error);
      };
    });
  };

if (window && window.IDBDatabase)
  IDBDatabase.prototype.get = async function (storeName, key) {
    const objectStore = this.getObjectStore(storeName, 'readonly');
    await FetchApi.refreshToken();
    return objectStore.getAsync(key);
  };

if (window && window.IDBDatabase)
  IDBDatabase.prototype.put = async function (storeName, value, key) {
    const objectStore = this.getObjectStore(storeName, 'readwrite');

    return objectStore.putAsync(value, key);
  };

if (window && window.IDBDatabase)
  IDBDatabase.prototype.getObjectStores = function (mode) {
    const objectStores = [];

    if (this.objectStoreNames.length) {
      const transaction = this.transaction([...this.objectStoreNames], mode);

      for (let i = 0; i < transaction.objectStoreNames.length; i++) {
        objectStores.push(
          transaction.objectStore(transaction.objectStoreNames[i])
        );
      }
    }

    return objectStores;
  };

if (window && window.IDBDatabase)
  IDBDatabase.prototype.getObjectStore = function (name, mode) {
    const transaction = this.transaction([name], mode);
    return transaction.objectStore(name);
  };

if (window && window.IDBObjectStore)
  IDBObjectStore.prototype.getAsync = async function (key) {
    return new Promise((resolve, reject) => {
      const request = this.get(key);

      request.onsuccess = (event) => {
        resolve(request.result);
      };

      request.onerror = (error) => {
        reject(error);
      };
    });
  };

if (window && window.IDBObjectStore)
  IDBObjectStore.prototype.putAsync = async function (value, key) {
    return new Promise((resolve, reject) => {
      const request = this.put(value, key);

      request.onsuccess = (event) => {
        resolve(request.result);
      };

      request.onerror = (error) => {
        reject(error);
      };
    });
  };

if (window && window.IDBObjectStore)
  IDBObjectStore.prototype.clearAsync = async function () {
    return new Promise((resolve, reject) => {
      const request = this.clear();

      request.onsuccess = (event) => {
        resolve(request.result);
      };

      request.onerror = (error) => {
        reject(error);
      };
    });
  };

if (
  window &&
  window.indexedDB &&
  typeof window.indexedDB.databases === 'undefined'
) {
  const LOCALSTORAGE_CACHE_KEY = 'indexedDBDatabases';

  const getFromStorage = () =>
    JSON.parse(window.localStorage[LOCALSTORAGE_CACHE_KEY] || '{}');

  const writeToStorage = (value) =>
    (window.localStorage[LOCALSTORAGE_CACHE_KEY] = JSON.stringify(value));

  IDBFactory.prototype.databases = () =>
    Promise.resolve(
      Object.entries(getFromStorage()).reduce((acc, [name, version]) => {
        acc.push({ name, version });
        return acc;
      }, [])
    );

  const open = IDBFactory.prototype.open;

  IDBFactory.prototype.open = function (...args) {
    const dbName = args[0];
    const version = args[1] || 1;
    const existing = getFromStorage();
    writeToStorage({ ...existing, [dbName]: version });
    return open.apply(this, args);
  };

  const deleteDatabase = IDBFactory.prototype.deleteDatabase;

  IDBFactory.prototype.deleteDatabase = function (...args) {
    const dbName = args[0];
    const existing = getFromStorage();
    delete existing[dbName];
    writeToStorage(existing);
    return deleteDatabase.apply(this, args);
  };
}
