export const expansionPanelSummaryStyles = (theme) => ({
  root: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.main,
    borderBottom: '1px solid rgba(0, 0, 0, .085)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
    justifyContent: 'space-between',
  },
  expanded: {},
});
