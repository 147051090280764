import React, { useEffect, useState } from 'react';

import Step1Property from './steps/Property';
import Step2SecurityMaintenance from './steps/SecurityMaintenance';
import Step3Enviroment from './steps/Enviroment';
import Step4Pictures from './steps/Pictures/index';
import Step5PicturesData from './steps/PicturesData/index';
import Step6Summary from './steps/Summary';
import { useDispatch } from '../../core/hooks/use-selector';
import { onCancelWizard } from './utils';
import { useHistory } from 'react-router-dom';

const ChecklistStepsWizard = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentContent, setCurrentContent] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    onCancelWizard(dispatch, history);
    return () => {
      onCancelWizard(dispatch, history);
    };
  }, []);

  useEffect(() => {
    switch (currentStep) {
      case 0:
        setCurrentContent(
          <Step1Property
            onChangeStepHandler={setCurrentStep}
            haveBack={false}
          />
        );
        break;
      case 1:
        setCurrentContent(
          <Step2SecurityMaintenance onChangeStepHandler={setCurrentStep} />
        );
        break;
      case 2:
        setCurrentContent(
          <Step3Enviroment onChangeStepHandler={setCurrentStep} />
        );
        break;
      case 3:
        setCurrentContent(
          <Step4Pictures onChangeStepHandler={setCurrentStep} />
        );
        break;
      case 4:
        setCurrentContent(
          <Step5PicturesData onChangeStepHandler={setCurrentStep} />
        );
        break;
      case 5:
        setCurrentContent(
          <Step6Summary onChangeStepHandler={setCurrentStep} />
        );
        break;
      default:
        setCurrentContent(null);
    }
  }, [currentStep]);

  return <>{currentContent}</>;
};

export default ChecklistStepsWizard;
