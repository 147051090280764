export const activitiesStyles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    height: '115%',
    backgroundColor: theme.palette.white.main,
  },
  header: {
    display: 'inline-flex',
    width: theme.wrappers.fullWidth,
  },
  title: {
    margin: 0,
    fontSize: 20,
    width: theme.wrappers.fullWidth,
  },
  form: {
    height: 'auto',
  },
  comment: {
    marginTop: 15,
    marginBottom: 15,
  },
  actions: {
    marginTop: 50,
    float: 'right',
  },
  cancel: {
    marginRight: 15,
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
});
