import React from 'react';

import { withStyles } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';

import { loadingMessageStyle } from './loading-message.style';

export const LoadingMessageComponent = withStyles(loadingMessageStyle)(
  ({ classes, resource }) => (
    <div className={classes.root}>
      <span>
        <CircularProgress size={15} /> Recuperando {resource.toLowerCase()} de
        la base de datos{' '}
      </span>
    </div>
  )
);
