import React from 'react';

import { Icon } from '@material-ui/core';

import { DescriptorItem } from './index';

export const ListDetailItem = ({ item, model, tableValues }) => {
  const getItemOptions = (item, tableValues) => {
    if (
      tableValues !== undefined &&
      item.type &&
      item.type === 'select' &&
      item.selectId
    ) {
      const options = tableValues.find(
        (element) => element.type === item.selectId
      );
      return options && options.data;
    }
  };

  if (item.type && item.type === 'select' && item.selectId) {
    item['options'] = getItemOptions(item, tableValues);
  } else if (item.type && item.type === 'checkbox') {
    item['options'] = [
      { value: '01', label: 'Sí' },
      { value: '02', label: 'No' },
    ];
  }

  return (
    <>
      {item.icon && (
        <>
          <span style={{ margin: '0 20px' }}>
            <Icon className="icon">{item.icon}</Icon>
          </span>
        </>
      )}
      {item.hasOwnProperty('fields') && item.fields.length > 0 ? (
        item.fields.map((item, i) => {
          return <DescriptorItem key={i} item={item} model={model} />;
        })
      ) : (
        <DescriptorItem item={item} model={model} />
      )}
    </>
  );
};
