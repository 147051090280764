import { getViewFromLocation } from '../../log-dna/constants';
import LogsBuffer from '../../logs-buffer/logs-buffer';

export class LogDnaStream {
  constructor(history) {
    this.history = history;
  }

  write(args) {
    const { name, level, msg, v, ...rest } = args;
    const view = getViewFromLocation(this.history);
    const resource = {
      message: msg,
      stacktrace: Object.keys(rest).map(
        (argKey) => `${argKey}: ${JSON.stringify(args[argKey])}`
      ),
    };

    LogsBuffer.addLog({
      ...view,
      type: 'Frontend',
      event: 'Stacktrace',
      resource,
      createdAt: Date.now(),
      url: this.history.location.pathname,
    });
  }
}
