const deleteNotification = (element, deteleData) => {
  if (element && element['_id'] && element['_rev'])
    deteleData(element['_id'], element['_rev']);
};

export const existsUr = (ur, urList) => {
  let exist = false;
  urList.forEach((p) => {
    if (p.propertyId === ur.propertyId) {
      exist = true;
    };
  });

  if (exist) return true;
  else return false;
}

export const formatChildrenPromo = (parent, children) => {
  if (children.promotionId)
    return {
      ...children,
      checklistExpireAt: parent.checklistExpireAt,
      checklistAssignedAt: parent.checklistAssignedAt,
      checklistStatus: children.checklistStatus ?? 'new',
      createdAt: parent.createdAt,
      document: 'properties',
      expireAt: parent.expireAt,
      urProperty: {
        checklistExpireAt: parent.checklistExpireAt,
        checklistStatus: children.checklistStatus ?? parent.checklistStatus,
        ...children,
        urPropertyDetail: {
          ...parent.urProperty.urPropertyDetail
        }
      }
    };
  else
    return children;
}

export const isNotificationValid = (element, deteleData) => {
  if (element && element.expireAt && element.expireAt.isValidDate()) {
    const isValid = new Date(element.expireAt).getTime() - new Date().getTime() > 0;

    if (isValid) return true;

    // TODO: If you want to remove from Cloudant, UNCOMMENT
    // deleteNotification(element, deteleData);
  } else {
    // TODO: If you want to remove from Cloudant, UNCOMMENT
    // deleteNotification(element, deteleData);
  }

  return false;
};

export const isPublished = (element, properties, deteleData) => {
  let published = false;

  if (element.ticketId || element.propertyId.includes('P')) {
    published = true
  } else {
    let property = {};
    if (property = properties.filter(prop => prop.propertyId === element.propertyId)[0]) {
      published = property.baja === "false" && property.vendida === "false" && property.publicable === "true";
    } else {
      published = true;
    }
  }

  return published;
};

export const getDeadlineDate = deadline => {
  let year = deadline.substr(0, 4);
  let month = deadline.substr(4, 2) - 1;
  let day = deadline.substr(6, 2);

  return new Date(year, month, day);
}

export const isChecklistExpired = (element, deteleData) => {
  let notExpired = false;

  if (element.ticketId && element.document === 'notifications') {
	notExpired = true;
  } else {
    if (element && element.checklistStatus === 'new' && element.checklistAssignedAt) {
      let checklistAssignedAt = new Date(element.checklistAssignedAt);

      checklistAssignedAt.setMonth(checklistAssignedAt.getMonth() + 3);
      notExpired = new Date() <= checklistAssignedAt;

      // TODO: If you want to remove from Cloudant, UNCOMMENT
      // deleteNotification(element, deteleData);
    } else if (element.checklistCreatedAt) {
      let checklistCreatedAt = new Date(element.checklistCreatedAt);

      checklistCreatedAt.setMonth(checklistCreatedAt.getMonth() + 3);
      notExpired = new Date() <= checklistCreatedAt;
    }
  }

  return notExpired;
};

export const isChecklistCreated = (dataInProgress, element, deteleData) => {
  let elementExistsDate;
  let created = false;

  const elementExists = (element.checklistStatus === 'new')
    ? existsInProgress(dataInProgress, element)
    : false;

  if (elementExists) {
    if (!element.ticketId) {
      elementExistsDate = new Date(elementExists.checklistCreatedAt);
      elementExistsDate.setFullYear(elementExistsDate.getFullYear() + 1);
    }
  };

  if (!elementExists || new Date() >= elementExistsDate) {
    created = true;
    // TODO: If you want to remove from Cloudant, UNCOMMENT
    // } else {
    //   deleteNotification(element, deteleData);
  }

  return created;
};

const existsInProgress = (dataInProgress, element) => {
  let exists = false;

  if (element.ticketId) {
    exists = dataInProgress.find(data => data.propertyId === element.propertyId && data.ticketId === element.ticketId) ? true : false
  } else {
    exists = dataInProgress.find(data => data.propertyId === element.propertyId) ? true : false
  }

  return exists;
};

/**
 * Method eliminate notifications which has the same "propertyId"
 * @param {object} element 
 * @param {object} index 
 * @param {array} self 
 * @returns 
 */
export const isChecklistDuplicated = (element, index, self) => {
  let duplicated = false;

  if (!element.ticketId) {
    duplicated = index === self.findIndex((t) => (
      t.propertyId === element.propertyId
    ))
  } else {
    duplicated = index === self.findIndex((t) => (
      t.propertyId === element.propertyId &&
      t.ticketId === element.ticketId
    ))
  }

  return duplicated;
};