import React, { useEffect, useContext, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { withForm } from '../../core';

import { SendPicsFormProvider } from '../contexts';
import { sendPicsInitFormDataEffect } from '../effects';
import { PropertiesContext } from '../../properties/contexts';
import { CheckListsWizardComponent } from './send-pics-wizard.component';
import {
  sendPicsHandleChangeCallback,
  sendPicsHandleSubmit,
} from '../callbacks';
import { SnackBarContext } from '../../ui/snack-bar';
import FetchApi from '../../core/fetch-api';

export const SendPics = withForm({})(({ form, setForm }) => {
  const history = useHistory();
  const { propertyId, property, tableValues } = useContext(PropertiesContext);
  const {
    actions: { open: openSnackBar },
  } = useContext(SnackBarContext);

  useEffect(() => {
    window.scrollTo(0, 0)
 }, [])
 
  useEffect(
    sendPicsInitFormDataEffect(
      form,
      setForm,
      propertyId || property.propertyId,
      property
    ),
    [setForm, propertyId, property]
  );

  const onSubmit = useCallback(
    sendPicsHandleSubmit(openSnackBar, form, history, FetchApi),
    [form]
  );
  const handleSendPicsChange = useCallback(
    sendPicsHandleChangeCallback(setForm, form),
    [form, setForm]
  );

  return (
    <SendPicsFormProvider
      value={{
        onSubmit,
        selectValues: tableValues,
        formValue: form,
        handleSendPicsChange,
      }}
    >
      <CheckListsWizardComponent />
    </SendPicsFormProvider>
  );
});
