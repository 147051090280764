import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Divider,
  Typography,
  withStyles,
} from '@material-ui/core';
import { EmojiEventsOutlined } from '@material-ui/icons';
import { controlPanelFields } from './constants';
import { renderBox } from './box';
import styles from './styles';
import { useSelector } from "../../../core/hooks/use-selector";
import { useConnectionStatus } from '../../../core/connection-status';

const ControlPanel = ({ classes }) => {
  const { online } = useConnectionStatus();
  const history = useHistory();
  const state = useSelector((s) => s.kpiData);
  const data = controlPanelFields(state).data;

  return (
    <>
      {online ?
        <header className={classes.header}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title} variant='button'>
              Seguimiento KPIs
            </Typography>
            <Typography variant='button'>
              {data.date}
            </Typography>
          </div>

          <Divider className={classes.divider} />

          <div className={classes.positionContainer}>
            <EmojiEventsOutlined className={classes.positionEmoji} />
            <div>
              <Typography variant='subtitle1' className={classes.positionTitle}>
                Posición Ranking
              </Typography>
              <Typography variant='body2' className={classes.positionRanking}>
                {data.ranking}{parseInt(data.ranking) === 1 ? 'ª' : 'º'} Posición
              </Typography>
            </div>
          </div>
        </header>
        : ''}

      {online ?
        <main className={classes.main}>
          {data.boxes.map((fields) => {
            return renderBox(fields, history, classes);
          })}
        </main>
        : <div className={classes.offlineContainer}>
          <Typography
            className={classes.title}
            variant="h5"
            align="center"
          >
            Esta operación solo funciona con conexión
          </Typography>
        </div>}
    </>
  );
};

export default withStyles(styles)(ControlPanel);
