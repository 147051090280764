import React, { useContext, useEffect, useState } from 'react';

import MoreVertIcon from 'mdi-react/MoreVertIcon';
import Container from '@material-ui/core/Container';
import AddIcon from '@material-ui/icons/Add';

import { useHistory } from 'react-router-dom';

import { PouchDBContext } from '../../../core/pouch-db/contexts';
import {
  updateDetailActivitiesEffect,
  detailActivitiesEffect,
} from '../effects';

import { List } from '../../list';
import { BoxContent } from '../../box-content';
import { CONFIG, DEFAULT_INFO, ACTIVITIES_INFO } from '../constants';
import FetchApi from '../../../core/fetch-api';

export const DetailContentComponent = ({ model, list, isClickable, url, localData }) => {
  const history = useHistory();
  const [activities, setActivities] = useState();
  const { getData, isSyncing } = useContext(PouchDBContext);

  useEffect(updateDetailActivitiesEffect(model.managementId, model.clientId, FetchApi), []);
  useEffect(detailActivitiesEffect(getData, false, model, setActivities), []);
  useEffect(detailActivitiesEffect(getData, isSyncing, model, setActivities), [
    getData,
    model,
    setActivities,
    isSyncing,
  ]);

  const goToAddActivity = () => {
    history.push(`${url}/activities/add`);
  };

  const goToPropertyView = (element) => {
    history.push(`${url}/properties/${element.propertyId || model.propertyId}`);
  };

  const title = model.typeText || 'Promoción Comercial';
  const location = window.location.hash;

  return (
    <Container style={{ paddingBottom: '16px' }}>
      <BoxContent
        dataCy="viewDetails"
        model={model}
        localData={localData}
        title={title}
        subtitle="propertyId"
        info={DEFAULT_INFO}
        extraTitle="rentalStatus"
        onClick={isClickable ? goToPropertyView : () => ({})}
        actionIcon={isClickable ? <MoreVertIcon /> : ''}
      />
      {!location.includes('#/properties') && (
        <BoxContent
          dataCy="createAdctivity"
          title="Actividades"
          model={activities}
          info={ACTIVITIES_INFO}
          actionIcon={<AddIcon />}
          onClick={goToAddActivity}
        />
      )}
      {list && list.length ? (
        <>
          <BoxContent
            title={`Promoción ${model.promoType || ''}`}
            onlyTitle={true}
          />
          <List
            {...CONFIG}
            data={list}
            onClick={(element) => goToPropertyView(element)}
            maxHeight={'500px'}
          />
        </>
      ) : (
        ''
      )}
    </Container>
  );
};
