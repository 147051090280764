import StorageService from './storage-service';

const apiMethods = ['GET', 'POST', 'PUT', 'DELETE'];
const headers$ = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};
const headers2$ = {
  Accept: 'application/json',
};

let authentication;
let lastUpdateToken;

const getApiEndPoint = () => process.env.REACT_APP_BACK_END_ENDPOINT;

const handleApi = async (response) => {
  if (!response.ok) {
    return {
      status: response.status,
      url: response.url,
      err: response.statusText,
    };
  }

  let responseJson;
  try {
    responseJson = await response.json().then((json) => ({
      status: response.status,
      results: json,
    }));
  } catch {
    return response;
  }

  return responseJson;
};

const parseHttpErrors = (err) => ({
  status: 500,
  results: err,
});

const getDataToRefresh = () => ({
  enrollment: authentication.enrollment,
  refreshToken: authentication.refreshToken,
  uid: window.localStorage.getItem('wuid_v2'),
});

const cleanObject = (obj) => {
  for (const propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ''
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

const getToken = async (requestHeader) => {
  let reducer = StorageService.get('reducer_v2');
  reducer = await JSON.parse(reducer);
  if (reducer && reducer.auth && reducer.auth.accessToken) {
    authentication = reducer.auth;
    requestHeader.Authorization = `Bearer ${reducer.auth.accessToken}`;
  }
};

const apiResponse = (path, parameters,openSnackBar) =>
  fetch(getApiEndPoint() + path, parameters)
    .then((response) => {
      if (response.status === 500) {
        if(openSnackBar)
         openSnackBar({
          type: 'error',
          visible: true,
          title: `Error de petición`,
          message: `No se han podido obtener los datos`,
        })
        else return parseHttpErrors(response)};

      return handleApi(response);
    })
    .catch((error) => parseHttpErrors(error));

const requestRefreshToken = async (path) => {
  if (authentication && path !== '/user/refresh' && path !== '/user/login') {
    if (
      lastUpdateToken &&
      new Date().getTime() - lastUpdateToken <
      process.env.REACT_APP_REFRESH_TIME_OUT * 1000
    ) {
      return true;
    } else {
      lastUpdateToken = new Date().getTime();
      let tokenRefreshed;
      await FetchApi.request('post', '/user/refresh', getDataToRefresh()).then(
        (res) => {
          if (res) {
            if (res.status === 401) {
              StorageService.save('reducer_v2', JSON.stringify({ auth: {} }));
              window.location.href = window.location.origin;
            }
            if (res.status === 200) tokenRefreshed = res.results;
          }
        }
      );
      if (tokenRefreshed && tokenRefreshed.userName) {
        const newAuthentication = {
          auth: { ...authentication, ...tokenRefreshed },
        };
        StorageService.save('reducer_v2', JSON.stringify(newAuthentication));
        return true;
      }
    }

    return false;
  }
};

const FetchApi = {
  refreshToken: async (header = headers$, path) => {
    try {
      await getToken(header);
      await requestRefreshToken(path);
    } catch (error) {
      console.error("");
    }
  },
  request: async (method, path, data, openSnackBar) => {
    await FetchApi.refreshToken(headers$, path);

    if (!apiMethods.includes(method.toUpperCase())) {
      return parseHttpErrors(
        `Error in method type: You used '${method}'. You must use GET, POST, PUT or DELETE`
      );
    }

    try {
      const parameters = {
        method: method.toUpperCase(),
        headers: headers$,
        body: JSON.stringify(data),
      };

      return apiResponse(path, parameters,openSnackBar);
    } catch (err) {
      return null;
    }
  },
  requestCustom: async (method, path, openSnackBar) => {
    await FetchApi.refreshToken(headers$, path);

    if (!apiMethods.includes(method.toUpperCase())) {
      return parseHttpErrors(
        `Error in method type: You used '${method}'. You must use GET, POST, PUT or DELETE`
      );
    }

    try {
      const parameters = {
        method: method.toUpperCase(),
        headers: headers$,
      };

      return apiResponse(path, parameters,openSnackBar);
    } catch (err) {
      return null
    }
  },
  sendImages: async (method, path, data) => {
    await FetchApi.refreshToken(headers2$);

    if (method.toUpperCase() !== 'POST') {
      return parseHttpErrors(
        `Error in method type: You used '${method}'. You must use POST to send images`
      );
    }

    const isAttachment = data && data.length;
    const isAttachmentAndData =
      data && data.attachments && data.attachments.length;

    const toSend = new FormData();
    const prepareAttachments = (attachs) => {
      attachs.forEach((file) => {
        const imageType =
          file.name && file.name.split('.')[1]
            ? file.name.split('.')[1].toLowerCase()
            : 'png';
        const img = new File([file.data], file.name, {
          type: `image/${imageType}`,
        });
        toSend.append('attachment', img, file.name);
      });
    };

    if (isAttachment) {
      prepareAttachments(data);
    } else {
      if (isAttachmentAndData) {
        prepareAttachments(data.attachments);
      }

      delete data.attachments;
      const entries = Object.entries(data);
      entries.forEach((x) => toSend.append(x[0], x[1]));
    }

    try {
      const parameters = {
        method: method.toUpperCase(),
        headers: headers2$,
        body: toSend,
      };

      return apiResponse(path, parameters);
    } catch (err) {
      return null;
    }
  },
  search: async (method, path, data) => {
    await FetchApi.refreshToken(headers2$);

    if (method.toUpperCase() !== 'GET') {
      return parseHttpErrors(
        `Error in method type: You used '${method}'. You must use GET to search`
      );
    }

    data = cleanObject(data);
    const params = new URLSearchParams(data).toString();
    const pathAndParams = `${path}?${params}`;

    try {
      const parameters = {
        method: method.toUpperCase(),
        headers: headers2$,
      };

      return apiResponse(pathAndParams, parameters);
    } catch (err) {
      return null;
    }
  },
};

export default FetchApi;
