import React from 'react';
import { withForm } from '../../../core';
import { WizardView } from '../../../shared/wizard';
import { useSendPicsForm } from '../../hooks/send-pics-form';
import { FORM_CONFIG, LIMIT } from '../../constants';
import { ImageUploader } from '../../../ui/image-uploader';

export const SendPicsStepOneFormComponent = withForm(
  {},
  false
)((formProps) => {
  const { form, handleChange, handleSubmit } = useSendPicsForm(formProps);

  return (
    <WizardView {...FORM_CONFIG.STEP_ONE.VIEW} onNext={handleSubmit}>
      <ImageUploader form={form} onChange={handleChange} limit={LIMIT} />
    </WizardView>
  );
});
