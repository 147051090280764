const styles = (theme) => ({
  header: {
    marginBottom: '1rem',
    backgroundSize: theme.wrappers.backgroundSizeCover,
    backgroundImage: theme.detail.image,
    backgroundRepeat: 'no-repeat',
    height: '80px',
  },
  gradient: {
    height: theme.wrappers.fullWidth,
    background: theme.wrappers.gradient(
      theme.colors.black.T00,
      theme.palette.secondary.main
    ),
  },
  circleStatus: {
    borderRadius: '3rem',
    backgroundColor: 'red',
    height: '15px',
    width: '15px',
    border: '1px solid white',
  },
  circleStatusGreen: {
    backgroundColor: '#4CAF50',
  },
  circleStatusYellow: {
    backgroundColor: '#FF9800',
  },
  circleStatusRed: {
    backgroundColor: '#C62828',
  },
  statusWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  statusItem: {
    margin: '0 .25rem',
    fontSize: '18px'
  },
  titleWrapper: {
    padding: '10px',
    display: theme.wrappers.display.flex,
  },
  icon: {
    color: theme.colors.white.main,
    fontSize: '2.5rem !important',
  },
  status: {
    justifySelf: 'center',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  back: {
    marginRight: theme.spacing(-6) + 'px !important',
    justifySelf: 'self-start',
  },
  title: {
    flex: 1,
    color: theme.colors.white.main,
    padding: theme.spacing(0, 5),
  },
  objectiveBox: {
    color: 'white',
    borderRadius: '3px',
    height: '20px'
  },
  objectiveGreen: {
    backgroundColor: '#4CAF50',
  },
  objectiveYellow: {
    backgroundColor: '#FF9800',
  },
  objectiveRed: {
    backgroundColor: '#C62828',
  }
});

export default styles;
