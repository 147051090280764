import React, { useState } from 'react';

import { ListDetail } from '../../elements';
import { INCIDENCES_CONFIRM } from '../constants';
import { IncidencesFormButtons } from './incidences-form-buttons.component';

const AttachmentsDisplayer = (props) => {
  const [imagesToDisplay, setImagesToDisplay] = useState([]);

  const setImages = async (attachments) => {
    const images = Object.values(attachments).map(async (attachment) => {
      const imageData = await getImage(attachment.data);
      return { src: imageData, name: attachment.data.name };
    });
    const resolvedImages = await Promise.all(images);
    setImagesToDisplay(resolvedImages);
  };

  const getImage = (file) => {
    return new Promise((resolve, reject) => {
      let result = '';
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        result = e.target.result;
        resolve(result);
      };
      reader.onerror = (e) => {
        reject(e);
      };
    });
  };

  if (
    imagesToDisplay.length === 0 &&
    Object.keys(props.state.formValues._attachments).length > 0
  ) {
    setImages(props.state.formValues._attachments);
  }

  return (
    <>
      <span
        style={{
          display: 'grid',
          marginTop: '-5px',
          width: '70%',
        }}
      >
        <small
          style={{
            color: 'rgba(0,0,0, .6)',
            fontWeight: '500',
            fontSize: '12px',
            height: '12px',
          }}
        >
          Fotos
        </small>
        <span style={{ fontSize: '16px' }}>
          {imagesToDisplay.length} Adjuntos
        </span>
      </span>
      <div style={{ marginTop: '6px' }}>
        {imagesToDisplay.length > 0 &&
          imagesToDisplay.map((image, i) => {
            return (
              <img
                key={i}
                src={image.src}
                alt="error"
                style={{
                  height: '80px',
                  width: '80px',
                  padding: '4px 4px 4px 0',
                }}
              />
            );
          })}
      </div>
    </>
  );
};

export const IncidencesConfirmForm = (props) => {
  let tableValues = [];
  const typeChildrenOptions = props.state.types.find(
    (element) => element.value === props.state.formValues['type']
  ).children;
  if (props.state.formValues['type'] === '01') {
    const subtypeChildrenOptions = typeChildrenOptions.find(
      (element) => element.value === props.state.formValues['keyType']
    ).children;
    tableValues = [
      {
        type: 'incidence-types',
        data: props.state.types,
      },
      {
        type: 'incidence-key-types',
        data: typeChildrenOptions,
      },
      {
        type: 'incidence-key-issues',
        data: subtypeChildrenOptions,
      },
      {
        type: 'incidence-can-access',
        data: [
          { value: 'true', label: 'Sí' },
          { value: 'false', label: 'No' },
        ],
      },
    ];
  } else {
    tableValues = [
      {
        type: 'incidence-types',
        data: props.state.types,
      },
      {
        type: 'incidence-typologies',
        data: typeChildrenOptions,
      },
    ];
  }
  return (
    <>
      <div
        style={{
          minHeight: '85%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <div style={{ flexGrow: 1, flexShrink: 0 }}>
          <ListDetail
            {...props}
            config={INCIDENCES_CONFIRM}
            model={props.state.formValues}
            tableValues={tableValues}
          />
          {props.state.formValues._attachments && (
            <AttachmentsDisplayer {...props} />
          )}
        </div>
        <div style={{ flexShrink: 0 }}>
          <IncidencesFormButtons {...props} />
        </div>
      </div>
    </>
  );
};
