export const INCIDENCES_CONFIG = [
  {
    type: '01',
    steps: [
      {
        component: 'type',
      },
      {
        component: 'contact',
        fields: [
          { key: 'phone', required: true },
          { key: 'email', required: false },
          { key: 'description', required: false },
        ],
      },
      {
        component: 'confirm',
      },
    ],
  },
  {
    type: '02',
    steps: [
      {
        component: 'type',
      },
      {
        component: 'attach',
      },
      {
        component: 'contact',
        fields: [
          { key: 'phone', required: false },
          { key: 'email', required: false },
          { key: 'description', required: false },
        ],
      },
      {
        component: 'confirm',
      },
    ],
  },
  {
    type: '03',
    steps: [
      {
        component: 'type',
      },
      {
        component: 'attach',
      },
      {
        component: 'contact',
        fields: [
          { key: 'phone', required: false },
          { key: 'email', required: false },
          { key: 'description', required: false },
        ],
      },
      {
        component: 'confirm',
      },
    ],
  },
  {
    type: '04',
    steps: [
      {
        component: 'type',
      },
      {
        component: 'attach',
      },
      {
        component: 'contact',
        fields: [
          { key: 'phone', required: false },
          { key: 'email', required: false },
          { key: 'description', required: false },
        ],
      },
      {
        component: 'confirm',
      },
    ],
  },
  {
    type: '05',
    steps: [
      {
        component: 'type',
      },
      {
        component: 'attach',
      },
      {
        component: 'contact',
        fields: [
          { key: 'phone', required: false },
          { key: 'email', required: false },
          { key: 'description', required: false },
        ],
      },
      {
        component: 'confirm',
      },
    ],
  },
  {
    type: '06',
    steps: [
      {
        component: 'type',
      },
      {
        component: 'attach',
      },
      {
        component: 'contact',
        fields: [
          { key: 'phone', required: false },
          { key: 'email', required: false },
          { key: 'description', required: false },
        ],
      },
      {
        component: 'confirm',
      },
    ],
  },
  {
    type: '07',
    steps: [
      {
        component: 'type',
      },
      {
        component: 'attach',
      },
      {
        component: 'contact',
        fields: [
          { key: 'phone', required: false },
          { key: 'email', required: false },
          { key: 'description', required: false },
        ],
      },
      {
        component: 'confirm',
      },
    ],
  },
  {
    type: '08',
    steps: [
      {
        component: 'type',
      },
      {
        component: 'contact',
        fields: [
          { key: 'phone', required: false },
          { key: 'email', required: false },
          { key: 'description', required: false },
        ],
      },
      {
        component: 'confirm',
      },
    ],
  },
  {
    type: '09',
    steps: [
      {
        component: 'type',
      },
      {
        component: 'contact',
        fields: [
          { key: 'phone', required: false },
          { key: 'email', required: false },
          { key: 'description', required: false },
        ],
      },
      {
        component: 'confirm',
      },
    ],
  },
];

export const INCIDENCES_CONFIRM = [
  {
    key: 'type',
    label: 'Tipo',
    type: 'select',
    selectId: 'incidence-types',
  },
  {
    key: 'typology',
    label: 'Tipología',
    type: 'select',
    selectId: 'incidence-typologies',
  },
  {
    key: 'keyType',
    label: 'Tipo de llave',
    type: 'select',
    selectId: 'incidence-key-types',
  },
  {
    key: 'keyIncidence',
    label: 'Incidencia',
    type: 'select',
    selectId: 'incidence-key-issues',
  },
  {
    key: 'canAccess',
    label: '¿Has podido acceder al inmueble?',
    type: 'select',
    selectId: 'incidence-can-access',
  },
  {
    key: 'description',
    label: 'Descripción de incidencia',
  },
  {
    key: 'name',
    label: 'Nombre de contacto',
  },
  {
    key: 'phone',
    label: 'Tel. de contacto',
  },
  {
    key: 'email',
    label: 'E-mail de contacto',
  },
];

export const FEEDBACK = {
  CREATION_PENDING: {
    visible: true,
    type: 'info',
    title: 'Incidencia pendiente de creación',
    message:
      'Se ha enviado correctamente la incidencia y está pendiente de confirmación',
  },
};

export const IMAGE_UPLOADER_CONFIG = {
  TYPES: ['jpg', 'jpeg', 'png'],
  MAX_WIDTH_SIZE: 1000,
  QUANTITY: 5,
};
