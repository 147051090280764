import { NotificationType } from '../enums';
import { isNotificationValid } from '../utils';
import { NOTIFICATIONS_VALIDITY_DAYS as VALIDITY } from '../constants';

export const notificationsEffect =
  (getData, type, setNotifications, isSyncing, deleteData) => () => {
    if (!isSyncing) {
      if (
        [NotificationType.Service, NotificationType.InactivtyService].includes(
          type
        )
      ) {
        return getData({ document: 'managements' }).then((res) => {
          let data = res.docs;
          data = data
            .map((element) => {
              element.isRead = true;

              return element;
            })
            .filter((element) => element.status === 'new')
            .filter((element) =>
              type === NotificationType.Service
                ? new Date() <
                  element.transferAt.addDays(VALIDITY.SERVICE.EXPIRE_AT)
                : new Date() >
                    element.transferAt.addDays(
                      VALIDITY.INACTIVITY_SERVICE.VALID_AT
                    ) &&
                  new Date() <
                    element.transferAt.addDays(
                      VALIDITY.INACTIVITY_SERVICE.EXPIRE_AT
                    )
            );

          if (data) setNotifications(data);
        });
      } else {
        return getData({ document: 'notifications' }).then(async (res) => {
          let data = res.docs;
          data = data.filter(
            (element) =>
              element.type === type && isNotificationValid(element, deleteData)
          );
          data = data
            .filter((element) => element.type === type)
            .map((element) => {
              element.typeText =
                NotificationType.Campaign === type ? 'Campaña' : 'Servicio';

              return element;
            });

          if (type === NotificationType.Property) {
            data = data.map((notification) => {
              if (
                notification &&
                notification.urProperty &&
                notification.urProperty.urPropertyDetail
              ) {
                const result = {
                  ...notification.urProperty.urPropertyDetail,
                  ...notification.urProperty,
                  ...notification,
                };
                delete result.metadata;
                delete result.recordedDomainEvents;
                delete result.urProperty;
                delete result.urPropertyDetail;
                return result;
              }

              return notification;
            })
            .filter(notification => !notification.ticketId)
            .filter((element, index, self) => index === self.findIndex((t) => (
              t.propertyId === element.propertyId &&
              t.ticketId === element.ticketId
            )));
          }

          if (data) setNotifications(data);
        });
      }
    }
  };
