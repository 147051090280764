import React from 'react';
import { useLocation } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  MenuItem,
  ListItemIcon,
  Typography,
  Collapse,
} from '@material-ui/core';

import { ListTypeIconComponent } from '../../../elements';

export const Menu = (props) => {
  const hasChildren = (element) => element.children && element.children.length;
  const ChildrenListItem = withStyles((theme) => ({
    container: { paddingLeft: theme.spacing(5) },
  }))(MenuItem);
  const { pathname, search } = useLocation();

  return (
    <List component="div">
      {props.menu.map((element, i) => {
        const key = hasChildren(element) ? i : `${i}-${props.parentIndex}`;
        const Item = hasChildren(element) ? ChildrenListItem : MenuItem;

        return (
          <span key={key}>
            {!hasChildren(element) ? (
              <Item
                selected={
                  element.search
                    ? pathname === element.path &&
                      search.split('?')[1] === element.search
                    : element.path &&
                      pathname.split('/')[1] === element.path.split('/')[1]
                }
                button
                onClick={() =>
                  props.handleNavigateTo(
                    element.path,
                    key,
                    element.search,
                    element.offlineMsg
                  )
                }
              >
                <ListItemIcon>
                  <ListTypeIconComponent type={element.icon} />
                </ListItemIcon>

                <Typography>{element.label}</Typography>
              </Item>
            ) : (
              <ListItem style={{ marginTop: '7px' }}>
                <ListItemIcon>
                  <ListTypeIconComponent type={element.icon} />
                </ListItemIcon>
                {element.label}
              </ListItem>
            )}

            {hasChildren(element) && (
              <Collapse in={true} timeout="auto" unmountOnExit>
                <Menu
                  style={{ background: '#eee' }}
                  {...props}
                  menu={element.children}
                  parentIndex={key}
                />
              </Collapse>
            )}
          </span>
        );
      })}
    </List>
  );
};
