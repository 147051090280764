import { RentalStatus } from '../enums';

const detailPropertyEffect =
  (getData, isSyncing, model,setLocalModel,setLocalData, testJest) => () => {
    if (!isSyncing) {
      let toRequest = { document: 'properties', propertyId: model.propertyId };
      if (model.promoId) toRequest = { ...toRequest, promoId: model.promoId };
      return getData(toRequest).then((res) => {
        let data = res.docs;
        if (data && data.length) {
          model.price =
            RentalStatus.Sale === model.rentalStatus
              ? data[0].salePrice
              : data[0].rentPrice;
          if (setLocalModel) setLocalModel(model);
          if (testJest && res) testJest(res.docs[0]);
          if (setLocalData) setLocalData(data[0]);
        }
      });
    }
  };

const updateDetailPropertyEffect =
  (model, fetchApi, doNotCallUpdateProperty) => () => {
    // TODO remove fetch Api when it is not necessary to use endpoint to update SAP
    if (!doNotCallUpdateProperty)
      fetchApi.request('get', `/urproperty/${model.propertyId}`);
  };

export { detailPropertyEffect, updateDetailPropertyEffect };
