import { RentalStatus } from '../../shared/detail/enums';

const promoProperties =
  (getData, isSyncing, promoId, rentalStatus, setPromos) => () => {
    if (!isSyncing) {
      return getData({ document: 'properties', promoId: promoId }).then(
        (res) => {
          let data = res.docs;
          data = data
            .filter((element) => !!element && !element._deleted)
            .map((element) => {
              element.price =
                RentalStatus.Sale === rentalStatus
                  ? element.salePrice
                  : element.rentPrice;

              return element;
            });

          setPromos(data);
        }
      );
    }
  };

const updatePromoProperty = (promoId, fetchApi) => () => {
  // TODO remove fetch Api when it is not necessary to use endpoint to update SAP
  fetchApi.request('get', `/urproperty/promotion/${promoId}`);
};

export { promoProperties, updatePromoProperty };
