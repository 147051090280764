export const getProvincesEffect = (fetchApi, setProvinceValues) => {
  fetchApi.request('get', '/location/province').then((res) => {
    if (res && res.results) setProvinceValues(res.results);
  });
};

export const getLocalitiesByProvince = (fetchApi, setPopulationValues, selectedProvince) => {
  if (selectedProvince) {
    return fetchApi
      .request('get', `/location/population/${selectedProvince}`)
      .then((res) => {
        setPopulationValues(res.results.sort((a, b) => a.label.localeCompare(b.label, 'es')));
      });
  }
};