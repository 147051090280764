import React, { useState, useEffect } from 'react';
import { renderElementForm } from '../../utils';

const OptionalA = props => {
    const { currentValues, inputs, functions, errors } = props;

    const [inputsHTML, setInputsHTML] = useState(null)

    useEffect(() => {
        return () => {
            inputs(functions.getValues).forEach(e => {
                functions.unregister(e.name);
            })
        }
    }, []);

    useEffect(() => {
        setInputsHTML(inputs(functions.getValues).map(e => {
            return renderElementForm(functions, e, currentValues, errors)
        }));
    }, [currentValues, errors]);

    return (
        <div style={{marginBottom: '16px'}}>{inputsHTML}</div>
    )
}

export default OptionalA;