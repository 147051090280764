import React from 'react';

import { FormItem, TextAreaInput, DescriptionCard } from '../../../elements';

export const PropertyDescriptionForm = (props) => {
  return (
    <DescriptionCard title="Descripción" border={props.border}>
      <FormItem>
        <TextAreaInput
          id="text-description"
          label="Descripción"
          state={props.state}
          value={props.state['description']}
          onChange={props.handleChange('description')}
          variant="filled"
          inputProps={{ maxLength: 80 }}
        />
      </FormItem>
    </DescriptionCard>
  );
};
