import React, { useEffect, useReducer } from 'react';

import { tableValuesReducer } from './reducers';
import { TableValuesProvider } from './contexts';
import { tableValuesPropertyFilter } from './effects';
import { tableValuesEffect } from './effects/table-values.effect';
import FetchApi from '../fetch-api';

export const TableValues = ({ children }) => {
  const [tableValues, setTableValues] = useReducer(tableValuesReducer, {});

  useEffect(tableValuesPropertyFilter(FetchApi, setTableValues), []);
  useEffect(tableValuesEffect(setTableValues), []);

  return (
    <TableValuesProvider value={tableValues}>{children}</TableValuesProvider>
  );
};
