import React from "react";
import { useSelector } from "../../../../../core/hooks/use-selector";
import { renderOptions } from "../../../../../shared/checklists-wizard/constants/summary/utils";
import { TRANSLATIONS_ITEMS_PROMOTION as propertyTranslations } from "../../../../../shared/checklists-wizard/constants/summary/propertySummaryItem/constants";

const PromotionSummaryItem = () => {
    const { screenGeneral } = useSelector((s) => s.promotionData);

    return <>{renderOptions(screenGeneral, propertyTranslations)}</>;
};

export default PromotionSummaryItem;