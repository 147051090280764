export const getDataFromView = (view, state, onClickBack) => {
  switch (view) {
    case 'rhythm_operative':
      return {
        title: 'Ritmo operativo',
        color: state.ritmoPromedio,
        data: [
          {
            name: 'Reporting 48h',
            header: {
              title: 'Reporting 48h',
              badge: {
                color: state.ritmoReportingKpi
              }
            },
            content: [
              { label: 'Leads traspasados', value: state.ritmoReportingNumLeadsTrasp },
              { label: 'Leads reportados 48h', value: state.ritmoReportingLeadsReport },
              { label: '% Leads reportados 48h', value: state.ritmoReportingPorcentReport + '%' },
              { label: 'KPI objetivo', value: state.ritmoReportingKpiObj + '%' },
            ]
          },
          {
            name: 'Mapis',
            header: {
              title: 'Mapis',
              badge: {
                color: state.ritmoMapisKpi
              }
            },
            content: [
              { label: '% Uso Mapis', value: state.ritmoMapisPorcent + '%' },
              { label: 'KPI objetivo', value: state.ritmoMapisKpiObj + '%' }
            ]
          },
          {
            name: 'Ilocalizados',
            header: {
              title: 'Ilocalizados',
              badge: {
                color: state.ritmoIlocalPromedio
              }
            },
            content: [
              { label: 'Leads traspasados', value: state.ritmoIlocalTrasp },
              { label: 'Leads ilocalizados', value: state.ritmoIlocalIlocal },
              { label: '% Leads ilocalizados', value: state.ritmoIlocalPorc + '%' },
              { label: 'KPI objetivo', value: state.ritmoIlocalKpiObj + '%' }
            ]
          },
        ]
      };
    case 'product_knowledge':
      return {
        title: 'Conocimiento producto',
        color: state.conocimientoPromedio,
        data: [
          {
            name: 'Stock Footprint',
            header: {
              title: 'Stock Footprint',
              badge: {
                color: state.conocimientoPromedio
              }
            },
            content: [
              { label: 'Checklist nuevas realizadas', value: state.conocimientoNumChecklist },
              { label: 'Total checklist', value: state.conocimientoTotalChecklist },
              { label: 'Checklist nuevas realizadas', value: state.conocimientoPorcent + '%' },
              { label: 'KPI objetivo', value: state.conocimientoKpiObj + '%' },
            ]
          }
        ]
      };
    case 'product_impact':
      return {
        title: 'Impacto en producto',
        color: state.impactoPromedio,
        data: [
          {
            name: 'Visitas',
            header: {
              title: 'Visitas',
              badge: {
                color: state.impactoVisitasPromedio
              }
            },
            content: [
              { label: 'Leads traspasados', value: state.impactoVisitasNumLeadsTrasp },
              { label: 'Leads visitados', value: state.impactoVisitasNumLeadsVisit },
              { label: '% Leads visitados', value: state.impactoVisitasPorcentLeadsVisit + '%' },
              { label: 'KPI objetivo', value: state.impactoVisitasKpiObj + '%' },
            ]
          },
          {
            name: 'Ofertas Totales',
            header: {
              title: 'Ofertas Totales',
              badge: {
                color: state.impactoOfertasPromedio
              }
            },
            content: [
              { label: 'Leads traspasados', value: state.impactoOfertasNumLeadsTrasp },
              { label: 'Ofertas', value: state.impactoOfertasNumOfertas },
              { label: '% Leads ofertados', value: state.impactoOfertasPorcentOfertas + '%' },
              { label: 'KPI objetivo', value: state.impactoOfertasKpiObj + '%' },
            ]
          },
          {
            name: 'Ofertas aportación propia',
            header: {
              title: 'Ofertas aportación propia',
              badge: {
                color: state.impactoAportPromedio
              }
            },
            content: [
              { label: 'Nº Ofertas totales', value: state.impactoAportTotalNumLeadsTrasp },
              { label: 'Ofertas origen propio', value: state.impactoAportNumOfertasOrigen },
              { label: '% Ofertas propias/Ofertas totales', value: state.impactoAportPorcentOfertas + '%' },
              { label: 'KPI objetivo', value: state.impactoAportKpiObj + '%' },
            ]
          },
          {
            name: 'Ofertas aportación propia no residencial',
            header: {
              title: 'Ofertas aportación propia no residencial',
              badge: {
                color: state.impactoAportNoResiPromedio
              }
            },
            content: [
              { label: 'Nº Ofertas totales no resi', value: state.impactoAportNoResiNumLeads },
              { label: 'Ofertas origen propio no resi', value: state.impactoAportNoResiNumOfertas },
              { label: '% Ofertas propias/Ofertas totales no resi', value: state.impactoAportNoResiPorcentOfertas + '%' },
              { label: 'KPI objetivo', value: state.impactoAportNoResiKpiObj + '%' },
            ]
          },
        ]
      };
    case 'ranking':
      return {
        title: 'Ranking',

        data: [
          {
            name: 'ranking',
            header: {
              title: 'ranking',
            },
            content: [
              { label: 'Importe ventas €', value: state.rankingVentas + '€' },
              { label: 'Nº Operaciones venta', value: state.rankingOperaciones },
              { label: 'Nº ventas origen propio', value: state.rankingURsVendidasAPI },
              { label: 'URs vendidas origen Servihabitat', value: state.rankingURsVendidasSVH },
            ],
          },
        ],
      };

    default:
      return {};
  }
};
