import React from 'react';

import { TextField } from '@material-ui/core';

export const TextInput = (props) => {
  return (
    <TextField
      type={props.type || 'text'}
      className={props.className}
      label={props.label}
      variant={props.variant}
      value={props.value}
      onChange={props.onChange}
      margin={props.margin}
      style={{ width: '100%', ...props.style }}
      inputProps={props.inputProps}
    />
  );
};
