import { radioOptions } from "../constants";

export const propertyStateOptions = [
  { label: 'Buen estado promedio de mercado​', value: '1' },
  { label: 'Necesita reforma < 5 k€', value: '2' },
  { label: 'Necesita reforma entre 5 - 15 k€​', value: '3' },
  { label: 'Necesita reforma entre 15 - 40 k€​', value: '4' },
  { label: 'Necesita reforma > 40 k€​', value: '5' },
];

export const saleTimeOptions = [
  {
    value: '1',
    label: 'Menos de 6 meses',
  },
  {
    value: '2',
    label: 'Entre 6 y 12 meses',
  },
  {
    value: '3',
    label: 'Más de 12 meses',
  },
  {
    value: '4',
    label: 'Actuación necesaria',
  },
];

export const keyworksOptions = [
  { label: 'Sí', value: '1' },
  { label: 'No', value: '2' },
  { label: 'No requiere llave', value: '3' },
];

export const propertyTypeOptions = [
  { label: 'Residencial', value: '1' },
  { label: 'Suelo', value: '2' },
  { label: 'Terciario', value: '3' },
  { label: 'WIP- inmueble no acabado', value: '4' },
];

export const reasonToNotAccessOptions = [
  { label: 'Está ocupado​', value: '1' },
  { label: 'Incidencia de llaves​', value: '2' },
  { label: 'El inmueble es inaccesible​', value: '3' },
  { label: 'Existe riesgo a terceros​', value: '4' },
  { label: 'Ilocalizado/No identificado​​', value: '5' },
  { label: 'Inmueble Alquilado​​', value: '6' },
];

export const correctPicsOptions = [
  { label: 'Sí', value: '1' },
  { label: 'No', value: '2' },
  { label: 'Fotos insuficientes', value: '3' },
];

export const stressedPriceOptions = [
  { label: 'Normal', value: '1' },
  { label: 'Estresado', value: '2' },
];

export const wipOptions = [
  { label: '0-25%', value: '1' },
  { label: '25-50%', value: '2' },
  { label: '50-75%', value: '3' },
  { label: '75-90%', value: '4' },
  { label: '90-99%', value: '5' },
];

export const clasificationUrbanisticOptions = [
  { label: 'Rústico: No Transformable', value: '1' },
  {
    label: 'Urbanizable: gestión pte. Sin expectativa de desarrollo',
    value: '2',
  },
  {
    label: 'Urbanizable: gestión pte. Con expectativa de desarrollo​',
    value: '3',
  },
  {
    label: 'No consolidado: Urbano con actuaciones ptes. importantes​',
    value: '4',
  },
  {
    label: 'No consolidado: Urbano con actuaciones ptes. no muy importantes​',
    value: '5',
  },
  { label: 'Finalista: Urbano. Listo para edificar​', value: '6' },
];

export const mayorityUseOptions = [
  { label: 'Residencia plurifamiliar', value: '1' },
  { label: 'Residencia unifamiliar', value: '2' },
  { label: 'Residencia mixta (plurifamiliar + unifamiliar)​', value: '3' },
  { label: 'Residencia VPO', value: '4' },
  { label: 'Industrial​', value: '5' },
  { label: 'Terciario​', value: '6' },
  { label: 'Dotacional', value: '7' },
  { label: 'Rústico secano', value: '8' },
  { label: 'Rústico regadío', value: '9' },
  { label: 'Erial sin uso agrario', value: '10' },
  { label: 'Otros', value: 'other' },
];

export const trustInfoOptions = [
  { label: 'No', value: '1' },
  { label: 'Sí, por documentación aportada personal Servihabitat', value: '2' },
  {
    label: 'Sí, por documentación aportada personal municipal',
    value: '3',
  },
  {
    label: 'Sí, por otros  (indicar cual: Texto libre)',
    value: 'other',
  },
];

export const bestLocalActivityOptions = [
  { label: 'Comercio minorista', value: '1' },
  { label: 'Restauración', value: '2' },
  { label: 'Alimentación​', value: '3' },
  { label: 'Servicios de salud', value: '4' },
  { label: 'Higiene personal', value: '5' },
  { label: 'Estética', value: '6' },
  { label: 'Educación-religión', value: '7' },
  { label: 'Sector público', value: '8' },
  { label: 'Asociaciones', value: '9' },
  { label: 'Autónomo', value: '10' },
  {
    label: 'Otros',
    value: 'other',
    isCustom: true,
    inputName: 'localTypologyOther',
  },
];

export const localTypologyOptions = [
  { label: 'Local a calle pública', value: '1' },
  { label: 'Local a calle privada', value: '2' },
  { label: 'Local en galería comercial', value: '3' },
  { label: 'Local en centro comercial', value: '4' },
  { label: 'Otros', value: 'other' },
];

export const smokeScapeOptions = [
  { label: 'Sí', value: '1' },
  { label: 'No', value: '2' },
  { label: 'Muy probable', value: '3' },
  { label: 'Poco probable', value: '4' },
];

const FORM_ELEMENTS_BASE = [
  {
    name: 'keyWorks',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: '¿La llave privativa de al menos una UR representativa te ha funcionado?',
    inline: true,
    options: keyworksOptions,
    props: {},
  },
  {
    name: 'propertyTypeCL',
    type: 'dropdown',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Tipo de inmueble mayoritario',
    props: {
      fluid: true,
      selection: true,
      clearable: true,
      search: true,
      options: propertyTypeOptions,
    },
  }
];

export const FORM_ELEMENTS_GENERAL = [
  {
    name: 'createdAt',
    type: 'input',
    label: 'Fecha de visita',
    variant: 'filled',
    props: {
      type: 'date',
      readOnly: true,
    },
  },
  {
    name: 'visited',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Ha podido acceder al inmueble',
    inline: true,
    options: radioOptions,
    props: {},
  },
  ...FORM_ELEMENTS_BASE,
  {
    name: 'newPublish',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: '¿Este activo es de nueva publicación?',
    inline: true,
    options: radioOptions,
    props: {},
  }

];

export const FORM_ELEMENTS_PROMOTION = [
  {
    name: 'createdAt',
    type: 'input',
    label: 'Fecha de visita',
    variant: 'filled',
    props: {
      type: 'date',
      readOnly: true,
    },
  },
  {
    name: 'visited',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: '¿Has podido acceder a la promoción?',
    inline: true,
    options: radioOptions,
    props: {},
  },
  ...FORM_ELEMENTS_BASE,
  {
    name: 'newPublish',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: '¿La promoción es de nueva publicación?',
    inline: true,
    options: radioOptions,
    props: {},
  }

];

export const FORM_ELEMENTS_PROPERTY = [
  {
    name: 'createdAt',
    type: 'input',
    label: 'Fecha de visita',
    variant: 'filled',
    props: {
      type: 'date',
      readOnly: true,
    },
  },
  {
    name: 'visited',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Ha podido acceder al inmueble',
    inline: true,
    options: radioOptions,
    props: {},
  },
  {
    name: 'keyWorks',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: '¿La llave privativa te ha funcionado?',
    inline: true,
    options: keyworksOptions,
    props: {},
  },
  {
    name: 'propertyTypeCL',
    type: 'dropdown',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Tipo de inmueble',
    props: {
      fluid: true,
      selection: true,
      clearable: true,
      search: true,
      options: propertyTypeOptions,
    },
  },
  {
    name: 'newPublish',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: '¿Este activo es de nueva publicación?',
    inline: true,
    options: radioOptions,
    props: {},
  },
  {
    name: 'isCommercialPromotion',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: '¿Se trata de una promoción comercial?',
    inline: true,
    options: radioOptions,
    props: {},
  },
];

export const OPTIONAL_ELEMENTS_A = (getValues) => [
  {
    name: 'recommendedPrice',
    type: 'input',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Recomendación precio estado actual',
    props: {
      type: 'number',
    },
    currencyFormat: true
  },
  {
    name: 'salePriceEstimated',
    type: 'dropdown',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Tiempo de venta estimado con el precio propuesto​',
    props: {
      fluid: true,
      selection: true,
      clearable: true,
      search: true,
      options: saleTimeOptions,
    },
  },
  {
    name: 'commentPrice',
    type: 'textarea',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Comentario precio',
    props: {
      maxLength: 150,
    },
  },
  {
    name: 'urStatus',
    type: 'dropdown',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Estado del inmueble',
    props: {
      fluid: true,
      selection: true,
      clearable: true,
      search: true,
      options: propertyStateOptions,
    },
  },
  {
    name: 'CAPEXText',
    type: 'textarea',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Descripción importe CAPEX',
    props: {
      maxLength: 150,
    },
  },
];

export const OPTIONAL_ELEMENTS_B = (getValues) => [
  {
    name: 'publishedPrice',
    type: 'input',
    required: false,
    requiredText: false,
    label: 'Precio publicado',
    props: {
      type: 'number',
    },
    currencyFormat: true
  },
  {
    name: 'stressedPrice',
    type: 'radio',
    required: false,
    requiredText: false,
    label: '¿El precio publicado es normal o estresado?',
    inline: true,
    options: stressedPriceOptions,
    props: {},
  },
  {
    name: 'recommendedPrice',
    type: 'input',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Recomendación precio estado actual',
    props: {
      type: 'number',
    },
    currencyFormat: true
  },
  {
    name: 'salePriceEstimated',
    type: 'dropdown',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Tiempo de venta estimado con el precio propuesto​',
    props: {
      fluid: true,
      selection: true,
      clearable: true,
      search: true,
      options: saleTimeOptions,
    },
  },
  {
    name: 'commentPrice',
    type: 'textarea',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Comentario precio',
    props: {
      maxLength: 150,
    },
  },
  {
    name: 'urStatus',
    type: 'dropdown',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Estado del inmueble',
    props: {
      fluid: true,
      selection: true,
      clearable: true,
      search: true,
      options: propertyStateOptions,
    },
  },
  {
    name: 'CAPEXText',
    type: 'textarea',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Descripción importe CAPEX',
    props: {
      maxLength: 150,
    },
  },
  {
    name: 'correctPics',
    type: 'radio',
    required: false,
    requiredText: false,
    label: '¿Las fotos de la web son correctas?',
    info: 'En caso de no serlo, envíe las fotos desde la opción “Envío fotos” del detalle del inmueble',
    inline: true,
    options: correctPicsOptions,
    props: {},
  },
  {
    name: 'correctDescription',
    type: 'radio',
    required: false,
    requiredText: false,
    label: '¿Es correcto el descriptivo de la web?',
    info: 'En caso de no serlo, envíe la descripción propuesta desde la opción "Envío fotos" del detalle del inmueble',
    inline: true,
    options: radioOptions,
    props: {},
  },
];

export const OPTIONAL_ELEMENTS_C = (getValues) => [
  {
    name: 'reasonToNotAccess',
    type: 'dropdown',
    required: false,
    requiredText: false,
    label: 'Señala el motivo para no poder acceder',
    props: {
      fluid: true,
      selection: true,
      clearable: true,
      search: true,
      options: reasonToNotAccessOptions,
    },
  },
  {
    name: 'recommendedPrice',
    type: 'input',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Recomendación precio estado actual',
    props: {
      type: 'number',
    },
    currencyFormat: true
  },
  {
    name: 'salePriceEstimated',
    type: 'dropdown',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Tiempo de venta estimado con el precio propuesto​',
    props: {
      fluid: true,
      selection: true,
      clearable: true,
      search: true,
      options: saleTimeOptions,
    },
  },
  {
    name: 'commentPrice',
    type: 'textarea',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: 'Comentario precio',
    props: {
      maxLength: 150,
    },
  },
];

export const OPTIONAL_ELEMENTS_RESIDENCIAL = (getValues) => [
  {
    name: 'garages',
    type: 'radio',
    required: false,
    requiredText: false,
    label: 'Garaje',
    inline: true,
    options: radioOptions,
    props: {},
  },
  {
    name: 'airConditioner',
    type: 'radio',
    required: false,
    requiredText: false,
    label: 'Aire acondicionado',
    inline: true,
    options: radioOptions,
    props: {},
  },
  {
    name: 'elevator',
    type: 'radio',
    required: false,
    requiredText: false,
    label: 'Ascensor',
    inline: true,
    options: radioOptions,
    props: {},
  },
  {
    name: 'penthouseWithTerrace',
    type: 'radio',
    required: false,
    requiredText: false,
    label: 'Promoción áticos con terraza',
    inline: true,
    options: radioOptions,
    props: {},
  },
  {
    name: 'groundFloorWithGarden',
    type: 'radio',
    required: false,
    requiredText: false,
    label: 'Bajos con jardín',
    inline: true,
    options: radioOptions,
    props: {},
  },
  {
    name: 'greenAreas',
    type: 'radio',
    required: false,
    requiredText: false,
    label: 'Zonas verdes',
    inline: true,
    options: radioOptions,
    props: {},
  },
  {
    name: 'sportsAreas',
    type: 'radio',
    required: false,
    requiredText: false,
    label: 'Zonas deportivas',
    inline: true,
    options: radioOptions,
    props: {},
  },
  {
    name: 'communalSwimmingPool',
    type: 'radio',
    required: false,
    requiredText: false,
    label: 'Piscina comunitaria',
    inline: true,
    options: radioOptions,
    props: {},
  },
];

export const OPTIONAL_ELEMENTS_WIP = (getValues) => [
  {
    name: 'percentageConstruction',
    type: 'radio',
    required: true,
    requiredText: false,
    inline: false,
    rules: { required: true },
    label: '% de construcción estimado en visita',
    options: wipOptions,
    props: {},
  },
];

export const OPTIONAL_ELEMENTS_SUELO = (getValues) => [
  {
    name: 'clasificationUrbanistic',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: { required: true },
    inline: false,
    label: 'Clasificación urbanística',
    options: clasificationUrbanisticOptions,
    props: {},
  },
  {
    name: 'mayorityUse',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: {
      required: true,
    },
    inline: false,
    hasCustomOption: true,
    customOptionRules: {
      validate: {
        required: (value) => {
          if (
            getValues('mayorityUse') &&
            getValues('mayorityUse') === 'other'
          ) {
            return typeof value !== 'undefined' && value.length > 0;
          } else {
            return true;
          }
        },
      },
    },
    label: 'Uso permitido mayoritario',
    options: mayorityUseOptions,
    props: {},
  },
  {
    name: 'trustInfo',
    type: 'radio',
    required: true,
    requiredText: false,
    inline: false,
    rules: {
      required: true,
    },
    hasCustomOption: true,
    customOptionRules: {
      validate: {
        required: (value) => {
          if (getValues('trustInfo') && getValues('trustInfo') === 'other') {
            return typeof value !== 'undefined' && value.length > 0;
          } else {
            return true;
          }
        },
      },
    },
    label:
      'La información urbanística (clasificación / uso)  que reportas está contrastada',
    options: trustInfoOptions,
    props: {},
  },
];

export const OPTIONAL_ELEMENTS_TERCIARIO = (getValues) => [
  {
    name: 'bestLocalActivity',
    type: 'radio',
    required: true,
    requiredText: false,
    inline: false,
    rules: {
      required: true,
    },
    customOptionRules: {
      validate: {
        required: (value) => {
          if (
            getValues('bestLocalActivity') &&
            getValues('bestLocalActivity') === 'other'
          ) {
            return typeof value !== 'undefined' && value.length > 0;
          } else {
            return true;
          }
        },
      },
    },
    label: 'Mejor actividad para el local',
    options: bestLocalActivityOptions,
    props: {},
  },
  {
    name: 'localTypology',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: {
      required: true,
    },
    inline: false,
    hasCustomOption: true,
    customOptionRules: {
      validate: {
        required: (value) => {
          if (
            getValues('localTypology') &&
            getValues('localTypology') === 'other'
          ) {
            return typeof value !== 'undefined' && value.length > 0;
          } else {
            return true;
          }
        },
      },
    },
    label: 'Tipología del local',
    options: localTypologyOptions,
    props: {},
  },
  {
    name: 'useChange',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: { required: true },
    label: '¿Valorarías un cambio de uso?',
    inline: true,
    options: radioOptions,
    props: {},
  },
  {
    name: 'smokeScape',
    type: 'radio',
    required: true,
    requiredText: false,
    rules: { required: true },
    inline: false,
    label: '¿Tiene salida de humos?',
    options: smokeScapeOptions,
    props: {},
  },
];
