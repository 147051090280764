import React, { useState } from 'react';
import { useSelector } from '../../../../../core/hooks/use-selector';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Box, withStyles } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { pricesSummaryStyles } from './styles';
import { renderOptions } from '../../../../../shared/checklists-wizard/constants/summary/utils';
import { TRANSLATIONS_ITEMS as securityTranslations } from '../../../../../shared/checklists-wizard/constants/summary/securityMaintanceSummaryItem/constants';
import { TRANSLATIONS_ITEMS as propertyTranslations } from "../../../../../shared/checklists-wizard/constants/summary/propertySummaryItem/constants";

const PriceSummaryItem = ({ classes }) => {
    const { urs } = useSelector((s) => s.promotionData);
    const [selectedAccordion, setSelectedAccordion] = useState(null);

    return <>
        {urs
            ? Object.entries(urs).map((ur) => {
                return (
                    <Box
                        key={ur[0]}
                        className={classes.boxContainer}
                    >
                        <Accordion
                            data-testid={`accordion-${ur[0]}`}
                            expanded={selectedAccordion === ur[0]}
                            onChange={() => setSelectedAccordion(selectedAccordion === ur[0] ? '' : ur[0])}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon />
                                }
                            >
                                <div className={classes.AccordionSummaryContainer}>
                                    <Typography>
                                        UR: {ur[0]}
                                    </Typography>
                                    <Typography sx={{ fontWeight: 500 }}>
                                        {ur[1].address}
                                    </Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.AccordionDetailsContainer}>
                                    {renderOptions(ur[1], propertyTranslations)}
                                    {ur[1].visited === '1'
                                        ? (
                                            <>
                                                <Typography className={classes.securityTitle} variant='h6'>
                                                    Seguridad y mantenimiento
                                                </Typography>
                                                {renderOptions(ur[1], securityTranslations)}
                                            </>
                                        )
                                        : null}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                );
            })
            : null
        }
    </>;
};

export default withStyles(pricesSummaryStyles)(PriceSummaryItem);