import React, { useContext, useEffect } from 'react';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { withStyles } from '@material-ui/core';

import { SnackBarContext } from '../contexts';
import { SNACKBAR_ANCHOR } from '../constants';
import { snackBarStyles } from './snack-bar.styles';

export const SnackBarComponent = withStyles(snackBarStyles)(({ classes }) => {
  const {
    actions: { close },
    visible,
    type,
    message,
    title,
  } = useContext(SnackBarContext);

  useEffect(() => {
    if (visible) {
      setTimeout(() => close({ visible: false }), 8000);
    }
  });

  return (
    <Snackbar
      open={visible}
      className={classes.snackBar}
      anchorOrigin={SNACKBAR_ANCHOR}
      key={`${SNACKBAR_ANCHOR.vertical},${SNACKBAR_ANCHOR.horizontal}`}
    >
      <MuiAlert
        variant="filled"
        onClose={() => close({ visible: false })}
        severity={type}
        className={classes.alert}
      >
        <AlertTitle>{title}</AlertTitle>
        {message}
      </MuiAlert>
    </Snackbar>
  );
});
