import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';

import { Container, withStyles } from '@material-ui/core';

import { loadingStyle } from './loading.style';

export const LoadingComponent = withStyles(loadingStyle)(({ classes }) => {
  return (
    <Container style={{ padding: 100, paddingTop: 300, textAlign: 'center' }}>
      Sincronizando con la base de datos
      <LinearProgress />
    </Container>
  );
});
