import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CHECKLISTS_LIST_ITEMS } from '../../notifications/constants';
import { CHECKLISTS_LIST_FILTER } from '../constants';
import { useChecklistsContext } from '../contexts';
import { PouchDBContext } from '../../../core/pouch-db';
import { useDispatch } from '../../../core/hooks/use-selector';
import { SwipeableListComponent } from './swipeable-list.component';
import { SnackBarContext } from '../../../ui/snack-bar';
import { handleSelectItem } from '../../checklists/utils/checklist.utils'
import { useModalContext } from '../../../ui/modal/contexts';
import FetchApi from '../../../core/fetch-api';

/** Component with checklists in managed status (in-progress) and pending (new) organized through tabs  */
export const ChecklistsListComponent = ({ filters, onChangeFilters }) => {
  const history = useHistory();
  /** Get data from end point with status new or in-progress */
  const { model } = useChecklistsContext();
  const { getData } = useContext(PouchDBContext);
  const dispatch = useDispatch();
  const {
    actions: { open: openSnackBar },
  } = useContext(SnackBarContext);
  const {
    actions: { open: openModal, close: closeModal },
  } = useModalContext();

  useEffect(() => {
    window.scrollTo(0, 0);

    updateNotificationProperties(getData, FetchApi);
    updateChecklistProperties(getData, FetchApi);
  }, []);

  /** Update all properties for each notification */
  const updateNotificationProperties = async (getData, FetchApi) => {
    let notifications = await getData({ document: "notifications" }).then(async (res) => {
      return res.docs;
    });

    await Promise.all(
      notifications
        .filter((element, index, self) => index === self.findIndex((t) => (
          t.propertyId === element.propertyId
        )))
        .map(notification => {
          if (notification.propertyId.includes('P')) {
            return FetchApi.request('get', `/urproperty/promotion/${notification.propertyId}`);
          } else {
            return FetchApi.request('get', `/urproperty/${notification.propertyId}`);
          }
        })
    );
  };

  /** Update all properties for each checklist in progress */
  const updateChecklistProperties = async (getData, FetchApi) => {
    let checklists = await getData({ document: "check-list", checklistStatus: 'in-progress' }).then(async (res) => {
      return res.docs;
    });
    let properties = await getData({ document: "properties" }).then(async (res) => {
      return res.docs;
    });

    await Promise.all(
      checklists
        .filter((element, index, self) => index === self.findIndex((t) => (
          t.propertyId === element.propertyId
        )))
        .filter(checklist => {
          let exists = false;

          properties.forEach(property => {
            if (checklist.propertyId === property.propertyId) exists = true;
          });

          return !exists;
        })
        .map(checklist => {
          if (checklist.propertyId.includes('P')) {
            return FetchApi.request('get', `/urproperty/promotion/${checklist.propertyId}`);
          } else {
            return FetchApi.request('get', `/urproperty/${checklist.propertyId}`);
          }
        })
    );
  };

  /** Tabs filtered */
  return model ? (
    <SwipeableListComponent
      model={model}
      filterModel={filters}
      style={{ paddingTop: 0 }}
      onClick={(model) => handleSelectItem(model, history, dispatch, getData, openSnackBar, openModal, closeModal)}
      listConfig={CHECKLISTS_LIST_ITEMS['property']}
      onChangeFilter={onChangeFilters}
      filterConfig={CHECKLISTS_LIST_FILTER}
      isChecklist={true}
    />
  ) : (
    ''
  );
};
