import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '95%',
      },
    },
    formControlTitle: {
      margin: '0px 8px',
      float: 'left',
    },
    formControl: {
      margin: theme.spacing(1),
      width: '95%',
    },
    radioGroup: {
      margin: '0 0 0 8px',
    },
    buttonBase: {
      fontWeight: '400',
      borderRadius: 5,
      padding: '10px',
      fontSize: '14px',
      border: 'none',
    },
    buttonBaseConfirm: {
      backgroundColor: '#005442 !important'
    },
    buttonBaseCancel: {
      color: '#005442 !important'
    },
    containerTitle: {
      padding: '20px'
    },
    title: {
      margin: '0px 8px',
      float: 'left',
    },
    filterContainer: {
      float: 'right',
      margin: '0px 8px',
    },
    filterButton: {
      cursor: 'pointer'
    },
    listContent: {
      padding: '8px 8px 0'
    }
  }));