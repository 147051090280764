export const detailStyles = (theme) => ({
  detail: {
    display: theme.wrappers.display,
    flexDirection: theme.wrappers.flexDirection.column,
  },
  detailHeader: {
    marginBottom: theme.spacing(-5),
    backgroundSize: theme.wrappers.backgroundSizeCover,
    backgroundImage: theme.detail.image,
    height: theme.detail.height,
  },
  detailHeaderMin: {
    marginBottom: theme.spacing(-5),
    backgroundSize: theme.wrappers.backgroundSizeCover,
    backgroundImage: theme.detail.image,
    height: theme.spacing(13),
  },
  gradient: {
    height: theme.wrappers.fullWidth,
    background: theme.wrappers.gradient(
      theme.colors.black.T00,
      theme.palette.secondary.main
    ),
  },
  titleWrapper: {
    padding: theme.wrappers.detailTitlePadding,
    display: theme.wrappers.display.flex,
    alignItems: theme.wrappers.alignCenter,
    justifyContent: theme.wrappers.alignCenter,
  },
  icon: {
    color: theme.colors.white.main,
    fontSize: theme.wrappers.iconSize,
  },
  back: {
    marginRight: theme.spacing(-6),
  },
  title: {
    flex: 1,
    color: theme.colors.white.main,
    padding: theme.spacing(0, 5),
  },
  buttonsWrapper: {
    flex: 1,
    textAlign: theme.wrappers.alignCenter,
    alignItems: theme.wrappers.alignCenter,
    justifyContent: theme.wrappers.alignCenter,
  },
  detailContent: {
    minHeight: theme.detail.offset,
    backgroundColor: theme.palette.background,
  },
  children: {
    marginTop: theme.spacing(-4),
    display: theme.wrappers.display.flex,
    alignItems: theme.wrappers.alignCenter,
    flexDirection: theme.wrappers.flexDirection.column,
  },
  iconButton: {
    margin: theme.wrappers.detailIconButtonPadding,
    backgroundColor: theme.colors.white.T30,
    border: theme.wrappers.border(theme.colors.white.main),
  },
});
