import { RentalStatus } from '../../shared/detail/enums';

export const propertiesReducer = (state, { payload }) => {
  if (payload.property) {
    if (!Object.values(RentalStatus).includes(payload.property.rentalStatus)) {
      delete payload.property.rentalStatus;
    }
  }

  return { ...state, ...payload };
};
