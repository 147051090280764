import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { renderElementForm } from '../../utils';
import { useDispatch, useSelector } from '../../../../core/hooks/use-selector';
import { setScreenThreeData } from '../../../../store/action';
import WizardView from '../../../../shared/checklists-wizard/step-base.component'
import { FORM_ELEMENTS } from '../../../../shared/checklists-wizard/constants/environment/form-elements';

/** View screen 3 : Entorno */
const Step3Enviroment = (props) => {
  const { onChangeStepHandler, haveBack = true } = props;
  const state3 = useSelector((s) => s.screenThreeData);
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: state3 });

  const dispatch = useDispatch();
  const state = useSelector((s) => s.screenOneData);
  /** This method (react hook form) will watch specified inputs and return their values.
   *  It is useful to render input value and for determining what to render by condition*/
  const watchAllFields = watch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /** Render variables and tabs with validations*/
  const formElementsHTML = FORM_ELEMENTS.map((e) =>
    renderElementForm({ register, setValue }, e, watchAllFields, errors)
  );

  /** Next step */
  const onSubmitHandler = (data) => {
    setScreenThreeData(dispatch, data);
    onChangeStepHandler(3);
  };

  /** Back step */
  const onBackWizard = () => {
    onChangeStepHandler(state.visited === '2' ? 0 : 1);
  };
  const fields = <>{formElementsHTML}</>;
  return (
    <WizardView
      title="Entorno"
      fields={fields}
      onNext={handleSubmit(onSubmitHandler)}
      onBack={onBackWizard}
      haveBack={haveBack}
    />
  );
};

export default Step3Enviroment;
