const CORRECT_VIEW_LABEL = {
  leads: 'Listado de gestiones',
  management: 'Detalle de una gestión',
  promo: 'Detalle de una gestión de tipo promoción',
  clients: 'Detalle de un cliente',
  properties: 'Detalle de una propiedad',
  'properties-filter': 'Listado Inmuebles',
  'properties-list': 'Resultado Listado Inmuebles',
  notifications: 'Notificaciones',
  checklists: 'Checklists',
  kpis: 'Panel de control',
  'check-list': 'Formulario de revisión de inmueble',
  'send-pics': 'Envio fotos',
  add: 'Añadir actividad',
  logout: 'Cerrar sesión',
};

export const MODAL_VIEW_LABEL = {
  'add-incidence': 'Formulario de alta incidencia',
  'edit-property': 'Formulario de editar propiedad',
  'add-lead': 'Creación nueva gestión',
  'send-logs': 'Enviar logs almacenados',
};

const CORRECT_SEARCH_LABEL = {
  '?type=service': ' Nuevas gestiones',
  '?type=inactivity-service': ' Nuevas gestiones de más de 48h',
  '?type=campaign': ' Nuevos comunicados',
  '?type=property': ' Nuevas unidades registrales',
};

export const getViewFromLocation = (
  history = { location: { pathname: '' } }
) => {
  const search = history.location.search;
  const location = history.location.pathname.split('/');

  let id = '';
  let view = location.pop();

  if (history.location.pathname === '/') {
    return { view: CORRECT_VIEW_LABEL.logout };
  } else if (+view || +view.split('-')[0]) {
    id = view;
    view = location.pop();

    return { view: CORRECT_VIEW_LABEL[view], resourceId: id };
  } else {
    return {
      view: CORRECT_VIEW_LABEL[view] + (CORRECT_SEARCH_LABEL[search] || ''),
    };
  }
};
