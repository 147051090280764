import { Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from '../../../../../core/hooks/use-selector';
import Picture from '../../../Picture';
import { TRANSLATIONS_ITEMS } from '../../../../../shared/checklists-wizard/constants/summary/picturesSummaryItem/constants';
import { renderOptions } from '../../../../../shared/checklists-wizard/constants/summary/utils';
import { picturesSummaryStyles } from './picturesSummary.styles';
import { useConnectionStatus } from '../../../../../core/connection-status';

export const PicturesSummaryItem = withStyles(picturesSummaryStyles)(
  ({ classes }) => {
    const state4 = useSelector((s) => s.screenFourData);
    const state5 = useSelector((s) => s.screenFiveData);
    const { online } = useConnectionStatus();
    let imagesHTML = [];

    if (state4.view) {
      // Summary before sending
      imagesHTML = (
        <div className={classes.imagesContainer}>
          {state4.view.map((i) => (
            <Picture src={i.src} />
          ))}
        </div>
      );
    } else {
      // In-progress checklist summary
      if (state4 && typeof state4 === 'object') {
        if (state4.nonConnection || !online) {
          imagesHTML = (
            <div className={classes.imagesContainer}>
              <Typography color="textSecondary">
                Fotografías no visibles sin conexión
              </Typography>
            </div>
          );
        } else if (Object.keys(state4).length > 0) {
          imagesHTML = (
            <div className={classes.imagesContainer}>
              {Object.values(state4).map((i) => (
                <Picture
                  src={
                    typeof i === 'object'
                      ? i.src
                      : `data:image/jpeg;base64,${i}`
                  }
                />
              ))}
            </div>
          );
        }
      }
    }

    return (
      <div>
        {!Object.values(state5).every((el) => el === undefined) ? (
          <>
            <Typography variant="h6">Datos de contacto</Typography>
            {renderOptions(state5, TRANSLATIONS_ITEMS)}
          </>
        ) : null}
        {/* {imagesHTML && imagesHTML.length > 0 ? (
          <>
            <Typography className={classes.marginText} variant="h6">
              Imágenes del inmueble
            </Typography>
          </>
        ) : null} */}
        {imagesHTML}
      </div>
    );
  }
);
