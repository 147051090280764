import React, { useEffect } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { DialogContent } from '@material-ui/core';

import { IncidencesDataForm } from './incidences-form-data.component';
import { IncidencesAttachForm } from './incidences-form-attach.component';
import { IncidencesContactForm } from './incidences-form-contact.component';
import { IncidencesConfirmForm } from './incidences-form-confirm.component';
import FetchApi from '../../core/fetch-api';

export const IncidenceStep = (props) => {
  useEffect(() => {
    FetchApi.refreshToken();
  }, [props.currentStep]);

  if (!props.state.formValues['type'] && props.steps.length === 0) {
    return <IncidencesDataForm {...props} />;
  }
  switch (props.steps[props.currentStep].component) {
    case 'type':
      return <IncidencesDataForm {...props} />;
    case 'attach':
      return <IncidencesAttachForm {...props} />;
    case 'contact':
      return <IncidencesContactForm {...props} />;
    case 'confirm':
      return <IncidencesConfirmForm {...props} />;
    default:
      return <IncidencesDataForm {...props} />;
  }
};

export const IncidencesModal = (props) => (
  <DialogContent>
    <div
      style={{
        display: 'inline-flex',
        width: '100%',
      }}
    >
      <h1
        style={{
          color: '#005442',
          width: '100%',
          fontSize: '20px',
          margin: 0,
        }}
      >
        Añadir incidencia llaves
      </h1>
      <IconButton size="small" color="inherit" onClick={props.closeModal}>
        <CloseIcon />
      </IconButton>
    </div>
    <div
      style={{
        color: '#545454',
        fontSize: '16px',
        textAlign: 'center',
        marginTop: '20px',
      }}
    >
      {props.steps.length === 0
        ? 'Selecciona tipo'
        : `Paso ${props.currentStep + 1} 
                de ${props.steps.length}`}
    </div>
    <IncidenceStep {...props} />
  </DialogContent>
);
