import React, { useEffect, useState } from 'react';
import Step1Promotion from './steps/promotion';
import Step2RegistrationUnits from './steps/registrationUnits';
import Step3Price from './steps/price';
import Step4Environment from './steps/environment';
import Step5Pictures from './steps/pictures';
import Step6PicturesData from './steps/picturesData';
import Step7Summary from './steps/summary';

const PromotionStepsWizard = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentContent, setCurrentContent] = useState(null);

  useEffect(() => {
    switch (currentStep) {
      case 0:
        setCurrentContent(
          <Step1Promotion
            onChangeStepHandler={setCurrentStep}
            haveBack={false}
          />
        );
        break;
      case 1:
        setCurrentContent(
          <Step2RegistrationUnits onChangeStepHandler={setCurrentStep} />
        );
        break;
      case 2:
        setCurrentContent(
          <Step3Price onChangeStepHandler={setCurrentStep} />
        );
        break;
      case 3:
        setCurrentContent(
          <Step4Environment onChangeStepHandler={setCurrentStep} />
        );
        break;
      case 4:
        setCurrentContent(
          <Step5Pictures onChangeStepHandler={setCurrentStep} />
        );
        break;
      case 5:
        setCurrentContent(
          <Step6PicturesData onChangeStepHandler={setCurrentStep} />
        );
        break;
      case 6:
        setCurrentContent(
          <Step7Summary onChangeStepHandler={setCurrentStep} />
        );
        break;
      default:
        setCurrentContent(null);
    }
  }, [currentStep]);

  return <>{currentContent}</>;
};

export default PromotionStepsWizard;
