export const wizardStyles = (theme) => ({
  header: {
    display: theme.wrappers.display.flex,
    justifyContent: theme.wrappers.justifyContent.spaceBetween,
    alignItems: 'flex-start'
  },
  container: {
    paddingTop: theme.spacing(2),
    minHeight: theme.wrappers.fullContentHeight,
    background: theme.palette.white.main,
    display: theme.wrappers.display.flex,
    flexDirection: theme.wrappers.flexDirection.column,
  },
  containerPromotion: {
    paddingTop: theme.spacing(0),
    minHeight: theme.wrappers.fullContentHeightPromotion,
    background: theme.palette.white.main,
    display: theme.wrappers.display.flex,
    flexDirection: theme.wrappers.flexDirection.column,
  },
  title: {
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: theme.wrappers.fullWidth,
    display: theme.wrappers.display.flex,
    justifyContent: theme.wrappers.justifyContent.end,
  },
});
